// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_tableOfContents__8VxW7{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    padding-bottom: 20px;
    padding-left: 0;

    list-style: inside;

    color: #04C;
    gap: 5px;
}

.styles_heading2__8fF2w {
    margin-left: 40px;

    list-style: circle inside;
  }
  
.styles_heading3__1x3gO {
    margin-left: 80px;

    list-style: square inside;
  }`, "",{"version":3,"sources":["webpack://./src/components/form/Editor/nodes/TableOfContentNode/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;;IAE3B,oBAAoB;IACpB,eAAe;;IAEf,kBAAkB;;IAElB,WAAW;IACX,QAAQ;AACZ;;AAEA;IACI,iBAAiB;;IAEjB,yBAAyB;EAC3B;;AAEF;IACI,iBAAiB;;IAEjB,yBAAyB;EAC3B","sourcesContent":[".tableOfContents{\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n\n    padding-bottom: 20px;\n    padding-left: 0;\n\n    list-style: inside;\n\n    color: #04C;\n    gap: 5px;\n}\n\n.heading2 {\n    margin-left: 40px;\n\n    list-style: circle inside;\n  }\n  \n.heading3 {\n    margin-left: 80px;\n\n    list-style: square inside;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableOfContents": `styles_tableOfContents__8VxW7`,
	"heading2": `styles_heading2__8fF2w`,
	"heading3": `styles_heading3__1x3gO`
};
export default ___CSS_LOADER_EXPORT___;
