export const stringToEditorContent = (str) => {
  const isValidJSON = (string) => {
    try {
      const parsed = JSON.parse(string)
      /**
       * Проверка на !!parsed нужна, что бы не допустить пустые значения вроде null
       * typeof parsed !== "number" - для цифр(они считаются валидным JSONом)
       */
      return !!parsed && typeof parsed !== "number"
    } catch (e) {
      return false
    }
  }

  if (isValidJSON(str)) {
    return JSON.parse(str)
  }
  return {
    root: {
      children: [
        {
          children: str
            ? [
                {
                  detail: 0,
                  format: 0,
                  mode: "normal",
                  style: "",
                  text: str || "",
                  type: "text",
                  version: 1,
                },
              ]
            : [],
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  }
}
