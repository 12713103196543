import React from "react"

import { Navigate, Route, Routes } from "react-router-dom"
import IntelligencePage from "./Intelligence"
import TeamsPage from "./teams"
import { PermissionsPage } from "@src/components/zones/Permissions"

import { ZONE_SETTINGS_SECTIONS, settingsSectionsList } from "@utils/zones"

const RedirectToFirstSection = () => <Navigate to={settingsSectionsList[0].slug} replace />

const SettingsPage = ({ setAdditionalBreadcrumbs }) => {
  return (
    <Routes>
      <Route path="/" element={<RedirectToFirstSection />} />
      <Route
        path={`/${ZONE_SETTINGS_SECTIONS.INTELLIGENCE}`}
        element={<IntelligencePage setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />}
      />
      <Route
        path={`/${ZONE_SETTINGS_SECTIONS.PERMISSIONS}`}
        element={<PermissionsPage setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />}
      />
      <Route
        path={`/${ZONE_SETTINGS_SECTIONS.TEAMS}`}
        element={<TeamsPage setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />}
      />
    </Routes>
  )
}

export default SettingsPage
