import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"
import { DateTime } from "luxon"
// import { useUnit } from "effector-react"
// import { $tasksStore } from "@store/tasksModal"

import { Editor, stringToEditorContent, EditorPreview } from "@components/form"
import { API_ENDPOINTS } from "@constants"
// import { useTaskContext } from "../contexts/TaskContext"
// import { checkPermission } from "@store/permissionModel"

import { Box, Stack, Text, Error } from "@mos-cat/ds"
import {
  apiClient,
  ERROR_MESSAGES,
  getLocationTimeByDate,
  Spinner,
  useAppContext,
  DataListWrapper,
} from "@dit/core-frontend"
import { CommentsItem } from "./CommentItem"

const CommentsContext = createContext({
  task: {},
  commentsList: [],
  updateComment: () => {},
  createComment: () => {},
})

export const useCommentsContext = () => {
  return useContext(CommentsContext)
}

const CommentsBlockContent = ({ page, stackClassName, data, getData, isLoading }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [commentsList, setCommentsList] = useState([])
  const [error, setError] = useState("")
  const [isScrollable, setIsScrollable] = useState(false)
  const [isEditable, setIsEditable] = useState(false)

  const { showAlert } = useAppContext()
  const { hash } = useLocation()

  const getComments = async () => {
    return getData(API_ENDPOINTS.commentList, {
      epp: 1000,
      pageId: page.id,
      sortDirection: "asc",
    })
  }

  const createComment = async (newComment, replyToId = null, onSuccess) => {
    setIsSubmitting(true)
    setError("")
    try {
      await apiClient.post(API_ENDPOINTS.comment, {
        pageId: page.id,
        text: newComment,
        replyToId,
      })
      setIsEditable(false)
      getComments().finally(() => {
        setIsSubmitting(false)
      })
      onSuccess?.()
    } catch (err) {
      if (err.status === 500) {
        setError(ERROR_MESSAGES.default)
      }

      if (err?.data?.errors?.length) {
        setError(err.data.errors[0]?.message)
      }
      setIsSubmitting(false)
    }
  }

  const updateComment = useCallback(
    async (text, commentId, setIsLocalSubmitting, setIsLocalEdit) => {
      setIsLocalSubmitting(true)

      try {
        await apiClient.patch(`${API_ENDPOINTS.comment}/${commentId}`, { text })
        setCommentsList((prevCommentsList) =>
          prevCommentsList.map((prevComment) => {
            const isEdited = prevComment.id === commentId
            return {
              ...prevComment,
              text: isEdited ? text : prevComment.text,
              modifiedAt: isEdited ? getLocationTimeByDate().toISO() : prevComment.modifiedAt,
            }
          }),
        )
        setIsLocalEdit(false)
      } catch (err) {
        showAlert(err?.data?.errors?.[0]?.message || ERROR_MESSAGES.default)
      }

      setIsLocalSubmitting(false)
    },
    [showAlert],
  )

  useEffect(() => {
    if (!hash || !commentsList.length || isScrollable) {
      return
    }

    const targetElement = document.getElementById(`comment-${hash.replace("#", "")}`)
    if (targetElement) {
      targetElement.scrollIntoView({
        block: "center",
        behavior: "smooth",
      })
      setIsScrollable(true)
    }
  }, [hash, commentsList, isScrollable])

  useEffect(() => {
    if (page?.id) {
      void getComments()
    }
  }, [page?.numberOfComments])

  useEffect(() => {
    setCommentsList(data?.itemsList || [])
  }, [data?.itemsList])

  const sortedCommentsList = useMemo(() => {
    return Object.values(
      commentsList.reduce((acc, comment) => {
        const isChildComment = !!comment.replyTo
        const mainCommentId = isChildComment ? comment.replyTo.id : comment.id

        let mainComment = acc[mainCommentId]

        if (!mainComment) {
          mainComment = isChildComment
            ? { ...comment.replyTo, reply: [] }
            : { ...comment, reply: [] }
        }

        if (isChildComment) {
          mainComment.reply.push(comment)
        } else {
          mainComment = { ...mainComment, ...comment }
        }

        return {
          ...acc,
          [mainCommentId]: mainComment,
        }
      }, {}),
    ).sort((a, b) => (DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? -1 : 1))
  }, [commentsList])

  if (!page.id || (page.archive && sortedCommentsList.length === 0)) {
    return null
  }

  return (
    <CommentsContext.Provider
      value={{
        updateComment,
        createComment,
        refetchList: getComments,
        page: page,
      }}
    >
      <Stack space="30px">
        <Box
          display="flex"
          alignItems="center"
          gridGap="5px"
          className={stackClassName}
          pb="5px"
          borderBottom="1px solid"
          borderColor="gray-10"
        >
          <Text fontSize="fs-14" color="gray-90">
            Обсуждения
          </Text>
          <Text
            as={Box}
            p="5px 10px"
            borderRadius="38px"
            backgroundColor="gray-05"
            fontSize="fs-14"
            color="gray-90"
            lineHeight="14px"
          >
            {isLoading ? (
              <Spinner
                wrapper="div"
                iconSize="14px"
                styles={{ position: "static" }}
                overlayVisible={false}
              />
            ) : (
              data?.itemsList?.filter((item) => !item.deleted).length || 0
            )}
          </Text>
        </Box>
        {!page.archive && (
          <Stack space="10px" className={stackClassName}>
            {isEditable ? (
              <Editor
                content={stringToEditorContent("")}
                nameSpace="CommentEditor"
                id="CommentCreateComment"
                onCancel={() => {
                  setIsEditable(false)
                }}
                onSave={createComment}
                disabled={isSubmitting}
              />
            ) : (
              <EditorPreview
                onClick={() => {
                  setIsEditable(true)
                }}
                content={stringToEditorContent("")}
                placeholder="Добавить комментарий"
              />
            )}

            {!!error && <Error>{error}</Error>}
          </Stack>
        )}
        {isLoading ? (
          <Spinner
            space="sp-16"
            wrapper="div"
            iconSize="24px"
            style={{ position: "static" }}
            overlayVisible={false}
          />
        ) : (
          <Stack space="10px">
            {sortedCommentsList.map((comment) => {
              return <CommentsItem key={`comment-${comment.id}`} comment={comment} />
            })}
          </Stack>
        )}
      </Stack>
    </CommentsContext.Provider>
  )
}

export const CommentsBlock = (props) => (
  <DataListWrapper>
    <CommentsBlockContent {...props} />
  </DataListWrapper>
)
