import React, { useState, useEffect } from "react"
import cc from "classcat"
import ReactDOM from "react-dom"
import { CircleCrossIcon } from "@dit/core-frontend"

import { animated, useTransition } from "react-spring"

import styles from "./curtain-modal.module.css"

const ModalContent = ({ children, isOpen, hide, wrapClass, contentClass, isCloseVisible }) => {
  const [el] = useState(document.createElement("div"))

  const lockBodyScroll = () => {
    document.body.classList[isOpen ? "add" : "remove"]("fixed")
  }

  useEffect(() => {
    document.body.appendChild(el)
    if (isOpen) {
      lockBodyScroll()
    }
    return () => {
      document.body.classList.remove("fixed")
    }
  }, [])

  useEffect(() => {
    lockBodyScroll()
  }, [isOpen])

  const transitions = useTransition(isOpen, {
    from: { opacity: 0, transform: 100 },
    enter: { opacity: 1, transform: 0 },
    leave: { opacity: 0, transform: 100 },
    config: { duration: 200 },
  })

  if (!el) {
    return null
  }

  return ReactDOM.createPortal(
    <div>
      {transitions(({ opacity, transform }, item) => {
        return (
          item && (
            <animated.div className={styles.root}>
              <animated.div
                style={{ opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }) }}
                className={styles.backdrop}
                onClick={hide}
              />
              <div className={cc([styles.modalWrap, wrapClass])}>
                <animated.div
                  style={{ transform: transform.to((x) => `translateX(${x}%)`) }}
                  className={styles.modal}
                >
                  {isCloseVisible && (
                    <span className={styles.close} onClick={hide}>
                      <CircleCrossIcon size={18} color="var(--gray-90)" />
                    </span>
                  )}
                  <div className={cc([styles.content, contentClass])}>{children}</div>
                </animated.div>
              </div>
            </animated.div>
          )
        )
      })}
    </div>,
    el,
  )
}

export default ModalContent
