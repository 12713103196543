import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"

import {
  CustomGetUsersOptionLabel,
  CustomUsersTarget,
  FilterDatePicker,
  FilterItem,
  FiltersBox,
  MainContainer,
  PageBlockContent,
  PageBlockHeading,
  PageBlockRow,
  PageBlockRowSection,
  PageBlockWrapper,
  Search,
  getUrlSearchParams,
  useStorageVariable,
} from "@dit/core-frontend"

import { Box } from "@mos-cat/ds"
import { PagesVirtualTable } from "@src/components/pages"
import usePagesList from "@src/components/pages/usePagesList"
import { API_ENDPOINTS, STORE_VARIABLES } from "@src/constants"
import HubLink from "@components/general/HubLink"
import { ViewSwitcher } from "@components/ViewSwitcher"
import { Helmet } from "react-helmet"

const filtersList = [
  {
    title: "Раздел:",
    endPoint: API_ENDPOINTS.zoneList,
    key: "zones",
    defaultText: "Все",
    component: FilterItem,
  },
  {
    title: "Автор",
    endPoint: API_ENDPOINTS.users,
    key: "users",
    customValue: "firstName",
    defaultText: "Все",
    customGetOptionLabel: (option) => CustomGetUsersOptionLabel(option),
    customTarget: ({ value, title, defaultText, isOpen, setIsOpen, handleCleanFilter }) => (
      <CustomUsersTarget
        value={value}
        title={title}
        defaultText={defaultText}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleCleanFilter={handleCleanFilter}
      />
    ),
    component: FilterItem,
  },
  {
    title: "Дата создания",
    key: "date",
    defaultText: "Не выбрано",
    component: FilterDatePicker,
  },
  {
    title: "Статус:",
    endPoint: API_ENDPOINTS.pageStatusList,
    key: "statuses",
    defaultText: "Все",
    component: FilterItem,
  },
  // {
  //   title: "Только отслеживаемые:",
  //   endPoint: "",
  //   key: "subscribe",
  //   defaultText: "Нет",
  //   optionsList: [{ id: "true", title: "Да", placeholder: "Да" }],
  //   isMultiValue: false,
  //   component: FilterItem,
  // },
  {
    title: "Только избранные:",
    endPoint: "",
    key: "favorites",
    defaultText: "Нет",
    optionsList: [{ id: "true", title: "Да", placeholder: "Да" }],
    isMultiValue: false,
    component: FilterItem,
  },
  {
    title: "Только архивные:",
    endPoint: "",
    key: "archive",
    defaultText: "Нет",
    optionsList: [{ id: "true", title: "Да", placeholder: "Да" }],
    isMultiValue: false,
    component: FilterItem,
  },
  // {
  //   title: "Только удаленные:",
  //   endPoint: "",
  //   key: "deleted",
  //   defaultText: "Нет",
  //   optionsList: [{ id: "true", title: "Да", placeholder: "Да" }],
  //   isMultiValue: false,
  //   component: FilterItem,
  // },
]
const AllPagesPage = () => {
  const { defaultFilterParams, onChangeParams, params, onSearch } = usePagesList()
  const [searchParams] = useSearchParams()
  const { search } = getUrlSearchParams(searchParams)
  const [projectList, setProjectList] = useState([])
  const [view, setView] = useStorageVariable(STORE_VARIABLES.pagesView)

  return (
    <>
      <Helmet title="Страницы · МосВики" />
      <MainContainer>
        <PageBlockWrapper flex="2" space="20px">
          <HubLink type="projects" items={projectList} />
          <PageBlockRow place="middle">
            <PageBlockRowSection
              gridGap={["10px", "20px"]}
              justifyContent={["space-between", "flex-start"]}
              flexWrap={["wrap", "nowrap"]}
              width={["100%", "auto"]}
            >
              <PageBlockHeading>Реестр страниц</PageBlockHeading>
              <Box width={["100%", "auto"]}>
                <Search
                  onSearch={onSearch}
                  defaultValue={search}
                  placeholder="Поиск страницы"
                  fullWidth={["100%", "300px"]}
                  width={["100%", "170px"]}
                />
              </Box>
            </PageBlockRowSection>
          </PageBlockRow>
          <PageBlockRow place="bottom">
            <PageBlockRowSection>
              <FiltersBox
                filteredList={filtersList}
                filterParams={params}
                defaultParams={defaultFilterParams}
                setFilterParams={onChangeParams}
                isResetAllFilters
                isQuery
              />
            </PageBlockRowSection>
            <ViewSwitcher value={view} onChange={setView} />
          </PageBlockRow>

          <PageBlockContent>
            <PagesVirtualTable view={view} updateVisibleProjectsList={setProjectList} />
          </PageBlockContent>
        </PageBlockWrapper>
      </MainContainer>
    </>
  )
}

export default AllPagesPage
