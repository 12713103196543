import React, { memo } from "react"
import { Link, useNavigate } from "react-router-dom"
import { DateTime } from "luxon"

import { Box, Text } from "@mos-cat/ds"
import { useZoneContext } from "@components/contexts/ZoneContext"
import { EditorPreview, stringToEditorContent } from "@components/form"
import {
  PageBlockRow,
  PageBlockRowSection,
  PageBlockContent,
  PageBlockHeading,
  // StarIcon,
  Avatar,
  getFormattedRecentDate,
  CustomGetUsersOptionLabel,
  Button,
  Favorites,
} from "@dit/core-frontend"
import TemplateActions from "./TemplateActions"

import { ROUTES, API_ENDPOINTS } from "@constants"
import { ENTITY_TYPES } from "@utils/favorites"

export const TemplatePagePreview = memo(
  ({ template, openEditTemplateCurtain, updateTemplateData }) => {
    const { zone } = useZoneContext()
    const navigate = useNavigate()

    return (
      <>
        <PageBlockRow
          place="middle"
          position="sticky"
          top="-40px"
          zIndex="10"
          alignItems="flex-start"
          backgroundColor="white"
          m={["0 -10px", "0 -20px", "0 -20px 0 -40px"]}
          p={["20px 10px", "20px", "20px 20px 20px 40px"]}
          width="auto"
          boxShadow="0 12px 12px -8px rgba(0, 20, 67, 0.12)"
        >
          <PageBlockRowSection
            overflow="hidden"
            flexDirection="column"
            alignItems="flex-start"
            gridGap="10px"
          >
            <PageBlockHeading styles={{ maxWidth: "860px" }}>
              <Text fontSize="44px" lineHeight="48px">
                {template?.pageTitle}
              </Text>
            </PageBlockHeading>
            <Box display="flex" alignItems="center" gridGap="10px">
              <Box display="flex" alignItems="center" gridGap="5px">
                <Avatar size={20} url={template.user?.photo?.url} />
                <Text fontSize="14px" color="gray-80">
                  {CustomGetUsersOptionLabel(template.user)}
                </Text>
              </Box>
              <Text fontSize="14px" color="gray-40">
                {getFormattedRecentDate(
                  DateTime.fromISO(template.modifiedAt || template.createdAt),
                )}
              </Text>
            </Box>
          </PageBlockRowSection>
          <Box display="flex" gridGap="20px" pt="10px">
            <Box
              display="flex"
              alignItems="center"
              padding="4px 10px 4px 10px"
              borderRadius="4px"
              backgroundColor="var(--warning-10)"
            >
              <Text fontSize="14px" color="var(--warning-70)">
                {template.title}
              </Text>
            </Box>
            <Box display="flex" alignItems="center" gridGap="5px">
              <Favorites
                itemId={template.id}
                favoritesId={template.favorites?.id}
                type={ENTITY_TYPES.TEMPLATE}
                requestUrl={API_ENDPOINTS.favorites}
                iconSize={21}
                p="5px !important"
                kind="gray"
                isButtonView
              />
              <PageBlockRowSection>
                <TemplateActions
                  template={template}
                  zone={zone}
                  as={Button}
                  kind="gray"
                  p="0 !important"
                  onDelete={() => {
                    navigate(`${ROUTES.ZONE}/${zone.slug}/templates`, { replace: true })
                  }}
                  openEditTemplateCurtain={openEditTemplateCurtain}
                  onUpdate={updateTemplateData}
                />
              </PageBlockRowSection>
            </Box>
            <Box>
              <Link to={`${ROUTES.ZONE}/${zone.slug}/templates`}>
                <Button kind="gray" backgroundColor="transparent !important">
                  <Text as={Box} px="16px" color="gray-60" fontSize="14px">
                    Закрыть
                  </Text>
                </Button>
              </Link>
            </Box>
          </Box>
        </PageBlockRow>
        <PageBlockContent style={{ width: "100%", maxWidth: "1200px" }}>
          <EditorPreview content={stringToEditorContent(template.content || "")} />
        </PageBlockContent>
      </>
    )
  },
)

TemplatePagePreview.displayName = "TemplatePagePreview"
