const declOfNum = (number, titles, isWithValue = false) => {
  const cases = [2, 0, 1, 1, 1, 2]
  const title =
    titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]

  return isWithValue ? `${number} ${title}` : title
}

const formattedDate = (date) => {
  const newDate = new Date(date)
  return (
    ("0" + newDate.getDate()).slice(-2) +
    "." +
    ("0" + (newDate.getMonth() + 1)).slice(-2) +
    "." +
    newDate.getFullYear()
  )
}

export { declOfNum, formattedDate }
