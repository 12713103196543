import React, { useState } from "react"
import * as yup from "yup"

import { Form, Field } from "react-final-form"
import { Box, Modal, ModalDisclosure, useModalState } from "@mos-cat/ds"
import {
  Button,
  useAppContext,
  apiClient,
  ExportIcon,
  CustomSelect,
  stylesForForm,
  ERROR_MESSAGES,
} from "@dit/core-frontend"
import EditorHtmlGenerator from "@components/form/Editor/EditorHtmlGenerator"

import { stringToEditorContent } from "@components/form"
import { API_ENDPOINTS } from "@constants"

const EXPORT_FORMATS = ["pdf", "docx"]
const EXPORT_FORMATS_OPTIONS = EXPORT_FORMATS.map((f) => ({ label: f, value: f }))

const validationSchema = yup.object().shape({
  format: yup.string().required(ERROR_MESSAGES.required),
})

export const PageExportAction = ({ page, onCloseModal, modalStateProps = {} }) => {
  const [htmlContent, setHtmlContent] = useState("")
  const modalState = useModalState()
  const fullModalState = {
    ...modalState,
    hide: () => {
      modalState.hide()
      onCloseModal?.()
    },
    ...modalStateProps,
  }
  const { showAlert } = useAppContext()

  return (
    <>
      <ModalDisclosure {...fullModalState} as={Button} kind="menu-button">
        <ExportIcon color="currentColor" size={21} />
        Экспорт страницы
      </ModalDisclosure>
      <Modal {...fullModalState} heading="Тип файла для экспорта">
        {fullModalState.visible && (
          <Form
            initialValues={{
              format: "",
            }}
            validate={(values) => {
              try {
                validationSchema.validateSync(values, { abortEarly: false })
                return {}
              } catch (error) {
                return error.inner.reduce(
                  (errors, currentError) => ({
                    ...errors,
                    [currentError.path]: currentError.message,
                  }),
                  {},
                )
              }
            }}
            onSubmit={async ({ format }) => {
              try {
                // const headHtml = document.head
                // const fullHtml = `
                //   <head>${headHtml?.innerHTML || ""}</head>
                //   <body>${htmlContent}</body>
                // `
                const response = await apiClient.post(
                  API_ENDPOINTS.export,
                  {
                    html: htmlContent,
                    format: format,
                  },
                  {
                    responseType: "blob",
                  },
                )

                const link = document.createElement("a")
                link.href = URL.createObjectURL(response.data)
                link.download = `${page.title}.${format}`
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                fullModalState.hide()
              } catch (err) {
                showAlert(err?.data?.errors?.[0]?.message || "Произошла ошибка загрузке файла")
              }

              return null
            }}
            render={({ handleSubmit, values, hasValidationErrors, submitting, form }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field name="format">
                    {() => (
                      <CustomSelect
                        type="select"
                        placeholder="Выберите тип файла для экспорта"
                        options={EXPORT_FORMATS_OPTIONS}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        value={EXPORT_FORMATS_OPTIONS.find((f) => f.value === values.format)}
                        customStyles={stylesForForm}
                        onChange={(selected) => {
                          form.change("format", selected.value)
                          form.blur("format")
                        }}
                        isSearchable={false}
                      />
                    )}
                  </Field>

                  <Box marginTop="32px" display="flex" justifyContent="start" gridGap="16px">
                    <Button
                      kind="primary"
                      padding="0.6875rem 1.9375rem !important"
                      disabled={submitting || hasValidationErrors}
                      loading={submitting}
                      onClick={handleSubmit}
                    >
                      Экспорт
                    </Button>
                    <Button
                      kind="secondary"
                      padding="0.6875rem 1.9375rem !important"
                      onClick={fullModalState.hide}
                    >
                      Отменить
                    </Button>
                  </Box>
                </form>
              )
            }}
          />
        )}
      </Modal>
      <EditorHtmlGenerator
        content={stringToEditorContent(page.text || "")}
        onGenerateHtmlString={setHtmlContent}
      />
    </>
  )
}
