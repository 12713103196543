export const grayButtonProps = {
  kind: "borderless",
  display: "flex",
  backgroundColor: "var(--gray-10) !important",
  borderRadius: "4px",
  padding: "0px !important",
  border: "0",
  minWidth: "32px",
  height: "32px",
}
