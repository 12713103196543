import React from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import {
  FilterItem,
  useQueryParams,
  CustomUsersTarget,
  CustomGetUsersOptionLabel,
  FilterDatePicker,
} from "@dit/core-frontend"

import { API_ENDPOINTS } from "@constants"

const defaultFilterParams = {
  users: [],
  createdAtFrom: "",
  createdAtTo: "",
  favorites: "",
  archive: "",
  // deleted: "",
}

const filtersList = [
  {
    title: "Автор",
    endPoint: API_ENDPOINTS.users,
    key: "users",
    customValue: "firstName",
    defaultText: "Все",
    customGetOptionLabel: (option) => CustomGetUsersOptionLabel(option),
    customTarget: ({ value, title, defaultText, isOpen, setIsOpen, handleCleanFilter }) => (
      <CustomUsersTarget
        value={value}
        title={title}
        defaultText={defaultText}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleCleanFilter={handleCleanFilter}
      />
    ),
    component: FilterItem,
  },
  {
    title: "Дата создания",
    key: "date",
    defaultText: "Не выбрано",
    component: FilterDatePicker,
  },
  {
    title: "Только избранные:",
    endPoint: "",
    key: "favorites",
    defaultText: "Нет",
    optionsList: [{ id: "true", title: "Да", placeholder: "Да" }],
    isMultiValue: false,
    component: FilterItem,
  },
  {
    title: "Только архивные:",
    endPoint: "",
    key: "archive",
    defaultText: "Нет",
    optionsList: [{ id: "true", title: "Да", placeholder: "Да" }],
    isMultiValue: false,
    component: FilterItem,
  },
]

const usePageTemplatesList = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { params, onChange: onChangeParams } = useQueryParams(defaultFilterParams)
  const onSearch = (val) => {
    const params = new URLSearchParams(searchParams)
    if (val) {
      params.set("search", (val || "").trim())
    } else {
      params.delete("search")
    }

    navigate(`${location.pathname}?${params}`)
  }

  return {
    filtersList,
    params,
    defaultFilterParams,
    onChangeParams,
    onSearch,
  }
}

export default usePageTemplatesList
