import React from "react"
import { Box, Button, Modal, ModalDisclosure, useModalState } from "@mos-cat/ds"
import ColorPickerPopover from "./ColorPickerPopover"
import { ColorPicker } from "."

const ColorPickerModal = ({ children, modalDisclosureProps, modalStateProps, color, onChange }) => {
  const modalState = useModalState()
  const getModalState = { ...modalState, ...modalStateProps }
  const isVisible = getModalState?.visible
  return (
    <>
      {children && (
        <ModalDisclosure {...modalDisclosureProps} {...getModalState}>
          {children}
        </ModalDisclosure>
      )}
      <Modal {...getModalState} heading="Выбор цвета ячейки" modalSize="small">
        {isVisible && <ColorPicker color={color} onChange={onChange} />}
      </Modal>
    </>
  )
}

export default ColorPickerModal
