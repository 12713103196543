import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { $getRoot, $getSelection, $isRangeSelection } from "lexical"
import { useOnChange } from "./useOnChange"
import { PlainTextEditor } from "./PlainTextEditor"
import { Button } from "@dit/core-frontend"
import { v4 as uuid } from "uuid"
import { createDOMRange, createRectsFromDOMRange } from "@lexical/selection"

import styles from "./styles.module.css"
import { $wrapSelectionInMarkNode } from "../../nodes/MarkNode"
import { usePageContext } from "@src/components/contexts/PageContext"
import { PAGE_STATUSES } from "@src/utils/pages"
import { useEditorCommentsContext } from "."

export const CommentInputBox = ({ editor, cancelAddComment }) => {
  const { page, statusList, updatePage } = usePageContext()

  const [content, setContent] = useState("")
  const [isCreateComment, setIsCreateComment] = useState(false)
  const { createComment, refetchList, setIsLoading } = useEditorCommentsContext()
  const [canSubmit, setCanSubmit] = useState(false)
  const boxRef = useRef(null)
  const mainContainer = document.getElementById("mainContainer")
  const selectionState = useMemo(
    () => ({
      container: document.createElement("div"),
      elements: [],
    }),
    [],
  )
  const selectionRef = useRef(null)
  const updateLocation = useCallback(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()

      if ($isRangeSelection(selection)) {
        selectionRef.current = selection.clone()
        const anchor = selection.anchor
        const focus = selection.focus
        const range = createDOMRange(
          editor,
          anchor.getNode(),
          anchor.offset,
          focus.getNode(),
          focus.offset,
        )
        const boxElem = boxRef.current
        if (range !== null && boxElem !== null) {
          const { left, bottom, top, width } = range.getBoundingClientRect()
          const selectionRects = createRectsFromDOMRange(editor, range)
          let correctedLeft = selectionRects.length === 1 ? left + width / 2 - 125 : left - 125
          if (correctedLeft < 10) {
            correctedLeft = 10
          }
          boxElem.style.left = `${correctedLeft}px`
          boxElem.style.top = `${
            bottom <= mainContainer.offsetHeight - 100
              ? bottom + 20 + (window.pageYOffset || document.documentElement.scrollTop)
              : top - 150 + (window.pageYOffset || document.documentElement.scrollTop)
          }px`

          const selectionRectsLength = selectionRects.length
          const { container } = selectionState
          const elements = selectionState.elements
          const elementsLength = elements.length

          for (let i = 0; i < selectionRectsLength; i++) {
            const selectionRect = selectionRects[i]
            let elem = elements[i]
            if (elem === undefined) {
              elem = document.createElement("span")
              elements[i] = elem
              container.appendChild(elem)
            }
            const color = "255, 212, 0"
            const style = `position:absolute;top:${
              selectionRect.top + (window.pageYOffset || document.documentElement.scrollTop)
            }px;left:${selectionRect.left}px;height:${selectionRect.height}px;width:${
              selectionRect.width
            }px;background-color:rgba(${color}, 0.3);pointer-events:none;z-index:5;`
            elem.style.cssText = style
          }
          for (let i = elementsLength - 1; i >= selectionRectsLength; i--) {
            const elem = elements[i]
            container.removeChild(elem)
            elements.pop()
          }
        }
      }
    })
  }, [editor, selectionState])

  useLayoutEffect(() => {
    updateLocation()
    const container = selectionState.container
    const body = document.body
    if (body !== null) {
      body.appendChild(container)
      return () => {
        body.removeChild(container)
      }
    }
  }, [selectionState.container, updateLocation])

  useEffect(() => {
    window.addEventListener("resize", updateLocation)

    return () => {
      window.removeEventListener("resize", updateLocation)
    }
  }, [updateLocation])

  useEffect(() => {
    document.body.style = "overflow-y: hidden"
    mainContainer.addEventListener("scroll", updateLocation)

    return () => {
      mainContainer.removeEventListener("scroll", updateLocation)
      document.body.style = ""
    }
  }, [updateLocation])

  const onEscape = (event) => {
    event.preventDefault()
    cancelAddComment()
    return true
  }

  useEffect(() => {
    let isMounted = true

    if (canSubmit && isCreateComment) {
      let quote = editor.getEditorState().read(() => {
        const selection = selectionRef.current
        return selection ? selection.getTextContent() : ""
      })
      if (quote.length > 100) {
        quote = quote.slice(0, 99) + "…"
      }
      const markId = uuid()
      editor.update(() => {
        if ($isRangeSelection(selectionRef.current)) {
          const isBackward = selectionRef.current.isBackward()
          // Wrap content in a MarkNode
          $wrapSelectionInMarkNode(selectionRef.current, isBackward, markId)
        }
      })
      const unregister = editor.registerUpdateListener(({ editorState }) => {
        if (page?.id) {
          setIsLoading(true)
          updatePage(
            {
              ...page,
              text: JSON.stringify(editorState),
              plainText: editorState.read(() => $getRoot().getTextContent()),
              version: String(Math.floor(Number(page.version) + 1)),
              status: statusList.find((status) => status.slug === PAGE_STATUSES.PUBLISH),
            },
            false,
            () => {
              createComment(content, null, markId)
              refetchList()
            },
          )
        }

        // createComment(content, null, markId)

        // submitAddComment(
        //   createThread(quote, [createComment(content, author)]),
        //   true,
        //   undefined,
        //   selectionRef.current,
        // )
      })

      selectionRef.current = null
      return () => {
        isMounted = false
        unregister()
      }
    }
  }, [editor, isCreateComment, canSubmit, page])

  // useEffect(() => {
  //   if (editorState?.text && editorState?.plainText) {
  //     submitAddComment(
  //       createThread(editorState?.quote, [createComment(content, author)]),
  //       true,
  //       undefined,
  //       selectionRef.current,
  //     )
  //     setIsCreateComment(false)
  //   }
  // }, [editor, editorState, setEditorState, submitAddComment])

  const onChange = useOnChange(setContent, setCanSubmit)

  return (
    <div className={styles.CommentPlugin_CommentInputBox} ref={boxRef}>
      <PlainTextEditor
        className={styles.CommentPlugin_CommentInputBox_Editor}
        onEscape={onEscape}
        onChange={onChange}
      />
      <div className={styles.CommentPlugin_CommentInputBox_Buttons}>
        <Button
          kind="primary"
          onClick={() => {
            setIsCreateComment(true)
          }}
          disabled={!canSubmit}
          className={styles.CommentPlugin_CommentInputBox_Button}
        >
          Добавить
        </Button>
        <Button
          kind="secondary"
          onClick={cancelAddComment}
          className={styles.CommentPlugin_CommentInputBox_Button}
        >
          Отмена
        </Button>
      </div>
    </div>
  )
}
