import React from "react"

import { MainContainer } from "@dit/core-frontend"
import ZonesBlock from "./components/ZonesBlock"
import DocumentsBlock from "./components/DocumentsBlock"
import { checkPermission } from "@src/store/permissionModel"
import { Helmet } from "react-helmet"

export const HomePage = () => {
  return (
    <>
      <Helmet title="Главная · МосВики" />
      <MainContainer>
        {checkPermission("ZONE_LIST_READ") && <ZonesBlock />}
        {checkPermission("PAGE_LIST_READ") && <DocumentsBlock />}
      </MainContainer>
    </>
  )
}
