import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"

import { Form, Field } from "react-final-form"
import { Box, Modal, ModalDisclosure, Stack, Text, useModalState } from "@mos-cat/ds"
import {
  CustomSelect,
  Spinner,
  apiClient,
  Button,
  useAppContext,
  stylesForForm,
  ERROR_MESSAGES,
} from "@dit/core-frontend"
import TemplatesList from "./TemplatesList"
import PageTemplatesCurtain from "@components/pageTemplates/PageTemplatesCurtain"

import { createNewDraftPage } from "@components/pages/utils"
import { API_ENDPOINTS, ROUTES } from "@constants"
import { ZONE_SECTION_LINKS } from "@utils/zones"

const validationSchema = yup.object().shape({
  zone: yup.object().required(ERROR_MESSAGES.required),
})

export const CreatePageModal = ({
  modalDisclosureProps,
  modalStateProps,
  defaultZone,
  withChangeZone = true,
  parentId,
  children,
  homePage,
}) => {
  const [templateCurtainConfig, setTemplateCurtainConfig] = useState({ zone: null, isOpen: false })
  const { showAlert } = useAppContext()
  const navigate = useNavigate()
  const modalState = useModalState()
  const fullModalState = { ...modalState, ...modalStateProps }
  const isVisible = fullModalState?.visible

  const fetchZones = async (search, _, { page }) => {
    try {
      const { data } = await apiClient.get(API_ENDPOINTS.zoneList, {
        params: { search: search, epp: 60, eppOffset: (page - 1) * 60 },
      })
      return {
        options: data?.data.itemsList,
        hasMore: !!data?.data?.itemsLeft,
        additional: {
          page: page + 1,
        },
      }
    } catch (err) {
      showAlert(err?.data?.errors?.[0]?.message || "Произошла ошибка при загрузке проектов")
    }
  }

  return (
    <>
      <ModalDisclosure {...modalDisclosureProps} {...fullModalState}>
        {children}
      </ModalDisclosure>

      <Modal {...fullModalState} heading={"Создание страницы"}>
        {isVisible && (
          <Form
            initialValues={{
              zone: defaultZone || null,
              template: null,
            }}
            validate={(values) => {
              try {
                validationSchema.validateSync(values, { abortEarly: false })
                return {}
              } catch (error) {
                return error.inner.reduce(
                  (errors, currentError) => ({
                    ...errors,
                    [currentError.path]: currentError.message,
                  }),
                  {},
                )
              }
            }}
            onSubmit={async ({ zone, template }) => {
              await createNewDraftPage({
                zoneId: zone.id,
                parent: parentId || null,
                homePage,
                copyPage: {
                  title: template?.pageTitle,
                  text: template?.content,
                },
                onError: (message) => {
                  showAlert(message)
                },
                onSuccess: async (page) => {
                  navigate(
                    homePage
                      ? `${ROUTES.ZONE}/${zone.slug}/${ZONE_SECTION_LINKS.HOMEPAGE}/edit`
                      : `${ROUTES.ZONE}/${zone.slug}/${ZONE_SECTION_LINKS.PAGES}/${
                          page?.slug || page?.id
                        }/edit`,
                  )
                  fullModalState.hide()
                },
              })
            }}
            render={({ handleSubmit, submitting, values, hasValidationErrors, form }) => {
              return (
                <form onSubmit={handleSubmit}>
                  {!homePage &&
                    (withChangeZone ? (
                      <Box marginBottom="1rem">
                        <Field name="zone">
                          {() => (
                            <CustomSelect
                              placeholder="Раздел"
                              type="async-paginate"
                              loadOptions={fetchZones}
                              getOptionLabel={(option) => `${option.title}(${option.slug})`}
                              getOptionValue={(option) => option.id}
                              value={values.zone}
                              isSearchable
                              customStyles={stylesForForm}
                              noOptionsMessage={() => "не найдено"}
                              loadingMessage={() => (
                                <Box position="relative" minHeight="25px">
                                  <Spinner space="sp-16" wrapper="div" />
                                </Box>
                              )}
                              onChange={(zone) => {
                                form.change("zone", zone)
                                form.change("template", null)
                                form.blur("zone")
                                form.blur("template")
                              }}
                              additional={{
                                page: 1,
                              }}
                              debounceTimeout={500}
                            />
                          )}
                        </Field>
                      </Box>
                    ) : (
                      <Stack space="8px">
                        <Text fontWeight="600">Раздел</Text>
                        <Text>{values.zone.title}</Text>
                      </Stack>
                    ))}

                  <Field name="template">
                    {() => (
                      <TemplatesList
                        zone={values.zone}
                        hideAddButton={homePage}
                        onClickOnCard={(template) => {
                          form.change("template", template)
                          form.blur("template")
                        }}
                        selectedCard={values.template}
                        openCreateTemplateCurtain={(zone) => {
                          fullModalState.hide()
                          setTemplateCurtainConfig({ zone, isOpen: true })
                        }}
                      />
                    )}
                  </Field>

                  <Box display="flex" justifyContent="start" gridGap="16px">
                    <Button
                      kind="primary"
                      padding="0.6875rem 1.9375rem !important"
                      disabled={submitting || hasValidationErrors}
                      loading={submitting}
                      onClick={handleSubmit}
                    >
                      Создать
                    </Button>
                    <Button
                      kind="secondary"
                      padding="0.6875rem 1.9375rem !important"
                      onClick={fullModalState.hide}
                    >
                      Отменить
                    </Button>
                  </Box>
                </form>
              )
            }}
          />
        )}
      </Modal>

      <PageTemplatesCurtain
        zone={templateCurtainConfig.zone}
        isOpen={templateCurtainConfig.isOpen}
        onClose={() => {
          setTemplateCurtainConfig({ zone: null, isOpen: false })
        }}
      />
    </>
  )
}
