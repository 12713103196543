import React from "react"

import { Link } from "react-router-dom"
import {
  CardWrapper,
  Subscription,
  Favorites,
  getFormattedDate,
  useAppContext,
} from "@dit/core-frontend"
import { Stack, Box, Text } from "@mos-cat/ds"

// import { declOfNum } from "@utils/general"

import { API_ENDPOINTS, ROUTES } from "@constants"
import { PageActions } from "./PageActions"
import { Person } from "../general"
import { PageStatus } from "./PageStatus"
import { getCellPropsByPageData } from "@src/utils/pages"
import { ENTITY_TYPES } from "@utils/favorites"

export const PageCard = ({ page, updateResponseData }) => {
  const { isMobileView } = useAppContext()

  return (
    <Link
      to={`${ROUTES.ZONE}/${page.zone.slug}/pages/${page.slug}`}
      style={{
        width: isMobileView ? "calc(50% - 5px)" : "unset",
      }}
    >
      <CardWrapper
        backgroundColor="#F7F5EE"
        color="gray-90"
        opacity={page.archive ? "0.4" : "1"}
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <Stack
          width={isMobileView ? "100%" : "304px"}
          height={isMobileView ? "200px" : "287px"}
          p={isMobileView ? "7.5px" : "15px"}
          space="0"
          justifyContent="space-between"
          borderRadius="8px"
        >
          <Stack position="relative" space="10px" flex="2" alignItems="flex-start">
            <Box display="flex" justifyContent="flex-end" width="100%">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                gridGap="10px"
                onClick={(e) => e.preventDefault()}
              >
                <Subscription
                  itemId={page.id}
                  subscriptionId={page.subscription?.id}
                  type="page"
                  requestUrl={API_ENDPOINTS.subscription}
                  onSuccess={(subscriptionId) => {
                    updateResponseData?.(page.id, "update", {
                      subscription: subscriptionId ? { id: subscriptionId } : null,
                    })
                  }}
                />
                <Favorites
                  itemId={page.id}
                  favoritesId={page.favorites?.id}
                  type={ENTITY_TYPES.PAGE}
                  requestUrl={API_ENDPOINTS.favorites}
                  iconSize={21}
                  onSuccess={(favoritesId) => {
                    updateResponseData?.(page.id, "update", {
                      favorites: favoritesId ? { id: favoritesId } : null,
                    })
                  }}
                />
                <Box onClick={(e) => e.preventDefault()}>
                  <PageActions
                    page={page}
                    onDelete={() => updateResponseData?.(page.id, "remove")}
                    onUpdate={(data) => {
                      updateResponseData?.(page.id, "update", data)
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Text
              as={Box}
              display="block"
              width="100%"
              fontSize={isMobileView ? "fs-14" : "fs-26"}
              lineHeight={isMobileView ? "20px" : "32px"}
              fontWeight="600"
              color="inherit"
              overflow="hidden"
              styles={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "3",
                textOverflow: "-webkit-box",
              }}
              {...getCellPropsByPageData(page)}
            >
              {page.title}
            </Text>
            {!isMobileView && (
              <>
                <Text
                  as={Box}
                  display="flex"
                  gridGap="10px"
                  width="100%"
                  fontSize="fs-14"
                  lineHeight="20px"
                  fontWeight="400"
                  color="inherit"
                  {...getCellPropsByPageData(page)}
                >
                  <Text color="gray-40">Создана:</Text> {getFormattedDate(page.createdAt)}
                </Text>
                <Text
                  as={Box}
                  display="flex"
                  gridGap="10px"
                  alignItems="flex-start"
                  width="100%"
                  fontSize="fs-14"
                  lineHeight="20px"
                  fontWeight="400"
                  color="inherit"
                  {...getCellPropsByPageData(page)}
                >
                  <Text color="gray-40">Автор: </Text>{" "}
                  <Person fontSize="fs-14" person={page.user} />
                </Text>
              </>
            )}
            <Box
              position="absolute"
              bottom="0"
              right="0"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <PageStatus status={page.status} />
            </Box>
          </Stack>
        </Stack>
      </CardWrapper>
    </Link>
  )
}
