import React from "react"

import { Modal, ModalDisclosure, useModalState } from "@mos-cat/ds"
import { ZoneForm } from "./ZoneForm"

export const ZoneCreateTarget = ({
  children,
  modalDisclosureProps,
  modalStateProps = {},
  copyZone,
  onCloseModal,
}) => {
  const modalState = useModalState()
  const fullModalState = {
    ...modalState,
    hide: () => {
      modalState.hide()
      onCloseModal?.()
    },
    ...modalStateProps,
  }

  return (
    <>
      <ModalDisclosure {...modalDisclosureProps} {...fullModalState}>
        {children}
      </ModalDisclosure>
      <Modal {...fullModalState} heading={copyZone ? "Копирование раздела" : "Добавить раздел"}>
        {fullModalState?.visible && (
          <ZoneForm zone={copyZone} isCopying={!!copyZone} onClose={fullModalState.hide} />
        )}
      </Modal>
    </>
  )
}
