import React, { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import {
  getUrlSearchParams,
  Spinner,
  DataListWrapper,
  VirtualizedTable,
  useAppContext,
  VirtualList,
} from "@dit/core-frontend"
import { Box } from "@mos-cat/ds"
import usePagesList from "@components/pages/usePagesList"
import { PageCard } from "./PageCard"
import PageCardsList from "./PageCardsList"
import PageListRowMobile from "@components/pages/PagesListItem"

import { createTableConfig } from "./createTableConfig"
import { API_ENDPOINTS } from "@constants"
import isEqual from "lodash/isEqual"

const LIMIT = 24

const PagesVirtualTableContent = ({
  data,
  getData,
  isLoading,
  isRequestLoading,
  additionalFilters,
  updateResponseData,
  onDeletePage,
  view = "list",
  isRowView = true,
  limit = LIMIT,
  updateVisibleProjectsList,
}) => {
  const [page, setPage] = useState(1)

  const [searchParams] = useSearchParams()
  const { search } = getUrlSearchParams(searchParams)

  const { params } = usePagesList()
  const { isMobileView } = useAppContext()

  const handleAddPage = useCallback(() => {
    if (data?.itemsList.length < data?.itemsTotal) {
      setPage((prev) => prev + 1)
    }
  }, [data])

  const fetchPages = useCallback(() => {
    getData(API_ENDPOINTS.pageList, {
      search: search || "",
      epp: limit,
      eppOffset: (page - 1) * limit,
      ...params,
      ...(additionalFilters || {}),
    })
  }, [getData, page, params, search, additionalFilters, view])

  useEffect(() => {
    void fetchPages()
  }, [getData, page, params, search, fetchPages])

  useEffect(() => {
    const uniqueProjectsList = Object.values(
      (data?.itemsList || []).reduce((acc, page) => {
        if (!page.zone || acc[page.zone.id]) {
          return acc
        }

        return {
          ...acc,
          [page.zone.id]: page.zone,
        }
      }, {}),
    )

    updateVisibleProjectsList?.((prevList) => {
      if (!isEqual(prevList, uniqueProjectsList)) {
        return uniqueProjectsList
      }

      return prevList
    })
  }, [updateVisibleProjectsList, data?.itemsList])

  if (view === "list") {
    const tableConfig = createTableConfig(updateResponseData, onDeletePage, params.archive, data)

    return (
      <Box position="relative" minHeight="200px" overflow="auto" height="100%">
        {!isLoading && !isMobileView && (
          <VirtualizedTable
            over={10}
            tableConfig={tableConfig}
            emptyText="Нет страниц"
            dataList={data?.itemsList}
            dataTotal={data?.itemsTotal}
            isLoading={isRequestLoading}
            onLoad={handleAddPage}
            infiniteScroll
            infiniteCount={2}
          />
        )}
        {!isLoading && isMobileView && (
          <VirtualList
            over={10}
            dataList={data?.itemsList}
            dataTotal={data?.itemsTotal}
            isLoading={isLoading}
            infiniteScroll
            infiniteCount={2}
            height={500}
            renderItem={(page) => (
              <PageListRowMobile
                page={page}
                updateResponseData={updateResponseData}
                onDeletePage={onDeletePage}
              />
            )}
            onLoad={handleAddPage}
          />
        )}
        {isLoading && <Spinner space="sp-16" wrapper="div" />}
      </Box>
    )
  }

  return (
    <Box
      position="relative"
      display="flex"
      alignItems="space-between"
      flexWrap={isRowView && !isMobileView ? "nowrap" : "wrap"}
      gridGap={isMobileView ? "10px" : "15px"}
      minHeight="286px"
      width={isMobileView ? "calc(100% + 20px)" : "unset"}
      overflow={isMobileView ? "hidden" : "auto"}
      mx={["-10px", "-20px"]}
      px={["10px", "20px"]}
      pt="12px"
      pb="5px"
    >
      {isLoading ? (
        <Spinner space="sp-16" iconSize="30px" />
      ) : (
        <PageCardsList
          onLoad={handleAddPage}
          dataTotal={data?.itemsTotal}
          infiniteCount={2}
          infiniteScroll={true}
          dataList={data?.itemsList}
          renderItem={(item) => <PageCard page={item} updateResponseData={updateResponseData} />}
        />
      )}
    </Box>
  )
}

export const PagesVirtualTable = (props) => {
  return (
    <DataListWrapper>
      <PagesVirtualTableContent {...props} />
    </DataListWrapper>
  )
}
