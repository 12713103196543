import React, { memo, useEffect, useRef } from "react"

import styles from "./styles.module.css"
import { CommentsPanelList } from "./CommentPanelList"
import { Spinner, useAppContext } from "@dit/core-frontend"
import { useEditorCommentsContext } from "."
import { Box } from "@mos-cat/ds"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

export const CommentsPanel = memo(
  ({
    activeID,
    deleteCommentOrThread,
    comments,
    submitAddComment,
    markNodeMap,
    setShowComments,

    clickComment,
  }) => {
    const { isLoading } = useEditorCommentsContext()
    const [editor] = useLexicalComposerContext()
    const { showAlert } = useAppContext()

    const listRef = useRef(null)
    const isEmpty = comments.length === 0
    useEffect(() => {
      if (!comments.length) {
        showAlert("Комментарии отсутствуют", { type: "info" })
      }
    }, [])

    return (
      <>
        {isLoading ? (
          <Box className={styles.CommentPlugin_CommentsPanel}>
            <Spinner space="sp-16" wrapper="div" />
          </Box>
        ) : (
          <Box
            className={
              `${styles.CommentPlugin_CommentsPanel}` +
              (editor._config.namespace === "EditorPreview"
                ? ` ${styles.CommentPlugin_CommentsPanelPreview}`
                : "")
            }
          >
            {!isEmpty && (
              <>
                {/* <Button
            kind="borderless"
            onClick={() => {
              setShowComments(false)
            }}
          >
            Свернуть
          </Button> */}

                <CommentsPanelList
                  activeID={activeID}
                  comments={comments}
                  deleteCommentOrThread={deleteCommentOrThread}
                  listRef={listRef}
                  submitAddComment={submitAddComment}
                  markNodeMap={markNodeMap}
                  setShowComments={setShowComments}
                  clickComment={clickComment}
                />
              </>
            )}
          </Box>
        )}
      </>
    )
  },
)

CommentsPanel.displayName = "CommentsPanelComp"
