import React from "react"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  FilterItem,
  useQueryParams,
  CustomUsersTarget,
  CustomGetUsersOptionLabel,
} from "@dit/core-frontend"
import { API_ENDPOINTS } from "@constants"

const defaultFilterParams = {
  userIds: [],
  favorites: "",
  subscribe: "",
  archive: "",
  // deleted: "",
}

const filtersList = [
  {
    title: "Автор раздела",
    endPoint: API_ENDPOINTS.users,
    key: "userIds",
    customValue: "firstName",
    defaultText: "Все",
    customGetOptionLabel: (option) => CustomGetUsersOptionLabel(option),
    customTarget: ({ value, title, defaultText, isOpen, setIsOpen, handleCleanFilter }) => (
      <CustomUsersTarget
        value={value}
        title={title}
        defaultText={defaultText}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleCleanFilter={handleCleanFilter}
      />
    ),
    component: FilterItem,
  },
  {
    title: "Только избранные:",
    endPoint: "",
    key: "favorites",
    defaultText: "Все",
    optionsList: [
      { id: "true", title: "Да", placeholder: "Да" },
      { id: "false", title: "Нет", placeholder: "Нет" },
    ],
    isMultiValue: false,
    component: FilterItem,
  },
  {
    title: "Только подписки:",
    endPoint: "",
    key: "subscribe",
    defaultText: "Все",
    optionsList: [
      { id: "true", title: "Да", placeholder: "Да" },
      { id: "false", title: "Нет", placeholder: "Нет" },
    ],
    isMultiValue: false,
    component: FilterItem,
  },
  {
    title: "Только архивные:",
    endPoint: "",
    key: "archive",
    defaultText: "Нет",
    optionsList: [{ id: "true", title: "Да", placeholder: "Да" }],
    isMultiValue: false,
    component: FilterItem,
  },
  // {
  //   title: "Только удаленные:",
  //   endPoint: "",
  //   key: "deleted",
  //   defaultText: "Нет",
  //   optionsList: [{ id: "true", title: "Да", placeholder: "Да" }],
  //   isMultiValue: false,
  //   component: FilterItem,
  // },
]

const useZonesList = () => {
  const [searchParams] = useSearchParams()
  const [sortBy, setSortBy] = useState("sortingWeightByBacklog")
  const [sortDirection, setSortDirection] = useState("desc")
  const navigate = useNavigate()
  const { params, onChange: onChangeParams } = useQueryParams(defaultFilterParams)

  const onSearch = (val) => {
    const params = new URLSearchParams(searchParams)
    if (val) {
      params.set("search", (val || "").trim())
    } else {
      params.delete("search")
    }

    navigate(`${location.pathname}?${params}`)
  }

  const handleSortClick = (column) => {
    if (sortBy === column && sortDirection === "desc") {
      return setSortDirection("asc")
    }

    if (sortBy === column) {
      setSortBy("sortingWeightByBacklog")
      return setSortDirection("desc")
    }

    setSortBy(column)
    setSortDirection("desc")
  }
  const handleTagSort = (id) => {
    onChangeParams("tags", [id])
  }

  return {
    filtersList,
    params,
    defaultFilterParams,
    onChangeParams,
    onSearch,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    handleSortClick,
    handleTagSort,
  }
}

export default useZonesList
