import React, { useState } from "react"

import { apiClient, Button, ConfirmModal, useAppContext, ERROR_MESSAGES } from "@dit/core-frontend"

import { API_ENDPOINTS } from "@constants"

export const ZoneArchiveButton = ({ zone, onSuccess, buttonProps = {}, onCancel }) => {
  return (
    <ConfirmModal
      title="Архивация раздела"
      description={`Архивировать раздел «${zone.title}»?`}
      modalDisclosureProps={{
        as: Button,
        ...buttonProps,
      }}
      successText="Раздел архивирован"
      confirmButtonText="Архивировать"
      onSuccess={onSuccess}
      onCancel={onCancel}
      request={() => apiClient.put(`${API_ENDPOINTS.zone}/${zone.id}/archive`, { archive: true })}
    />
  )
}

export const ZoneUnArchiveButton = ({ zone, onSuccess, buttonProps = {} }) => {
  const [isArchiveLoading, setIsArchiveLoading] = useState(false)
  const { showAlert } = useAppContext()

  return (
    <Button
      disabled={isArchiveLoading}
      loading={isArchiveLoading}
      {...buttonProps}
      onClick={async () => {
        setIsArchiveLoading(true)
        try {
          await apiClient.put(`${API_ENDPOINTS.zone}/${zone.id}/archive`, {
            archive: false,
          })
          onSuccess()
        } catch (err) {
          showAlert(err?.data?.errors?.[0].message || ERROR_MESSAGES.default)
        } finally {
          setIsArchiveLoading(false)
        }
      }}
    />
  )
}
