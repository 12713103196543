import React, { useEffect, useState } from "react"
import styles from "./changeTeamMember.module.css"
import { Field, Form } from "react-final-form"
import { Box, Button, Modal, ModalDisclosure, Stack, Text, useModalState } from "@mos-cat/ds"
import {
  Message,
  apiClient,
  ERROR_MESSAGES,
  formatSubmitErrors,
  getValidationErrors,
  CustomSelect,
  useAppContext,
  getFormErrorMessageForMessage,
  Spinner,
  CustomGetUsersOptionLabel,
} from "@dit/core-frontend"
import { API_ENDPOINTS } from "@constants"
import { useZoneContext } from "@components/contexts/ZoneContext"

const validationSchema = {
  roleId: { required: true, type: "string" },
  assignedToUserId: { required: true, type: "string" },
}

export const ChangeTeamMember = ({
  onSuccess,
  modalDisclosureProps,
  modalStateProps,
  children,
  teamMemberData,
  type = "update",
}) => {
  const [assignedToUser, setAssignedToUser] = useState({})
  const [roleData, setRoleData] = useState({})
  const { showAlert } = useAppContext()
  const { zone } = useZoneContext()
  const modalState = useModalState()
  const getModalState = { ...modalState, ...modalStateProps }
  const isVisible = getModalState.visible

  const getRoles = async (search, _, { page }) => {
    try {
      const { data } = await apiClient.get(API_ENDPOINTS.rolesList, {
        params: {
          search: search,
          epp: 60,
          eppOffset: (page - 1) * 60,
        },
      })
      return {
        options: data?.data.itemsList,
        hasMore: !!data?.data?.itemsLeft,
        additional: {
          page: page + 1,
        },
      }
    } catch (err) {
      showAlert(err?.data?.errors?.[0]?.message || "Произошла ошибка при загрузке ролей")
    }
  }

  const getUsers = async (search, _, { page }) => {
    try {
      const { data } = await apiClient.get(API_ENDPOINTS.users, {
        params: {
          search: search,
          epp: 60,
          eppOffset: (page - 1) * 60,
        },
      })
      return {
        options: data?.data.itemsList,
        hasMore: !!data?.data?.itemsLeft,
        additional: {
          page: page + 1,
        },
      }
    } catch (err) {
      showAlert(err?.data?.errors?.[0]?.message || "Произошла ошибка при загрузке пользователей")
    }
  }

  const handleSuccess = (requestData) => {
    if (requestData) {
      getModalState.hide()

      delete requestData.roleId
      onSuccess?.({ ...requestData, role: roleData })
      return
    }
    // setIsSuccess(true)
    getModalState.hide()
    onSuccess?.()
  }

  useEffect(() => {
    if (teamMemberData?.role) {
      setRoleData(teamMemberData?.role)
    }
    if (teamMemberData?.assignedToUser) {
      setAssignedToUser(teamMemberData?.assignedToUser)
    }
  }, [teamMemberData?.role, teamMemberData?.assignedToUser])

  useEffect(() => {
    if (!isVisible) {
      setAssignedToUser({})
      setRoleData({})
    }
  }, [isVisible])

  return (
    <>
      {children && (
        <ModalDisclosure {...modalDisclosureProps} {...getModalState}>
          {children}
        </ModalDisclosure>
      )}
      <Modal
        {...getModalState}
        heading={type === "update" ? "Редактирование участника" : "Добавление участника"}
      >
        {isVisible && (
          <Form
            initialValues={{
              assignedToUserId: teamMemberData?.assignedToUser?.id || "",
              roleId: teamMemberData?.role?.id || "",
            }}
            validate={(values) => getValidationErrors(values, validationSchema)}
            onSubmit={async (values, form) => {
              let errors = null
              const requestData = { roleId: values?.roleId }
              try {
                if (type === "update") {
                  const { data } = await apiClient.patch(
                    `${API_ENDPOINTS.teamMember}/${teamMemberData.id}`,
                    requestData,
                  )
                  if (data.success) {
                    form.restart()
                    form.reset()
                    showAlert("Обновление прошло успешно", { type: "success" })
                    handleSuccess(requestData)
                  }
                } else {
                  const { data } = await apiClient.post(API_ENDPOINTS.teamMember, {
                    zoneId: zone.id,
                    ...values,
                  })
                  if (data.success) {
                    form.restart()
                    showAlert("Участник добавлен", { type: "success" })
                    handleSuccess()
                  }
                }
              } catch (err) {
                if (err.status === 500) {
                  errors = {
                    common_error: ERROR_MESSAGES.default,
                  }
                }

                if (err?.data?.errors?.length) {
                  errors = formatSubmitErrors(err.data.errors)
                }
              }

              if (errors) {
                return errors
              }
            }}
            render={({
              handleSubmit,
              submitting,
              form,
              submitErrors,
              pristine,
              dirtySinceLastSubmit,
              valid,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Message
                    type="error"
                    isAlwaysVisible
                    isVisible={!dirtySinceLastSubmit}
                    text={Object.values(submitErrors || {})}
                  />
                  <Stack space="0" minHeight="500px">
                    <Stack space="10px">
                      <Text
                        as={Box}
                        fontSize="fs-16"
                        lineHeight="24px"
                        fontWeight="600"
                        color="black"
                        className={styles.fieldTitle}
                      >
                        Выберите участника
                      </Text>
                      <Field name="assignedToUserId">
                        {() => (
                          <>
                            <CustomSelect
                              type="async-paginate"
                              placeholder=""
                              cacheOptions
                              isDisabled={type === "update" ? true : false}
                              defaultOptions
                              loadOptions={getUsers}
                              isMulti={false}
                              noOptionsMessage={() => "не найдено"}
                              isSearchable
                              value={assignedToUser?.id ? assignedToUser : null}
                              getOptionLabel={(option) => CustomGetUsersOptionLabel(option)}
                              getOptionValue={(option) => option.id}
                              onChange={(selected) => {
                                form.change("assignedToUserId", selected.id)
                                setAssignedToUser(selected)
                              }}
                              loadingMessage={() => (
                                <Box position="relative" minHeight="25px">
                                  <Spinner space="sp-16" wrapper="div" />
                                </Box>
                              )}
                              additional={{
                                page: 1,
                              }}
                              isGroupedOptions
                              debounceTimeout={500}
                            />
                          </>
                        )}
                      </Field>

                      <Text
                        as={Box}
                        fontSize="fs-16"
                        lineHeight="24px"
                        fontWeight="600"
                        color="black"
                        className={styles.fieldTitle}
                      >
                        Выберите роль
                      </Text>
                      <Field name="roleId">
                        {() => (
                          <>
                            <CustomSelect
                              type="async-paginate"
                              placeholder=""
                              cacheOptions
                              defaultOptions
                              loadOptions={getRoles}
                              isMulti={false}
                              isSearchable
                              noOptionsMessage={() => "не найдено"}
                              value={roleData || null}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.id}
                              onChange={(selected) => {
                                form.change("roleId", selected.id)
                                setRoleData(selected)
                              }}
                              loadingMessage={() => (
                                <Box position="relative" minHeight="25px">
                                  <Spinner space="sp-16" wrapper="div" />
                                </Box>
                              )}
                              additional={{
                                page: 1,
                              }}
                              isGroupedOptions
                              debounceTimeout={500}
                            />
                          </>
                        )}
                      </Field>
                    </Stack>

                    <Box display="flex" alignItems="flex-end" flex="2">
                      <Button
                        kind="primary"
                        type="submit"
                        disabled={submitting || pristine || (!valid && !dirtySinceLastSubmit)}
                        marginTop="20px"
                      >
                        Сохранить
                      </Button>
                    </Box>
                  </Stack>
                </form>
              )
            }}
          />
        )}
      </Modal>
    </>
  )
}
