import styles from "./collapsible.module.css"
import { ElementNode } from "lexical"

export function convertCollapsibleContentElement() {
  const node = $createCollapsibleContentNode()
  return {
    node,
  }
}

export class CollapsibleContentNode extends ElementNode {
  static getType() {
    return "collapsible-content"
  }

  static clone(node) {
    return new CollapsibleContentNode(node.__key)
  }

  createDOM() {
    const dom = document.createElement("div")
    dom.classList.add(styles.CollapsibleContent)
    return dom
  }

  updateDOM() {
    return false
  }

  static importDOM() {
    return {
      div: (domNode) => {
        if (!domNode.hasAttribute("data-lexical-collapsible-content")) {
          return null
        }
        return {
          conversion: convertCollapsibleContentElement,
          priority: 2,
        }
      },
    }
  }

  exportDOM() {
    const element = document.createElement("p")
    element.setAttribute("data-lexical-collapsible-content", "true")
    return { element }
  }

  static importJSON() {
    return $createCollapsibleContentNode()
  }

  isShadowRoot() {
    return true
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: "collapsible-content",
      version: 1,
    }
  }
}

export function $createCollapsibleContentNode() {
  return new CollapsibleContentNode()
}

export function $isCollapsibleContentNode(node) {
  return node instanceof CollapsibleContentNode
}
