// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curtain-modal_root__U1fmN {
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;

  overflow: hidden auto;
  justify-content: flex-end;

  width: 100%;
  height: 100%;
}

.curtain-modal_backdrop__CcK39 {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.curtain-modal_modalWrap__rn8Px {
  position: absolute;
  z-index: 1;
  right: 0;

  max-width: 100%;
  height: 100%;
}

.curtain-modal_modal__7C6l8 {
  position: relative;

  display: flex;
  flex: 1 1;

  height: 100%;

  box-shadow:  0 16px 32px 0 rgb(0 20 67 / 12%);
}

.curtain-modal_content__DEN12 {
  position: sticky;
  top: 50px;

  overflow: auto;
  flex: 0 0 620px;

  width: 620px;
  max-width: 100%;

  height: var(--page-max-height);

  padding: 40px;

  border-left: 1px solid var(--gray-20);

  background: var(--white);
}

.curtain-modal_content__DEN12 > div {
  width: 100%;
}

.curtain-modal_close__Vdmcn {
  position: absolute;
  z-index: 1;
  top: 90px; /* 50 шапка + 40 отступ */
  right: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/general/CurtainModal/curtain-modal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;EACX,MAAM;EACN,OAAO;;EAEP,qBAAqB;EACrB,yBAAyB;;EAEzB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;;EAEP,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;;EAER,eAAe;EACf,YAAY;AACd;;AAEA;EACE,kBAAkB;;EAElB,aAAa;EACb,SAAO;;EAEP,YAAY;;EAEZ,6CAA6C;AAC/C;;AAEA;EACE,gBAAgB;EAChB,SAAS;;EAET,cAAc;EACd,eAAe;;EAEf,YAAY;EACZ,eAAe;;EAEf,8BAA8B;;EAE9B,aAAa;;EAEb,qCAAqC;;EAErC,wBAAwB;AAC1B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS,EAAE,yBAAyB;EACpC,WAAW;;EAEX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,WAAW;EACX,YAAY;;EAEZ,eAAe;AACjB","sourcesContent":[".root {\n  position: fixed;\n  z-index: 90;\n  top: 0;\n  left: 0;\n\n  overflow: hidden auto;\n  justify-content: flex-end;\n\n  width: 100%;\n  height: 100%;\n}\n\n.backdrop {\n  position: absolute;\n  top: 0;\n  left: 0;\n\n  width: 100%;\n  height: 100%;\n}\n\n.modalWrap {\n  position: absolute;\n  z-index: 1;\n  right: 0;\n\n  max-width: 100%;\n  height: 100%;\n}\n\n.modal {\n  position: relative;\n\n  display: flex;\n  flex: 1;\n\n  height: 100%;\n\n  box-shadow:  0 16px 32px 0 rgb(0 20 67 / 12%);\n}\n\n.content {\n  position: sticky;\n  top: 50px;\n\n  overflow: auto;\n  flex: 0 0 620px;\n\n  width: 620px;\n  max-width: 100%;\n\n  height: var(--page-max-height);\n\n  padding: 40px;\n\n  border-left: 1px solid var(--gray-20);\n\n  background: var(--white);\n}\n\n.content > div {\n  width: 100%;\n}\n\n.close {\n  position: absolute;\n  z-index: 1;\n  top: 90px; /* 50 шапка + 40 отступ */\n  right: 40px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 32px;\n  height: 32px;\n\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `curtain-modal_root__U1fmN`,
	"backdrop": `curtain-modal_backdrop__CcK39`,
	"modalWrap": `curtain-modal_modalWrap__rn8Px`,
	"modal": `curtain-modal_modal__7C6l8`,
	"content": `curtain-modal_content__DEN12`,
	"close": `curtain-modal_close__Vdmcn`
};
export default ___CSS_LOADER_EXPORT___;
