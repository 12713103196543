import React, { useEffect } from "react"
import { Route, Routes, useLocation, useParams } from "react-router-dom"

import { Spinner } from "@dit/core-frontend"
import { DefaultHomePage } from "@src/components/ZoneHomePage"
import { Helmet } from "react-helmet"
import { PageForm } from "@components/pages"
import { PageContextProvider } from "@components/contexts/PageContext"
import { useQuery } from "@tanstack/react-query"
import { QUERIES } from "@constants/queries"
import { PagePreview } from "@components/pages/PagePreview"

const ViewPage = ({ setAdditionalBreadcrumbs }) => {
  const { zoneSlug } = useParams()
  const location = useLocation()

  const { refetch, ...zone } = useQuery(QUERIES.zone.get(zoneSlug))

  const home = useQuery({
    ...QUERIES.page.get(zone.data?.homePage),
    enabled: !!zone.data?.homePage,
  })

  useEffect(() => {
    setAdditionalBreadcrumbs([])
  }, [setAdditionalBreadcrumbs])

  // при добавлении домашней страницы нужно обновить раздел
  useEffect(() => {
    refetch()
  }, [location.pathname, refetch])

  if (zone.isFetching || home.isFetching) {
    return <Spinner bg={["gray-05", "white"]} space="sp-16" wrapper="div" />
  }

  return (
    <>
      {zone.data?.title && (
        <Helmet title={`Главная страница раздела · ${zone.data?.title || ""} · МосВики`} />
      )}

      {!home.data ? (
        <DefaultHomePage />
      ) : (
        <PageContextProvider pageSlug={zone.data?.homePage} isHomePage withoutRedirect>
          <Routes>
            <Route path="/" element={<PagePreview />} />
            <Route path="/edit" element={<PageForm />} />
          </Routes>
        </PageContextProvider>
      )}
    </>
  )
}

export default ViewPage
