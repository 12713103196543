import React, { memo, useCallback, useEffect, useState } from "react"

import { API_ENDPOINTS } from "@constants"

import { PagesList } from "@components/pages"
import { Spinner } from "@dit/core-frontend"

const LIMIT = 20

export const PagesBlock = memo(
  ({ isVisible, isLoading, isRequestLoading, data, getData, params, setCount, showUpdater }) => {
    const [page, setPage] = useState(1)

    const handleAddPage = useCallback(() => {
      if (data?.itemsList.length < data?.itemsTotal) {
        setPage((prev) => prev + 1)
      }
    }, [data])

    const fetchData = () => {
      const limit = isVisible ? LIMIT : 0

      getData(API_ENDPOINTS.pageList, {
        sortDirection: "desc",
        epp: limit,
        eppOffset: (page - 1) * limit,
        ...params,
      })
    }

    // При поиске скидываем страницу на 1 и делаем запрос
    // Но изначально делать запрос не нужно, по этому проверяем наличие data
    useEffect(() => {
      setPage(1)
      if (page === 1 && !!data) {
        fetchData()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.search])

    // Начальный запрос + при переключении страницы
    useEffect(() => {
      fetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    // При открытии таба, но только если там запрашивалось только кол-во
    useEffect(() => {
      if (isVisible && data?.itemsTotal && !data?.itemsList?.length) {
        fetchData()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isVisible])

    useEffect(() => {
      setCount(data?.itemsTotal || 0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.itemsTotal])

    return (
      <>
        {isLoading && <Spinner space="sp-16" wrapper="div" />}
        {!!data?.itemsList?.length && (
          <PagesList
            list={data.itemsList}
            itemsTotal={data.itemsTotal}
            onLoad={handleAddPage}
            refetch={fetchData}
            showUpdater={showUpdater}
            isRequestLoading={isRequestLoading}
          />
        )}
      </>
    )
  },
)

PagesBlock.displayName = "PagesBlock"
