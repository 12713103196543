import React, { useState, useMemo, useEffect, useRef } from "react"

import { Text, Box } from "@mos-cat/ds"
import { Button } from "@dit/core-frontend"

const MIN_HEIGHT = 80
const MAX_HEIGHT = 450

const CommentContent = ({ text }) => {
  const [isLargeView, setIsLargeView] = useState(false)
  const [isButtonVisible, setIsButtonVisible] = useState(false)
  const textRef = useRef(null)

  useEffect(() => {
    if (textRef.current) {
      setIsButtonVisible(textRef.current.scrollHeight > MIN_HEIGHT)
    }
  }, [textRef])

  const stylesByView = useMemo(() => {
    if (isLargeView) {
      return {
        container: {
          maxHeight: MAX_HEIGHT + 15 + 26 + "px", // С отступами и кнопкой
          borderColor: "#E1D0B9",
        },
        text: {},
      }
    }

    return {
      container: {
        maxHeight: MIN_HEIGHT + 20 + 26 + "px", // С отступами и кнопкой
        borderColor: "transparent",
      },
      text: {
        overflow: "hidden",
        styles: {
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: String(MIN_HEIGHT / 20),
          textOverflow: "-webkit-box",
        },
      },
    }
  }, [isLargeView])

  return (
    <Box pt="5px" mb="15px" overflow="auto" borderTop="1px solid" {...stylesByView.container}>
      <Box {...stylesByView.text}>
        <Text ref={textRef} as={Box} fontSize="14px" lineHeight="20px" color="black">
          {text}
        </Text>
      </Box>
      {isButtonVisible && (
        <Box mt="5px" styles={{ "& *": { transition: "var(--default-transition)" } }}>
          <Button
            kind="borderless"
            padding="0 !important"
            onClick={() => setIsLargeView((prev) => !prev)}
          >
            <Box
              width="0"
              height="0"
              borderLeft="4px solid transparent"
              borderRight="4px solid transparent"
              borderTop="8px solid currentColor"
              styles={{ transform: `rotate(${isLargeView ? 180 : 0}deg)` }}
            />
            {isLargeView ? "Показать часть" : "Показать все"}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CommentContent
