// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagePreview_bodyBlock__rud5W {
    margin-top: 60px;
    margin-left: -40px;
    padding: 0 40px;
}

.pagePreview_title__sx5Jy {
  word-break: break-all;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/pagePreview.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".bodyBlock {\n    margin-top: 60px;\n    margin-left: -40px;\n    padding: 0 40px;\n}\n\n.title {\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyBlock": `pagePreview_bodyBlock__rud5W`,
	"title": `pagePreview_title__sx5Jy`
};
export default ___CSS_LOADER_EXPORT___;
