import React, { forwardRef } from "react"
import classcat from "classcat"
import { NavLink } from "react-router-dom"

import { Box, Stack, Text } from "@mos-cat/ds"
import { Add } from "@mos-cat/ds-icons"

import styles from "./TreeItem.module.css"
import { Tooltip } from "@dit/core-frontend"

export const TreeItem = forwardRef(
  (
    {
      childCount,
      depth,
      maxDepth,
      disableSelection,
      disableInteraction,
      handleProps,
      indentationWidth,
      collapsed,
      onCollapse,
      style,
      value,
      wrapperRef,
      nextElement,
      lines,
      isActive,
      link,
      setCreateModal,
      isCreate,
      ...props
    },
    ref,
  ) => {
    const [isHovered, setIsHovered] = React.useState(false)

    return (
      <li
        className={classcat({
          [styles.Wrapper]: true,
          [styles.disableSelection]: disableSelection,
          [styles.disableInteraction]: disableInteraction,
        })}
        ref={wrapperRef}
        style={{
          "--spacing": `${indentationWidth * depth}px`,
          position: "relative",
        }}
        {...props}
      >
        {!isActive &&
          lines?.map((line, index) => (
            <div
              key={index}
              className={styles.order}
              style={{ left: 8 + (depth - 1 - index) * 12, opacity: line ? 1 : 0 }}
            ></div>
          ))}
        <div
          className={classcat({ [styles.TreeItem]: true, [styles.isActiveItem]: isActive })}
          ref={ref}
          style={style}
        >
          {!maxDepth && (
            <div
              className={classcat({
                [styles.order]: true,
                [styles.nextElement]: !nextElement,
              })}
            ></div>
          )}
          {!isActive && collapsed && childCount > 1 && (
            <div
              className={classcat({
                [styles.order]: true,
                [styles.childOrder]: true,
              })}
            ></div>
          )}

          {onCollapse && (
            <Box
              onClick={onCollapse}
              className={classcat({
                [styles.collapseButton]: true,
                [styles.collapseLine]: !maxDepth,
                [styles.collapsed]: collapsed && !isActive,
              })}
            >
              <svg
                width="6"
                height="8"
                viewBox="0 0 6 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M6 4L8.90085e-08 7.4641L6.39053e-09 0.535899L6 4Z" fill="#0E0E0F" />
              </svg>
            </Box>
          )}

          <Stack
            space="2px"
            className={classcat({ [styles.content]: true, [styles.active]: isActive })}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...handleProps}
          >
            <NavLink to={link} style={{ color: "inherit" }}>
              {({ isActive }) => (
                <Box display="flex" justifyContent="space-between" alignItems="start">
                  <Tooltip
                    content={value.title}
                    style={{
                      height: "18px",
                      width: isHovered
                        ? `calc(160px - ${depth * 12}px)`
                        : `calc(200px - ${depth * 12}px)`,
                      flexShrink: 1,
                      // overflow: "hidden",
                      // textOverflow: "ellipsis",
                    }}
                  >
                    <Text
                      className={classcat({
                        [styles.title]: true,
                        [styles.linkActive]: isActive,
                      })}
                    >
                      {value.title}
                    </Text>
                  </Tooltip>

                  {isHovered && isCreate && (
                    <Box
                      marginRight="10px"
                      flexShrink={0}
                      onClick={(e) => {
                        e.preventDefault()
                        setCreateModal({
                          id: value.id,
                          zone: value.zone,
                        })
                        setIsHovered(false)
                      }}
                      width={21}
                      height={18}
                    >
                      <Tooltip content="Создать дочернюю страницу">
                        <Text
                          as={Box}
                          className={styles.addButton}
                          style={{ opacity: isActive || isHovered ? 1 : 0 }}
                        >
                          <Add fr={1} width={21} height={21} />
                        </Text>
                      </Tooltip>
                    </Box>
                  )}
                </Box>
              )}
            </NavLink>
          </Stack>
        </div>
      </li>
    )
  },
)

TreeItem.displayName = "TreeItem"
