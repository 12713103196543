import React from "react"

import { Delete } from "@mos-cat/ds-icons"
import {
  ActionsPopover,
  apiClient,
  ConfirmModal,
  CopyLinkIcon,
  PencilIcon,
  useAppContext,
  Button,
} from "@dit/core-frontend"

import { API_ENDPOINTS } from "@constants"
import { useCommentsContext } from "@components/Comment/CommentsBlock"
import { useZoneContext } from "../contexts/ZoneContext"
// import { checkPermission } from "@app/src/store/permissionModel"

export const CommentActions = ({ comment, turnOnEdit }) => {
  const { user } = useAppContext()
  const { showAlert } = useAppContext()
  const { zone } = useZoneContext()
  const { page, refetchList } = useCommentsContext()

  const copyLink = async (callback) => {
    try {
      await navigator.clipboard.writeText(
        `${window.location.origin}/wiki/zone/${zone.slug}/pages/${page.slug}#${comment.id}`,
      )
      showAlert("Ссылка скопирована", { type: "success" })
      callback()
    } catch (e) {
      showAlert("Не удалось скопировать ссылку")
    }
  }
  const isEditable = user.id === comment.user?.id
  const isDeletable = user.id === comment.user?.id

  return (
    <>
      <ActionsPopover customBackground="inherit">
        {(b, hidePopover) => (
          <>
            <Button kind="menu-button" onClick={() => copyLink(hidePopover)}>
              <CopyLinkIcon color="currentColor" />
              Скопировать ссылку
            </Button>
            {!comment.deleted && user.id === comment.user?.id && (
              <>
                {isEditable && (
                  <Button kind="menu-button" onClick={turnOnEdit}>
                    <PencilIcon color="currentColor" />
                    Редактировать
                  </Button>
                )}
                {isDeletable && (
                  <ConfirmModal
                    title="Удаление комментария"
                    description="Вы точно хотите удалить комментарий?"
                    modalDisclosureProps={{
                      as: Button,
                      kind: "menu-button",
                      children: (
                        <>
                          <Delete color="currentColor" size={21} />
                          Удалить
                        </>
                      ),
                    }}
                    successText="Удаление прошло успешно"
                    confirmButtonText="Удалить"
                    onSuccess={() => {
                      refetchList()
                      hidePopover()
                    }}
                    onCancel={hidePopover}
                    request={() => apiClient.delete(`${API_ENDPOINTS.comment}/${comment.id}`)}
                  />
                )}
              </>
            )}
          </>
        )}
      </ActionsPopover>
    </>
  )
}
