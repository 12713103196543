import React from "react"

import { Link } from "react-router-dom"
import {
  Avatar,
  CardWrapper,
  Favorites,
  Subscription,
  useAppContext,
  Tooltip,
} from "@dit/core-frontend"
import { Stack, Box, Text } from "@mos-cat/ds"
import { ZoneActions, ZoneState } from "@components/zones"
import { Meatball } from "@mos-cat/ds-icons"

import { declOfNum } from "@utils/general"

import { ROUTES, API_ENDPOINTS } from "@constants"
import { getCellPropsByZoneData } from "@utils/zones"
import { ENTITY_TYPES } from "@utils/favorites"

export const ZoneCard = ({ updateResponseData, zone }) => {
  const { isMobileView } = useAppContext()

  return (
    <Link
      to={`${ROUTES.ZONE}/${zone.slug}`}
      style={{
        width: isMobileView ? "calc(50% - 5px)" : "unset",
      }}
    >
      <CardWrapper
        backgroundColor="#F7F5EE"
        color="gray-90"
        style={{
          width: "100%",
          display: "flex",
        }}
      >
        <Stack
          width={isMobileView ? "100%" : "306px"}
          height={isMobileView ? "200px" : "287px"}
          p={isMobileView ? "7.5px" : "15px"}
          space="0"
          justifyContent="space-between"
          borderRadius="8px"
          overflow="hidden"
        >
          <Stack space="10px" flex="2" alignItems="flex-start">
            <Box display="flex" justifyContent="space-between" width="100%" gridGap="5px">
              <Box
                width={isMobileView ? "32px" : "48px"}
                height={isMobileView ? "32px" : "48px"}
                borderRadius="4px"
                border="var(--gray-10)"
                backgroundColor="var(--white)"
                padding={isMobileView ? "2px" : "6px"}
                {...getCellPropsByZoneData(zone)}
              >
                <Avatar
                  url={zone.icon?.url || " "}
                  size={isMobileView ? 28 : 36}
                  borderRadius="0"
                  backgroundColor="var(--white)"
                />
              </Box>
              <Box
                display="flex"
                alignItems="flex-start"
                gridGap={isMobileView ? "5px" : "10px"}
                onClick={(e) => e.preventDefault()}
              >
                <Subscription
                  itemId={zone.id}
                  subscriptionId={zone.subscription?.id}
                  type="zone"
                  p="5px 0 !important"
                  requestUrl={API_ENDPOINTS.subscription}
                  onSuccess={(subscriptionId) => {
                    updateResponseData?.(zone.id, "update", {
                      subscription: subscriptionId ? { id: subscriptionId } : null,
                    })
                  }}
                />
                <Favorites
                  itemId={zone.id}
                  favoritesId={zone.favorites?.id}
                  type={ENTITY_TYPES.ZONE}
                  p="5px 0 !important"
                  requestUrl={API_ENDPOINTS.favorites}
                  onSuccess={(favoritesId) => {
                    updateResponseData?.(zone.id, "update", {
                      favorites: favoritesId ? { id: favoritesId } : null,
                    })
                  }}
                />
                <ZoneActions
                  zone={zone}
                  onDelete={() => updateResponseData?.(zone.id, "remove")}
                  onArchive={(archiveState) =>
                    updateResponseData?.(zone.id, "update", { archive: archiveState })
                  }
                  p="0"
                  content={
                    <Tooltip content="Действия" style={{ display: "flex" }}>
                      <Text
                        as={Box}
                        color="gray-40"
                        fontSize="14px"
                        backgroundColor="var(--white)"
                        borderRadius="2px"
                      >
                        <Meatball />
                      </Text>
                    </Tooltip>
                  }
                />
              </Box>
            </Box>
            <Box display="flex" width="100%" overflow="hidden" {...getCellPropsByZoneData(zone)}>
              <Text
                fontSize={isMobileView ? "fs-14" : "fs-26"}
                lineHeight={isMobileView ? "18px" : "32px"}
                fontWeight="600"
                color="inherit"
                styles={{
                  overflow: "hidden",
                  display: "-webkit-box",
                  "-webkit-box-orient": "vertical",
                  "-webkit-line-clamp": "3",
                  textOverflow: "ellipsis",
                }}
              >
                {zone.title}
              </Text>
            </Box>
            {/*<Text*/}
            {/*  as={Box}*/}
            {/*  display="inline-flex"*/}
            {/*  gridGap="5px"*/}
            {/*  p="5px 10px"*/}
            {/*  backgroundColor="white"*/}
            {/*  color="gray-60"*/}
            {/*  fontSize="14px"*/}
            {/*  lineHeight="20px"*/}
            {/*>*/}
            {/*  <Text>Мои документы:</Text>*/}
            {/*  <Text color="blue">{0}</Text>*/}
            {/*</Text>*/}

            <Link to={`${ROUTES.ZONE}/${zone.slug}/pages`} onClick={(e) => e.stopPropagation()}>
              <Box
                display="flex"
                gridGap="5px"
                styles={{
                  fontSize: isMobileView ? "fs-14" : "fs-16",
                }}
              >
                {zone.numberOfPages || 0}
                <Text>
                  {declOfNum(zone.numberOfPages || 0, ["документ", "документа", "документов"])}
                </Text>
              </Box>
            </Link>
          </Stack>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box />
            <ZoneState zone={zone} />
          </Box>
        </Stack>
      </CardWrapper>
    </Link>
  )
}
