// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.row_PageListRow__VyJc1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 10px;

  padding: 6px 10px;

  border-bottom: 1px solid var(--gray-05);
}

.row_PageListRowContent__gprZq {
  display: flex;
  align-items: center;
  grid-gap: 10px;

  width: calc(50% - 5px);
}

.row_PageListRowPerson__znF9M {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;

  max-width: 50%;
}

.row_PageListRowZone__ZWmrV {
  display: flex;
  align-items: center;
  grid-gap: 10px;

  max-width: calc(50% - 5px);
}

@media (max-width: 768px) {
  .row_PageListRow__VyJc1 {
    align-items: flex-start;
    flex-direction: column-reverse;

    border-top: 1px solid var(--gray-05);
    border-bottom: none;
  }

  .row_PageListRowContent__gprZq {
    align-items: flex-start;
    flex-direction: column;

    width: 100%;
    max-width: 100%;

    padding-left: 40px;
  }

  .row_PageListRowPerson__znF9M {
    max-width: 100%;
  }

  .row_PageListRowTitle__4DaFV {
    width: 100%;
  }

  .row_PageListRowZone__ZWmrV {
    flex-direction: row-reverse;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/row.module.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;;EAEd,iBAAiB;;EAEjB,uCAAuC;AACzC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;;EAEd,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,aAAa;;EAEb,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;;EAEd,0BAA0B;AAC5B;;AAEA;EACE;IACE,uBAAuB;IACvB,8BAA8B;;IAE9B,oCAAoC;IACpC,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,sBAAsB;;IAEtB,WAAW;IACX,eAAe;;IAEf,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,2BAA2B;EAC7B;AACF","sourcesContent":["\n.PageListRow {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  grid-gap: 10px;\n\n  padding: 6px 10px;\n\n  border-bottom: 1px solid var(--gray-05);\n}\n\n.PageListRowContent {\n  display: flex;\n  align-items: center;\n  grid-gap: 10px;\n\n  width: calc(50% - 5px);\n}\n\n.PageListRowPerson {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  grid-gap: 5px;\n\n  max-width: 50%;\n}\n\n.PageListRowZone {\n  display: flex;\n  align-items: center;\n  grid-gap: 10px;\n\n  max-width: calc(50% - 5px);\n}\n\n@media (max-width: 768px) {\n  .PageListRow {\n    align-items: flex-start;\n    flex-direction: column-reverse;\n\n    border-top: 1px solid var(--gray-05);\n    border-bottom: none;\n  }\n\n  .PageListRowContent {\n    align-items: flex-start;\n    flex-direction: column;\n\n    width: 100%;\n    max-width: 100%;\n\n    padding-left: 40px;\n  }\n\n  .PageListRowPerson {\n    max-width: 100%;\n  }\n\n  .PageListRowTitle {\n    width: 100%;\n  }\n\n  .PageListRowZone {\n    flex-direction: row-reverse;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PageListRow": `row_PageListRow__VyJc1`,
	"PageListRowContent": `row_PageListRowContent__gprZq`,
	"PageListRowPerson": `row_PageListRowPerson__znF9M`,
	"PageListRowZone": `row_PageListRowZone__ZWmrV`,
	"PageListRowTitle": `row_PageListRowTitle__4DaFV`
};
export default ___CSS_LOADER_EXPORT___;
