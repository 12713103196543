import React, { useEffect, useMemo } from "react"

import {
  FiltersBox,
  PageBlockContent,
  PageBlockHeading,
  PageBlockRow,
  PageBlockRowSection,
  Search,
  useStorageVariable,
  Button,
  useAppContext,
} from "@dit/core-frontend"
import { Box } from "@mos-cat/ds"

import { ROUTES, STORE_VARIABLES } from "@constants"
import { PagesVirtualTable } from "@components/pages"
import { CreatePageModal } from "@src/components/pages/CreatePageModal"
import { ViewSwitcher } from "@components/ViewSwitcher"
import { useZoneContext } from "@components/contexts/ZoneContext"

import usePagesList from "@components/pages/usePagesList"
import { Helmet } from "react-helmet"

const PagesPage = ({ setAdditionalBreadcrumbs }) => {
  const { zone, deletePage, checkZonePermission } = useZoneContext()
  const { filtersList, defaultFilterParams, onChangeParams, params, onSearch } = usePagesList()
  const [view, setView] = useStorageVariable(STORE_VARIABLES.zonePagesView)
  const { isMobileView } = useAppContext()

  useEffect(() => {
    setAdditionalBreadcrumbs([
      { label: "Страницы раздела", to: `${ROUTES.ZONE}/${zone.slug}/pages` },
    ])
  }, [setAdditionalBreadcrumbs, zone.slug])

  const additionalFilters = useMemo(() => {
    return { zones: [zone.id] }
  }, [zone.id])

  const renderButton = (
    <CreatePageModal defaultZone={zone}>
      <Button kind="primary">Добавить страницу</Button>
    </CreatePageModal>
  )

  return (
    <>
      {zone?.title && <Helmet title={`Страницы раздела · ${zone?.title || ""} · МосВики`} />}

      <PageBlockRow place="middle">
        <PageBlockRowSection
          gridGap={["10px", "20px"]}
          justifyContent={["space-between", "flex-start"]}
          flexWrap={["wrap", "nowrap"]}
          width={["100%", "auto"]}
        >
          <PageBlockHeading>Страницы раздела</PageBlockHeading>
          {isMobileView && checkZonePermission("PAGE_CREATE") && renderButton}
          <Box width={["100%", "auto"]}>
            <Search
              onSearch={onSearch}
              placeholder="Поиск страницы"
              fullWidth={["100%", "300px"]}
              width={["100%", "170px"]}
            />
          </Box>
        </PageBlockRowSection>
        {!isMobileView && checkZonePermission("PAGE_CREATE") && (
          <PageBlockRowSection>{renderButton}</PageBlockRowSection>
        )}
      </PageBlockRow>
      <PageBlockRow
        place="bottom"
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexWrap="nowrap"
      >
        <PageBlockRowSection maxWidth="90%">
          <FiltersBox
            filteredList={filtersList}
            filterParams={params}
            defaultParams={defaultFilterParams}
            setFilterParams={onChangeParams}
            isResetAllFilters
            isQuery
          />
        </PageBlockRowSection>
        <ViewSwitcher value={view} onChange={setView} />
      </PageBlockRow>

      <PageBlockContent>
        <PagesVirtualTable
          isRowView={false}
          additionalFilters={additionalFilters}
          onDeletePage={deletePage}
          view={view}
        />
      </PageBlockContent>
    </>
  )
}

export default PagesPage
