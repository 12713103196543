import React, { useRef, useState } from "react"
import { PlainTextEditor } from "./PlainTextEditor"
import { Button } from "@dit/core-frontend"
import { useOnChange } from "./useOnChange"

import { CLEAR_EDITOR_COMMAND } from "lexical"
import styles from "./styles.module.css"
import { useEditorCommentsContext } from "."
import { SubmitCommentIcon } from "../../theme/editorIcons"
import { Box } from "@mos-cat/ds"

export const CommentsComposer = ({ thread }) => {
  const [content, setContent] = useState("")
  const [canSubmit, setCanSubmit] = useState(false)
  const editorRef = useRef(null)
  const { createComment } = useEditorCommentsContext()
  const onChange = useOnChange(setContent, setCanSubmit)

  const submitComment = () => {
    if (canSubmit) {
      createComment(content, thread.id, thread.textBlockId)
      const editor = editorRef.current
      if (editor !== null) {
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined)
      }
    }
  }

  return (
    <Box position="relative" maxHeight="100px">
      <PlainTextEditor
        className={styles.CommentPlugin_CommentsPanel_Editor}
        autoFocus={false}
        onEscape={() => {
          return true
        }}
        onChange={onChange}
        editorRef={editorRef}
      />
      <Button
        className={styles.CommentPlugin_CommentsPanel_SendButton}
        onClick={submitComment}
        disabled={!canSubmit}
      >
        <SubmitCommentIcon />
      </Button>
    </Box>
  )
}
