import React, { useState, useCallback } from "react"

import { CurtainModal } from "@components/general"
import CardTemplateForm from "./CardTemplateForm"

export const PageTemplatesCurtainTrigger = ({ children, onClose, ...curtainProps }) => {
  const [isOpen, setIsOpen] = useState(false)

  const openCurtain = useCallback(() => {
    setIsOpen(true)
  }, [])

  return (
    <>
      {children(openCurtain)}
      <PageTemplatesCurtain
        isOpen={isOpen}
        onClose={(withUpdate) => {
          onClose?.(withUpdate)
          setIsOpen(false)
        }}
        {...curtainProps}
      />
    </>
  )
}

const PageTemplatesCurtain = ({ isOpen, onClose, isFormInViewMode, ...curtainProps }) => {
  return (
    <CurtainModal
      isOpen={isOpen}
      onClose={onClose}
      content={(closeCurtain) => (
        <CardTemplateForm
          closeCurtain={(withUpdate) => {
            onClose?.(withUpdate)
            closeCurtain()
          }}
          defaultIsFormInViewMode={isFormInViewMode}
          {...curtainProps}
        />
      )}
      isCloseVisible={false}
    />
  )
}

export default PageTemplatesCurtain
