// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history_arrow__o\\+dGo {
    transition: var(--default-transition);

    color: var(--gray-90);
}

.history_arrowRotate__RNtD2 {
    transform: rotate(-90deg);

    color: var(--gray-40);
}

.history_historyChildList__dkT4U {
    display: grid;

    overflow: hidden;

    transition: var(--default-transition);

    grid-template-rows: 1fr;
}

.history_historyChildList__dkT4U.history_hide__tAsOj {
    grid-template-rows: 0fr;
}


.history_historyChildList__dkT4U > div {
    min-height: 0;

    transition: var(--default-transition);
}

.history_historyChildList__dkT4U:not(.history_hide__tAsOj) > div {
    padding-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/History/history.module.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;;IAErC,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;;IAEzB,qBAAqB;AACzB;;AAEA;IACI,aAAa;;IAEb,gBAAgB;;IAEhB,qCAAqC;;IAErC,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;;AAGA;IACI,aAAa;;IAEb,qCAAqC;AACzC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".arrow {\n    transition: var(--default-transition);\n\n    color: var(--gray-90);\n}\n\n.arrowRotate {\n    transform: rotate(-90deg);\n\n    color: var(--gray-40);\n}\n\n.historyChildList {\n    display: grid;\n\n    overflow: hidden;\n\n    transition: var(--default-transition);\n\n    grid-template-rows: 1fr;\n}\n\n.historyChildList.hide {\n    grid-template-rows: 0fr;\n}\n\n\n.historyChildList > div {\n    min-height: 0;\n\n    transition: var(--default-transition);\n}\n\n.historyChildList:not(.hide) > div {\n    padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrow": `history_arrow__o+dGo`,
	"arrowRotate": `history_arrowRotate__RNtD2`,
	"historyChildList": `history_historyChildList__dkT4U`,
	"hide": `history_hide__tAsOj`
};
export default ___CSS_LOADER_EXPORT___;
