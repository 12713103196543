import React, { memo, useCallback, useEffect, useState } from "react"
import { $createCodeNode, $isCodeNode, CODE_LANGUAGE_FRIENDLY_NAME_MAP } from "@lexical/code"
import {
  $isListNode,
  ListNode,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_CHECK_LIST_COMMAND,
} from "@lexical/list"

import { INSERT_EMBED_COMMAND } from "@lexical/react/LexicalAutoEmbedPlugin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  $isQuoteNode,
} from "@lexical/rich-text"
import { TOGGLE_LINK_COMMAND } from "@lexical/link"
import {
  $getSelectionStyleValueForProperty,
  $patchStyleText,
  $wrapNodes,
  $setBlocksType,
} from "@lexical/selection"
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils"
import {
  $createParagraphNode,
  $getNodeByKey,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $isRootOrShadowRoot,
  $isTextNode,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  DEPRECATED_$isGridSelection,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical"
import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode"
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode"
import {
  BulletList,
  CenterAlign,
  LeftAlign,
  NextIcon,
  NumberedList,
  PrevIcon,
  RightAlign,
  ImageIcon,
  LinkIcon,
  CheckList,
  ColorText,
  BaseTextIcon,
  Heading1Icon,
  Heading3Icon,
  Heading2Icon,
  QuoteIcon,
  CodeIcon,
  TextIcon,
  MatchCaseIcon,
  BackgroundText,
  JustifyAlign,
  IndentIncreaseIcon,
  IndentDecreaseIcon,
  DelimiterIcon,
  PageBreakIcon,
  FileIcon,
  BoardIcon,
  TableIcon,
  ColumnIcon,
  NoteIcon,
  PlayIcon,
  StrikethroughIcon,
  SuperScriptIcon,
  SubScriptIcon,
  DeleteFormatIcon,
  TableOfContentIcon,
} from "../theme/editorIcons"

import { INSERT_IMAGE_COMMAND } from "./ImagesPlugin"
import { INSERT_FILE_COMMAND } from "./FilesPlugin"
import styles from "./toolbarPlugin.module.css"
import { ActionsPopover, Tooltip, useAppContext } from "@dit/core-frontend"
import { Box, Button, Stack, Text } from "@mos-cat/ds"
import { Add, ArrowDown } from "@mos-cat/ds-icons"
import { InsertTableDialog } from "./TablePlugin"
import { ColorPicker } from "../components/ColorPicker"
import { AutoEmbedDialog, EmbedConfigs } from "./AutoEmbdedPlugin"
import { sanitizeUrl } from "./LinkPlugin"
import InsertLayoutDialog from "./LayoutPlugin/InsertLayoutDialog"
import { INSERT_EXCALIDRAW_COMMAND } from "./ExalidrawPlugin"
import { INSERT_COLLAPSIBLE_COMMAND } from "./CollapsiblePlugin"
import { INSERT_PAGE_BREAK } from "./PageBreakPlugin"
import { $createStickyNode } from "../nodes/StickyNode"
import { INSERT_TABLE_OF_CONTENT } from "./TableOfContentPlugin"
import { InsertImageModal } from "../components/InsertImageDialog"
import { ImportPageModal } from "../components/ImportPageModal"

const buttonProps = {
  kind: "borderless",
  vertSize: "32",
  styles: {
    padding: "0 !important",
    "& > *": {
      display: "flex",
      alignItems: "center",
      gridGap: "5px",
      fontWeight: "400",
      color: "var(--gray-90)",
    },
    "&:hover > *, &:hover > * > *": {
      color: "var(--blue-60)",
    },
  },
}
const ACCEPTFORMATS = ["image/jpeg", "image/jpg", "image/png", "application/msword", "video/mp4"]
const blockTypeToBlockName = {
  paragraph: {
    value: "Обычный",
    icon: <BaseTextIcon />,
  },
  h1: {
    value: "Заголовок 1",
    icon: <Heading1Icon />,
  },
  h2: {
    value: "Заголовок 2",
    icon: <Heading2Icon />,
  },
  h3: {
    value: "Заголовок 3",
    icon: <Heading3Icon />,
  },
  quote: {
    value: "Цитата",
    icon: <QuoteIcon />,
  },
  code: {
    value: "Блок кода",
    icon: <CodeIcon />,
  },
}
//Подготовка для добавления шрифтов
const FONT_FAMILY_OPTIONS = [
  ["Arial", "Arial"],
  ["Courier New", "Courier New"],
  ["Georgia", "Georgia"],
  ["Times New Roman", "Times New Roman"],
  ["Trebuchet MS", "Trebuchet MS"],
  ["Verdana", "Verdana"],
]

const FONT_SIZE_OPTIONS = [
  ["10px", "10px"],
  ["12px", "12px"],
  ["14px", "14px"],
  ["16px", "16px"],
  ["18px", "18px"],
  ["20px", "20px"],
  ["22px", "22px"],
  ["24px", "24px"],
  ["26px", "26px"],
  ["28px", "28px"],
  ["30px", "30px"],
]

function getCodeLanguageOptions() {
  const options = []

  for (const [lang, friendlyName] of Object.entries(CODE_LANGUAGE_FRIENDLY_NAME_MAP)) {
    options.push([lang, friendlyName])
  }

  return options
}

const CODE_LANGUAGE_OPTIONS = getCodeLanguageOptions()

function BlockFormatDropDown({ editor, value, blockType, isMobileView }) {
  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection))
          $wrapNodes(selection, () => $createParagraphNode())
      })
    }
  }

  const formatHeading = (headingSize) => {
    if (blockType !== headingSize) {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $wrapNodes(selection, () => $createHeadingNode(headingSize))
        }
      })
    }
  }

  const formatQuote = () => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode())
        }
      })
    }
  }

  const formatCode = () => {
    if (blockType !== "code") {
      editor.update(() => {
        let selection = $getSelection()

        if (selection !== null) {
          if (selection.isCollapsed()) {
            $setBlocksType(selection, () => $createCodeNode())
          } else {
            const textContent = selection.getTextContent()
            const codeNode = $createCodeNode()
            selection.insertNodes([codeNode])
            selection = $getSelection()
            if ($isRangeSelection(selection)) selection.insertRawText(textContent)
          }
        }
      })
    }
  }

  function handleSelectChange(value) {
    // const value = e.target.value

    switch (value) {
      case "paragraph":
        formatParagraph()
        break
      case "h1":
        formatHeading("h1")
        break
      case "h2":
        formatHeading("h2")
        break
      case "h3":
        formatHeading("h3")
        break
      case "h4":
        formatHeading("h4")
        break
      case "h5":
        formatHeading("h5")
        break
      case "quote":
        formatQuote()
        break
      case "code":
        formatCode()
        break
      default:
        break
    }
  }

  return (
    <ActionsPopover
      content={
        <Button
          {...buttonProps}
          kind="borderless"
          vertSize="30"
          width={["unset", "135px"]}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" gridGap="2px">
            {blockTypeToBlockName[value]?.icon || blockTypeToBlockName["paragraph"].icon}
            {!isMobileView && (
              <Text fontSize="fs-14">
                {" "}
                {blockTypeToBlockName[value]?.value || blockTypeToBlockName["paragraph"].value}
              </Text>
            )}
            <Text fontSize="fs-14">
              <ArrowDown />
            </Text>
          </Box>
        </Button>
      }
    >
      {(buttonProps) => (
        <>
          {Object.keys(blockTypeToBlockName).map((type) => (
            <Button
              {...buttonProps}
              key={type.value}
              {...buttonProps}
              onClick={() => {
                handleSelectChange(type)
              }}
            >
              <Box display="flex" alignItems="center" gridGap="2px">
                {blockTypeToBlockName[type]?.icon}
                <Text>{blockTypeToBlockName[type].value}</Text>
              </Box>
            </Button>
          ))}
        </>
      )}
    </ActionsPopover>
  )
}

function FontDropDown({ editor, value, style, isMobileView }) {
  return (
    <ActionsPopover
      content={
        <Button
          {...buttonProps}
          kind="borderless"
          vertSize="30"
          width={["unset", "64px"]}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" gridGap="2px">
            <Text fontSize="fs-14"> {isMobileView ? "px" : value || FONT_SIZE_OPTIONS[0][0]}</Text>
            <Text fontSize="fs-14">
              <ArrowDown />
            </Text>
          </Box>
        </Button>
      }
    >
      {(buttonProps) => (
        <>
          {FONT_SIZE_OPTIONS.map(([option, text]) => (
            <Button
              {...buttonProps}
              key={text}
              {...buttonProps}
              onClick={() => {
                editor.update(() => {
                  const selection = $getSelection()
                  if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, {
                      [style]: option,
                    })
                  }
                })
              }}
            >
              {text}
            </Button>
          ))}
        </>
      )}
    </ActionsPopover>
  )
}

function FontFamilyDropDown({ editor, value, style, isMobileView }) {
  return (
    <ActionsPopover
      content={
        <Button
          {...buttonProps}
          kind="borderless"
          vertSize="30"
          width={["unset", "120px"]}
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" gridGap="2px">
            <TextIcon />
            {!isMobileView && <Text fontSize="fs-14"> {value || FONT_FAMILY_OPTIONS[0][0]}</Text>}
            <Text fontSize="fs-14">
              <ArrowDown />
            </Text>
          </Box>
        </Button>
      }
    >
      {(buttonProps) => (
        <>
          {FONT_FAMILY_OPTIONS.map(([option, text]) => (
            <Button
              {...buttonProps}
              key={text}
              {...buttonProps}
              onClick={() => {
                editor.update(() => {
                  const selection = $getSelection()
                  if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, {
                      [style]: option,
                    })
                  }
                })
              }}
            >
              {text}
            </Button>
          ))}
        </>
      )}
    </ActionsPopover>
  )
}
const FormatedList = ({ editor, blockType }) => {
  const formatBulletList = () => {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatCheckList = () => {
    if (blockType !== "check") {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  return (
    <Box display="flex" alignItems="center" gridGap="10px">
      <Tooltip content="Маркировочный список">
        <Button {...buttonProps} width="16px" kind="borderless" onClick={formatBulletList}>
          <BulletList />
        </Button>
      </Tooltip>
      <Tooltip content="Нумерованный список">
        <Button {...buttonProps} kind="borderless" onClick={formatNumberedList}>
          <NumberedList />
        </Button>
      </Tooltip>
      <Tooltip content="Чеклист">
        <Button {...buttonProps} kind="borderless" onClick={formatCheckList}>
          <CheckList />
        </Button>
      </Tooltip>
    </Box>
  )
}

export const ToolbarPlugin = memo(({ setIsLinkEditMode, onFileUploadStart }) => {
  const [editor] = useLexicalComposerContext()
  const [activeEditor, setActiveEditor] = useState(
    editor?._parentEditor ? editor?._parentEditor : editor,
  )
  const [fontSize, setFontSize] = useState("14px")
  const [blockType, setBlockType] = useState("paragraph")
  const [selectedElementKey, setSelectedElementKey] = useState(null)
  const [fontColor, setFontColor] = useState("#000")
  const [bgColor, setBgColor] = useState("#fff")
  const [fontFamily, setFontFamily] = useState("Arial")
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isLink, setIsLink] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [isEditable, setIsEditable] = useState(() => editor.isEditable())
  const [showModal, setShowModal] = useState(null)
  const handleSetModal = (value) => {
    setShowModal(value)
  }

  const { isMobileView } = useAppContext()

  const updateToolbar = useCallback(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode()
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent()
              return parent !== null && $isRootOrShadowRoot(parent)
            })

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow()
      }

      const elementKey = element.getKey()
      const elementDOM = activeEditor.getElementByKey(elementKey)

      setIsBold(selection.hasFormat("bold"))
      setIsItalic(selection.hasFormat("italic"))
      setIsUnderline(selection.hasFormat("underline"))
      setIsStrikethrough(selection.hasFormat("strikethrough"))

      // const node = getSelectedNode(selection);
      // const parent = node.getParent();
      // if ($isLinkNode(parent) || $isLinkNode(node)) {
      //   setIsLink(true);
      // } else {
      //   setIsLink(false);
      // }

      // const tableNode = $findMatchingParent(node, $isTableNode);
      // if ($isTableNode(tableNode)) {
      //   setRootType('table');
      // } else {
      //   setRootType('root');
      // }

      if (elementDOM !== null) {
        setSelectedElementKey(elementKey)
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode)
          const type = parentList ? parentList.getListType() : element.getListType()
          setBlockType(type)
        } else {
          const type = $isHeadingNode(element) ? element.getTag() : element.getType()
          if (type in blockTypeToBlockName) {
            setBlockType(type)
          }
        }
      }

      setFontSize($getSelectionStyleValueForProperty(selection, "font-size", "16px"))
      setFontFamily($getSelectionStyleValueForProperty(selection, "font-family", "Arial"))
      setFontColor($getSelectionStyleValueForProperty(selection, "color", "#000"))
      setBgColor($getSelectionStyleValueForProperty(selection, "background-color", "#fff"))
    }
  }, [activeEditor])

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar()
        setActiveEditor(newEditor?._parentEditor ? newEditor._parentEditor : newEditor)
        return false
      },
      COMMAND_PRIORITY_CRITICAL,
    )
  }, [editor, updateToolbar])

  useEffect(() => {
    return mergeRegister(
      editor.registerEditableListener((editable) => {
        setIsEditable(editable)
      }),
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar()
        })
      }),
      activeEditor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
      activeEditor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload)
          return false
        },
        COMMAND_PRIORITY_CRITICAL,
      ),
    )
  }, [activeEditor, editor, updateToolbar])

  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor
        const focus = selection.focus
        const nodes = selection.getNodes()

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            // Use a separate variable to ensure TS does not lose the refinement
            let textNode = node
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode
            }

            if (textNode.__style !== "") {
              textNode.setStyle("")
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0)
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat("")
            }
            node = textNode
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true)
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat("")
          }
        })
      }
    })
  }, [activeEditor])

  //цвет текста

  const applyStyleText = useCallback(
    (styles, skipHistoryStack) => {
      activeEditor.update(
        () => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            $patchStyleText(selection, styles)
          }
        },
        skipHistoryStack ? { tag: "historic" } : {},
      )
    },
    [activeEditor],
  )
  const onFontColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ color: value }, skipHistoryStack)
    },
    [applyStyleText],
  )

  const onBgColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ "background-color": value }, skipHistoryStack)
    },
    [applyStyleText],
  )

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true)
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, sanitizeUrl("https://"))
    } else {
      setIsLinkEditMode(false)
      activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [activeEditor, isLink, setIsLinkEditMode])

  const onCodeLanguageSelect = useCallback(
    (value) => {
      activeEditor.update(() => {
        if (selectedElementKey !== null) {
          const node = $getNodeByKey(selectedElementKey)
          if ($isCodeNode(node)) {
            node.setLanguage(value)
          }
        }
      })
    },
    [activeEditor, selectedElementKey],
  )

  return (
    <div className={styles.toolbarItems}>
      <Box display="flex" alignItems="center" gridGap="7px">
        <Tooltip content="Отменить ввод">
          <Button
            {...buttonProps}
            kind="borderless"
            vertSize="32"
            disabled={!canUndo || !isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(UNDO_COMMAND, undefined)
            }}
            title="Undo"
            type="button"
            className=""
          >
            <PrevIcon />
          </Button>
        </Tooltip>

        <Tooltip content="Вернуть ввод">
          <Button
            {...buttonProps}
            kind="borderless"
            vertSize="32"
            disabled={!canRedo || !isEditable}
            onClick={() => {
              activeEditor.dispatchCommand(REDO_COMMAND, undefined)
            }}
            title="Redo"
            type="button"
            className=""
          >
            <NextIcon />
          </Button>
        </Tooltip>
      </Box>

      {blockType !== "code" && (
        <>
          <div className={styles.separator}></div>
          <Tooltip content="Сформировать оглавление">
            <Button
              {...buttonProps}
              kind="borderless"
              disabled={!isEditable}
              onClick={() => {
                activeEditor.dispatchCommand(INSERT_TABLE_OF_CONTENT, undefined)
              }}
            >
              <TableOfContentIcon />
            </Button>
          </Tooltip>
          <div className={styles.separator}></div>
        </>
      )}

      <BlockFormatDropDown
        disabled={!isEditable}
        blockType={blockType}
        editor={editor}
        value={blockType}
        label={blockType}
        isMobileView={isMobileView}
      />
      <div className={styles.separator}></div>
      {blockType === "code" ? (
        <>
          <ActionsPopover
            content={
              <Button
                {...buttonProps}
                kind="borderless"
                vertSize="30"
                width="70px"
                display="flex"
                justifyContent="start"
                alignItems="center"
              >
                <Box display="flex">
                  <Text fontSize="fs-14">язык</Text>
                  <Text fontSize="fs-14">
                    <ArrowDown />
                  </Text>
                </Box>
              </Button>
            }
          >
            {(buttonProps) => (
              <>
                {CODE_LANGUAGE_OPTIONS.map(([value, name]) => (
                  <Button
                    {...buttonProps}
                    {...buttonProps}
                    key={value}
                    onClick={() => onCodeLanguageSelect(value)}
                  >
                    {name}
                  </Button>
                ))}
              </>
            )}
          </ActionsPopover>
          <div className={styles.separator}></div>
        </>
      ) : (
        <>
          <FontDropDown
            disabled={!isEditable}
            style={"font-size"}
            value={fontSize}
            editor={editor}
            isMobileView={isMobileView}
          />
          <div className={styles.separator}></div>
          <FontFamilyDropDown
            disabled={!isEditable}
            style={"font-family"}
            value={fontFamily}
            editor={editor}
            isMobileView={isMobileView}
          />
          <div className={styles.separator}></div>
          <Box display="flex" alignItems="center" gridGap="10px">
            <Tooltip content="Применение полужирного начертания к тексту">
              <Button
                {...buttonProps}
                kind="borderless"
                disabled={!isEditable}
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
                }}
                className={`${styles.boldBtn} ` + (isBold ? ` ${styles.active}` : "")}
              >
                <span>B</span>
              </Button>
            </Tooltip>
            <Tooltip content="Применение курсивного начертания к тексту">
              <Button
                {...buttonProps}
                kind="borderless"
                disabled={!isEditable}
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
                }}
                className={`${styles.italicBtn} ` + (isItalic ? ` ${styles.active}` : "")}
              >
                <span>I</span>
              </Button>
            </Tooltip>
            <Tooltip content="Подчеркивание текста">
              <Button
                {...buttonProps}
                kind="borderless"
                disabled={!isEditable}
                onClick={() => {
                  activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
                }}
                className={`${styles.underLineBtn} ` + (isUnderline ? ` ${styles.active}` : "")}
              >
                <span>U</span>
              </Button>
            </Tooltip>
          </Box>

          <div className={styles.separator}></div>
          <Box paddingRight="10px" display="flex" alignItems="center">
            <Tooltip content="Добавление ссылки">
              <Button
                {...buttonProps}
                kind="borderless"
                disabled={!isEditable}
                onClick={insertLink}
              >
                <span>
                  <LinkIcon />
                </span>
              </Button>
            </Tooltip>
          </Box>
          <FormatedList disabled={!isEditable} blockType={blockType} editor={editor} />
          <div className={styles.separator}></div>
          <ActionsPopover
            aria-expanded={true}
            content={
              <Tooltip content="Изменение цвета текста">
                <Button
                  {...buttonProps}
                  kind="borderless"
                  vertSize="30"
                  width="30px"
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <Text fontSize="fs-14">
                      <ColorText />
                    </Text>
                    <Text fontSize="fs-14">
                      <ArrowDown />
                    </Text>
                  </Box>
                </Button>
              </Tooltip>
            }
          >
            {() => (
              <>
                <ColorPicker color={fontColor} onChange={onFontColorSelect} />
              </>
            )}
          </ActionsPopover>
          <div className={styles.separator}></div>
          <ActionsPopover
            aria-expanded={true}
            content={
              <Tooltip content="Изменение фона текста">
                <Button
                  {...buttonProps}
                  kind="borderless"
                  vertSize="30"
                  width="30px"
                  display="flex"
                  justifyContent="start"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <Text fontSize="fs-14">
                      <BackgroundText />
                    </Text>
                    <Text fontSize="fs-14">
                      <ArrowDown />
                    </Text>
                  </Box>
                </Button>
              </Tooltip>
            }
          >
            {() => (
              <>
                <ColorPicker color={bgColor} onChange={onBgColorSelect} />
              </>
            )}
          </ActionsPopover>
          <div className={styles.separator}></div>
          <ActionsPopover
            content={
              <Button
                {...buttonProps}
                kind="borderless"
                vertSize="30"
                width="30px"
                display="flex"
                justifyContent="start"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <Text fontSize="fs-14">
                    <MatchCaseIcon />
                  </Text>
                  <Text fontSize="fs-14">
                    <ArrowDown />
                  </Text>
                </Box>
              </Button>
            }
          >
            {(buttonProps) => (
              <>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
                  }}
                >
                  <StrikethroughIcon />
                  <Text>Зачеркнутый</Text>
                </Button>

                <Button
                  {...buttonProps}
                  onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript")
                  }}
                >
                  <SuperScriptIcon />
                  <Text>Надстрочный</Text>
                </Button>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript")
                  }}
                >
                  <SubScriptIcon />
                  <Text>Подстрочный</Text>
                </Button>
                <Button {...buttonProps} {...buttonProps} onClick={clearFormatting}>
                  <DeleteFormatIcon />
                  <Text>Очистка формата</Text>
                </Button>
              </>
            )}
          </ActionsPopover>
          <div className={styles.separator}></div>
        </>
      )}

      <ActionsPopover
        content={
          <Button
            {...buttonProps}
            kind="borderless"
            vertSize="30"
            width="30px"
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Text fontSize="fs-14">
                <LeftAlign />
              </Text>
              <Text fontSize="fs-14">
                <ArrowDown />
              </Text>
            </Box>
          </Button>
        }
      >
        {(buttonProps) => (
          <>
            <Button
              {...buttonProps}
              {...buttonProps}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")
              }}
            >
              <LeftAlign /> По левому краю
            </Button>
            <Button
              {...buttonProps}
              {...buttonProps}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")
              }}
            >
              <CenterAlign /> По центру
            </Button>
            <Button
              {...buttonProps}
              {...buttonProps}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")
              }}
            >
              <RightAlign /> По правому краю
            </Button>
            <Button
              {...buttonProps}
              {...buttonProps}
              onClick={() => {
                activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")
              }}
            >
              <JustifyAlign /> По ширине
            </Button>
            <Button
              {...buttonProps}
              {...buttonProps}
              onClick={() => {
                activeEditor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)
              }}
            >
              <IndentIncreaseIcon /> Увеличить отступ
            </Button>
            <Button
              {...buttonProps}
              {...buttonProps}
              onClick={() => {
                activeEditor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
              }}
            >
              <IndentDecreaseIcon /> Уменьшить отступ
            </Button>
          </>
        )}
      </ActionsPopover>
      {blockType !== "code" && (
        <>
          <div className={styles.separator}></div>
          <ActionsPopover
            content={
              <Button
                {...buttonProps}
                kind="borderless"
                vertSize="32"
                display="flex"
                justifyContent="start"
                alignItems="center"
                gridGap="5px"
              >
                <Box display="flex">
                  {" "}
                  <Add />
                  {!isMobileView && <Text fontSize="fs-14">Добавить</Text>}
                  <Text fontSize="fs-14">
                    <ArrowDown />
                  </Text>
                </Box>
              </Button>
            }
          >
            {(buttonProps) => (
              <>
                <Button
                  {...buttonProps}
                  onClick={() => handleSetModal("create-image")}
                  kind="borderless"
                >
                  <ImageIcon />
                  <Text>Изображение</Text>
                </Button>

                <Button kind="borderless" {...buttonProps} onClick={onFileUploadStart}>
                  <FileIcon />
                  <Text>{"Файл"}</Text>
                </Button>
                <InsertLayoutDialog
                  modalDisclosureProps={{
                    as: Stack,
                    position: "relative",
                    styles: { cursor: "pointer" },
                  }}
                  activeEditor={activeEditor}
                >
                  <Button {...buttonProps} kind="borderless">
                    <ColumnIcon />
                    <Text>Столбцы</Text>
                  </Button>
                </InsertLayoutDialog>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    activeEditor.dispatchCommand(INSERT_COLLAPSIBLE_COMMAND, undefined)
                  }}
                >
                  <PlayIcon />
                  <Text> Спойлер</Text>
                </Button>
                {activeEditor._config.namespace === "Editor" && (
                  <Button
                    {...buttonProps}
                    onClick={() => {
                      activeEditor.update(() => {
                        const root = $getRoot()
                        const stickyNode = $createStickyNode(0, 0)
                        root.append(stickyNode)
                      })
                    }}
                  >
                    <NoteIcon />
                    <Text>Заметка</Text>
                  </Button>
                )}

                <Button
                  {...buttonProps}
                  onClick={() => handleSetModal("import-page")}
                  kind="borderless"
                >
                  <FileIcon />
                  <Text>Импорт страницы</Text>
                </Button>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    activeEditor.dispatchCommand(INSERT_PAGE_BREAK, undefined)
                  }}
                >
                  {" "}
                  <PageBreakIcon />
                  <Text> Разрыв страницы</Text>
                </Button>
                <Button
                  {...buttonProps}
                  onClick={() => {
                    activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined)
                  }}
                >
                  <DelimiterIcon />
                  <Text>Разделитель</Text>
                </Button>
                <Button
                  {...buttonProps}
                  kind="borderless"
                  onClick={() => {
                    activeEditor.dispatchCommand(INSERT_EXCALIDRAW_COMMAND, undefined)
                  }}
                  // {...buttonProps}
                  // onClick={() => {
                  //   activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
                  // }}
                >
                  <BoardIcon />
                  <Text>Доска</Text>
                </Button>
                <Button
                  {...buttonProps}
                  onClick={() => handleSetModal("create-table")}
                  kind="borderless"
                  // {...buttonProps}
                  // onClick={() => {
                  //   activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
                  // }}
                >
                  <TableIcon />
                  <Text>Таблица</Text>
                </Button>

                {EmbedConfigs.map((embedConfig) => (
                  <AutoEmbedDialog
                    key={embedConfig.type}
                    embedConfig={embedConfig}
                    modalDisclosureProps={{
                      as: Stack,
                      position: "relative",
                      styles: { cursor: "pointer" },
                    }}
                  >
                    <Button
                      {...buttonProps}
                      kind="borderless"
                      onClick={() => {
                        activeEditor.dispatchCommand(INSERT_EMBED_COMMAND, embedConfig.type)
                        handleSetModal(embedConfig.type)
                      }}
                    >
                      {embedConfig.icon}
                      <Text>{embedConfig.contentName}</Text>
                    </Button>
                  </AutoEmbedDialog>
                ))}
              </>
            )}
          </ActionsPopover>
          <ImportPageModal
            activeEditor={activeEditor}
            modalDisclosureProps={{
              as: Stack,
              position: "relative",
              styles: { cursor: "pointer" },
            }}
            modalStateProps={{
              visible: showModal === "import-page",
              hide: () => handleSetModal(null),
            }}
          ></ImportPageModal>
          <InsertTableDialog
            activeEditor={activeEditor}
            modalDisclosureProps={{
              as: Stack,
              position: "relative",
              styles: { cursor: "pointer" },
            }}
            modalStateProps={{
              visible: showModal === "create-table",
              hide: () => handleSetModal(null),
            }}
          ></InsertTableDialog>
          <InsertImageModal
            activeEditor={activeEditor}
            modalDisclosureProps={{
              as: Stack,
              position: "relative",
              styles: { cursor: "pointer" },
            }}
            modalStateProps={{
              visible: showModal === "create-image",
              hide: () => handleSetModal(null),
            }}
          ></InsertImageModal>
          {EmbedConfigs.map((embedConfig) => (
            <AutoEmbedDialog
              key={embedConfig.type}
              embedConfig={embedConfig}
              modalDisclosureProps={{
                as: Stack,
                position: "relative",
                styles: { cursor: "pointer" },
              }}
              modalStateProps={{
                visible: showModal === embedConfig.type,
                hide: () => handleSetModal(null),
              }}
            />
          ))}
        </>
      )}
    </div>
  )
})

ToolbarPlugin.displayName = "ToolbarPlugin"
