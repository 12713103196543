import styles from "./editor.module.css"

export const theme = {
  ltr: styles.editor__ltr,
  rtl: styles.editor__rtl,
  placeholder: styles.editor__placeholder,
  paragraph: styles.editor__paragraph,
  quote: styles.editor__quote,
  heading: {
    h1: styles.editor__h1,
    h2: styles.editor__h2,
    h3: styles.editor__h3,
    h4: styles.editor__h4,
    h5: styles.editor__h5,
  },
  list: {
    nested: {
      listitem: styles.editor__nestedListItem,
    },
    ol: styles.editor__listOl,
    ul: styles.editor__listUl,
    listitem: styles.editor__listItem,
    checklist: styles.editor__checklist,
    listitemChecked: styles.editor__listItemChecked,
    listitemUnchecked: styles.editor__listItemUnchecked,
  },
  image: styles.editor__image,
  file: styles.editor__file,
  link: styles.editor__link,
  text: {
    bold: styles.editor__textBold,
    italic: styles.editor__textItalic,
    //overflowed: styles.editor__textOverflowed,
    //hashtag: styles.editor__textHashtag,
    underline: styles.editor__textUnderline,
    strikethrough: styles.editor__textStrikethrough,
    underlineStrikethrough: styles.editor__textUnderlineStrikethrough,
    code: styles.editor__textCode,
  },
  mark: styles.editor__mark,
  markOverlap: styles.editor__markOverlap,
  markSelected: styles.editor__markSelected,
  table: styles.editor__table,
  tablePreview: styles.editor__tablePreview,
  tableAddColumns: styles.editor__tableAddColumns,
  tableAddRows: styles.editor__tableAddRows,
  tableCell: styles.editor__tableCell,
  tableCellActionButton: styles.editor__tableCellActionButton,
  tableCellActionButtonContainer: styles.editor__tableCellActionButtonContainer,
  tableCellEditing: styles.editor__tableCellEditing,
  tableCellHeader: styles.editor__tableCellHeader,
  tableCellPrimarySelected: styles.editor__tableCellPrimarySelected,
  tableCellResizer: styles.editor__tableCellResizer,
  tableCellSelected: styles.editor__tableCellSelected,
  tableCellSortedIndicator: styles.editor__tableCellSortedIndicator,
  tableResizeRuler: styles.editor__tableCellResizeRuler,
  tableSelected: styles.editor__tableSelected,

  code: styles.editor__code,
  codeHighlight: {
    atrule: styles.editor__tokenAttr,
    attr: styles.editor__tokenAttr,
    boolean: styles.editor__tokenProperty,
    builtin: styles.editor__tokenSelector,
    cdata: styles.editor__tokenComment,
    char: styles.editor__tokenSelector,
    class: styles.editor__tokenFunction,
    "class-name": styles.editor__tokenFunction,
    comment: styles.editor__tokenComment,
    constant: styles.editor__tokenProperty,
    deleted: styles.editor__tokenProperty,
    doctype: styles.editor__tokenComment,
    entity: styles.editor__tokenOperator,
    function: styles.editor__tokenFunction,
    important: styles.editor__tokenVariable,
    inserted: styles.editor__tokenSelector,
    keyword: styles.editor__tokenAttr,
    namespace: styles.editor__tokenVariable,
    number: styles.editor__tokenProperty,
    operator: styles.editor__tokenOperator,
    prolog: styles.editor__tokenComment,
    property: styles.editor__tokenProperty,
    punctuation: styles.editor__tokenPunctuation,
    regex: styles.editor__tokenVariable,
    selector: styles.editor__tokenSelector,
    string: styles.editor__tokenSelector,
    symbol: styles.editor__tokenProperty,
    tag: styles.editor__tokenProperty,
    url: styles.editor__tokenOperator,
    variable: styles.editor__tokenVariable,
  },
  embedBlock: {
    base: styles.editor__embedBlock,
    focus: styles.editor__embedBlockFocus,
  },
  layoutContainer: styles.editor__layoutContainer,
  layoutItem: styles.editor__layoutItem,
  mention: styles.editor__mention,
}

export default theme
