import { ActionsPopover, Button } from "@dit/core-frontend"
import { Box, Text } from "@mos-cat/ds"
import { ArrowDown } from "@mos-cat/ds-icons"
import React from "react"
import { $getSelection, $isRangeSelection } from "lexical"

import { $patchStyleText } from "@lexical/selection"
const buttonProps = {
  kind: "borderless",
  vertSize: "32",
  styles: {
    padding: "0 !important",
    "& > *": {
      display: "flex",
      alignItems: "center",
      gridGap: "5px",
      fontWeight: "400",
      color: "var(--gray-90)",
    },
    "&:hover > *, &:hover > * > *": {
      color: "var(--blue-60)",
    },
  },
}

const FONT_SIZE_OPTIONS = [
  ["10px", "10px"],
  ["12px", "12px"],
  ["14px", "14px"],
  ["16px", "16px"],
  ["18px", "18px"],
  ["20px", "20px"],
  ["22px", "22px"],
  ["24px", "24px"],
  ["26px", "26px"],
  ["28px", "28px"],
  ["30px", "30px"],
]

const FontSizeDropDown = ({ editor, value, style }) => {
  return (
    <ActionsPopover
      content={
        <Button
          {...buttonProps}
          kind="borderless"
          vertSize="30"
          width="64px"
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Box display="flex" alignItems="center" gridGap="2px" color="#13151A">
            <Text fontSize="fs-14"> {value || FONT_SIZE_OPTIONS[0][0]}</Text>
            <Text fontSize="fs-14">
              <ArrowDown />
            </Text>
          </Box>
        </Button>
      }
    >
      {(buttonProps) => (
        <>
          {FONT_SIZE_OPTIONS.map(([option, text]) => (
            <Button
              {...buttonProps}
              key={text}
              {...buttonProps}
              onClick={() => {
                editor.update(() => {
                  const selection = $getSelection()
                  if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, {
                      [style]: option,
                    })
                  }
                })
              }}
            >
              {text}
            </Button>
          ))}
        </>
      )}
    </ActionsPopover>
  )
}

export default FontSizeDropDown
