import React from "react"

import { EditorComposer } from "@components/form/Editor/context/LexicalComposer"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { PlaceholderComp } from "./EditorPreview"

import styles from "./editor.module.css"

const EditorHtmlGenerator = ({ content, onGenerateHtmlString }) => {
  const onCreateEditor = ($editor) => {
    onGenerateHtmlString($editor?.innerHTML || "")
  }

  return (
    <EditorComposer readonly jsonState={content}>
      <div ref={onCreateEditor} style={{ display: "none" }}>
        <RichTextPlugin
          ErrorBoundary={LexicalErrorBoundary}
          contentEditable={<ContentEditable className={styles.editorPreview__input} />}
          placeholder={<PlaceholderComp />}
        />
      </div>
    </EditorComposer>
  )
}

export default EditorHtmlGenerator
