import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { mergeRegister } from "@lexical/utils"
import {
  $createTextNode,
  $getSelection,
  $setSelection,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_NORMAL,
  KEY_BACKSPACE_COMMAND,
  KEY_DOWN_COMMAND,
  PASTE_COMMAND,
} from "lexical"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import * as ReactDOM from "react-dom"

import { $createMentionNode, $isMentionNode } from "../nodes/MentionNode"
import { MenuOption } from "./Menu"
import {
  $insertMentionAtSelection,
  $insertTriggerAtSelection,
  $removeMention,
  $renameMention,
  INSERT_MENTION_COMMAND,
  OPEN_MENTION_MENU_COMMAND,
  REMOVE_MENTIONS_COMMAND,
  RENAME_MENTIONS_COMMAND,
} from "./mention-commands"
import {
  $getSelectionInfo,
  $selectEnd,
  DEFAULT_PUNCTUATION,
  LENGTH_LIMIT,
  TRIGGERS,
  VALID_CHARS,
  getMenuItemLimitProp,
  getTextContent,
  isWordChar,
} from "./mention-utils"
import { useIsFocused } from "./useIsFocused"
import {
  VirtualList,
  apiClient,
  DefaultTypeIcon,
  useAppContext,
  ERROR_MESSAGES,
} from "@dit/core-frontend"

import { Box, Text } from "@mos-cat/ds"
import { API_ENDPOINTS } from "@src/constants"
import { debounce } from "lodash-es"
import { taskTypeIconsBySlug } from "../utils/TaskTypeIconsBySlug"
import { defaultColorProps, statusBySlug } from "../utils/statusBySlug"
import { LexicalTypeaheadMenuPlugin } from "./TypeaheadMenuPlugin"
import OutsideClickHandler from "react-outside-click-handler"

export const CAN_USE_DOM =
  typeof window !== "undefined" &&
  typeof window.document !== "undefined" &&
  typeof window.document.createElement !== "undefined"

export const IS_IOS =
  CAN_USE_DOM &&
  /iPad|iPhone|iPod/.test(navigator.userAgent) &&
  // @ts-ignore
  !window.MSStream

export const IS_MOBILE = CAN_USE_DOM && window.matchMedia("(pointer: coarse)").matches

class MentionOption extends MenuOption {
  menuItem
  constructor(trigger, value, displayValue, data) {
    super(value, displayValue, data)
    this.menuItem = {
      trigger,
      value,
      displayValue,
      data,
    }
  }
}

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS = (punctuation) =>
  "(?:" +
  "\\.[ |$]|" + // E.g. "r. " in "Mr. Smith"
  "\\s|" + // E.g. " " in "Josh Duck"
  "[" +
  punctuation +
  "]|" + // E.g. "-' in "Salier-Hellendag"
  ")"

// Regex used to trigger the mention menu.
function createMentionsRegex(triggers, punctuation, allowSpaces) {
  return new RegExp(
    "(^|\\s|\\()(" +
      TRIGGERS(triggers) +
      "((?:" +
      VALID_CHARS(triggers, punctuation) +
      (allowSpaces ? VALID_JOINS(punctuation) : "") +
      "){0," +
      LENGTH_LIMIT +
      "})" +
      ")$",
  )
}

export function checkForMentions(text, triggers, punctuation, allowSpaces) {
  const match = createMentionsRegex(triggers, punctuation, allowSpaces).exec(text)
  if (match !== null) {
    // The strategy ignores leading whitespace, but we need to know its
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1]
    const matchingStringWithTrigger = match[2]
    const matchingString = match[3]
    if (matchingStringWithTrigger.length >= 1) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString: matchingString,
        replaceableString: matchingStringWithTrigger,
      }
    }
  }
  return null
}

/**
 * A plugin that adds mentions to the lexical editor.
 */

const triggers = ["#"]

export function MentionsPlugin(props) {
  const {
    allowSpaces = true,
    insertOnBlur = false,
    creatable = false,
    menuComponent: MenuComponent = "ul",
    menuItemComponent: MenuItemComponent = "li",
    menuAnchorClassName,
    showMentionsOnDelete,
    showCurrentMentionsAsSuggestions = true,
    mentionEnclosure,
    onMenuItemSelect,

    punctuation = DEFAULT_PUNCTUATION,
  } = props
  const justSelectedAnOption = useRef(false)
  const isEditorFocused = useIsFocused()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [itemsTotal, setItemsTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const selectedMenuIndexRef = useRef()
  const [oldSelection, setOldSelection] = useState(null)
  const [editor] = useLexicalComposerContext()
  const [queryString, setQueryString] = useState(null)
  const [previousQueryString, setPreviousQueryString] = useState("")
  const [trigger, setTrigger] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const menuItemLimit = getMenuItemLimitProp(props.menuItemLimit, trigger)
  const { showAlert } = useAppContext()

  const debouncedSetQueryString = debounce(setQueryString, 500)

  const handleQueryChange = (newQuery) => {
    setPreviousQueryString(queryString)
    setPage(1)
    setQueryString(newQuery)
  }

  const getUsers = useCallback(
    async (search, page) => {
      try {
        setIsLoading(true)
        const { data } = await apiClient.get(API_ENDPOINTS.tasksListShort, {
          params: {
            search: search,
            epp: 10,
            eppOffset: (page - 1) * 10,
          },
        })
        return data.data || []
      } catch (err) {
        showAlert(err?.data?.errors?.[0]?.message || ERROR_MESSAGES.default)
        return []
      }
    },
    [showAlert],
  )

  useEffect(() => {
    if (trigger && queryString !== null) {
      getUsers(queryString, page).then((data) => {
        if (data && data?.itemsList) {
          if (queryString !== previousQueryString) {
            setItems([])
            setPreviousQueryString(queryString)
          }
          setItems((prev) => {
            if (Array.isArray(prev)) {
              return [...prev, ...data.itemsList]
            } else {
              return [prev, ...data.itemsList]
            }
          })
          setItemsTotal(data?.itemsTotal)
        }
        setIsLoading(false)
      })
    }
  }, [trigger, page, queryString])

  // const { results, query } = useMentionLookupService({
  //   queryString,
  //   searchDelay,
  //   trigger,
  //   items,
  //   onSearch,
  //   justSelectedAnOption,
  // })

  const options = useMemo(() => {
    if (!trigger) {
      return []
    }
    // Add options from the lookup service
    let opt = items.map((result) => {
      if (typeof result === "string") {
        return new MentionOption(trigger, result, result)
      } else {
        return new MentionOption(trigger, result, result, result)
      }
    })

    // limit the number of menu items
    if (menuItemLimit !== false && menuItemLimit > 0) {
      opt = opt.slice(0, menuItemLimit)
    }
    // Add mentions from the editor. When a search function is provided, wait for the
    // delayed search to prevent flickering.

    setLoading(false)
    return opt
  }, [trigger, menuItemLimit, items])
  const handleAddPage = useCallback(() => {
    if (items.length < itemsTotal) {
      setPage((prev) => prev + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, itemsTotal, page])
  const handleClose = useCallback(() => {
    setTrigger(null)
    setItems([])
    setItemsTotal(0)
    setPage(1)
  }, [])

  const handleSelectOption = useCallback(
    (selectedOption, nodeToReplace, closeMenu) => {
      editor.update(() => {
        if (!trigger) {
          return
        }

        const value = selectedOption.displayValue
        const mentionNode = $createMentionNode(trigger, value, selectedOption.menuItem)
        if (nodeToReplace) {
          nodeToReplace.replace(mentionNode)
        }
        closeMenu?.()
        justSelectedAnOption.current = true
      })
    },
    [editor, trigger],
  )

  const handleSelectMenuItem = useCallback(
    (selectedOption, nodeToReplace, closeMenu) => {
      if (!trigger) {
        return
      }
      onMenuItemSelect?.({
        trigger,
        value: selectedOption.value,
        displayValue: selectedOption.displayValue,
        data: selectedOption.data,
      })
      handleSelectOption(selectedOption, nodeToReplace, closeMenu)
    },
    [handleSelectOption, onMenuItemSelect, trigger],
  )

  const checkForMentionMatch = useCallback(
    (text) => {
      // Don't show the menu if the next character is a word character
      const selectionInfo = $getSelectionInfo(triggers, punctuation)
      if (selectionInfo?.isTextNode && selectionInfo.wordCharAfterCursor) {
        return null
      }

      const queryMatch = checkForMentions(text, triggers, punctuation, allowSpaces)

      if (queryMatch) {
        const { replaceableString, matchingString } = queryMatch
        const index = replaceableString.lastIndexOf(matchingString)
        const trigger =
          index === -1
            ? replaceableString
            : replaceableString.substring(0, index) +
              replaceableString.substring(index + matchingString.length)
        setTrigger(trigger || null)
        if (queryMatch.replaceableString) {
          return queryMatch
        }
      } else {
        setTrigger(null)
      }

      return null
    },
    [allowSpaces, punctuation],
  )

  const convertTextToMention = useCallback(() => {
    const selectedMenuIndex = selectedMenuIndexRef.current
    let option = typeof selectedMenuIndex === "number" ? items[selectedMenuIndex] : undefined
    const newMention = items.find((o) => o.value !== o.displayValue)
    if (newMention && (IS_MOBILE || option === null)) {
      option = newMention
    }
    if (!option) {
      return false
    }
    const selectionInfo = $getSelectionInfo(triggers, punctuation)
    if (!trigger || !selectionInfo || !selectionInfo.isTextNode) {
      return false
    }
    const node = selectionInfo.node
    const textContent = getTextContent(node)
    const queryMatch = checkForMentions(textContent, triggers, punctuation, false)
    if (queryMatch === null) {
      return false
    }
    const textEndIndex = textContent.search(new RegExp(`${queryMatch.replaceableString}\\s?$`))
    if (textEndIndex === -1) {
      return false
    }

    const mentionNode = $createMentionNode(trigger, option.title, option)
    node.setTextContent(textContent.substring(0, textEndIndex))
    node.insertAfter(mentionNode)
    mentionNode.selectNext()
    return true
  }, [items, punctuation])

  const archiveSelection = useCallback(() => {
    const selection = $getSelection()
    if (selection) {
      setOldSelection(selection)
      $setSelection(null)
    }
  }, [])

  const restoreSelection = useCallback(() => {
    const selection = $getSelection()
    if (!selection && oldSelection) {
      $setSelection(oldSelection)
    } else if (!selection) {
      $selectEnd()
    }
    if (oldSelection) {
      setOldSelection(null)
    }
  }, [oldSelection])

  const handleDeleteMention = useCallback(
    (event) => {
      if (!showMentionsOnDelete) {
        return false
      }
      const selectionInfo = $getSelectionInfo(triggers, punctuation)
      if (selectionInfo) {
        const { node, prevNode, offset } = selectionInfo
        const mentionNode = $isMentionNode(node)
          ? node
          : $isMentionNode(prevNode) && offset === 0
            ? prevNode
            : null
        if (mentionNode) {
          const trigger = mentionNode.getTrigger()
          mentionNode.replace($createTextNode(trigger))
          event.preventDefault()
          return true
        }
      }
      return false
    },
    [showMentionsOnDelete, punctuation],
  )

  const insertSpaceIfNecessary = useCallback(
    (startsWithTriggerChar = false) => {
      const selectionInfo = $getSelectionInfo(triggers, punctuation)
      if (!selectionInfo) {
        return false
      }
      const {
        node,
        offset,
        isTextNode,
        textContent,
        prevNode,
        nextNode,
        wordCharAfterCursor,
        cursorAtStartOfNode,
        cursorAtEndOfNode,
      } = selectionInfo
      // [Mention][|][Text]
      if (isTextNode && cursorAtStartOfNode && $isMentionNode(prevNode)) {
        node.insertBefore($createTextNode(" "))
      }
      // [Text][|][Mention]
      if (isTextNode && cursorAtEndOfNode && $isMentionNode(nextNode)) {
        node.insertAfter($createTextNode(" "))
      }
      // [Text][|][Word]
      if (isTextNode && startsWithTriggerChar && wordCharAfterCursor) {
        const content = textContent.substring(0, offset) + " " + textContent.substring(offset)
        node.setTextContent(content)
      }
      // [Mention][|]
      if ($isMentionNode(node) && nextNode === null) {
        node.insertAfter($createTextNode(" "))
      }
    },
    [punctuation],
  )

  const handleKeyDown = useCallback(
    (event) => {
      const { key, metaKey, ctrlKey } = event
      const simpleKey = key.length === 1
      const isTrigger = triggers.some((trigger) => key === trigger)
      const wordChar = isWordChar(key, triggers, punctuation)
      if (!simpleKey || (!wordChar && !isTrigger) || metaKey || ctrlKey) {
        return false
      }
      insertSpaceIfNecessary(isTrigger)
      return false
    },
    [insertSpaceIfNecessary, punctuation],
  )

  const handlePaste = useCallback(
    (event) => {
      const text = event.clipboardData?.getData("text/plain")
      const firstChar = text && text.charAt(0)
      const isTrigger = triggers.some((trigger) => firstChar === trigger)
      const isPunctuation = firstChar && new RegExp(`[\\s${punctuation}]`).test(firstChar)
      if (isTrigger || !isPunctuation) {
        insertSpaceIfNecessary()
      }
      return false // will be handled by the lexical clipboard module
    },
    [insertSpaceIfNecessary, punctuation],
  )

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(KEY_DOWN_COMMAND, handleKeyDown, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, handleDeleteMention, COMMAND_PRIORITY_LOW),
      // editor.registerCommand(
      //   BLUR_COMMAND,
      //   () => {
      //     if (insertOnBlur) {
      //       return convertTextToMention()
      //     }
      //     return false
      //   },
      //   COMMAND_PRIORITY_LOW,
      // ),
      // editor.registerCommand(
      //   KEY_SPACE_COMMAND,
      //   () => {
      //     if (!allowSpaces && creatable) {
      //       return convertTextToMention()
      //     } else {
      //       return false
      //     }
      //   },
      //   COMMAND_PRIORITY_LOW,
      // ),
      editor.registerCommand(
        INSERT_MENTION_COMMAND,
        ({ trigger, value, data, focus = true }) => {
          restoreSelection()
          const inserted = $insertMentionAtSelection(triggers, punctuation, trigger, value, data)
          if (!focus) {
            archiveSelection()
          }
          return inserted
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        REMOVE_MENTIONS_COMMAND,
        ({ trigger, value, focus }) => {
          const removed = $removeMention(trigger, value, focus)
          if (removed && !focus) {
            archiveSelection()
          }
          return removed
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        RENAME_MENTIONS_COMMAND,
        ({ trigger, newValue, value, focus }) => {
          const renamed = $renameMention(trigger, newValue, value, focus)
          if (renamed && !focus) {
            archiveSelection()
          }
          return renamed
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(
        OPEN_MENTION_MENU_COMMAND,
        ({ trigger }) => {
          restoreSelection()
          return $insertTriggerAtSelection(triggers, punctuation, trigger)
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(PASTE_COMMAND, handlePaste, COMMAND_PRIORITY_LOW),
    )
  }, [
    editor,
    punctuation,
    allowSpaces,
    insertOnBlur,
    creatable,
    isEditorFocused,
    convertTextToMention,
    restoreSelection,
    archiveSelection,
    handleKeyDown,
    handleDeleteMention,
    handlePaste,
  ])

  useEffect(() => {
    if (trigger && isEditorFocused) {
      setMenuIsOpen(true)
    } else {
      setMenuIsOpen(false)
    }
    if (trigger && !isEditorFocused) {
      setMenuIsOpen(false)
    }
  }, [trigger, isEditorFocused])

  if (!CAN_USE_DOM) {
    return null
  }

  return (
    <LexicalTypeaheadMenuPlugin
      commandPriority={COMMAND_PRIORITY_NORMAL}
      onQueryChange={handleQueryChange}
      onSelectOption={handleSelectMenuItem}
      triggerFn={checkForMentionMatch}
      options={options}
      anchorClassName={menuAnchorClassName}
      onClose={handleClose}
      menuRenderFn={(
        anchorElementRef,
        { selectedIndex, selectOptionAndCleanUp, setHighlightedIndex },
      ) => {
        selectedMenuIndexRef.current = selectedIndex
        if (anchorElementRef.current && options.length === 0 && queryString && isEditorFocused) {
          return ReactDOM.createPortal(
            <Box
              position="relative"
              padding="5px 20px"
              display="flex"
              backgroundColor="white"
              width="600px"
              boxShadow="0px 4px 12px 0px rgba(0, 20, 67, 0.12)"
              alignItems="center"
              justifyContent="space-between"
              gridGap="10px"
            >
              Нет данных
            </Box>,
            anchorElementRef.current,
          )
        }
        return anchorElementRef.current
          ? ReactDOM.createPortal(
              <MenuComponent
                isOpen={true}
                role="menu"
                aria-activedescendant={
                  !IS_MOBILE && selectedIndex !== null && !!items[selectedIndex]
                    ? items[selectedIndex].displayValue
                    : ""
                }
              >
                <OutsideClickHandler
                  onOutsideClick={() => {
                    handleClose()
                  }}
                >
                  <VirtualList
                    overflowX="hidden"
                    over={10}
                    dataList={options}
                    dataTotal={itemsTotal}
                    isLoading={isLoading}
                    infiniteScroll
                    infiniteCount={1000}
                    onLoad={handleAddPage}
                    height={230}
                    renderItem={(item, i) => {
                      const TypeIcon =
                        taskTypeIconsBySlug[item?.menuItem?.data?.type.slug] || DefaultTypeIcon
                      const statusColorProps =
                        statusBySlug[item?.menuItem?.data?.status.slug] || defaultColorProps

                      return (
                        <MenuItemComponent
                          key={item.menuItem.data.id}
                          tabIndex={-1}
                          selected={!IS_MOBILE && selectedIndex === i}
                          ref={item.setRefElement}
                          aria-selected={!IS_MOBILE && selectedIndex === i}
                          option={item.menuItem}
                          itemValue={item.displayValue}
                          label={item.displayValue}
                          // {...option}
                          onClick={() => {
                            setHighlightedIndex(i)
                            selectOptionAndCleanUp(item)
                          }}
                          onMouseDown={(event) => {
                            event.preventDefault()
                          }}
                          onMouseEnter={() => {
                            setHighlightedIndex(i)
                          }}
                        >
                          <Box
                            position="relative"
                            padding="5px 20px"
                            display="flex"
                            backgroundColor="white"
                            width="560px"
                            alignItems="center"
                            gridGap="5px"
                            overflowX="hidden"
                            style={{ cursor: "pointer" }}
                          >
                            <Text as={Box} display="flex" alignItems="center" gridGap="5px">
                              <TypeIcon />
                            </Text>

                            <Text
                              as={Box}
                              display="flex"
                              alignItems="center"
                              gridGap="5px"
                              fontSize="fs-14"
                              color="rgba(0, 68, 204, 1)"
                              maxWidth="100px"
                              styles={{
                                display: "-webkit-box",
                                "-webkit-box-orient": "vertical",
                                "-webkit-line-clamp": "1",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.menuItem?.data?.slug.toUpperCase() || ""}{" "}
                            </Text>

                            <Text
                              as={Box}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              p="2px 10px"
                              borderRadius="4px"
                              backgroundColor={statusColorProps.backgroundColor}
                              color={statusColorProps.color}
                              fontSize="fs-14"
                              lineHeight="20px"
                              fontWeight="400"
                            >
                              {item?.menuItem?.data?.status.title || ""}{" "}
                            </Text>
                            <Text
                              as={Box}
                              styles={{
                                display: "-webkit-box",
                                "-webkit-box-orient": "vertical",
                                "-webkit-line-clamp": "1",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              fontSize="fs-14"
                            >
                              {item?.menuItem?.data?.title || ""}{" "}
                            </Text>
                          </Box>
                        </MenuItemComponent>
                      )
                    }}
                  />
                </OutsideClickHandler>
              </MenuComponent>,
              anchorElementRef.current,
            )
          : null
      }}
    />
  )
}
