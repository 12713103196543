import React from "react"
import { Box } from "@mos-cat/ds"
import { getCellPropsByZoneData } from "@utils/zones"
import { Avatar, Favorites, Subscription } from "@dit/core-frontend"
import { EntityLink } from "@components/entity"
import { API_ENDPOINTS, ROUTES } from "@constants"
import { ZoneState } from "@components/zones/ZoneState"
import { ZoneActions } from "@components/zones/ZoneActions"

const ZoneListItem = ({ zone, updateResponseData }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gridGap="5px"
      padding="10px 0"
      borderTop="1px solid var(--gray-10)"
      styles={{
        fontSize: "14px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        gridGap="10px"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          overflow="hidden"
          gridGap="7px"
          {...getCellPropsByZoneData(zone)}
        >
          <Avatar url={zone.icon?.url || " "} size={28} borderRadius="0" />
          <EntityLink
            entity={zone}
            link={ROUTES.ZONE}
            overflow="hidden"
            styles={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "3",
              textOverflow: "-webkit-box",
            }}
          />
          <Box
            styles={{
              "& > div": {
                width: "auto",
                height: "24px",
                padding: "2px 10px",
              },
            }}
          >
            <ZoneState zone={zone} />
          </Box>
        </Box>
        <ZoneActions
          zone={zone}
          onDelete={() => updateResponseData?.(zone.id, "remove")}
          onArchive={(archiveState) =>
            updateResponseData?.(zone.id, "update", { archive: archiveState })
          }
        />
      </Box>
      <Box display="flex" alignItems="center" gridGap="10px" justifyContent="space-between">
        <Box
          display="flex"
          alignItems="flex-start"
          overflow="hidden"
          gridGap="7px"
          {...getCellPropsByZoneData(zone)}
        >
          <EntityLink
            visibleField="title"
            entity={zone}
            link={ROUTES.ZONE}
            overflow="hidden"
            styles={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
              textOverflow: "-webkit-box",
              "& > span": {
                whiteSpace: "normal!important",
                display: "-webkit-box!important",
                textOverflow: "-webkit-box!important",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "2",
                textAlign: "left",
              },
            }}
          />
        </Box>
        <Box display="flex" alignItems="center" gridGap="10px">
          <Subscription
            itemId={zone.id}
            subscriptionId={zone.subscription?.id}
            type="zone"
            requestUrl={API_ENDPOINTS.subscription}
            onSuccess={(subscriptionId) => {
              updateResponseData?.(zone.id, "update", {
                subscription: subscriptionId ? { id: subscriptionId } : null,
              })
            }}
          />
          <Favorites
            itemId={zone.id}
            favoritesId={zone.favorites?.id}
            type="zone"
            requestUrl={API_ENDPOINTS.favorites}
            iconSize={21}
            onSuccess={(favoritesId) => {
              updateResponseData?.(zone.id, "update", {
                favorites: favoritesId ? { id: favoritesId } : null,
              })
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default ZoneListItem
