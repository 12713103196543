import { Box } from "@mos-cat/ds"
import { ROUTES } from "@constants"
import React from "react"

export const ENTITY_TYPES = {
  ZONE: "zone",
  PAGE: "page",
  TEMPLATE: "page_template",
}

export const titleObjectType = {
  [ENTITY_TYPES.PAGE]: "Страница",
  [ENTITY_TYPES.ZONE]: "Раздел",
  [ENTITY_TYPES.TEMPLATE]: "Шаблон",
}

export const objectTypeArray = Object.entries(titleObjectType).map(([key, value]) => ({
  titleObjectType: key,
  value: key,
  label: value,
  id: key,
}))

export const getObjectType = (object) => {
  return titleObjectType[object] || undefined
}

export const PageIcon = () => (
  <Box borderRadius="2px" backgroundColor="red-25">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <rect x="3" y="4" width="14" height="2" fill="white" />
      <rect x="3" y="9" width="14" height="2" fill="white" />
      <rect x="3" y="14" width="10" height="2" fill="white" />
    </svg>
  </Box>
)

export const ZoneIcon = () => (
  <Box borderRadius="2px" backgroundColor="red-25">
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d={`
          M4.9 15C4.66 15 4.45 14.9036 4.27 14.7109C4.09 
          14.5182 4 14.3021 4 14.0625V5.9375C4 5.69792 
          4.09 5.48177 4.27 5.28906C4.45 5.09635 4.66 
          5 4.9 5H9.115L10.015 7.0625H15.1C15.33 7.0625 
          15.5375 7.15885 15.7225 7.35156C15.9075 7.54427 
          16 7.76042 16 8V14.0625C16 14.3021 15.9075 14.5182 
          15.7225 14.7109C15.5375 14.9036 15.33 15 15.1 15H4.9Z
        `}
        fill="white"
      />
    </svg>
  </Box>
)

const TemplateIcon = () => (
  <Box borderRadius="2px" backgroundColor="red-25">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10" cy="10" r="5" fill="white" />
    </svg>
  </Box>
)

export const getIcon = {
  [ENTITY_TYPES.PAGE]: <PageIcon />,
  [ENTITY_TYPES.ZONE]: <ZoneIcon />,
  [ENTITY_TYPES.TEMPLATE]: <TemplateIcon />,
}

export const getUrlByEntity = (objectType, entity) => {
  switch (objectType) {
    case ENTITY_TYPES.ZONE:
      return `${ROUTES.ZONE}/${entity.slug || entity.id}`
    case ENTITY_TYPES.PAGE:
      return `${ROUTES.ZONE}/${entity.zone.slug}/pages/${entity.slug || entity.id}`
    case ENTITY_TYPES.TEMPLATE:
      return `${ROUTES.ZONE}/${entity.zone.slug}/templates/${entity.id}`
    default:
      return ""
  }
}
