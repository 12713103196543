import React, { useEffect, useState } from "react"
import { Field, Form } from "react-final-form"
import { Box, Button, Modal, ModalDisclosure, Text, useModalState } from "@mos-cat/ds"
import {
  Message,
  apiClient,
  ERROR_MESSAGES,
  formatSubmitErrors,
  getValidationErrors,
  CustomSelect,
  getFormErrorMessageForMessage,
} from "@dit/core-frontend"
import { API_ENDPOINTS } from "@constants"

import { useZoneContext } from "@components/contexts/ZoneContext"

const validationSchema = {
  roleId: { required: true, type: "string" },
  userId: { required: true, type: "string" },
}

export const AddPermissionModal = ({
  onSuccess,
  modalDisclosureProps,
  modalStateProps,
  children,
}) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const modalState = useModalState()
  const { zone } = useZoneContext()
  const getModalState = { ...modalState, ...modalStateProps }
  const isVisible = getModalState?.visible

  const getRoles = async (search) => {
    const { data } = await apiClient.get(API_ENDPOINTS.roles, {
      params: {
        title: search,
        epp: 25,
      },
    })
    return data.data.itemsList
  }
  const getUsers = async (search) => {
    const { data } = await apiClient.get(API_ENDPOINTS.users, {
      params: {
        search: search,
        epp: 25,
      },
    })
    return data.data.itemsList
  }

  const handleSuccess = () => {
    setIsSuccess(true)
    getModalState.hide()
    onSuccess?.()
  }

  function handleSubmit() {
    return async (values, form) => {
      let errors = null
      try {
        const { data } = await apiClient.post(API_ENDPOINTS.grantedRoles, {
          object: "zone",
          objectId: zone.id,
          roleId: values.roleId,
          userId: values.userId,
        })
        if (data.success) {
          form.restart()
          handleSuccess()
        }
      } catch (err) {
        if (err.status === 500) {
          errors = {
            common_error: ERROR_MESSAGES.default,
          }
        }

        if (err?.data?.errors?.length) {
          errors = formatSubmitErrors(err.data.errors)
        }
      }

      if (errors) {
        return errors
      }
    }
  }

  useEffect(() => {
    if (!isVisible) {
      setIsSuccess(false)
    }
  }, [isVisible])

  return (
    <>
      {children && (
        <ModalDisclosure {...modalDisclosureProps} {...getModalState}>
          {children}
        </ModalDisclosure>
      )}

      <Modal {...getModalState} heading="Выдать роль">
        {isVisible && (
          <>
            {isSuccess ? (
              <Message isVisible isAlwaysVisible text="Участник добавлен" />
            ) : (
              <Form
                initialValues={{
                  userId: "",
                  roleId: "",
                }}
                validate={(values) => getValidationErrors(values, validationSchema)}
                onSubmit={handleSubmit}
                render={({
                  handleSubmit,
                  submitting,
                  form,
                  submitErrors,
                  pristine,
                  valid,
                  dirtySinceLastSubmit,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Message
                        type="error"
                        isAlwaysVisible
                        isVisible={!dirtySinceLastSubmit}
                        text={getFormErrorMessageForMessage(submitErrors)}
                      />
                      <Text
                        as={Box}
                        fontSize="fs-16"
                        lineHeight="24px"
                        fontWeight="600"
                        color="black"
                        marginY={10}
                      >
                        Выберите пользователя
                      </Text>
                      <Field name="userId">
                        {() => (
                          <>
                            <CustomSelect
                              type="async"
                              placeholder=""
                              cacheOptions
                              defaultOptions
                              loadOptions={getUsers}
                              isMulti={false}
                              noOptionsMessage={() => "Нет результатов"}
                              loadingMessage={() => "Загрузка..."}
                              isSearchable
                              getOptionLabel={(option) => ` ${option.firstName} ${option.lastName}`}
                              getOptionValue={(option) => option.id}
                              onChange={(selected) => {
                                form.change("userId", selected.id)
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                              }}
                            />
                          </>
                        )}
                      </Field>
                      <Text
                        as={Box}
                        fontSize="fs-16"
                        lineHeight="24px"
                        fontWeight="600"
                        color="black"
                        marginY={10}
                      >
                        Выберите роль
                      </Text>
                      <Field name="roleId">
                        {() => (
                          <>
                            <CustomSelect
                              type="async"
                              placeholder=""
                              cacheOptions
                              defaultOptions
                              loadOptions={getRoles}
                              isSearchable
                              noOptionsMessage={() => "Нет результатов"}
                              loadingMessage={() => "Загрузка..."}
                              isMulti={false}
                              getOptionLabel={(option) => option.title}
                              getOptionValue={(option) => option.id}
                              onChange={(selected) => {
                                form.change("roleId", selected.id)
                              }}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
                              }}
                            />
                          </>
                        )}
                      </Field>

                      <Button
                        kind="primary"
                        type="submit"
                        disabled={submitting || pristine || (!valid && !dirtySinceLastSubmit)}
                        marginTop="20px"
                      >
                        Сохранить
                      </Button>
                    </form>
                  )
                }}
              />
            )}
          </>
        )}
      </Modal>
    </>
  )
}
