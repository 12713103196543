// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment_CommentEditorTheme__paragraph__QKLJS {
    position: 'relative';

    margin: 0;
  }`, "",{"version":3,"sources":["webpack://./src/components/form/Editor/theme/comment.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;;IAEpB,SAAS;EACX","sourcesContent":[".CommentEditorTheme__paragraph {\n    position: 'relative';\n\n    margin: 0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CommentEditorTheme__paragraph": `comment_CommentEditorTheme__paragraph__QKLJS`
};
export default ___CSS_LOADER_EXPORT___;
