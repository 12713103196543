import React from "react"
import { Box, Text } from "@mos-cat/ds"
import { getFormattedDate, Ellipsis, Favorites } from "@dit/core-frontend"
import { Person } from "@components/general"
import TemplateActions from "./TemplateActions"

import { API_ENDPOINTS } from "@constants"
import { ENTITY_TYPES } from "@utils/favorites"

const getCellPropsByStatus = (template) => {
  if (template.isArchive) {
    return {
      opacity: "0.4",
    }
  }
  return {}
}

export const createTableConfig = (zone, updateResponseData, openEditTemplateCurtain) => ({
  cells: [
    {
      label: "Название",
      key: "title",
      getContent: (template) => (
        <Text
          as={Box}
          fontSize="fs-14"
          color="var(--blue-60)"
          styles={{ cursor: "pointer" }}
          {...getCellPropsByStatus(template)}
          onClick={() => {
            openEditTemplateCurtain(template.id, true)
          }}
        >
          <Ellipsis>{template.title}</Ellipsis>
        </Text>
      ),
    },
    {
      label: "Описание",
      key: "description",
      getContent: (template) => (
        <Text as={Box} fontSize="fs-14" {...getCellPropsByStatus(template)}>
          <Ellipsis lines={3}>{template.description}</Ellipsis>
        </Text>
      ),
    },
    {
      label: "Дата создания",
      key: "createdAt",
      getContent: (template) => (
        <Text as={Box} fontSize="fs-14" {...getCellPropsByStatus(template)}>
          {getFormattedDate(template.createdAt)}
        </Text>
      ),
    },
    {
      label: "Дата обновления",
      key: "modifiedAt",
      getContent: (template) => (
        <Text as={Box} fontSize="fs-14" {...getCellPropsByStatus(template)}>
          {getFormattedDate(template.modifiedAt || template.createdAt)}
        </Text>
      ),
    },
    {
      label: "Автор",
      key: "user",
      getContent: (template) => (
        <Person person={template.user} {...getCellPropsByStatus(template)} />
      ),
    },
    {
      label: "",
      key: "secret",
      width: "120px",
      getContent: (template) => (
        <Box display="flex" alignItems="center" justifyContent="flex-end" gridGap="2px">
          <Favorites
            itemId={template.id}
            favoritesId={template.favorites?.id}
            type={ENTITY_TYPES.TEMPLATE}
            requestUrl={API_ENDPOINTS.favorites}
            iconSize={21}
            onSuccess={(favoritesId) => {
              updateResponseData?.(template.id, "update", {
                favorites: favoritesId ? { id: favoritesId } : null,
              })
            }}
          />
          <TemplateActions
            template={template}
            zone={zone}
            onDelete={() => {
              updateResponseData?.(template.id, "remove")
            }}
            openEditTemplateCurtain={openEditTemplateCurtain}
            onUpdate={(data) => {
              updateResponseData?.(template.id, "update", data)
            }}
          />
        </Box>
      ),
    },
  ],
})
