export const PAGE_STATUSES = {
  DRAFT: "draft",
  PUBLISH: "publish",
  ARCHIVE: "archive",
}

export const PAGE_STATUSES_OBJECT = {
  [PAGE_STATUSES.DRAFT]: {
    title: "Черновик",
    slug: "draft",
  },
  [PAGE_STATUSES.PUBLISH]: {
    title: "Опубликовано",
    slug: "publish",
  },
  [PAGE_STATUSES.ARCHIVE]: {
    title: "Архив",
    slug: "archive",
  },
}

export const getCellPropsByPageData = (page) => {
  if (page.archive) {
    return {
      opacity: "0.4",
    }
  }
  return {}
}

export const sortPagesByTitle = (pages) => {
  return pages.sort((a, b) => {
    const langA = /^[а-яёА-ЯЁ]/.test(a.title) ? 0 : /^[a-zA-Z]/.test(a.title) ? 1 : 2
    const langB = /^[а-яёА-ЯЁ]/.test(b.title) ? 0 : /^[a-zA-Z]/.test(b.title) ? 1 : 2

    if (langA !== langB) {
      return langA - langB
    }

    return a.title.localeCompare(b.title)
  })
}
