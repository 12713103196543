import React, { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import {
  FiltersBox,
  PageBlockContent,
  PageBlockHeading,
  PageBlockRow,
  PageBlockRowSection,
  Search,
  Button,
  DataListWrapper,
  getUrlSearchParams,
  useStorageVariable,
} from "@dit/core-frontend"
import usePageTemplatesList from "@components/pageTemplates/usePageTemplatesList"
import { PageTemplatesCurtainTrigger } from "@components/pageTemplates/PageTemplatesCurtain"
import { PageTemplatesVirtualTable } from "@components/pageTemplates/PageTemplatesVirtualTable"
import { ViewSwitcher } from "@components/ViewSwitcher"

import { useZoneContext } from "@components/contexts/ZoneContext"
import { API_ENDPOINTS, ROUTES, STORE_VARIABLES } from "@constants"

const LIMIT = 24

const PageTemplatesListContent = ({
  setAdditionalBreadcrumbs,
  getData,
  data,
  ...dataListWrapperProps
}) => {
  const [page, setPage] = useState(1)
  const { zone } = useZoneContext()
  const { filtersList, defaultFilterParams, onChangeParams, params, onSearch } =
    usePageTemplatesList()
  const [view, setView] = useStorageVariable(STORE_VARIABLES.pagesView)

  const [searchParams] = useSearchParams()
  const { search } = getUrlSearchParams(searchParams)

  const handleAddPage = useCallback(() => {
    if (data?.itemsList.length < data?.itemsTotal) {
      setPage((prev) => prev + 1)
    }
  }, [data])

  const fetchPageTemplates = useCallback(() => {
    getData(API_ENDPOINTS.pageTemplates, {
      search: search || "",
      epp: LIMIT,
      eppOffset: (page - 1) * LIMIT,
      zones: [zone.id],
      ...params,
    })
  }, [getData, page, params, search, zone.id])

  useEffect(() => {
    void fetchPageTemplates()
  }, [fetchPageTemplates])

  useEffect(() => {
    setAdditionalBreadcrumbs([{ label: "Шаблоны", to: `${ROUTES.ZONE}/${zone.slug}/templates` }])
  }, [setAdditionalBreadcrumbs, zone.slug])

  return (
    <>
      <PageBlockRow place="middle">
        <PageBlockRowSection>
          <PageBlockHeading>Шаблоны раздела</PageBlockHeading>
          <Search
            onSearch={onSearch}
            placeholder="Поиск страницы"
            width="170px"
            fullWidth="300px"
          />
        </PageBlockRowSection>
        <PageBlockRowSection>
          <PageTemplatesCurtainTrigger
            zone={zone}
            onClose={(withUpdate) => {
              if (withUpdate) {
                fetchPageTemplates()
              }
            }}
          >
            {(openCurtain) => (
              <Button kind="primary" onClick={openCurtain}>
                Добавить шаблон
              </Button>
            )}
          </PageTemplatesCurtainTrigger>
        </PageBlockRowSection>
      </PageBlockRow>
      <PageBlockRow place="bottom" alignItems="flex-start">
        <PageBlockRowSection width="90%">
          <FiltersBox
            filteredList={filtersList}
            filterParams={params}
            defaultParams={defaultFilterParams}
            setFilterParams={onChangeParams}
            isResetAllFilters
            isQuery
          />
        </PageBlockRowSection>
        <ViewSwitcher value={view} onChange={setView} />
      </PageBlockRow>

      <PageBlockContent>
        <PageTemplatesVirtualTable
          isRowView={false}
          view={view}
          data={data}
          {...dataListWrapperProps}
          handleAddPage={handleAddPage}
          fetchPageTemplates={fetchPageTemplates}
        />
      </PageBlockContent>
    </>
  )
}

const PageTemplatesList = ({ ...props }) => {
  return (
    <DataListWrapper>
      <PageTemplatesListContent {...props} />
    </DataListWrapper>
  )
}

export default PageTemplatesList
