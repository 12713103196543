import { $createParagraphNode, $getRoot, createEditor } from "lexical"
import React, { memo } from "react"
import { PreviewNodes } from "../../context/PreviewNodesList"
import theme from "../../theme"
import { LexicalNestedComposer } from "@lexical/react/LexicalNestedComposer"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { $generateNodesFromSerializedNodes } from "@lexical/clipboard"

const TableContent = memo(({ rows }) => {
  return rows.map((row, rowIndex) => (
    <tr key={rowIndex}>
      {Object.keys(row).map((column, index) => {
        const newEditor = createEditor({
          nodes: PreviewNodes,
          editable: false,
          disableEvents: true,
        })
        newEditor.update(() => {
          const root = $getRoot()
          const paragraphNode = $createParagraphNode()
          const node = $generateNodesFromSerializedNodes(row[column][1]?.content?.children || [])
          paragraphNode.append(...(node || node))
          root.append(paragraphNode)
        })
        return (
          <td
            key={index}
            className={theme.tableCell}
            style={{ backgroundColor: column.backgroundColor, width: column.width }}
          >
            <LexicalNestedComposer
              initialEditor={newEditor}
              initialTheme={theme}
              initialNodes={PreviewNodes}
            >
              <RichTextPlugin
                contentEditable={<ContentEditable />}
                placeholder={<div> {""} </div>}
                ErrorBoundary={LexicalErrorBoundary}
              />
            </LexicalNestedComposer>
          </td>
        )
      })}
    </tr>
  ))
})

TableContent.displayName = "TableContentComp"
export default TableContent
