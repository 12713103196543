import React, { memo } from "react"

import { Box, Text } from "@mos-cat/ds"
import { PAGE_STATES } from "@utils/zones"

const statusColorBySlug = {
  [PAGE_STATES.DEFAULT]: {
    color: "var(--warning-70)",
    backgroundColor: "var(--warning-10)",
    children: "активный",
  },
  [PAGE_STATES.ARCHIVE]: {
    color: "var(--white)",
    backgroundColor: "#C5AD8D",
    children: "в архиве",
  },
}

export const ZoneState = memo(({ zone = {} }) => {
  let state = PAGE_STATES.DEFAULT
  if (zone.archive) {
    state = PAGE_STATES.ARCHIVE
  }

  const additionalProps = statusColorBySlug[state]

  return (
    <Text
      as={Box}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100px"
      height="28px"
      borderRadius="4px"
      fontSize="14px"
      lineHeight="1"
      {...additionalProps}
    />
  )
})

ZoneState.displayName = "ZoneState"
