import React from "react"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { HeadingNode, QuoteNode } from "@lexical/rich-text"
import { ListItemNode, ListNode } from "@lexical/list"
import { LinkNode } from "@lexical/link"
import { CodeHighlightNode, CodeNode } from "@lexical/code"
import { ImageNode } from "../ImageNode"
import theme from "../theme"
import { SharedHistoryContext } from "./SharedHistory"
import { FigmaNode } from "../nodes/FigmaNode"
import { AutoLinkNode } from "@lexical/link"
import { YouTubeNode } from "../nodes/YouTubeNode"
import { LayoutContainerNode } from "../nodes/LayoutContainerNode"
import { LayoutItemNode } from "../nodes/LayoutItemNode"
import { ExcalidrawNode } from "../nodes/ExcalidrawNode"
import { FileNode } from "../nodes/FileNode"
import { CollapsibleContainerNode } from "../plugins/CollapsiblePlugin/CollapsibleContainerNode"
import { CollapsibleContentNode } from "../plugins/CollapsiblePlugin/CollapsibleContentNode"
import { CollapsibleTitleNode } from "../plugins/CollapsiblePlugin/CollapsibleTitleNode"
import { PageBreakNode } from "../nodes/PageBreakNode"
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode"
import { StickyNode } from "../nodes/StickyNode"
import { TableOfContentNode } from "../nodes/TableOfContentNode"
import { TableCellNode, TableRowNode } from "@lexical/table"
import { TableNode } from "../nodes/TablePreviewNode"
import { MarkNode } from "../nodes/MarkNode"
import { MentionNode } from "../nodes/MentionNode"

export const EditorPreviewComposer = ({ jsonState, children, nameSpace = "EditorPreview" }) => {
  return (
    <LexicalComposer
      initialConfig={{
        namespace: nameSpace,
        editorState: JSON.stringify(jsonState),
        editable: false,
        theme: theme,
        nodes: [
          HeadingNode,
          ListNode,
          ListItemNode,
          LinkNode,
          QuoteNode,
          CodeNode,
          CodeHighlightNode,
          TableCellNode,
          TableNode,
          TableRowNode,
          ImageNode,
          FileNode,
          FigmaNode,
          YouTubeNode,
          AutoLinkNode,
          LayoutContainerNode,
          LayoutItemNode,
          ExcalidrawNode,
          CollapsibleContainerNode,
          CollapsibleContentNode,
          CollapsibleTitleNode,
          PageBreakNode,
          HorizontalRuleNode,
          StickyNode,
          TableOfContentNode,
          MarkNode,
          MentionNode,
        ],

        onError(error) {
          throw error
        },
      }}
    >
      <SharedHistoryContext>{children}</SharedHistoryContext>
    </LexicalComposer>
  )
}
