import React, { useCallback, useEffect, useState } from "react"

import { Spinner, apiClient, Button, Search, useAppContext } from "@dit/core-frontend"
import { Box, Stack } from "@mos-cat/ds"
import { PageTemplateCard } from "@components/pageTemplates/PageTemplateCard"

import { API_ENDPOINTS } from "@constants"

const LIMIT = 50

const TemplatesList = ({
  zone,
  hideAddButton,
  selectedCard,
  onClickOnCard,
  openCreateTemplateCurtain,
}) => {
  const [search, setSearch] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [templatesList, setTemplatesList] = useState([])
  const { showAlert } = useAppContext()

  const getTemplatesList = useCallback(async () => {
    setIsLoading(true)

    try {
      const { data } = await apiClient.get(API_ENDPOINTS.pageTemplates, {
        params: {
          search: search || "",
          epp: LIMIT,
        },
      })
      setTemplatesList(data.data?.itemsList || [])
    } catch (err) {
      showAlert("Не удалось получить шаблоны")
    }

    setIsLoading(false)
  }, [search, showAlert])

  useEffect(() => {
    setTemplatesList([])
    if (zone) {
      void getTemplatesList()
    }
  }, [zone, getTemplatesList])

  const onSearch = (search) => {
    setSearch(search)
  }

  return (
    <Stack space="10px" marginBottom="20px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row-reverse"
        borderBottom="1px solid var(--gray-20)"
        paddingY="10px"
      >
        <Search
          onSearch={onSearch}
          defaultValue={search}
          placeholder="Искать значения"
          width="146px"
          fullWidth="300px"
        />
        {zone && !hideAddButton && (
          <Button
            kind="borderless"
            padding="0 !important"
            onClick={() => {
              openCreateTemplateCurtain(zone)
            }}
          >
            Добавить шаблон
          </Button>
        )}
      </Box>
      <Box
        display="flex"
        alignItems="center"
        gridGap="15px"
        marginX="-40px"
        padding="0 40px 20px"
        overflow="auto"
      >
        <PageTemplateCard
          template={{
            title: "Пустая страница",
            description: "Начните с чистого листа",
          }}
          isSelectedCard={!selectedCard}
          onClickOnCard={() => onClickOnCard(null)}
          zone={zone}
        />

        {isLoading ? (
          <Box display="flex" width="30px" height="30px" marginLeft="30px">
            <Spinner
              iconSize="30px"
              wrapper="div"
              styles={{ position: "static" }}
              overlayVisible={false}
            />
          </Box>
        ) : (
          <>
            {templatesList.map((template) => (
              <PageTemplateCard
                key={`templates-cards-list-${template.id}`}
                template={template}
                isSelectedCard={selectedCard?.id === template.id}
                onClickOnCard={() => onClickOnCard(template)}
                zone={zone}
                isActionsVisible={false}
              />
            ))}
          </>
        )}
      </Box>
    </Stack>
  )
}

export default TemplatesList
