// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[type='page-break'] {
    position: relative;

    display: block;
    overflow: visible;
    overflow: initial;

    width: calc(100% + 28px * 2);

    width: calc(100% + var(--editor-input-padding, 28px) * 2);
    margin-top: 28px;
    margin-top: var(--editor-input-padding, 28px);
    margin-bottom: 28px;
    margin-bottom: var(--editor-input-padding, 28px);
    margin-left: calc(28px * -1);
    margin-left: calc(var(--editor-input-padding, 28px) * -1);
  
    border: none;
    border-top: 1px dashed #eee;
    border-top: 1px dashed var(--editor-color-secondary, #eee);
    border-bottom: 1px dashed #eee;
    border-bottom: 1px dashed var(--editor-color-secondary, #eee);
    background-color: #eee;
    background-color: var(--editor-color-secondary, #eee);
  }
  
  [type='page-break']::before {
    position: absolute;
    top: 50%;
    left: calc(28px + 12px);
    left: calc(var(--editor-input-padding, 28px) + 12px);
  
    /* background-size: cover;
    background-image: url(/src/images/icons/scissors.svg); */
    width: 16px;
    height: 16px;

    content: '';
    transform: translateY(-50%);

    opacity: 0.5;
  }
  
 
  
  .styles_selected__UcDpg[type='page-break'] {
    border-color: #4766cb;
    border-color: var(--editor-color-primary, #4766cb);
  }
  
  .styles_selected__UcDpg[type='page-break']::before {
    opacity: 1;
  }`, "",{"version":3,"sources":["webpack://./src/components/form/Editor/nodes/PageBreakNode/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;;IAElB,cAAc;IACd,iBAAe;IAAf,iBAAe;;IAEf,4BAAyD;;IAAzD,yDAAyD;IACzD,gBAA6C;IAA7C,6CAA6C;IAC7C,mBAAgD;IAAhD,gDAAgD;IAChD,4BAAyD;IAAzD,yDAAyD;;IAEzD,YAAY;IACZ,2BAA0D;IAA1D,0DAA0D;IAC1D,8BAA6D;IAA7D,6DAA6D;IAC7D,sBAAqD;IAArD,qDAAqD;EACvD;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,uBAAoD;IAApD,oDAAoD;;IAEpD;4DACwD;IACxD,WAAW;IACX,YAAY;;IAEZ,WAAW;IACX,2BAA2B;;IAE3B,YAAY;EACd;;;;EAIA;IACE,qBAAkD;IAAlD,kDAAkD;EACpD;;EAEA;IACE,UAAU;EACZ","sourcesContent":["[type='page-break'] {\n    position: relative;\n\n    display: block;\n    overflow: unset;\n\n    width: calc(100% + var(--editor-input-padding, 28px) * 2);\n    margin-top: var(--editor-input-padding, 28px);\n    margin-bottom: var(--editor-input-padding, 28px);\n    margin-left: calc(var(--editor-input-padding, 28px) * -1);\n  \n    border: none;\n    border-top: 1px dashed var(--editor-color-secondary, #eee);\n    border-bottom: 1px dashed var(--editor-color-secondary, #eee);\n    background-color: var(--editor-color-secondary, #eee);\n  }\n  \n  [type='page-break']::before {\n    position: absolute;\n    top: 50%;\n    left: calc(var(--editor-input-padding, 28px) + 12px);\n  \n    /* background-size: cover;\n    background-image: url(/src/images/icons/scissors.svg); */\n    width: 16px;\n    height: 16px;\n\n    content: '';\n    transform: translateY(-50%);\n\n    opacity: 0.5;\n  }\n  \n \n  \n  .selected[type='page-break'] {\n    border-color: var(--editor-color-primary, #4766cb);\n  }\n  \n  .selected[type='page-break']::before {\n    opacity: 1;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selected": `styles_selected__UcDpg`
};
export default ___CSS_LOADER_EXPORT___;
