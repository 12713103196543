import React, { useEffect, useMemo, useState } from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

import styles from "./styles.module.css"
import { $getNodeByKey } from "lexical"

import { $isMarkNode } from "@lexical/mark"
import { CommentsPanelListComment } from "./CommentPanelListComment"
import { CommentsComposer } from "./CommentComposer"
import { Box } from "@mos-cat/ds"

export const CommentsPanelList = ({
  activeID,
  comments,
  deleteCommentOrThread,
  listRef,
  markNodeMap,
  setShowComments,
  clickComment,
}) => {
  const [editor] = useLexicalComposerContext()
  const [counter, setCounter] = useState(0)
  const rtf = useMemo(
    () =>
      new Intl.RelativeTimeFormat("rus", {
        localeMatcher: "best fit",
        numeric: "auto",
        style: "short",
      }),
    [],
  )

  useEffect(() => {
    // Used to keep the time stamp up to date
    const id = setTimeout(() => {
      setCounter(counter + 1)
    }, 10000)

    return () => {
      clearTimeout(id)
    }
  }, [counter])

  return (
    <ul className={styles.CommentPlugin_CommentsPanel_List} ref={listRef}>
      {comments.map((commentOrThread) => {
        const id = commentOrThread.textBlockId
        if (commentOrThread.id) {
          const handleClickThread = () => {
            const markNodeKeys = markNodeMap.get(id)
            if (markNodeKeys !== undefined && (activeID === null || activeID !== id)) {
              const activeElement = document.activeElement
              // Move selection to the start of the mark, so that we
              // update the UI with the selected thread.
              editor.update(
                () => {
                  const markNodeKey = markNodeKeys
                  const markNode = $getNodeByKey(markNodeKey)
                  if ($isMarkNode(markNode)) {
                    markNode.selectStart()
                  }
                },
                {
                  onUpdate() {
                    // Restore selection to the previous element
                    if (activeElement !== null) {
                      activeElement.focus()
                    }
                  },
                },
              )
            }
          }

          return (
            <li
              key={id}
              onClick={handleClickThread}
              className={`${styles.CommentPlugin_CommentsPanel_List_Thread} `}
            >
              <div className={"{styles.CommentPlugin_CommentsPanel_List_Thread_QuoteBox}"}>
                <CommentsPanelListComment
                  key={id}
                  activeID={activeID}
                  markNodeMap={markNodeMap}
                  comment={commentOrThread}
                  deleteComment={deleteCommentOrThread}
                  rtf={rtf}
                  setShowComments={setShowComments}
                  clickComment={clickComment}
                />
              </div>
              {!!commentOrThread?.reply?.length && (
                <ul className={styles.CommentPlugin_CommentsPanel_List_Thread_Comments}>
                  {(commentOrThread.reply || []).map((replyComment) => (
                    <CommentsPanelListComment
                      key={replyComment.id}
                      activeID={activeID}
                      markNodeMap={markNodeMap}
                      comment={replyComment}
                      deleteComment={deleteCommentOrThread}
                      thread={commentOrThread}
                      rtf={rtf}
                      setShowComments={setShowComments}
                      clickComment={clickComment}
                    />
                  ))}
                </ul>
              )}
              {!commentOrThread?.approved ? (
                <div className={styles.CommentPlugin_CommentsPanel_List_Thread_Editor}>
                  <CommentsComposer
                    thread={commentOrThread}
                    // placeholder="Reply to comment..."
                  />
                </div>
              ) : (
                <Box height="44px"></Box>
              )}
            </li>
          )
        }
        // return (
        //   <CommentsPanelListComment
        //     key={id}
        //     comment={commentOrThread}
        //     deleteComment={deleteCommentOrThread}
        //     rtf={rtf}
        //   />
        // )
        return undefined
      })}
    </ul>
  )
}
