import React, { memo, useState } from "react"
import cc from "classcat"

import {
  ArrowIcon,
  Avatar,
  CustomGetUsersOptionLabel,
  getFormattedRecentDate,
} from "@dit/core-frontend"
import { Box, Stack, Text } from "@mos-cat/ds"
import { Link } from "react-router-dom"
import { ArrowForward } from "@mos-cat/ds-icons"
import { DateTime } from "luxon"
import { ROUTES } from "@src/constants"
import { EditorPreview, stringToEditorContent } from "@src/components/form"

import styles from "@assets/styles/collapse.module.css"

const CollapsedText = ({ context }) => {
  const [isCollapsed, setIsCollapsed] = useState(true)

  return (
    <Stack space="10px">
      <Box
        display="flex"
        alignItems="center"
        gridGap="6px"
        style={{ cursor: "pointer" }}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <ArrowIcon
          size="10"
          className={cc({ [styles.collapseButton]: true, [styles.collapsed]: !isCollapsed })}
          color="var(--gray-90)"
        />{" "}
        <Text fontWeight={600} fontSize="14px">
          Подробнее
        </Text>
      </Box>
      <Stack
        space="0"
        display="grid !important"
        overflowY={"hidden"}
        styles={{
          gridTemplateColumns: "100%",
          transition: "var(--default-transition)",
          gridTemplateRows: isCollapsed ? "0fr" : "1fr",
        }}
      >
        <Stack space="10px" minHeight="0" overflowY="clip" overflowX="visible">
          <Text color="black" fontSize="fs-14" style={{ marginLeft: "30px" }}>
            <EditorPreview
              content={stringToEditorContent(context.after)}
              nameSpace="EditorPreviewFeed"
            />
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}

const FeedContent = ({ item }) => {
  if (item.context.before && item.context.after && item.context.field !== "statusId") {
    return (
      <Box display="flex" alignItems="center" gridGap="5px" flexWrap="wrap">
        <Text
          as={Box}
          minHeight="28px"
          padding="10px"
          width="fit-content"
          gridGap="10px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="var(--error-05)"
          color="black"
          borderRadius="5px"
          fontSize="fs-14"
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {item.context.before}
        </Text>
        <ArrowForward width={20} height={20} />
        <Text
          as={Box}
          minHeight="28px"
          padding="10px"
          width="fit-content"
          gridGap="10px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          backgroundColor="var(--success-05)"
          color="black"
          borderRadius="5px"
          fontSize="fs-14"
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
          }}
        >
          {item.context.after}
        </Text>
      </Box>
    )
  }

  if (item.context.after && item.context.field === "statusId") {
    return (
      <Text
        as={Box}
        height="28px"
        padding="10px"
        width="fit-content"
        gridGap="10px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        backgroundColor="var(--success-05)"
        color="black"
        borderRadius="5px"
        fontSize="fs-14"
      >
        {item.context.after}
      </Text>
    )
  }

  if (item.context.after && (item.context.field === "text" || item.context.field === "pageText")) {
    return <CollapsedText context={item.context} />
  }

  return <></>
}

export const FeedItem = memo(({ item }) => {
  return (
    <Stack space="5px">
      <Box display="flex" justifyContent="space-between" styles={{ fontSize: 14 }}>
        <Box display="flex" alignItems="center" gridGap="10px">
          <Box display="flex" alignItems="center" gridGap="5px">
            <Avatar url={item.user?.photo?.url} size={20} />
            <Text>{CustomGetUsersOptionLabel(item.user)}</Text>
          </Box>
          <Text color="gray-40">{item.message.toLowerCase()}</Text>
          {item.page.isDeleted ? (
            <Text color="gray-40">{item.page.title}</Text>
          ) : (
            <Box
              maxWidth="300px"
              styles={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Link
                to={`${ROUTES.ZONE}/${item.page.zone.slug}/pages/${item.page.slug}`}
                target="_blank"
              >
                <Text
                  styles={{
                    "&:hover": {
                      textDecoration: "underline",
                      color: "var(--blue-60)",
                    },
                  }}
                >
                  {item.page.title}
                </Text>
              </Link>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" gridGap="10px">
          <Link to={`${ROUTES.ZONE}/${item.page.zone.slug}`} target="_blank">
            <Text
              color="gray-40"
              styles={{
                "&:hover": {
                  textDecoration: "underline",
                  color: "var(--blue-60)",
                },
              }}
            >
              {item.page.zone.slug}
            </Text>
          </Link>
          <Avatar
            url={item.page.zone?.icon?.url || " "}
            size={30}
            borderRadius="4px"
            border="1px solid var(--gray-10)"
            backgroundColor="var(--white)"
          />
        </Box>
      </Box>
      <FeedContent item={item} />
      <Box display="flex">
        <Text fontSize="fs-14" color="gray-40">
          {getFormattedRecentDate(DateTime.fromISO(item.createdAt), true)}
        </Text>
      </Box>
    </Stack>
  )
})

FeedItem.displayName = "FeedItem"
