// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changeTeamMember_fieldTitle__S\\+Oqe{
    margin-top:10px;
    margin-bottom: 9px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Zone/Settings/teams/components/changeTeamMember.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".fieldTitle{\n    margin-top:10px;\n    margin-bottom: 9px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldTitle": `changeTeamMember_fieldTitle__S+Oqe`
};
export default ___CSS_LOADER_EXPORT___;
