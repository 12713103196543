// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageComponent_ImageNode__contentEditable__BuH7e {
  position: relative;

  display: block;

  width: calc(100% - 20px);
  min-height: 20px;
  padding: 10px;

  resize: none;
  cursor: text;
  -webkit-user-select: text;
          user-select: text;
  white-space: pre-wrap;
  word-break: break-word;

  border: 0;
  outline: 0;

  font-size: 12px;
  caret-color: rgb(5 5 5);
}

.imageComponent_ImageNode__placeholder__PiIzk {
  position: absolute;
  top: 10px;
  left: 10px;

  display: inline-block;
  overflow: hidden;

  -webkit-user-select: none;

          user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;

  color: #888;

  font-size: 12px;
}

.imageComponent_image-control-wrapper--resizing__fSNKj {
  touch-action: none;
}

.imageComponent_focused__3iXeX {
  /* outline: 2px solid black; */

  /* user-select: none; */
}

.imageComponent_draggable__UDKHa {
  /* cursor: grab; */
}

.imageComponent_draggable__UDKHa:active {
  /* cursor: grabbing; 
  outline: 20px solid black; */
}

.imageComponent_imageBlock__HnHf9 {
  position: relative;

  margin: 0;
  margin-top: 8px;
  margin-right: 30px;
  padding: 0;
}

.imageComponent_deleteImage__c1e2z {
  position: absolute;
  right: -25px;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/Editor/imageComponent.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,cAAc;;EAEd,wBAAwB;EACxB,gBAAgB;EAChB,aAAa;;EAEb,YAAY;EACZ,YAAY;EACZ,yBAAiB;UAAjB,iBAAiB;EACjB,qBAAqB;EACrB,sBAAsB;;EAEtB,SAAS;EACT,UAAU;;EAEV,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;;EAEV,qBAAqB;EACrB,gBAAgB;;EAEhB,yBAAiB;;UAAjB,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;;EAEpB,WAAW;;EAEX,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;;EAE9B,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE;8BAC4B;AAC9B;;AAEA;EACE,kBAAkB;;EAElB,SAAS;EACT,eAAe;EACf,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;;EAET,aAAa;EACb,mBAAmB;EACnB,uBAAuB;;EAEvB,WAAW;EACX,YAAY;AACd","sourcesContent":[".ImageNode__contentEditable {\n  position: relative;\n\n  display: block;\n\n  width: calc(100% - 20px);\n  min-height: 20px;\n  padding: 10px;\n\n  resize: none;\n  cursor: text;\n  user-select: text;\n  white-space: pre-wrap;\n  word-break: break-word;\n\n  border: 0;\n  outline: 0;\n\n  font-size: 12px;\n  caret-color: rgb(5 5 5);\n}\n\n.ImageNode__placeholder {\n  position: absolute;\n  top: 10px;\n  left: 10px;\n\n  display: inline-block;\n  overflow: hidden;\n\n  user-select: none;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  pointer-events: none;\n\n  color: #888;\n\n  font-size: 12px;\n}\n\n.image-control-wrapper--resizing {\n  touch-action: none;\n}\n\n.focused {\n  /* outline: 2px solid black; */\n\n  /* user-select: none; */\n}\n\n.draggable {\n  /* cursor: grab; */\n}\n\n.draggable:active {\n  /* cursor: grabbing; \n  outline: 20px solid black; */\n}\n\n.imageBlock {\n  position: relative;\n\n  margin: 0;\n  margin-top: 8px;\n  margin-right: 30px;\n  padding: 0;\n}\n\n.deleteImage {\n  position: absolute;\n  right: -25px;\n  bottom: 0;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImageNode__contentEditable": `imageComponent_ImageNode__contentEditable__BuH7e`,
	"ImageNode__placeholder": `imageComponent_ImageNode__placeholder__PiIzk`,
	"image-control-wrapper--resizing": `imageComponent_image-control-wrapper--resizing__fSNKj`,
	"focused": `imageComponent_focused__3iXeX`,
	"draggable": `imageComponent_draggable__UDKHa`,
	"imageBlock": `imageComponent_imageBlock__HnHf9`,
	"deleteImage": `imageComponent_deleteImage__c1e2z`
};
export default ___CSS_LOADER_EXPORT___;
