import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import {
  ActionsPopover,
  apiClient,
  Button,
  ConfirmModal,
  PencilIcon,
  CopyIcon,
  OpenListIcon,
  useAppContext,
  ERROR_MESSAGES,
  UnArchiveIcon,
  ArchiveIcon,
} from "@dit/core-frontend"
import { Text } from "@mos-cat/ds"
import { PageExportAction } from "@components/pages/PageExportAction"

import { Delete } from "@mos-cat/ds-icons"

import { API_ENDPOINTS, ROUTES } from "@constants"
import { templateRequest } from "./utils"
import { createNewDraftPage } from "@components/pages/utils"

const TemplateActions = ({
  template,
  zone,
  onDelete,
  openEditTemplateCurtain,
  onUpdate,
  ...popoverProps
}) => {
  const [isCopyLoading, setIsCopyLoading] = useState(false)
  const [isCreatePageLoading, setIsCreatePageLoading] = useState(false)
  const [isArchiveLoading, setIsArchiveLoading] = useState(false)
  const { isArchive } = template
  const navigate = useNavigate()
  const { showAlert } = useAppContext()
  const { user } = useAppContext()

  const isEditable = user.id === template?.user?.id

  const handleCopyTemplate = async (hidePopover) => {
    setIsCopyLoading(true)

    const { success, alertMessage, templateId } = await templateRequest({
      values: { ...template, title: `copy ${template.title}` },
    })

    if (success) {
      showAlert("Шаблон успешно скопирована", { type: "success" })
      navigate(`${ROUTES.ZONE}/${zone.slug}/templates/${templateId}`)
    } else {
      showAlert(alertMessage)
    }

    hidePopover()
    setIsCopyLoading(false)
  }

  const handleCreatePage = async (hidePopover) => {
    setIsCreatePageLoading(true)

    void createNewDraftPage({
      zoneId: zone.id,
      parent: null,
      copyPage: {
        title: template.pageTitle,
        text: template?.content || "",
      },
      onError: (message) => {
        showAlert(message)
        setIsCreatePageLoading(false)
      },
      onSuccess: (page) => {
        setIsCreatePageLoading(false)
        hidePopover()
        navigate(`${ROUTES.ZONE}/${zone.slug}/pages/${page?.slug || page?.id}/edit`)
      },
    })
  }

  return (
    <>
      <ActionsPopover
        customHoverStyle={{ backgroundColor: "" }}
        hoverColor=""
        customBackground="none"
        {...popoverProps}
      >
        {(b, hidePopover) => (
          <>
            {!isArchive && (
              <Button
                kind="menu-button"
                disabled={isCreatePageLoading}
                loading={isCreatePageLoading}
                onClick={() => handleCreatePage(hidePopover)}
              >
                <OpenListIcon color="currentColor" size={21} />
                Создать страницу из шаблона
              </Button>
            )}
            <PageExportAction
              page={{ title: template.pageTitle, text: template.content }}
              onCloseModal={hidePopover}
            />
            {!isArchive && (
              <>
                {isEditable && (
                  <>
                    <Button
                      kind="menu-button"
                      onClick={() => {
                        openEditTemplateCurtain(template.id)
                        hidePopover()
                      }}
                    >
                      <PencilIcon color="currentColor" size={21} />
                      Редактировать карточку шаблона
                    </Button>

                    <Button
                      as={Link}
                      kind="menu-button"
                      to={`${ROUTES.ZONE}/${zone.slug}/templates/${template.id}/edit`}
                    >
                      <PencilIcon color="currentColor" size={21} />
                      Редактировать страницу шаблона
                    </Button>
                  </>
                )}

                <Button
                  kind="menu-button"
                  disabled={isCopyLoading}
                  loading={isCopyLoading}
                  onClick={() => handleCopyTemplate(hidePopover)}
                >
                  <CopyIcon color="currentColor" size={21} />
                  Копировать
                </Button>
              </>
            )}

            {isEditable && (
              <ConfirmModal
                title="Удаление шаблона"
                description={`Вы действительно хотите удалить шаблон «${template.title}»?`}
                modalDisclosureProps={{
                  as: Button,
                  kind: "menu-button",
                  children: (
                    <>
                      <Delete color="currentColor" />
                      Удалить
                    </>
                  ),
                }}
                successText="Шаблон удален"
                confirmButtonText="Удалить"
                onSuccess={() => {
                  hidePopover()
                  onDelete()
                }}
                onCancel={hidePopover}
                request={() => apiClient.delete(`${API_ENDPOINTS.pageTemplates}/${template.id}`)}
              />
            )}
            {isArchive ? (
              <Button
                kind="menu-button"
                disabled={isArchiveLoading}
                loading={isArchiveLoading}
                onClick={async () => {
                  setIsArchiveLoading(true)
                  try {
                    await apiClient.put(`${API_ENDPOINTS.pageTemplates}/${template.id}/archive`, {
                      archive: false,
                    })
                    onUpdate({ isArchive: false })
                    hidePopover()
                  } catch (err) {
                    showAlert(err?.data?.errors?.[0].message || ERROR_MESSAGES.default)
                  } finally {
                    setIsArchiveLoading(false)
                  }
                }}
              >
                <UnArchiveIcon color="currentColor" size={21} />
                Вернуть из архива
              </Button>
            ) : (
              <ConfirmModal
                title="Архивация шаблона"
                description={
                  <Text>
                    Вы действительно хотите архивировать шаблон «{template.title}»?
                    <br />
                    Шаблон больше не будет доступен для выбора при создании страницы.
                  </Text>
                }
                modalDisclosureProps={{
                  as: Button,
                  kind: "menu-button",
                  children: (
                    <>
                      <ArchiveIcon color="currentColor" size={21} />
                      Архивировать
                    </>
                  ),
                }}
                successText="Страница архивирована"
                confirmButtonText="Архивировать"
                onCancel={hidePopover}
                onSuccess={() => {
                  onUpdate({ isArchive: true })
                  hidePopover()
                }}
                request={() =>
                  apiClient.put(`${API_ENDPOINTS.pageTemplates}/${template.id}/archive`, {
                    archive: true,
                  })
                }
              />
            )}
          </>
        )}
      </ActionsPopover>
    </>
  )
}

export default TemplateActions
