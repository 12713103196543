import React, { memo } from "react"
import { Link, useNavigate, useMatch } from "react-router-dom"
import { DateTime } from "luxon"

import { Box, Text } from "@mos-cat/ds"
import { usePageContext } from "@components/contexts/PageContext"
import { useZoneContext } from "@components/contexts/ZoneContext"
import { EditorPreview, stringToEditorContent } from "@components/form"
import {
  PageBlockRow,
  PageBlockRowSection,
  PageBlockContent,
  PageBlockHeading,
  // StarIcon,
  getFormattedRecentDate,
  Button,
  Favorites,
  Subscription,
  useAppContext,
  CircleCrossIcon,
  CustomGetUsersOptionLabel,
  Avatar,
} from "@dit/core-frontend"

import { PageActions } from "@components/pages/PageActions"
import { PageStatus } from "@components/pages/PageStatus"
import { CommentsBlock } from "../Comment/CommentsBlock"
import { Edit } from "@mos-cat/ds-icons"

import { API_ENDPOINTS, ROUTES } from "@constants"
import { PAGE_STATUSES } from "@utils/pages"
import { ENTITY_TYPES } from "@utils/favorites"

import styles from "./pagePreview.module.css"
import { ZONE_SECTION_LINKS } from "@utils/zones"
import TextCommentsButton from "../general/TextCommentsButton"

export const PagePreview = memo(() => {
  const { page, setPage } = usePageContext()
  const { zone, deletePage, checkZonePermission, togleTextCommentsShow } = useZoneContext()
  const navigate = useNavigate()
  const isInPages = !!useMatch(`/${ROUTES.ZONE}/:zoneSlug/${ZONE_SECTION_LINKS.PAGES}/*`)

  const isArchive = !!page.archive
  const isDraft = page.status.slug === PAGE_STATUSES.DRAFT
  const { isMobileView } = useAppContext()

  return (
    <>
      <PageBlockRow
        place="middle"
        position="sticky"
        top="-40px"
        zIndex="10"
        alignItems="flex-start"
        backgroundColor="white"
        m={["0 -10px", "0 -20px", "0 -20px 0 -40px"]}
        p={["10px", "20px", "20px 20px 20px 40px"]}
        width="auto"
        boxShadow="0 12px 12px -8px rgba(0, 20, 67, 0.12)"
        display="flex"
        flexDirection={["column-reverse", "row"]}
      >
        <PageBlockRowSection
          overflow="hidden"
          flexDirection={["column-reverse", "column"]}
          alignItems="flex-start"
          gridGap="10px"
          width={["100%", "auto"]}
        >
          <PageBlockHeading styles={{ maxWidth: "860px" }} opacity={isArchive ? "0.4" : ""}>
            <Text
              fontSize={["24px", "34px", "44px"]}
              lineHeight={["28px", "38px", "48px"]}
              className={styles.title}
            >
              {page?.title}
            </Text>
          </PageBlockHeading>
          <Box
            display="flex"
            alignItems={["flex-start", "center"]}
            gridGap="10px"
            justifyContent="space-between"
            width={["100%", "auto"]}
          >
            <Box display="flex" alignItems="center" gridGap="5px">
              <Avatar size={20} url={(page.lastModifiedBy || page.user)?.photo?.url} />
              <Text fontSize="14px" color="gray-80">
                {CustomGetUsersOptionLabel(page.lastModifiedBy || page.user)}
              </Text>
            </Box>
            <Text fontSize="14px" color="gray-40" styles={{ whiteSpace: "nowrap" }}>
              {getFormattedRecentDate(DateTime.fromISO(page.modifiedAt))}
            </Text>
            {!isDraft && (
              <Text
                as={Box}
                backgroundColor="gray-10"
                p="5px 10px"
                color="gray-60"
                fontSize="14px"
                borderRadius="4px"
              >
                Версия страницы : {page.version}
              </Text>
            )}
          </Box>
        </PageBlockRowSection>
        <Box
          display="flex"
          flexWrap="wrap"
          gridGap={["5px", "20px"]}
          pt={[0, "10px"]}
          width={["100%", "auto"]}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
            gridGap="5px"
            flex="2"
            opacity={isArchive ? "0.4" : ""}
          >
            <PageStatus status={page.status} />
          </Box>
          {!isArchive && checkZonePermission("PAGE_EDIT") && (
            <Box display="flex" alignItems="center" gridGap="5px">
              {/* Переход на относительный путь */}
              <Link to="edit">
                <Button kind="gray" padding={["5px!important", "4px 16px!important"]}>
                  {isMobileView ? <Edit /> : "Редактировать"}
                </Button>
              </Link>
            </Box>
          )}
          <Box display="flex" alignItems="center" gridGap="5px">
            <Subscription
              itemId={page.id}
              subscriptionId={page.subscription?.id}
              type="page"
              requestUrl={API_ENDPOINTS.subscription}
              iconSize={21}
              p="5px !important"
              kind="gray"
              isButtonView
            />
            <Favorites
              itemId={page.id}
              favoritesId={page.favorites?.id}
              type={ENTITY_TYPES.PAGE}
              requestUrl={API_ENDPOINTS.favorites}
              iconSize={21}
              p="5px !important"
              kind="gray"
              isButtonView
            />
            <TextCommentsButton
              togleTextCommentsShow={togleTextCommentsShow}
              kind="gray"
              p="5px !important"
            />
            <PageBlockRowSection>
              <PageActions
                page={page}
                as={Button}
                kind="gray"
                p="0 !important"
                onDelete={() => {
                  deletePage(page.id)
                  navigate(
                    isInPages ? `${ROUTES.ZONE}/${zone.slug}/pages` : `${ROUTES.ZONE}/${zone.slug}`,
                    { replace: true },
                  )
                }}
                onUpdate={(data) => {
                  setPage((prevPageData) => ({ ...prevPageData, ...data }))
                }}
              />
            </PageBlockRowSection>
          </Box>
          {/* TODO Команда */}
          {/*<Box>*/}
          {/*  <Button {...grayButtonProps}>*/}
          {/*    <Text as={Box} px="16px" color="gray-60" fontSize="14px">*/}
          {/*      Подписаться*/}
          {/*    </Text>*/}
          {/*  </Button>*/}
          {/*</Box>*/}
          {isInPages && (
            <Box display="flex" alignItems="center">
              <Link to={`${ROUTES.ZONE}/${zone.slug}/pages`}>
                <Button
                  kind="gray"
                  backgroundColor={["", "transparent !important"]}
                  styles={{
                    minWidth: "32px!important",
                    height: "32px!important",
                    padding: ["5px!important", "0.25rem 32px!important"],
                    color: "gray-60",
                    fontSize: "14px",
                    "& span": {
                      justifyContent: "center",
                    },
                  }}
                >
                  {isMobileView ? <CircleCrossIcon /> : "Закрыть"}
                </Button>
              </Link>
            </Box>
          )}
        </Box>
      </PageBlockRow>
      <PageBlockContent
        opacity={isArchive ? "0.4" : ""}
        style={{ width: "100%", maxWidth: "1200px" }}
      >
        {checkZonePermission("PAGE_READ") && (
          <EditorPreview content={stringToEditorContent(page.text || page.plainText)} addComments />
        )}
        <CommentsBlock page={page} stackClassName={styles.bodyBlock} />
      </PageBlockContent>
    </>
  )
})

PagePreview.displayName = "PagePreview"
