import React, { useCallback, useEffect, useRef, useState } from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection"
import { mergeRegister } from "@lexical/utils"
import {
  $getNodeByKey,
  $getSelection,
  $isDecoratorNode,
  $isElementNode,
  $isNodeSelection,
  $isTextNode,
  $setSelection,
  BLUR_COMMAND,
  CLICK_COMMAND,
  COMMAND_PRIORITY_LOW,
  KEY_ARROW_LEFT_COMMAND,
  KEY_ARROW_RIGHT_COMMAND,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  SELECTION_CHANGE_COMMAND,
  COMMAND_PRIORITY_NORMAL,
} from "lexical"

import { Box } from "@mos-cat/ds"
import { $isMentionNode } from "@editor/nodes/MentionNode"
import { IS_IOS } from "@editor/plugins/MentionPlugin"
import { getNextSibling, getPreviousSibling } from "@editor/plugins/mention-utils"
import { Spinner, apiClient } from "@dit/core-frontend"
import { API_ENDPOINTS } from "@src/constants"
import TaskMention from "./TaskMention"

export default function MentionComponent(props) {
  const { data, nodeKey, trigger } = props
  const [isLoading, setIaLoading] = useState(true)
  const [currentData, setCurrentData] = useState(data?.data || [])
  const [editor] = useLexicalComposerContext()
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey)
  const ref = useRef(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (trigger === "#") {
          const response = await apiClient.get(`${API_ENDPOINTS.task}/${currentData.id}`)

          const responseData = response.data.data
          setCurrentData((prev) => ({
            ...prev,
            title: responseData.title,
            status: responseData.status,
            slug: responseData.slug,
          }))
        }

        setIaLoading(false)
      } catch (error) {
        setIaLoading(false)

        setCurrentData([])
      }
    }
    fetchData()
  }, [])

  const onDelete = useCallback(
    (payload) => {
      if (isSelected && $isNodeSelection($getSelection())) {
        payload.preventDefault()
        const node = $getNodeByKey(nodeKey)
        if ($isMentionNode(node)) {
          node.remove()
        }
      }
      return false
    },
    [isSelected, nodeKey],
  )

  const onArrowLeftPress = useCallback(
    (event) => {
      const node = $getNodeByKey(nodeKey)
      if (!node || !node.isSelected()) {
        return false
      }
      let handled = false
      const nodeToSelect = getPreviousSibling(node)
      if ($isElementNode(nodeToSelect)) {
        nodeToSelect.selectEnd()
        handled = true
      }
      if ($isTextNode(nodeToSelect)) {
        nodeToSelect.select()
        handled = true
      }
      if ($isDecoratorNode(nodeToSelect)) {
        nodeToSelect.selectNext()
        handled = true
      }
      if (nodeToSelect === null) {
        node.selectPrevious()
        handled = true
      }
      if (handled) {
        event.preventDefault()
      }
      return handled
    },
    [nodeKey],
  )

  const onArrowRightPress = useCallback(
    (event) => {
      const node = $getNodeByKey(nodeKey)
      if (!node || !node.isSelected()) {
        return false
      }
      let handled = false
      const nodeToSelect = getNextSibling(node)
      if ($isElementNode(nodeToSelect)) {
        nodeToSelect.selectStart()
        handled = true
      }
      if ($isTextNode(nodeToSelect)) {
        nodeToSelect.select(0, 0)
        handled = true
      }
      if ($isDecoratorNode(nodeToSelect)) {
        nodeToSelect.selectPrevious()
        handled = true
      }
      if (nodeToSelect === null) {
        node.selectNext()
        handled = true
      }
      if (handled) {
        event.preventDefault()
      }
      return handled
    },
    [nodeKey],
  )

  const onClick = useCallback(
    (event) => {
      if (event.target === ref.current || ref.current?.contains(event.target)) {
        if (!event.shiftKey) {
          clearSelection()
        }
        setSelected(true)
        return true
      }
      return false
    },
    [clearSelection, setSelected],
  )

  const onBlur = useCallback(() => {
    const node = $getNodeByKey(nodeKey)
    if (node && node.isSelected()) {
      $setSelection(null)
    }
    return false
  }, [nodeKey])

  // Make sure that the focus is removed when clicking next to the mention
  const onSelectionChange = useCallback(() => {
    if (IS_IOS && isSelected) {
      setSelected(false)
      return true
    }
    return false
  }, [isSelected, setSelected])

  useEffect(() => {
    const unregister = mergeRegister(
      editor.registerCommand(CLICK_COMMAND, onClick, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_LEFT_COMMAND, onArrowLeftPress, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ARROW_RIGHT_COMMAND, onArrowRightPress, COMMAND_PRIORITY_LOW),
      editor.registerCommand(BLUR_COMMAND, onBlur, COMMAND_PRIORITY_LOW),
      editor.registerCommand(SELECTION_CHANGE_COMMAND, onSelectionChange, COMMAND_PRIORITY_NORMAL),
    )
    return () => {
      unregister()
    }
  }, [editor, onArrowLeftPress, onArrowRightPress, onClick, onBlur, onDelete, onSelectionChange])

  return (
    <>
      {isLoading ? (
        <Box>
          <Spinner space="sp-16" wrapper="div" iconSize="16px" />
        </Box>
      ) : (
        <TaskMention currentData={currentData} ref={ref} editor={editor} nodeKey={nodeKey} />
      )}
    </>
  )
}
