import React, { memo, useCallback, useEffect, useState } from "react"

import { API_ENDPOINTS } from "@constants"

import { Stack } from "@mos-cat/ds"
import { DataListWrapper, Spinner, VirtualList } from "@dit/core-frontend"
import { FeedItem } from "./FeedItem"

const ITEMS_PER_PAGE = 100

const Feed = memo(({ isRequestLoading, isLoading, isVisible, data, getData }) => {
  const [page, setPage] = useState(1)

  const fetchData = () => {
    getData(API_ENDPOINTS.feed, {
      sortDirection: "desc",
      epp: ITEMS_PER_PAGE,
      eppOffset: (page - 1) * ITEMS_PER_PAGE,
    })
  }

  useEffect(() => {
    setPage(1)
    if (page === 1 && !!data) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Начальный запрос + при переключении страницы
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  // При открытии таба, но только если там запрашивалось только кол-во
  useEffect(() => {
    if (isVisible && data?.itemsTotal && !data?.itemsList?.length) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isVisible])

  const handleAddPage = useCallback(() => {
    if (data?.itemsList.length < data?.itemsTotal) {
      setPage((prev) => prev + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, page])

  return (
    <>
      {isRequestLoading && <Spinner space="sp-16" wrapper="div" />}
      {data?.itemsList && (
        <Stack space="0px" height="380px" overflowY="auto" paddingX="5px" paddingY="5px">
          <VirtualList
            over={10}
            dataList={data.itemsList}
            dataTotal={data.itemsTotal}
            isLoading={isLoading}
            infiniteScroll
            infiniteCount={2}
            height={380}
            renderItem={(item, index) => (
              <FeedItem key={`feed-${item.user?.id}-${index}`} item={item} />
            )}
            onLoad={handleAddPage}
          />
        </Stack>
      )}
    </>
  )
})

export const FeedBlock = ({ ...props }) => {
  return (
    <DataListWrapper>
      <Feed {...props} />
    </DataListWrapper>
  )
}

Feed.displayName = "Feed"
FeedBlock.displayName = "FeedBlock"
