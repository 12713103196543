import React, { memo, useEffect, useMemo, useState } from "react"
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import {
  buildTree,
  dropAnimationConfig,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  setProperty,
} from "./utils"
import { createPortal } from "react-dom"
import { SortableTreeItem } from "./SortableTreeItem"
import { Stack } from "@mos-cat/ds"
import { useZoneContext } from "@src/components/contexts/ZoneContext"
import { ROUTES } from "@src/constants"
import { ZONE_SECTION_LINKS } from "@src/utils/zones"
import { CreatePageModal } from "@components/pages/CreatePageModal"

const indentationWidth = 12
// Уровени вложенности. Пока оставил, наверняка потом будет нужно ограничение
const MAX_LEVELS = 9999

export const TreePages = memo(({ pages, isCreate }) => {
  const { zone, updatePage } = useZoneContext()
  const [activeId, setActiveId] = useState(null)
  const [items, setItems] = useState(pages)
  const [overId, setOverId] = useState(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const [createModal, setCreateModal] = useState(null)

  useEffect(() => {
    setItems(pages)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pages])

  const startLink = `${ROUTES.ZONE}/${zone.slug}`

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items)

    const collapsedItems = flattenedTree.reduce(
      (acc, { children, collapsed, id }) => (collapsed && children.length ? [...acc, id] : acc),
      [],
    )

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems,
    )
  }, [activeId, items])

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems])
  // const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null

  function resetState() {
    setOverId(null)
    setActiveId(null)
    setOffsetLeft(0)

    document.body.style.setProperty("cursor", "")
  }

  const projected =
    activeId && overId
      ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
      : null

  function handleCollapse(id) {
    setItems(
      setProperty(items, id, "collapsed", (value) => {
        return !value
      }),
    )
  }

  function handleDragStart({ active: { id: activeId } }) {
    setActiveId(activeId)
    setOverId(activeId)

    document.body.style.setProperty("cursor", "grabbing")
  }

  function handleDragMove({ delta }) {
    setOffsetLeft(delta.x)
  }

  function handleDragOver({ over }) {
    setOverId(over?.id ?? null)
  }

  function handleDragEnd({ active, over }) {
    resetState()

    if (projected && over) {
      const { depth, parentId } = projected
      const clonedItems = JSON.parse(JSON.stringify(flattenTree(items)))
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id)
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id)
      const activeTreeItem = clonedItems[activeIndex]

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId }

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)
      const newItems = buildTree(sortedItems)

      if (parentId !== activeTreeItem.parentId) {
        updatePage(active.id, { parent: { id: parentId } }, true)
      }
      setItems(newItems)
    }
  }

  function handleDragCancel() {
    resetState()
  }

  return (
    <>
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        <Stack space={10} marginBottom="14px">
          {flattenedItems.map(
            ({ id, children, collapsed, depth, title, modifiedAt, user, zone }) => {
              const currentIndex = flattenedItems.findIndex((item) => item.id === id)
              let nextSameDepthElement = false
              const itemsAfterCurrent = flattenedItems.slice(currentIndex + 1)
              for (let i = 0; i < itemsAfterCurrent.length; i++) {
                if (itemsAfterCurrent[i].depth < depth) {
                  break
                }
                if (itemsAfterCurrent[i].depth === depth) {
                  nextSameDepthElement = true
                  break
                }
              }

              const minDepth = Math.min(MAX_LEVELS, depth)
              const lines = Array(minDepth).fill(false)
              for (let i = 0; i < minDepth; i++) {
                let nextSameDepthElement = false
                for (let j = 0; j < itemsAfterCurrent.length; j++) {
                  if (itemsAfterCurrent[j].depth < i) {
                    break
                  }
                  if (itemsAfterCurrent[j].depth === i) {
                    nextSameDepthElement = true
                    break
                  }
                }
                lines[i] = nextSameDepthElement
              }

              return (
                <SortableTreeItem
                  key={`tree-page-${id}`}
                  id={id}
                  value={{ title, modifiedAt, user, id, zone }}
                  MAX_LEVELS={MAX_LEVELS}
                  depth={id === activeId && projected ? projected.depth : depth}
                  indentationWidth={indentationWidth}
                  childCount={getChildCount(items, id) + 1}
                  collapsed={!(collapsed && children.length)}
                  onCollapse={children.length ? () => handleCollapse(id) : undefined}
                  nextElement={!!nextSameDepthElement}
                  lines={lines.reverse()}
                  isActive={id === activeId}
                  link={`${startLink}/${ZONE_SECTION_LINKS.PAGES}/${id}`}
                  setCreateModal={setCreateModal}
                  isCreate={isCreate}
                />
              )
            },
          )}
        </Stack>
      </SortableContext>
      {createPortal(
        <DragOverlay dropAnimation={dropAnimationConfig}>
          <div></div>
        </DragOverlay>,
        document.body,
      )}
      <CreatePageModal
        defaultZone={createModal?.zone}
        withChangeZone={false}
        parentId={createModal?.id}
        modalStateProps={{
          visible: !!createModal,
          hide: () => setCreateModal(null),
        }}
      />
    </>
  )
})

TreePages.displayName = "Tree"
