import React, { useEffect } from "react"
import { Route, Routes, useParams } from "react-router-dom"

import { useZoneContext } from "@components/contexts/ZoneContext"
import { PageContext, PageContextProvider, usePageContext } from "@components/contexts/PageContext"
import { PageForm } from "@components/pages"
import { PagePreview } from "@components/pages/PagePreview"
import { PageVersionPreview } from "@components/pages/PageVersionPreview"
import { PermissionsPage } from "@src/components/pages/Permissions"
import DiffPage from "@src/components/pages/Diff"

import { ROUTES } from "@constants"
import { Helmet } from "react-helmet"

const PagePageContent = ({ setAdditionalBreadcrumbs, setAdditionalTopSection }) => {
  const { pageSlug } = useParams()
  const { zone } = useZoneContext()
  const { page } = usePageContext()

  useEffect(() => {
    const defaultBreadcrumbs = [
      { label: "Страницы раздела", to: `${ROUTES.ZONE}/${zone.id}/pages` },
    ]

    setAdditionalBreadcrumbs([
      ...defaultBreadcrumbs,
      { label: page.title, to: `${ROUTES.ZONE}/${zone.id}/pages/${page.id}` },
    ])

    return () => {
      setAdditionalBreadcrumbs(defaultBreadcrumbs)
    }
  }, [page, setAdditionalBreadcrumbs, zone.slug, pageSlug])

  return (
    <>
      {page?.title && <Helmet title={`Страница · ${page?.title || ""} · МосВики`} />}

      <Routes>
        <Route path="/" element={<PagePreview />} />
        <Route
          path="/version/:version"
          element={<PageVersionPreview setAdditionalTopSection={setAdditionalTopSection} />}
        />
        <Route path="/edit" element={<PageForm />} />
        <Route path="/permissions" element={<PermissionsPage />} />
        <Route
          path="/diff"
          element={<DiffPage setAdditionalTopSection={setAdditionalTopSection} />}
        />
      </Routes>
    </>
  )
}

const PagePage = (props) => {
  const { pageSlug } = useParams()

  return (
    <PageContextProvider pageSlug={pageSlug}>
      <PagePageContent {...props} />
    </PageContextProvider>
  )
}

export default PagePage
