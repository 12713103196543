import React, { memo, Fragment, useCallback, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { DateTime } from "luxon"
import cc from "classcat"

import { Box, Stack, Text, Checkbox } from "@mos-cat/ds"
import {
  Avatar,
  ActionsPopover,
  CustomGetUsersOptionLabel,
  Button,
  useAppContext,
  apiClient,
} from "@dit/core-frontend"

import { ArrowDown } from "@mos-cat/ds-icons"

import { createNewDraftPage } from "@components/pages/utils"
import { API_ENDPOINTS, ROUTES } from "@constants"

import styles from "./history.module.css"

const CopyVersionButton = ({ zone, page, currentVersion, onSuccess }) => {
  const [isCreateLoading, setIsCreateLoading] = useState(false)
  const { showAlert } = useAppContext()

  const onCreatePage = async () => {
    setIsCreateLoading(true)
    await createNewDraftPage({
      zoneId: zone.id,
      copyPage: currentVersion,
      parent: page.parent?.id || null,
      onError: showAlert,
      onSuccess,
    })

    setIsCreateLoading(false)
  }

  return (
    <Button
      kind="menu-button"
      disabled={isCreateLoading}
      loading={isCreateLoading}
      onClick={onCreatePage}
    >
      Копировать версию
    </Button>
  )
}

const UpdateLoadingVersion = ({ page, currentVersion, onSuccess }) => {
  const [isUpdateVersionLoading, setIsUpdateVersionLoading] = useState(false)
  const { showAlert } = useAppContext()

  const onUpdateVersion = async () => {
    setIsUpdateVersionLoading(true)

    try {
      const version = String(Math.floor(Number(page.version) + 1))

      await apiClient.patch(`${API_ENDPOINTS.page}/${page.id}`, {
        zoneId: page.zone.id,
        title: currentVersion.title,
        text: currentVersion.text,
        // plainText: item.plainText,
        statusId: page.status.id,
        parentId: page.parent?.id,
        version,
      })
      onSuccess({
        zone: page.zone,
        title: currentVersion.title,
        text: currentVersion.text,
        status: page.status,
        parent: page.parent,
        version,
      })
    } catch (err) {
      showAlert(err?.data?.errors?.[0]?.message)
    }

    setIsUpdateVersionLoading(false)
  }

  return (
    <Button
      kind="menu-button"
      disabled={isUpdateVersionLoading}
      loading={isUpdateVersionLoading}
      onClick={onUpdateVersion}
    >
      Сделать актуальной
    </Button>
  )
}

const HistoryItem = memo(
  ({
    selectedVersions,
    onSelectVersion,
    item,
    zone,
    page,
    closeModal,
    isMainVersion = false,
    onUpdatePage,
  }) => {
    const [listIsOpen, setListIsOpen] = useState(false)
    const isArrowVisible = isMainVersion && !!item.historyList?.length

    const navigate = useNavigate()
    const isCheckboxVisible = !!selectedVersions.length
    const isCheckboxDisabled =
      selectedVersions.length === 2 && !selectedVersions.includes(item.version)

    const toggleIsOpen = () => {
      setListIsOpen((prev) => !prev)
    }

    const modifiedAt = DateTime.fromISO(item.modifiedAt)
      .setLocale("ru")
      .toFormat("dd MMMM yyyy г., HH:mm")

    return (
      <Stack space="5px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gridGap="8px" pl={isArrowVisible ? "0" : "32px"}>
            {isArrowVisible && (
              <Box>
                <ArrowDown
                  onClick={toggleIsOpen}
                  className={cc({
                    [styles.arrow]: true,
                    [styles.arrowRotate]: !listIsOpen,
                  })}
                />
              </Box>
            )}
            {isCheckboxVisible && (
              <Box
                onClick={() => {
                  onSelectVersion(item.version)
                }}
              >
                <Checkbox
                  checked={selectedVersions.includes(item.version)}
                  disabled={isCheckboxDisabled}
                />
              </Box>
            )}
            <Text color="gray-90" fontSize="16px" fontWeight="600">
              {modifiedAt}
            </Text>
            <Text color="gray-40" fontSize="16px" fontWeight="600">
              v. {item.version}
            </Text>
          </Box>
          <Box>
            <ActionsPopover
              customHoverStyle={{ backgroundColor: "" }}
              hoverColor=""
              customBackground="none"
            >
              {(buttonProps, hidePopover) => (
                <>
                  <Box
                    as={Link}
                    display="flex"
                    to={`${ROUTES.ZONE}/${page.zone.slug}/pages/${page.id}/version/${item.version}`}
                  >
                    <Button kind="menu-button">Посмотреть версию</Button>
                  </Box>
                  <Button
                    kind="menu-button"
                    disabled={isCheckboxDisabled}
                    onClick={() => {
                      onSelectVersion(item.version)
                      hidePopover()
                    }}
                  >
                    Сравнить версии
                  </Button>
                  <CopyVersionButton
                    zone={zone}
                    page={page}
                    currentVersion={item}
                    onSuccess={(page) => {
                      closeModal()
                      navigate(`${ROUTES.ZONE}/${zone.slug}/pages/${page?.slug || page?.id}/edit`)
                    }}
                  />
                  {item.version !== page.version && (
                    <UpdateLoadingVersion
                      page={page}
                      currentVersion={item}
                      onSuccess={(data) => {
                        // Что бы динамично обновить страницу
                        onUpdatePage(data)
                        navigate(`${ROUTES.ZONE}/${zone.slug}/pages/${page.id}`)
                      }}
                    />
                  )}
                </>
              )}
            </ActionsPopover>
          </Box>
        </Box>
        <Box pl="32px" display="flex" gridGap="10px" overflow="hidden">
          <Avatar url={item.user?.photo?.url} />
          {CustomGetUsersOptionLabel(item.user)}
        </Box>
        {isArrowVisible && (
          <Box className={cc([styles.historyChildList, { [styles.hide]: !listIsOpen }])}>
            <Stack space="20px">
              {item.historyList.map((childHistory) => (
                <Fragment key={`history-item-${item.id}-${childHistory.id}`}>
                  <Box height="1px" backgroundColor="gray-20" />
                  <HistoryItem
                    selectedVersions={selectedVersions}
                    onSelectVersion={onSelectVersion}
                    item={childHistory}
                    zone={zone}
                    closeModal={closeModal}
                    page={page}
                    onUpdatePage={onUpdatePage}
                  />
                </Fragment>
              ))}
            </Stack>
          </Box>
        )}
      </Stack>
    )
  },
)

HistoryItem.displayName = "HistoryItem"

const HistoryList = ({ page, historyList, zone, closeModal, onUpdatePage }) => {
  const [selectedVersions, setSelectedVersions] = useState([])
  const navigate = useNavigate()

  const onSelectVersion = useCallback(
    (version) => {
      if (selectedVersions.includes(version)) {
        setSelectedVersions((prev) => prev.filter((v) => v !== version))
      } else if (selectedVersions.length < 2) {
        setSelectedVersions((prev) => [...prev, version])
      }
    },
    [selectedVersions],
  )

  return (
    <Stack space="20px" justifyContent="space-between !important" flex="2">
      <Stack space="20px">
        {historyList.map((historyItem, i) => (
          <Fragment key={`history-item-${historyItem.id}`}>
            {!!i && <Box height="1px" backgroundColor="gray-20" />}
            <HistoryItem
              selectedVersions={selectedVersions}
              onSelectVersion={onSelectVersion}
              item={historyItem}
              zone={zone}
              closeModal={closeModal}
              page={page}
              isMainVersion
              onUpdatePage={onUpdatePage}
            />
          </Fragment>
        ))}
      </Stack>
      {!!selectedVersions.length && (
        <Box>
          <Button
            kind="primary"
            disabled={selectedVersions.length !== 2}
            onClick={() => {
              navigate(
                // eslint-disable-next-line max-len
                `${ROUTES.ZONE}/${zone.slug}/pages/${page?.id}/diff?firstVersion=${selectedVersions[0]}&secondVersion=${selectedVersions[1]}`,
              )
            }}
          >
            Сравнить версии
          </Button>
        </Box>
      )}
    </Stack>
  )
}

export default HistoryList
