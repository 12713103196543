import styles from "./styles.module.css"

import { $isCodeHighlightNode } from "@lexical/code"
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"

import {
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  $isTextNode,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_LOW,
  DEPRECATED_$isGridSelection,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
} from "lexical"
import {
  $isListNode,
  ListNode,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_CHECK_LIST_COMMAND,
} from "@lexical/list"
import { useCallback, useEffect, useRef, useState } from "react"
import * as React from "react"
import { createPortal } from "react-dom"
import { getDOMRangeRect } from "../../utils/getDOMRangeRect"
import { setFloatingElemPositionForLinkEditor } from "../FloatingLinkEditorPlugin"
import { getSelectedNode } from "../../utils/getSelectedNode"
import {
  BackgroundText,
  BaseTextIcon,
  BoldIcon,
  CodeIcon,
  ColorText,
  CommentTextIcon,
  Heading1Icon,
  Heading2Icon,
  Heading3Icon,
  ItalicIcon,
  LinkIcon,
  QuoteIcon,
  StrikethroughIcon,
  UnderLineIcon,
} from "../../theme/editorIcons"

import { $wrapNodes, $patchStyleText, $getSelectionStyleValueForProperty } from "@lexical/selection"

import { $createQuoteNode, $isHeadingNode } from "@lexical/rich-text"
import { Box, Text } from "@mos-cat/ds"
import { INSERT_INLINE_COMMAND } from "../CommentPlugin"
import { usePageContext } from "@src/components/contexts/PageContext"
import FontSizeDropDown from "../../components/ToolbarDropDowns/FontSizeDropDown"
import FontFamilyDropDown from "../../components/ToolbarDropDowns/FontFamilyDropDown"
import TextFormatDropdown from "../../components/ToolbarDropDowns/TextFormatDropdown"
import ChangeColorDropDown from "../../components/ToolbarDropDowns/ChangeColorDropDown"
import TextAlignDropDown from "../../components/ToolbarDropDowns/TextAlignDropDown"
import { Button } from "@dit/core-frontend"
import FormattedListDropDown from "../../components/ToolbarDropDowns/FormattedListDropDown"
import {
  $findMatchingParent,
  $getNearestBlockElementAncestorOrThrow,
  $getNearestNodeOfType,
  mergeRegister,
} from "@lexical/utils"
import { buttonProps } from "../../components/ToolbarDropDowns/buttonProps"

const blockTypeToBlockName = {
  paragraph: {
    value: "Обычный",
    icon: <BaseTextIcon />,
  },
  h1: {
    value: "Заголовок 1",
    icon: <Heading1Icon />,
  },
  h2: {
    value: "Заголовок 2",
    icon: <Heading2Icon />,
  },
  h3: {
    value: "Заголовок 3",
    icon: <Heading3Icon />,
  },
  quote: {
    value: "Цитата",
    icon: <QuoteIcon />,
  },
  code: {
    value: "Блок кода",
    icon: <CodeIcon />,
  },
}
const getSelectionY = () => {
  const selection = window?.getSelection()
  const range = selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : null
  const selectionY = range ? range.getBoundingClientRect().y : 290
  return selectionY
}

function TextFormatFloatingToolbar({
  editor,
  anchorElem,
  isLink,
  isBold,
  isItalic,
  isUnderline,
  isCode,
  isStrikethrough,
  setIsLinkEditMode,
}) {
  const popupCharStylesEditorRef = useRef(null)

  const insertLink = useCallback(() => {
    if (!isLink) {
      setIsLinkEditMode(true)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://")
    } else {
      setIsLinkEditMode(false)
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null)
    }
  }, [editor, isLink, setIsLinkEditMode])

  const insertComment = () => {
    editor.dispatchCommand(INSERT_INLINE_COMMAND, undefined)
  }

  function mouseMoveListener(e) {
    if (popupCharStylesEditorRef?.current && (e.buttons === 1 || e.buttons === 3)) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== "none") {
        const x = e.clientX
        const y = e.clientY
        const elementUnderMouse = document.elementFromPoint(x, y)

        if (!popupCharStylesEditorRef.current.contains(elementUnderMouse)) {
          // Mouse is not over the target element => not a normal click, but probably a drag
          popupCharStylesEditorRef.current.style.pointerEvents = "none"
        }
      }
    }
  }

  function mouseUpListener() {
    if (popupCharStylesEditorRef?.current) {
      if (popupCharStylesEditorRef.current.style.pointerEvents !== "auto") {
        popupCharStylesEditorRef.current.style.pointerEvents = "auto"
      }
    }
  }

  const [fontSize, setFontSize] = useState("14px")
  const [fontFamily, setFontFamily] = useState("Arial")
  const [fontColor, setFontColor] = useState("#000")
  const [bgColor, setBgColor] = useState("#fff")
  const [blockType, setBlockType] = useState("paragraph")
  const [selectedElementKey, setSelectedElementKey] = useState(null)

  const applyStyleText = useCallback(
    (styles, skipHistoryStack) => {
      editor.update(
        () => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            $patchStyleText(selection, styles)
          }
        },
        skipHistoryStack ? { tag: "historic" } : {},
      )
    },
    [editor],
  )
  const onFontColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ color: value }, skipHistoryStack)
    },
    [applyStyleText],
  )

  const onBgColorSelect = useCallback(
    (value, skipHistoryStack) => {
      applyStyleText({ "background-color": value }, skipHistoryStack)
    },
    [applyStyleText],
  )
  useEffect(() => {
    if (popupCharStylesEditorRef?.current) {
      document.addEventListener("mousemove", mouseMoveListener)
      document.addEventListener("mouseup", mouseUpListener)

      return () => {
        document.removeEventListener("mousemove", mouseMoveListener)
        document.removeEventListener("mouseup", mouseUpListener)
      }
    }
  }, [popupCharStylesEditorRef])

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection()

    const popupCharStylesEditorElem = popupCharStylesEditorRef.current
    const nativeSelection = window.getSelection()
    setFontSize($getSelectionStyleValueForProperty(selection, "font-size", "16px"))
    setFontFamily($getSelectionStyleValueForProperty(selection, "font-family", "Arial"))
    setFontColor($getSelectionStyleValueForProperty(selection, "color", "#000"))
    setBgColor($getSelectionStyleValueForProperty(selection, "background-color", "#fff"))
    const anchorNode = selection.anchor.getNode()
    let element =
      anchorNode.getKey() === "root"
        ? anchorNode
        : $findMatchingParent(anchorNode, (e) => {
            const parent = e.getParent()
            return parent !== null && $isRootOrShadowRoot(parent)
          })

    if (element === null) {
      element = anchorNode.getTopLevelElementOrThrow()
    }

    const elementKey = element.getKey()
    const elementDOM = editor.getElementByKey(elementKey)
    if (elementDOM !== null) {
      setSelectedElementKey(elementKey)
      if ($isListNode(element)) {
        const parentList = $getNearestNodeOfType(anchorNode, ListNode)
        const type = parentList ? parentList.getListType() : element.getListType()
        setBlockType(type)
      } else {
        const type = $isHeadingNode(element) ? element.getTag() : element.getType()
        if (type in blockTypeToBlockName) {
          setBlockType(type)
        }
      }
    }
    if (popupCharStylesEditorElem === null) {
      return
    }

    const rootElement = editor.getRootElement()
    if (
      selection !== null &&
      nativeSelection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const rangeRect = getDOMRangeRect(nativeSelection, rootElement)

      setFloatingElemPositionForLinkEditor(rangeRect, popupCharStylesEditorElem, anchorElem, isLink)
    }
  }, [editor, anchorElem, isLink])

  useEffect(() => {
    const scrollerElem = anchorElem.parentElement

    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar()
      })
    }

    window.addEventListener("resize", update)
    if (scrollerElem) {
      scrollerElem.addEventListener("scroll", update)
    }

    return () => {
      window.removeEventListener("resize", update)
      if (scrollerElem) {
        scrollerElem.removeEventListener("scroll", update)
      }
    }
  }, [editor, updateTextFormatFloatingToolbar, anchorElem])

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar()
    })
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateTextFormatFloatingToolbar()
        })
      }),

      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateTextFormatFloatingToolbar()
          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
    )
  }, [editor, updateTextFormatFloatingToolbar])

  return (
    <Box
      ref={popupCharStylesEditorRef}
      top={editor?._config?.namespace === "EditorPreview" ? "-45px" : ""}
      className={
        getSelectionY() < 360 && editor?._config?.namespace !== "EditorPreview"
          ? styles.floatingTextFormatBottom
          : styles.floatingTextFormatPopup
      }
    >
      <Box
        display="flex"
        alignItems="center"
        width={editor?._config?.namespace !== "EditorPreview" ? "475px" : "207px"}
        flexWrap="wrap"
      >
        {editor?._config?.namespace !== "EditorPreview" && (
          <>
            <Box display="flex" alignItems="center" gridGap="8px">
              {" "}
              <FontFamilyDropDown style={"font-family"} value={fontFamily} editor={editor} />
              <FontSizeDropDown editor={editor} style={"font-size"} value={fontSize} />
              <Button
                style={{ cursor: "pointer" }}
                {...buttonProps}
                kind="borderless"
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
                }}
                className={"popup-item spaced " + (isBold ? "active" : "")}
              >
                <BoldIcon />
              </Button>
              <Button
                style={{ cursor: "pointer" }}
                {...buttonProps}
                kind="borderless"
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")
                }}
                className={"popup-item spaced " + (isItalic ? "active" : "")}
              >
                <ItalicIcon />
              </Button>
              <Button
                style={{ cursor: "pointer" }}
                {...buttonProps}
                kind="borderless"
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
                }}
                className={"popup-item spaced " + (isUnderline ? "active" : "")}
              >
                <UnderLineIcon />
              </Button>
              <div className={styles.separator}></div>
              <TextFormatDropdown activeEditor={editor} />
              <ChangeColorDropDown
                dropDownIcon={ColorText}
                color={fontColor}
                onChange={onFontColorSelect}
              />
              <ChangeColorDropDown
                dropDownIcon={BackgroundText}
                color={bgColor}
                onChange={onBgColorSelect}
              />
            </Box>

            <TextAlignDropDown activeEditor={editor} />
            <div className={styles.separator}></div>
            <FormattedListDropDown blockType={blockType} editor={editor} />
            <div className={styles.separator}></div>
            <Box display="flex" alignItems="center" gridGap="8px">
              <Button
                style={{ cursor: "pointer" }}
                {...buttonProps}
                kind="borderless"
                onClick={() => {
                  editor.update(() => {
                    const selection = $getSelection()
                    if ($isRangeSelection(selection) || DEPRECATED_$isGridSelection(selection)) {
                      $wrapNodes(selection, () => $createQuoteNode())
                    }
                  })
                }}
                className={"popup-item spaced " + (isCode ? "active" : "")}
              >
                <QuoteIcon />
              </Button>
              <Button
                style={{ cursor: "pointer" }}
                {...buttonProps}
                kind="borderless"
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "code")
                }}
                className={"popup-item spaced " + (isCode ? "active" : "")}
              >
                <CodeIcon />
              </Button>
              <Button
                style={{ cursor: "pointer" }}
                {...buttonProps}
                kind="borderless"
                onClick={insertLink}
                className={"popup-item spaced " + (isLink ? "active" : "")}
              >
                <LinkIcon />
              </Button>
            </Box>

            <div className={styles.separator}></div>
          </>
        )}

        {editor?._config?.namespace !== "CommentEditor" && (
          <Button
            style={{ cursor: "pointer" }}
            {...buttonProps}
            kind="borderless"
            onClick={insertComment}
          >
            <>
              {" "}
              <CommentTextIcon />
              <Text color="#13151A">Добавить комментарий</Text>
            </>
          </Button>
        )}
      </Box>
    </Box>
  )
}

function useFloatingTextFormatToolbar(editor, anchorElem, setIsLinkEditMode) {
  const [isText, setIsText] = useState(false)
  const [isLink, setIsLink] = useState(false)
  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [isStrikethrough, setIsStrikethrough] = useState(false)
  const [isSubscript, setIsSubscript] = useState(false)
  const [isSuperscript, setIsSuperscript] = useState(false)
  const [isCode, setIsCode] = useState(false)

  const updatePopup = useCallback(() => {
    editor.getEditorState().read(() => {
      // Should not to pop up the floating toolbar when using IME input
      if (editor.isComposing()) {
        return
      }
      const selection = $getSelection()
      const nativeSelection = window.getSelection()
      const rootElement = editor.getRootElement()
      if (
        nativeSelection !== null &&
        (!$isRangeSelection(selection) ||
          rootElement === null ||
          !rootElement.contains(nativeSelection.anchorNode))
      ) {
        setIsText(false)
        return
      }

      if (!$isRangeSelection(selection)) {
        return
      }

      const node = getSelectedNode(selection)

      // Update text format
      setIsBold(selection.hasFormat("bold"))
      setIsItalic(selection.hasFormat("italic"))
      setIsUnderline(selection.hasFormat("underline"))
      setIsStrikethrough(selection.hasFormat("strikethrough"))
      setIsSubscript(selection.hasFormat("subscript"))
      setIsSuperscript(selection.hasFormat("superscript"))
      setIsCode(selection.hasFormat("code"))

      // Update links
      const parent = node.getParent()
      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true)
      } else {
        setIsLink(false)
      }

      if (!$isCodeHighlightNode(selection.anchor.getNode()) && selection.getTextContent() !== "") {
        setIsText($isTextNode(node) || $isParagraphNode(node))
      } else {
        setIsText(false)
      }

      const rawTextContent = selection.getTextContent().replace(/\n/g, "")
      if (!selection.isCollapsed() && rawTextContent === "") {
        setIsText(false)
        return
      }
    })
  }, [editor])

  useEffect(() => {
    document.addEventListener("selectionchange", updatePopup)
    return () => {
      document.removeEventListener("selectionchange", updatePopup)
    }
  }, [updatePopup])

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        updatePopup()
      }),
      editor.registerRootListener(() => {
        if (editor.getRootElement() === null) {
          setIsText(false)
        }
      }),
    )
  }, [editor, updatePopup])

  if (!isText) {
    return null
  }

  return createPortal(
    <TextFormatFloatingToolbar
      editor={editor}
      anchorElem={anchorElem}
      isLink={isLink}
      isBold={isBold}
      isItalic={isItalic}
      isStrikethrough={isStrikethrough}
      isSubscript={isSubscript}
      isSuperscript={isSuperscript}
      isUnderline={isUnderline}
      isCode={isCode}
      setIsLinkEditMode={setIsLinkEditMode}
    />,
    anchorElem,
  )
}

export default function FloatingTextFormatToolbarPlugin({
  anchorElem = document.body,
  setIsLinkEditMode,
}) {
  const [editor] = useLexicalComposerContext()
  return useFloatingTextFormatToolbar(editor, anchorElem, setIsLinkEditMode)
}
