import { Box } from "@mos-cat/ds"
import styles from "./input.module.css"

import * as React from "react"

export default function TextInput({
  label,
  value,
  onChange,
  placeholder = "",
  "data-test-id": dataTestId,
  type = "text",
}) {
  return (
    <Box className={styles.wrapper}>
      <label className={styles.label}>{label}</label>
      <input
        type={type}
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
        data-test-id={dataTestId}
      />
    </Box>
  )
}
