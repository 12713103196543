import { createStore, createEffect, createEvent, sample } from "effector"
import { API_ENDPOINTS } from "@constants"
import { apiClient, setDefaultApiToken } from "@dit/core-frontend"

setDefaultApiToken(process.env.APP_SECURITY_COOKIE_NAME)

// global
const loadPermissions = createEvent()
const fetchPermissionsFx = createEffect(async () => {
  const { data } = await apiClient.get(API_ENDPOINTS.permissions, {
    params: { system: "wiki", object: "zone" },
  })
  return data.data
})

const $permissionStore = createStore([])
const $isPermissionsLoading = createStore(true)
  .on(fetchPermissionsFx, () => true)
  .on(fetchPermissionsFx.done, () => false)
  .on(fetchPermissionsFx.fail, () => false)

sample({
  clock: loadPermissions,
  target: fetchPermissionsFx,
})

$permissionStore.on(fetchPermissionsFx.doneData, (_, data) => data)

// objectId
const fetchPermissionsByObjectIdFx = createEffect(async (zoneId) => {
  const { data } = await apiClient.get(API_ENDPOINTS.permissions, {
    params: { system: "wiki", object: "zone", objectIds: [zoneId] },
  })
  return { [zoneId]: data.data }
})

const loadObjectIdPermissions = createEvent()
const $objectIdPermissionsStore = createStore({})
const $objectIdPermissionsLoading = createStore(false)
  .on(fetchPermissionsByObjectIdFx, () => true)
  .on(fetchPermissionsByObjectIdFx.done, () => false)
  .on(fetchPermissionsByObjectIdFx.fail, () => false)

$objectIdPermissionsStore.on(fetchPermissionsByObjectIdFx.doneData, (state, data) => ({
  ...state,
  ...data,
}))

sample({
  clock: loadObjectIdPermissions,
  filter: (objectId) => !$objectIdPermissionsStore.getState()[objectId],
  target: fetchPermissionsByObjectIdFx,
})

const checkPermission = (identifier) => {
  const permissions = $permissionStore.getState()
  const findPermission = permissions.find((permission) => permission === identifier)
  if (findPermission) {
    return true
  }
  return false
}

export {
  $isPermissionsLoading,
  $permissionStore,
  checkPermission,
  loadPermissions,
  $objectIdPermissionsStore,
  $objectIdPermissionsLoading,
  loadObjectIdPermissions,
}
