import React, { memo, useEffect, useState, useCallback } from "react"
import { useParams } from "react-router-dom"
import { DateTime } from "luxon"

import { Box, Text } from "@mos-cat/ds"
import { usePageContext } from "@components/contexts/PageContext"
import { useZoneContext } from "@components/contexts/ZoneContext"
import { EditorPreview, stringToEditorContent } from "@components/form"
import {
  PageBlockRow,
  PageBlockRowSection,
  PageBlockContent,
  PageBlockHeading,
  Avatar,
  CustomGetUsersOptionLabel,
  Spinner,
  apiClient,
  ErrorPage,
} from "@dit/core-frontend"

import { API_ENDPOINTS, ROUTES } from "@constants"
import TopSectionButton from "@components/pages/TopSectionButton"

export const PageVersionPreview = memo(({ setAdditionalTopSection }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [versionPage, setVersionPage] = useState(null)
  const { page } = usePageContext()
  const { zone } = useZoneContext()
  const isArchive = !!page.archive

  const { version } = useParams()

  const getSelectedVersion = useCallback(async () => {
    if (!version) {
      setIsLoading(false)
      return
    }

    try {
      const { data } = await apiClient.get(`${API_ENDPOINTS.page}/${page.id}?version=${version}`)

      setVersionPage(data?.data)
    } catch (err) {
      console.error(err)
    }

    setIsLoading(false)
  }, [version, page.id])

  useEffect(() => {
    void getSelectedVersion()
  }, [getSelectedVersion])

  useEffect(() => {
    setAdditionalTopSection(<TopSectionButton zoneId={zone?.id} pageId={page?.id} />)

    return () => {
      setAdditionalTopSection(null)
    }
  }, [setAdditionalTopSection, zone?.id, page?.id])

  if (isLoading) {
    return (
      <Spinner
        styles={{ position: "static" }}
        space="sp-16"
        iconSize="30px"
        overlayVisible={false}
      />
    )
  } else if (!isLoading && !versionPage) {
    return (
      <ErrorPage
        title="Версия не найдена"
        text="Версия, на которую вы пытаетесь попасть, не существует"
        mainLink={`${ROUTES.ZONE}/${zone.id}/pages/${page.id}`}
      />
    )
  }

  return (
    <>
      <PageBlockRow
        place="middle"
        position="sticky"
        top="-40px"
        zIndex="10"
        alignItems="flex-start"
        backgroundColor="white"
        m={["0 -10px", "0 -20px", "0 -20px 0 -40px"]}
        p={["20px 10px", "20px", "20px 20px 20px 40px"]}
        width="auto"
        boxShadow="0 12px 12px -8px rgba(0, 20, 67, 0.12)"
      >
        <PageBlockRowSection
          overflow="hidden"
          flexDirection="column"
          alignItems="flex-start"
          gridGap="10px"
        >
          <PageBlockHeading styles={{ maxWidth: "860px" }} opacity={isArchive ? "0.4" : ""}>
            <Text fontSize="44px" lineHeight="48px">
              {versionPage?.title}
            </Text>
          </PageBlockHeading>
          <Box display="flex" alignItems="center" gridGap="10px">
            <Box display="flex" alignItems="center" gridGap="5px">
              <Avatar size={20} url={versionPage.user?.photo?.url} />
              <Text fontSize="14px" color="gray-80">
                {CustomGetUsersOptionLabel(versionPage.user)}
              </Text>
            </Box>
            <Text fontSize="14px" color="gray-40">
              {DateTime.fromISO(versionPage.modifiedAt).toFormat("dd.MM.yyyy • HH:mm")}
            </Text>
            <Text
              as={Box}
              backgroundColor="gray-10"
              p="5px 10px"
              color="gray-60"
              fontSize="14px"
              borderRadius="4px"
            >
              Версия страницы : {versionPage.version}
            </Text>
          </Box>
        </PageBlockRowSection>
      </PageBlockRow>
      <PageBlockContent style={{ width: "100%", maxWidth: "1200px" }}>
        <EditorPreview content={stringToEditorContent(versionPage.text)} />
      </PageBlockContent>
    </>
  )
})

PageVersionPreview.displayName = "PageVersionPreview"
