import React, { useState } from "react"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { EditorComposer } from "./context/LexicalComposer"
import { Box, Text } from "@mos-cat/ds"

import styles from "./editor.module.css"
import TableOfContentPlugin from "./plugins/TableOfContentPlugin"
import { EditorPreviewComposer } from "./context/LexicalPreviewComposer"
import FilesPlugin from "./plugins/FilesPlugin"
import ImagesPlugin from "./plugins/ImagesPlugin"
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin"
import { CommentPlugin } from "./plugins/CommentPlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { useSharedHistoryContext } from "./context/SharedHistory"

export const PlaceholderComp = ({ children }) => {
  return <Text color="gray-40">{children}</Text>
}

export const EditorPreview = ({
  content,
  nameSpace,
  onClick,
  placeholder = "",
  addComments = false,
}) => {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null)
  const [isLinkEditMode, setIsLinkEditMode] = useState(false)
  const { historyState } = useSharedHistoryContext()

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }
  return (
    <Box
      className={styles.editorPreview__container}
      ref={onRef}
      style={{ cursor: onClick ? "pointer" : "default" }}
      onClick={onClick}
    >
      <EditorPreviewComposer readonly={false} jsonState={content} nameSpace={nameSpace}>
        <div className={styles.editorPreview__inner}>
          <RichTextPlugin
            ErrorBoundary={LexicalErrorBoundary}
            contentEditable={<ContentEditable className={styles.editorPreview__input} />}
            placeholder={<PlaceholderComp>{placeholder}</PlaceholderComp>}
          />
        </div>
        {addComments && (
          <>
            {floatingAnchorElem && (
              <>
                <FloatingTextFormatToolbarPlugin
                  anchorElem={floatingAnchorElem}
                  setIsLinkEditMode={setIsLinkEditMode}
                />
                <CommentPlugin />
              </>
            )}
          </>
        )}

        <TableOfContentPlugin />

        <ImagesPlugin captionsEnabled={true} />
        <FilesPlugin captionsEnabled={true} />
        <HistoryPlugin externalHistoryState={historyState} />
      </EditorPreviewComposer>
    </Box>
  )
}
