import React, { useCallback, useEffect, useRef, useState } from "react"
import { Button, Text } from "@mos-cat/ds"
import { Spinner, useAppContext } from "@dit/core-frontend"

const PageCardsList = ({
  dataList = [],
  dataTotal,
  isLoading = false,
  infiniteScroll,
  infiniteCount = 0,
  renderItem,
  onLoad,
  flexDirection = "row",
}) => {
  const [infiniteCounter, setInfiniteCounter] = useState(infiniteCount)
  const [hasLoaded, setHasLoaded] = useState(false)
  const parentRef = useRef(null)
  const lastElementRef = useRef(null)
  const [distanceToTop, setDistanceToTop] = useState(0)
  const itemsPerRow = 4
  const rows = []
  for (let i = 0; i < dataList.length; i += itemsPerRow) {
    rows.push(dataList.slice(i, i + itemsPerRow))
  }
  const { isMobileView } = useAppContext()

  useEffect(() => {
    if (parentRef.current) {
      const rect = parentRef.current.getBoundingClientRect()
      const distance = rect.top + window.pageYOffset
      setDistanceToTop(distance)
    }
  }, [parentRef])

  const handleLoad = useCallback(() => {
    if (infiniteScroll && infiniteCounter > 0 && !hasLoaded) {
      onLoad?.()
      setInfiniteCounter((prev) => prev - 1)
      setHasLoaded(true)
    }
  }, [infiniteCounter, infiniteScroll, onLoad, hasLoaded])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (!infiniteScroll) {
          return
        }
        if (entries[0].isIntersecting && !hasLoaded) {
          handleLoad()
        } else if (!entries[0].isIntersecting) {
          setHasLoaded(false)
        }
      },
      { threshold: 1 },
    )

    if (lastElementRef.current) {
      observer.observe(lastElementRef.current)
    }

    return () => {
      if (lastElementRef.current) {
        observer.unobserve(lastElementRef.current)
      }
    }
  }, [lastElementRef, handleLoad, infiniteScroll, hasLoaded])

  const showLoadButton =
    !(infiniteScroll && infiniteCounter > 0) &&
    !!dataTotal &&
    dataList.length < dataTotal &&
    !isLoading

  if (!rows?.length) {
    return <Text>Нет страниц</Text>
  }

  return (
    <div
      ref={parentRef}
      style={{
        width: "100%",
        height: `calc(100vh - ${distanceToTop}px - 50px)`,
        overflowY: "auto",
        position: "relative",
        contain: "strict",
      }}
    >
      <div
        style={{
          position: "relative",

          width: "100%",
        }}
      >
        {rows.map((row, rowIndex) => (
          <div
            key={rowIndex}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: flexDirection,
              flexWrap: isMobileView ? "wrap" : "nowrap",
              marginBottom: "10px",
              gridGap: isMobileView ? "10px" : "15px",
            }}
          >
            {row.map((item, itemIndex) => (
              <div key={itemIndex} style={{ width: isMobileView ? "calc(50% - 5px)" : "unset" }}>
                {renderItem?.(item)}
              </div>
            ))}
          </div>
        ))}
      </div>

      <div ref={lastElementRef} />
      {isLoading && (
        <div>
          <Spinner
            styles={{
              position: "relative",
              minHeight: "30px",
              paddingTop: "20px",
              paddingBottom: "20px",
            }}
            overlayVisible={false}
          />
        </div>
      )}
      {showLoadButton && (
        <Button
          kind="tertiary"
          onClick={() => onLoad?.()}
          textAlign="center"
          width="100%"
          paddingTop="20px"
          marginTop="20px"
          paddingBottom="20px"
          position="relative"
          bottom="10px"
        >
          Показать еще
        </Button>
      )}
    </div>
  )
}

export default PageCardsList
