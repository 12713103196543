import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import {
  MainContainer,
  PageBlockWrapper,
  PageBlockRow,
  PageBlockRowSection,
  PageBlockHeading,
  PageBlockContent,
  Spinner,
  DataListWrapper,
  getUrlSearchParams,
  VirtualizedTable,
  useQueryParams,
  FiltersBox,
  FilterItem,
  Search,
  VirtualList,
  useAppContext,
} from "@dit/core-frontend"

import { API_ENDPOINTS } from "@constants"
import { createTableConfig } from "./createTableConfig"
import { objectTypeArray } from "@utils/favorites"
import { Box } from "@mos-cat/ds"
import FavoritesMobile from "@components/zones/FavoritesMobile"
import { Helmet } from "react-helmet"

const defaultFilterParams = {
  objectType: [],
}

const filtersList = [
  {
    title: "Тип объекта",
    endPoint: "",
    key: "objectType",
    defaultText: "Все",
    customValue: "label",
    optionsList: objectTypeArray,
    component: FilterItem,
  },
]

const FavoritesBlock = ({ isLoading, isRequestLoading, data, getData }) => {
  const [page, setPage] = useState(1)
  const [searchParams] = useSearchParams()
  const { search } = getUrlSearchParams(searchParams)
  const navigate = useNavigate()
  const { params, onChange: onChangeParams } = useQueryParams(defaultFilterParams)
  const { isMobileView } = useAppContext()

  const handleAddPage = useCallback(() => {
    if (data?.itemsList.length < data?.itemsTotal) {
      setPage((prev) => prev + 1)
    }
  }, [data])

  const fetchFavorites = useCallback(() => {
    getData(API_ENDPOINTS.favorites, {
      epp: 25,
      eppOffset: (page - 1) * 25,
      objects: params.objectType,
      search,
    })
  }, [getData, page, params.objectType, search])

  useEffect(() => {
    setPage(1)
  }, [search])

  useEffect(() => {
    fetchFavorites(search)
  }, [getData, search, fetchFavorites, page])

  const tableConfig = createTableConfig()

  const onSearch = (val) => {
    const params = new URLSearchParams(searchParams)
    if (val) {
      params.set("search", (val || "").trim())
    } else {
      params.delete("search")
    }

    navigate(`${location.pathname}?${params}`)
  }

  const currentItemsList = useMemo(() => {
    return (data?.itemsList || []).filter((entity) => !!entity.entity)
  }, [data?.itemsList])

  return (
    <>
      <Helmet title="Избранное · МосВики" />
      <PageBlockWrapper>
        <PageBlockRow place="middle">
          <PageBlockRowSection
            gridGap={["10px", "20px"]}
            justifyContent={["space-between", "flex-start"]}
            flexWrap={["wrap", "nowrap"]}
            width={["100%", "auto"]}
          >
            <PageBlockHeading>Избранное</PageBlockHeading>
            <Box width={["100%", "auto"]}>
              <Search
                onSearch={onSearch}
                defaultValue={search}
                fullWidth={["100%", "400px"]}
                width={["100%", "102px"]}
              />
            </Box>
          </PageBlockRowSection>
        </PageBlockRow>
        <PageBlockRow place="bottom">
          <FiltersBox
            filteredList={filtersList}
            filterParams={params}
            defaultParams={defaultFilterParams}
            setFilterParams={onChangeParams}
            isResetAllFilters={false}
          />
        </PageBlockRow>
        <PageBlockContent position="relative" minHeight="200px">
          {!isLoading && !isMobileView && (
            <VirtualizedTable
              over={10}
              tableConfig={tableConfig}
              dataList={currentItemsList}
              itemsTotal={data?.itemsTotal}
              emptyText="Нет избранных объектов"
              infiniteScroll
              infiniteCount={2}
              onLoad={handleAddPage}
              isLoading={isRequestLoading}
            />
          )}
          {!isLoading && isMobileView && (
            <VirtualList
              over={10}
              dataList={currentItemsList}
              dataTotal={data?.itemsTotal}
              isLoading={isLoading}
              infiniteScroll
              infiniteCount={2}
              height={500}
              renderItem={(favoriteItem) => <FavoritesMobile favoriteItem={favoriteItem} />}
              onLoad={handleAddPage}
            />
          )}
          {isLoading && <Spinner space="sp-16" wrapper="div" />}
        </PageBlockContent>
      </PageBlockWrapper>
    </>
  )
}

export const FavoritesPage = () => {
  return (
    <MainContainer>
      <DataListWrapper>
        <FavoritesBlock />
      </DataListWrapper>
    </MainContainer>
  )
}
