import { $isRootTextContentEmpty, $rootTextContent } from "@lexical/text"
import { useCallback } from "react"

export const useOnChange = (setContent, setCanSubmit) => {
  return useCallback(
    (editorState, _editor) => {
      editorState.read(() => {
        setContent($rootTextContent())
        setCanSubmit(!$isRootTextContentEmpty(_editor.isComposing(), true))
      })
    },
    [setCanSubmit, setContent],
  )
}
