// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbarPlugin_toolbarItems__mljbm {
  position: relative;
  z-index: 3;
  top: 0;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  

  box-sizing: border-box;
  width: 100%;
  min-height: 30px;
  margin-bottom: 10px;
  padding: 0 10px;

  border-radius: 0.25rem;
  background-color: rgb(243 245 247 / 100%);

  font-weight: 500;
grid-gap: 1px;
}

.toolbarPlugin_boldBtn__ec19N {
  width: 20px;

  text-transform: uppercase;

  font-size: 16px;
  font-style: bold;
}

.toolbarPlugin_italicBtn__VaiB2 {
  width: 20px;

  text-transform: uppercase;

  font-size: 16px;
  font-style: italic;
}

.toolbarPlugin_underLineBtn__74F3N {
  width: 20px;

  text-decoration: underline;
  text-transform: uppercase;

  font-size: 16px;
}

.toolbarPlugin_StrikeLineBtn__K3kHD {
  width: 20px;

  text-decoration: line-through;
  text-transform: uppercase;

  font-size: 16px;
}

.toolbarPlugin_fontSizeItems__R4RFQ {
  padding: 2px 0;

  background-color: rgb(243 245 247 / 100%);
}

.toolbarPlugin_formattingItems__XWBwT {
  padding: 2px 0;

  background-color: rgb(243 245 247 / 100%);
}


.toolbarPlugin_toolbarHistory__PLeSy{
  display: flex;
  gap: 15px;
}

.toolbarPlugin_separator__HRCLe {
  width: 2px;
  height: 15px;
  margin: 0 10px;

  background-color: #FFF;
}

.toolbarPlugin_active__-dBjK {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/Editor/plugins/toolbarPlugin.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,MAAM;;EAEN,aAAa;EACb,mBAAmB;EACnB,eAAe;;;EAGf,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;;EAEf,sBAAsB;EACtB,yCAAyC;;EAEzC,gBAAgB;AAClB,aAAa;AACb;;AAEA;EACE,WAAW;;EAEX,yBAAyB;;EAEzB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;;EAEX,yBAAyB;;EAEzB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,WAAW;;EAEX,0BAA0B;EAC1B,yBAAyB;;EAEzB,eAAe;AACjB;;AAEA;EACE,WAAW;;EAEX,6BAA6B;EAC7B,yBAAyB;;EAEzB,eAAe;AACjB;;AAEA;EACE,cAAc;;EAEd,yCAAyC;AAC3C;;AAEA;EACE,cAAc;;EAEd,yCAAyC;AAC3C;;;AAGA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,cAAc;;EAEd,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".toolbarItems {\n  position: relative;\n  z-index: 3;\n  top: 0;\n\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  \n\n  box-sizing: border-box;\n  width: 100%;\n  min-height: 30px;\n  margin-bottom: 10px;\n  padding: 0 10px;\n\n  border-radius: 0.25rem;\n  background-color: rgb(243 245 247 / 100%);\n\n  font-weight: 500;\ngrid-gap: 1px;\n}\n\n.boldBtn {\n  width: 20px;\n\n  text-transform: uppercase;\n\n  font-size: 16px;\n  font-style: bold;\n}\n\n.italicBtn {\n  width: 20px;\n\n  text-transform: uppercase;\n\n  font-size: 16px;\n  font-style: italic;\n}\n\n.underLineBtn {\n  width: 20px;\n\n  text-decoration: underline;\n  text-transform: uppercase;\n\n  font-size: 16px;\n}\n\n.StrikeLineBtn {\n  width: 20px;\n\n  text-decoration: line-through;\n  text-transform: uppercase;\n\n  font-size: 16px;\n}\n\n.fontSizeItems {\n  padding: 2px 0;\n\n  background-color: rgb(243 245 247 / 100%);\n}\n\n.formattingItems {\n  padding: 2px 0;\n\n  background-color: rgb(243 245 247 / 100%);\n}\n\n\n.toolbarHistory{\n  display: flex;\n  gap: 15px;\n}\n\n.separator {\n  width: 2px;\n  height: 15px;\n  margin: 0 10px;\n\n  background-color: #FFF;\n}\n\n.active {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbarItems": `toolbarPlugin_toolbarItems__mljbm`,
	"boldBtn": `toolbarPlugin_boldBtn__ec19N`,
	"italicBtn": `toolbarPlugin_italicBtn__VaiB2`,
	"underLineBtn": `toolbarPlugin_underLineBtn__74F3N`,
	"StrikeLineBtn": `toolbarPlugin_StrikeLineBtn__K3kHD`,
	"fontSizeItems": `toolbarPlugin_fontSizeItems__R4RFQ`,
	"formattingItems": `toolbarPlugin_formattingItems__XWBwT`,
	"toolbarHistory": `toolbarPlugin_toolbarHistory__PLeSy`,
	"separator": `toolbarPlugin_separator__HRCLe`,
	"active": `toolbarPlugin_active__-dBjK`
};
export default ___CSS_LOADER_EXPORT___;
