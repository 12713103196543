import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import {
  PageBlockContent,
  PageBlockHeading,
  PageBlockRow,
  PageBlockRowSection,
  apiClient,
  ConfirmModal,
  Tooltip,
} from "@dit/core-frontend"
import { Button, Text } from "@mos-cat/ds"
import { Delete } from "@mos-cat/ds-icons"
import { useZoneContext } from "@components/contexts/ZoneContext"
import { ZoneForm } from "@components/zones/ZoneForm"

import { API_ENDPOINTS, ROUTES } from "@constants"
import { ZONE_SETTINGS_SECTIONS } from "@utils/zones"
import { grayButtonProps } from "@utils/buttons"
import { Helmet } from "react-helmet"

const Intelligence = ({ setAdditionalBreadcrumbs }) => {
  const { zone, checkZonePermission } = useZoneContext()
  const navigate = useNavigate()

  useEffect(() => {
    setAdditionalBreadcrumbs([
      {
        label: "Сведения о разделе",
        to: `${ROUTES.ZONE}/${zone.slug}/settings/${ZONE_SETTINGS_SECTIONS.INTELLIGENCE}`,
      },
    ])
  }, [setAdditionalBreadcrumbs, zone.slug])

  return (
    <>
      {zone?.title && <Helmet title={`Настройки · ${zone?.title || ""} · МосВики`} />}

      <PageBlockRow place="middle" maxWidth="960px">
        <PageBlockRowSection>
          <PageBlockHeading>{zone.title}</PageBlockHeading>
        </PageBlockRowSection>
        <PageBlockRowSection>
          {checkZonePermission("ZONE_DELETE") && (
            <Tooltip content="Удалить">
              <ConfirmModal
                title="Удаление раздела"
                description={`Удалить раздел «${zone.title}»?`}
                modalDisclosureProps={{
                  as: Button,
                  ...grayButtonProps,
                  children: (
                    <Text color="gray-60" fontSize="14px">
                      <Delete />
                    </Text>
                  ),
                }}
                successText="Раздел удален"
                confirmButtonText="Удалить"
                onSuccess={() => {
                  navigate(ROUTES.ZONES_LIST)
                }}
                request={() => apiClient.delete(`${API_ENDPOINTS.zone}/${zone.id}`)}
              />
            </Tooltip>
          )}
        </PageBlockRowSection>
      </PageBlockRow>

      <PageBlockContent maxWidth="960px">
        <ZoneForm zone={zone} />
      </PageBlockContent>
    </>
  )
}

export default Intelligence
