// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.diff_diffKey__aGgad > * {
  padding: 2px 6px;

  font-size: 14px;
}

.diff_versionCard__Fsycn {
  padding: 10px 20px;

  background-color: var(--gray-05);
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Diff/diff.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;;EAEhB,eAAe;AACjB;;AAEA;EACE,kBAAkB;;EAElB,gCAAgC;AAClC","sourcesContent":[".diffKey > * {\n  padding: 2px 6px;\n\n  font-size: 14px;\n}\n\n.versionCard {\n  padding: 10px 20px;\n\n  background-color: var(--gray-05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"diffKey": `diff_diffKey__aGgad`,
	"versionCard": `diff_versionCard__Fsycn`
};
export default ___CSS_LOADER_EXPORT___;
