import React from "react"

import { Box, Stack, Text } from "@mos-cat/ds"
import { Avatar, CustomGetUsersOptionLabel } from "@dit/core-frontend"

export const ZoneAuthor = ({ title, user = {}, stackProps = {} }) => {
  return (
    <div>
      <Stack space="0.5rem" alignItems="flex-start" {...stackProps}>
        <Text fontWeight="600">{title}</Text>
        <Box
          display="inline-flex"
          p="10px 20px"
          gridGap="10px"
          backgroundColor="var(--beige-05)"
          borderRadius="5px"
        >
          <Avatar url={user?.photo?.url} size={30} />
          <Stack space="0">
            <Text
              styles={{
                display: "-webkit-box",
                "-webkit-box-orient": "vertical",
                "-webkit-line-clamp": "2",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              fontSize="18px"
              lineHeight="28px"
            >
              {CustomGetUsersOptionLabel(user)}
            </Text>
            {!!user?.username && (
              <Text fontSize="14px" lineHeight="20px" color="var(--gray-60)">
                {user?.username}
              </Text>
            )}
          </Stack>
        </Box>
      </Stack>
    </div>
  )
}
