import { ActionsPopover, Button, Tooltip } from "@dit/core-frontend"
import React from "react"
import { buttonProps } from "./buttonProps"
import { Box, Text } from "@mos-cat/ds"
import { ArrowDown } from "@mos-cat/ds-icons"
import { ColorPicker } from "../ColorPicker"

const ChangeColorDropDown = ({ dropDownIcon, color, onChange }) => {
  return (
    <ActionsPopover
      content={
        <Tooltip content="Изменение цвета текста">
          <Button
            {...buttonProps}
            kind="borderless"
            vertSize="30"
            width="30px"
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Text fontSize="fs-14">{dropDownIcon}</Text>
              <Text fontSize="fs-14">
                <ArrowDown />
              </Text>
            </Box>
          </Button>
        </Tooltip>
      }
    >
      {() => (
        <>
          <ColorPicker color={color} onChange={onChange} />
        </>
      )}
    </ActionsPopover>
  )
}

export default ChangeColorDropDown
