export const modulesLinks = {
  id: process.env.REACT_APP_CORE_ID_URL || "/id",
  storage: process.env.REACT_APP_CORE_STORAGE_URL || "/storage",
  admin: process.env.REACT_APP_CORE_ADMIN_URL || "/admin",
  log: process.env.REACT_APP_CORE_LOG_URL || "/log",
  passport: process.env.REACT_APP_CORE_PASSPORT_URL || "/passport",
  notify: process.env.REACT_APP_CORE_NOTIFY_URL || "/notify",
  track: process.env.REACT_APP_CORE_TRACK_URL || "/track",
  wiki: process.env.REACT_APP_CORE_WIKI_URL || "/wiki",
}
