import {
  apiClient,
  Button as ImportButton,
  fileValidation,
  useAppContext,
} from "@dit/core-frontend"
import { Box, Button, Modal, ModalDisclosure, Text, useModalState } from "@mos-cat/ds"
import { API_ENDPOINTS } from "@src/constants"
import React from "react"
import Dropzone from "react-dropzone"
import { Form } from "react-final-form"
import * as yup from "yup"
import { FileIcon } from "../../theme/editorIcons"
import { $generateNodesFromDOM } from "@lexical/html"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { $getRoot, $insertNodes } from "lexical"

const validationSchema = yup.object().shape({
  fileId: yup.string().required(),
})

export function ImportPageModal({ activeEditor, children, modalDisclosureProps, modalStateProps }) {
  const { appSettings, showAlert } = useAppContext()
  const [editor] = useLexicalComposerContext()
  const modalState = useModalState()
  const getModalState = { ...modalState, ...modalStateProps }
  const isVisible = getModalState?.visible
  const MAX_FILE_SIZE = appSettings?.sso_max_file_weight_mb?.value || 5
  const handleFileChange = async (file, rejected, form) => {
    const formData = new FormData()
    formData.append("file", file[0])
    formData.append("public", true)
    if (rejected.length) {
      const error = fileValidation(rejected[0], undefined, 1024 * 1024 * MAX_FILE_SIZE)
      if (error.startsWith("File is", 0)) {
        showAlert("Слишком большой размер файла")
      } else {
        showAlert(error)
      }

      return
    }
    try {
      const { data } = await apiClient.post(`${API_ENDPOINTS.file}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      if (data?.success && data?.data?.url) {
        showAlert("Загрузка прошла успешно", { type: "success" })
        form.change("fileId", data?.data.id)
      }
    } catch (err) {
      let messageError = err?.data?.errors?.[0]?.message || err?.data?.detail
      if (!messageError && err.status === 413) {
        form.change("fileId", "")
        showAlert("Слишком большой размер файла")
      }
    }
  }

  const handleClose = () => {
    getModalState.hide()
  }

  const onValidate = (values) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false })
    } catch (errors) {
      return errors.inner.reduce(
        (errors, error) => ({
          ...errors,
          [error.path]: error.message,
        }),
        {},
      )
    }
  }
  const onSubmit = async (values) => {
    try {
      const { data } = await apiClient.post(`${API_ENDPOINTS.import}`, {
        fileId: values.fileId,
        format: values.fileType,
      })

      if (data?.success) {
        activeEditor.update(() => {
          const parser = new DOMParser()
          const dom = parser.parseFromString(data.data, "text/html")
          const nodes = $generateNodesFromDOM(editor, dom)
          $getRoot().select()
          $insertNodes(nodes)
        })
      }
    } catch (err) {
      showAlert("Некорректный файл")
    }
  }

  return (
    <>
      {children && (
        <ModalDisclosure {...modalDisclosureProps} {...getModalState}>
          {children}
        </ModalDisclosure>
      )}
      <Modal {...getModalState} heading="Импорт страницы">
        {isVisible && (
          <Form
            initialValues={{
              fileId: "",
              fileType: "",
            }}
            validate={(values) => onValidate(values)}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitErrors,
              errors,
              touched,
              submitting,
              form,
              pristine,
              dirtyFieldsSinceLastSubmit,
              hasValidationErrors,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Box>Для импорта страницы требуется файл с расширением .docx</Box>
                  <Button kind="borderless" boxShadow="none !important">
                    <Dropzone
                      maxSize={1024 * 1024 * MAX_FILE_SIZE}
                      onDrop={(file, rejected) => handleFileChange(file, rejected, form)}
                      validator={(file) => {
                        if (
                          file?.name &&
                          file?.name.endsWith(".docx")
                          // (file?.name.endsWith(".docx") || file?.name.endsWith(".doc"))
                        ) {
                          if (file?.name.endsWith(".docx")) {
                            form.change("fileType", "docx")
                          }
                          // if (file?.name.endsWith(".doc")) {
                          //   form.change("fileType", "doc")
                          // }
                          return (
                            fileValidation({ file }, undefined, 1024 * 1024 * MAX_FILE_SIZE) || null
                          )
                        }
                        form.change("fileId", "")
                        showAlert("Неверный формат файла")
                        return "Неверный формат файла"
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <>
                          <Box
                            position={"relative"}
                            top={"0px"}
                            left="0px"
                            height={""}
                            width={""}
                            borderWidth={"0"}
                            backgroundColor={""}
                            display="flex"
                            alignItems="center"
                            gridGap="2px"
                            justifyContent={""}
                            {...getRootProps()}
                          >
                            <FileIcon />
                            <Text>{"Загрузить файл"}</Text>
                          </Box>

                          <input {...getInputProps()} />
                        </>
                      )}
                    </Dropzone>
                  </Button>

                  <Box display="flex" justifyContent="start" gridGap="16px" marginTop="16px">
                    <Button
                      kind="primary"
                      type="submit"
                      disabled={submitting || hasValidationErrors}
                    >
                      Сохранить
                    </Button>
                    <Button kind="secondary" onClick={handleClose} type="reset">
                      Отменить
                    </Button>
                  </Box>
                </form>
              )
            }}
          />
        )}
      </Modal>
    </>
  )
}
