import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { KEY_ESCAPE_COMMAND } from "lexical"
import { useEffect } from "react"

export const EscapeHandlerPlugin = ({ onEscape }) => {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      KEY_ESCAPE_COMMAND,
      (event) => {
        return onEscape(event)
      },
      2,
    )
  }, [editor, onEscape])

  return null
}
