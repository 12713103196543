import React from "react"
import { Box, Button, Text } from "@mos-cat/ds"
import { ActionsPopover, apiClient, ConfirmModal, PencilIcon } from "@dit/core-frontend"
import { Delete } from "@mos-cat/ds-icons"
import { API_ENDPOINTS } from "@constants"
import { Person } from "@components/general"
import { Link } from "react-router-dom"

const TeamMemberMobile = ({ person, updateResponseData, handleSetModal, setTeamMemberData }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="10px 0"
      borderColor="gray-05"
      borderTop="1px solid var(--gray-10)"
      styles={{
        fontSize: "fs-14",
      }}
    >
      <Box display="flex" justifyContent="space-between" gridGap="20px">
        <Person person={person?.assignedToUser} emptyText="Не назначен" />
        <ActionsPopover customHoverStyle={{ backgroundColor: "" }} customBackground="none">
          {(buttonProps) => (
            <Box display="flex" flexDirection="column" alignItems="flex-start" gridGap="10px">
              <Button
                display="flex"
                {...buttonProps}
                onClick={() => {
                  handleSetModal("update-member")
                  setTeamMemberData(person)
                }}
              >
                <Text color="gray-60" fontSize="14px">
                  <PencilIcon />
                </Text>
                <Text fontSize="14px">Редактировать</Text>
              </Button>

              <ConfirmModal
                title="Удаление участника"
                description="Вы точно хотите удалить участника?"
                modalDisclosureProps={{
                  as: Button,
                  ...buttonProps,
                  children: (
                    <>
                      <Text color="gray-60" fontSize="14px">
                        <Delete />
                      </Text>
                      <Text fontSize="14px">Удалить</Text>
                    </>
                  ),
                }}
                successText="Удаление прошло успешно"
                confirmButtonText="Удалить"
                onSuccess={() => {
                  updateResponseData?.(person.id, "remove")
                }}
                request={() => apiClient.delete(`${API_ENDPOINTS.teamMember}/${person.id}`)}
              />
            </Box>
          )}
        </ActionsPopover>
      </Box>
      <Box display="flex" flexDirection="column" paddingLeft="30px">
        <Box as={Link} to={`mailto:${person?.assignedToUser?.email}`} fontSize="fs-14">
          {person?.assignedToUser?.email}{" "}
        </Box>
        <Box>{person?.role?.title} </Box>
      </Box>
    </Box>
  )
}

export default TeamMemberMobile
