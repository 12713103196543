import styles from "./comment.module.css"

import baseTheme from "./editor.module.css"

const CommentTheme = {
  ...baseTheme,
  paragraph: styles.CommentEditorTheme__paragraph,
}

export default CommentTheme
