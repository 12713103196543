import React from "react"

import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { TreeItem } from "./TreeItem"

const animateLayoutChanges = ({ isSorting, wasDragging }) =>
  isSorting || wasDragging ? false : true

export const SortableTreeItem = ({ id, depth, value, isCreate, ...props }) => {
  const {
    attributes,
    isDragging,
    isSorting,
    listeners,
    setDraggableNodeRef,
    setDroppableNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
    animateLayoutChanges,
  })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const minDepth = Math.min(2, depth)
  return (
    <TreeItem
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={style}
      depth={minDepth}
      maxDepth={depth > 2}
      ghost={isDragging.toString()}
      disableInteraction={isSorting}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      value={value}
      isCreate={isCreate}
      {...props}
    />
  )
}
