// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_wrapper__tC\\+Nw {
    display: flex;
    align-items: center;

    margin: 20px 0;
  }

  .input_label__ujD7e {
    display: flex;
    flex: 1 1;

    color: #666;
  }

  .input_input__T-kcO {
    display: flex;
    flex: 2 1;

    width: 166px;
    min-width: 0;
    padding: 7px 10px;

    border: 1px solid #999;
    border-radius: 5px;

    font-size: 16px;
  }`, "",{"version":3,"sources":["webpack://./src/components/form/Editor/components/ColorPicker/input.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;;IAEnB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,SAAO;;IAEP,WAAW;EACb;;EAEA;IACE,aAAa;IACb,SAAO;;IAEP,YAAY;IACZ,YAAY;IACZ,iBAAiB;;IAEjB,sBAAsB;IACtB,kBAAkB;;IAElB,eAAe;EACjB","sourcesContent":[".wrapper {\n    display: flex;\n    align-items: center;\n\n    margin: 20px 0;\n  }\n\n  .label {\n    display: flex;\n    flex: 1;\n\n    color: #666;\n  }\n\n  .input {\n    display: flex;\n    flex: 2;\n\n    width: 166px;\n    min-width: 0;\n    padding: 7px 10px;\n\n    border: 1px solid #999;\n    border-radius: 5px;\n\n    font-size: 16px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `input_wrapper__tC+Nw`,
	"label": `input_label__ujD7e`,
	"input": `input_input__T-kcO`
};
export default ___CSS_LOADER_EXPORT___;
