import React, { useState } from "react"
import {
  PageBlockContent,
  PageBlockHeading,
  PageBlockRow,
  PageBlockRowSection,
  PageBlockWrapper,
} from "@dit/core-frontend"
import { ZonesList, ZoneCreateTarget } from "@components/zones"
import { Box, Text } from "@mos-cat/ds"
import { Add } from "@mos-cat/ds-icons"
import { checkPermission } from "@src/store/permissionModel"
import HubLink from "@components/general/HubLink"

const ZonesBlock = () => {
  const [projectList, setProjectList] = useState([])

  return (
    <PageBlockWrapper>
      <HubLink type="projects" items={projectList} />
      <PageBlockRow place="middle">
        <PageBlockRowSection>
          <PageBlockHeading>Мои разделы</PageBlockHeading>
          {/*{!isLoading && (*/}
          {/*  <Text fontSize="22px" color="blue-20">*/}
          {/*    {zonesData.itemsTotal}*/}
          {/*  </Text>*/}
          {/*)}*/}
        </PageBlockRowSection>
        {checkPermission("ZONE_CREATE") && (
          <PageBlockRowSection>
            <ZoneCreateTarget>
              <Text
                as={Box}
                display="flex"
                alignItems="center"
                gridGap="5px"
                color="blue-60"
                fontSize="fs-14"
              >
                <Add /> Создать новый раздел
              </Text>
            </ZoneCreateTarget>
          </PageBlockRowSection>
        )}
      </PageBlockRow>
      <PageBlockContent minHeight="287px">
        <ZonesList view="cards" limit={10} onUpdateList={setProjectList} />
      </PageBlockContent>
    </PageBlockWrapper>
  )
}

export default ZonesBlock
