import * as yup from "yup"

import { API_ENDPOINTS } from "@constants"
import {
  apiClient,
  ERROR_MESSAGES,
  yupTitleSchema,
  createValidation,
  createFormError,
} from "@dit/core-frontend"

export const VISIBILITY_LEVEL = {
  PRIVATE: "private",
  PUBLIC: "public",
  RESTRICTED: "restricted",
}

const VISIBILITY_LEVEL_OPTIONS = {
  [VISIBILITY_LEVEL.PRIVATE]: { label: "Приватный", value: VISIBILITY_LEVEL.PRIVATE },
  [VISIBILITY_LEVEL.PUBLIC]: { label: "Публичный", value: VISIBILITY_LEVEL.PUBLIC },
  [VISIBILITY_LEVEL.RESTRICTED]: { label: "Ограниченный", value: VISIBILITY_LEVEL.RESTRICTED },
}

export const visibilityLevelOptions = Object.values(VISIBILITY_LEVEL_OPTIONS)

export const validationSchema = createValidation({
  schema: yup.object({
    title: yupTitleSchema(),
    description: yup.string().max(500, "Максимальное допустимое количество символов - 500"),
    visibility: yup.string().required(ERROR_MESSAGES.required),
    assignedUsers: yup.array().when("visibility", {
      is: VISIBILITY_LEVEL.RESTRICTED,
      then: (schema) => schema.required(ERROR_MESSAGES.required).min(1, ERROR_MESSAGES.required),
    }),
  }),
})

export const templateRequest = async ({ values, template = {} }) => {
  let errors = null

  const assignedUsers = values.assignedUsers || template.assignedUsers || []

  const requestData = {
    title: values.title || template.title,
    description: values.description || template.description || "",
    visibility: values.visibility || template.visibility,
    pageTitle: values.pageTitle || template.pageTitle || "Без названия",
    content: values.content || template.content,
    assignedUsers: assignedUsers.map((user) => user.id),
    zoneId: values.zone?.id || template.zone?.id,
    iconId: values.icon?.id || null,
  }

  try {
    let requestMethod = "post"
    let requestUrl = API_ENDPOINTS.pageTemplates

    if (template.id) {
      requestMethod = "patch"
      requestUrl += `/${template.id}`
    }

    const { data } = await apiClient[requestMethod](requestUrl, requestData)
    return { success: data.success, templateId: data.data.id, errors, alertMessage: "" }
  } catch (err) {
    if (err.status === 500) {
      errors = createFormError(ERROR_MESSAGES.default)
    } else if (err?.data?.errors?.length) {
      errors = createFormError(err.data.errors, values)
    }

    return {
      success: false,
      errors,
      alertMessage: err?.data?.errors?.[0]?.message || ERROR_MESSAGES.default,
    }
  }
}
