import React, { useState, useEffect, memo, useRef, useCallback, useLayoutEffect } from "react"

import { $getRoot } from "lexical"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { ListPlugin } from "@lexical/react/LexicalListPlugin"
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin"
import { ToolbarPlugin } from "./plugins/ToolbarPlugin"

import { useSharedHistoryContext } from "./context/SharedHistory"
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin"
import { EditorComposer } from "./context/LexicalComposer"
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentPlugin"
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin"
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin"
import { TRANSFORMERS } from "@lexical/markdown"
import { Box, Button, Text } from "@mos-cat/ds"

import ImagesPlugin, { CAN_USE_DOM, INSERT_IMAGE_COMMAND } from "./plugins/ImagesPlugin"

import styles from "./editor.module.css"
import { TablePlugin } from "@lexical/react/LexicalTablePlugin"
import TableCellResizerPlugin from "./plugins/TableCellResizer"
import TableActionMenuPlugin from "./plugins/TableActionMenuPlugin"
import FigmaPlugin from "./plugins/FigmaPlugin"
import AutoEmbedPlugin from "./plugins/AutoEmbdedPlugin"
import LinkPlugin from "./plugins/LinkPlugin"
import FloatingLinkEditorPlugin from "./plugins/FloatingLinkEditorPlugin"
import CodeActionMenuPlugin from "./plugins/CodeActionMenuPlugin"
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin"
import YouTubePlugin from "./plugins/YouTubePlugin"
import { LayoutPlugin } from "./plugins/LayoutPlugin"
import ExcalidrawPlugin from "./plugins/ExalidrawPlugin"
import FilesPlugin, { INSERT_FILE_COMMAND } from "./plugins/FilesPlugin"
import CollapsiblePlugin from "./plugins/CollapsiblePlugin"
import PageBreakPlugin from "./plugins/PageBreakPlugin"
import { Menu, MenuItem } from "./components/CustomMenu"

import { HorizontalRulePlugin } from "@lexical/react/LexicalHorizontalRulePlugin"
import StickyPlugin from "./plugins/StickyPlugin"
import TableOfContentPlugin from "./plugins/TableOfContentPlugin"
import FloatingTextFormatToolbarPlugin from "./plugins/FloatingTextFormatToolbarPlugin"
import { CommentPlugin } from "./plugins/CommentPlugin"
import { MentionsPlugin } from "./plugins/MentionPlugin"
import { useDropzone } from "react-dropzone"
import { apiClient, fileValidation, useAppContext } from "@dit/core-frontend"
import { API_ENDPOINTS } from "@constants"

// import { TablePlugin } from "./plugins/TablePlugin"

const PlaceholderComp = ({ children }) => (
  <Text color="gray-40" className={styles.editor__placeholder}>
    {children}
  </Text>
)

const handleOnChange = (state, setJsonState, setIsClear, setIsHasValue, onChange) => {
  state.read(() => {
    const clearText = $getRoot().getTextContent()
    setIsHasValue(state?._nodeMap?.size > 2)

    if (onChange) {
      onChange({ text: clearText, jsonText: state.isEmpty() ? "" : JSON.stringify(state) })
    }

    if (state.isEmpty()) {
      setJsonState(undefined)
      setIsClear(false)

      return
    }

    setIsClear(true)
    setJsonState(state)
    return
  })
}

export const Editor = memo(
  ({
    id,
    nameSpace,
    onSave,
    onCancel,
    onBlur,
    content,
    readOnly,
    disabled,
    onChange,
    placeholder,
  }) => {
    const { showAlert } = useAppContext()
    const { appSettings } = useAppContext()
    const { historyState } = useSharedHistoryContext()
    const [jsonState, setJsonState] = useState(content)
    const [isClear, setIsClear] = useState(false)
    const [isHasValue, setIsHasValue] = useState(false)
    const [hasFocus, setHasFocus] = useState(false)
    const [isDragging, setIsDragging] = useState(false)
    const [floatingAnchorElem, setFloatingAnchorElem] = useState(null)
    const [isSmallWidthViewport, setIsSmallWidthViewport] = useState(false)
    const [isLinkEditMode, setIsLinkEditMode] = useState(false)
    const [ismenuOpen, setIsMenuOpen] = useState(false)

    //функционал для onBlur
    const editorRef = useRef(null)
    const editorInstanceRef = useRef(null)

    const [containerSize, setContainerSize] = useState({ width: 0, height: 0, top: 0 })

    const handleOverlayClick = useCallback(() => {
      if (onBlur && jsonState !== content && !onSave) {
        const newComment = JSON.stringify(jsonState)
        if (JSON.stringify(content) !== newComment) {
          onBlur(newComment)
        }
        onCancel?.()
      }
    }, [content, jsonState, onBlur, onCancel, onSave])

    useEffect(() => {
      function handleClickOutside(event) {
        if (editorRef.current && !editorRef.current.contains(event.target)) {
          handleOverlayClick()
        }
      }

      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [jsonState, content, onBlur, onSave, handleOverlayClick])

    const handleSave = () => {
      onSave(JSON.stringify(jsonState))
      setJsonState(undefined)
    }

    const handleCancel = () => {
      setJsonState(undefined)
      onCancel()
    }

    const handleMenuOpen = useCallback(() => {
      setIsMenuOpen(true)
    }, [])

    const handleMenuClose = useCallback(() => {
      setIsMenuOpen(false)
    }, [])
    useEffect(() => {
      if (containerSize.height !== editorRef.current.clientHeight) {
        setContainerSize({
          width: editorRef.current.clientWidth,
          height: editorRef.current.clientHeight,
          top: onBlur ? editorRef.current.offsetTop : 0,
        })
      }
    }, [onBlur])

    const onRef = (_floatingAnchorElem) => {
      if (_floatingAnchorElem !== null) {
        setFloatingAnchorElem(_floatingAnchorElem)
      }
    }

    useEffect(() => {
      const updateViewPortWidth = () => {
        const isNextSmallWidthViewport =
          CAN_USE_DOM && window.matchMedia("(max-width: 1025px)").matches

        if (isNextSmallWidthViewport !== isSmallWidthViewport) {
          setIsSmallWidthViewport(isNextSmallWidthViewport)
        }
      }
      updateViewPortWidth()
      window.addEventListener("resize", updateViewPortWidth)

      return () => {
        window.removeEventListener("resize", updateViewPortWidth)
      }
    }, [isSmallWidthViewport])

    const MAX_FILE_SIZE = appSettings?.sso_max_file_weight_mb?.value || 5

    const insertImage = (payload) => {
      editorInstanceRef.current?.dispatchCommand(INSERT_IMAGE_COMMAND, payload)
    }
    const insertFile = (payload) => {
      editorInstanceRef.current?.dispatchCommand(INSERT_FILE_COMMAND, payload)
    }
    const handleFileChange = async (file, rejected, isImage = true) => {
      const formData = new FormData()
      formData.append("file", file[0])
      if (rejected.length) {
        const error = fileValidation(rejected[0], undefined, 1024 * 1024 * MAX_FILE_SIZE)
        if (error.startsWith("File is", 0)) {
          showAlert("Слишком большой размер файла")
        } else {
          showAlert(error)
        }

        return
      }
      try {
        const { data } = await apiClient.post(`${API_ENDPOINTS.file}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })

        if (data?.success && data?.data?.url) {
          if (isImage) {
            insertImage({
              src: data.data.url,
              width: data.data.width,
              height: data.data.height,
            })
          } else {
            insertFile({
              src: data.data.url,
              fileType: data.data.mimeType,
              width: data.data.width,
              height: data.data.height,
              fileName: data.data.fileName,
            })
          }

          showAlert("Загрузка прошла успешно", { type: "success" })
        }
      } catch (err) {
        let messageError = err?.data?.errors?.[0]?.message || err?.data?.detail
        if (!messageError && err.status === 413) {
          messageError = "Слишком большой размер файла"
        }
        showAlert(messageError)
      }
    }

    const dropzone = useDropzone({
      maxSize: 1024 * 1024 * MAX_FILE_SIZE,
      onDrop: (file, rejected) => handleFileChange(file, rejected, false),
      validator: (file) => fileValidation({ file }, undefined, 1024 * 1024 * MAX_FILE_SIZE) || null,
    })

    useEffect(() => {
      // eslint-disable-next-line
      console.log("test")
    }, [])

    useLayoutEffect(() => {
      let dragging = 0
      const onEnter = (event) => {
        dragging++
        setIsDragging(true)
      }
      const onLeave = (event) => {
        dragging--
        if (dragging === 0) {
          setIsDragging(false)
        }
      }
      const onDrop = () => {
        setIsDragging(false)
        dragging = 0
      }

      window.addEventListener("dragenter", onEnter, false)
      window.addEventListener("dragleave", onLeave, false)
      window.addEventListener("drop", onDrop)
      return () => {
        window.removeEventListener("dragenter", onEnter)
        window.removeEventListener("dragleave", onLeave)
        window.removeEventListener("drop", onDrop)
      }
    }, [])

    return (
      <Box ref={editorRef}>
        <input {...dropzone.getInputProps()} />
        <Box
          ref={onRef}
          id={id}
          className={styles.editor__container}
          onClick={() => setHasFocus(true)}
        >
          <EditorComposer
            readonly={readOnly || disabled}
            jsonState={jsonState}
            nameSpace={nameSpace}
          >
            <EditorRefPlugin editorRef={editorInstanceRef} />
            {!readOnly && (
              <Box
                position="sticky"
                top="-40px"
                paddingTop="20px"
                left="-20px"
                backgroundColor="white"
                zIndex="9"
                // bottom shadow  0 12px 12px -8px rgba(0, 20, 67, 0.12)
                boxShadow="0 12px 12px -8px rgba(0, 20, 67, 0.2)"
              >
                <ToolbarPlugin
                  setIsLinkEditMode={setIsLinkEditMode}
                  onFileUploadStart={dropzone.open}
                />
              </Box>
            )}

            <div className={styles.editor__inner}>
              {isDragging && (
                <div
                  {...dropzone.getRootProps({
                    style: {
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      backdropFilter: "blur(3px)",
                      WebkitBackdropFilter: "blur(3px)",
                      zIndex: 1000,
                      borderRadius: "inherit",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "4px dashed var(--blue-60)",
                    },
                  })}
                >
                  <Text color={"blue-60"} fontSize={"32px"} fontWight={700}>
                    Бросьте файл здесь для загрузки
                  </Text>
                </div>
              )}
              <RichTextPlugin
                ErrorBoundary={LexicalErrorBoundary}
                contentEditable={<ContentEditable id={id} className={styles.editor__input} />}
                placeholder={<PlaceholderComp>{placeholder}</PlaceholderComp>}
              />
              <ListPlugin />
              <CodeHighlightPlugin />
              <CheckListPlugin />
              <LinkPlugin />
              <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
              <OnChangePlugin
                onChange={(editor) =>
                  handleOnChange(editor, setJsonState, setIsClear, setIsHasValue, onChange)
                }
              />
            </div>
            <AutoFocusPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <AutoEmbedPlugin />
            {nameSpace !== "CommentEditor" && <CommentPlugin />}

            <FigmaPlugin />
            <YouTubePlugin />
            <ExcalidrawPlugin />
            <CollapsiblePlugin />
            <PageBreakPlugin />
            <StickyPlugin />
            <HorizontalRulePlugin />
            <TablePlugin />
            <TableCellResizerPlugin />
            <TableOfContentPlugin />
            <LayoutPlugin />
            <MentionsPlugin
              menuOpen={ismenuOpen}
              menuComponent={Menu}
              menuItemComponent={MenuItem}
              onMenuOpen={handleMenuOpen}
              onMenuClose={handleMenuClose}
            />
            {floatingAnchorElem && (
              <>
                <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
                <FloatingLinkEditorPlugin
                  anchorElem={floatingAnchorElem}
                  isLinkEditMode={isLinkEditMode}
                  setIsLinkEditMode={setIsLinkEditMode}
                />
                <FloatingTextFormatToolbarPlugin
                  anchorElem={floatingAnchorElem}
                  setIsLinkEditMode={setIsLinkEditMode}
                />
                <TableActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge={true} />
              </>
            )}

            <TabIndentationPlugin />
            <ImagesPlugin captionsEnabled={true} />
            <FilesPlugin captionsEnabled={true} />
            <HistoryPlugin externalHistoryState={historyState} />
          </EditorComposer>

          {!!onSave && (
            <Box
              display="flex"
              justifyContent="flex-start"
              gridGap="15px"
              alignItems="center"
              marginTop="10px"
            >
              <Text
                as={Button}
                onClick={onCancel}
                kind="borderless"
                marginLeft="15px"
                fontSize="fs-14"
                vertSize={20}
                styles={{
                  "& span": { color: "var(--gray-60)" },
                  "& span:hover": { color: "var(--gray-80)" },
                }}
                disabled={disabled}
              >
                Отмена
              </Text>
              <Button
                onClick={handleSave}
                kind="primary"
                vertSize={32}
                disabled={disabled || !isHasValue}
                loading={disabled}
              >
                Сохранить
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    )
  },
)

Editor.displayName = "EditorComp"
