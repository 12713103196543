import React, { useCallback, useEffect, useState } from "react"

import {
  DataListWrapper,
  getUrlSearchParams,
  Spinner,
  VirtualizedTable,
  useAppContext,
  VirtualList,
} from "@dit/core-frontend"
import { Box, Text } from "@mos-cat/ds"
import { ZoneCard } from "./ZoneCard"
import { API_ENDPOINTS } from "@constants"
import { createTableConfig } from "@pages/Zones/createTableConfig"
import useZonesList from "@components/zones/useZonesList"
import { useSearchParams } from "react-router-dom"
import isEqual from "lodash/isEqual"
import ZoneListItem from "@components/zones/ZoneListItem"

const LIMIT = 15

// view = "cards" / "list"
const ZonesListContent = ({
  view = "list",
  isRowView = true,
  limit = LIMIT,
  data,
  getData,
  isLoading,
  isRequestLoading,
  updateResponseData,
  onUpdateList,
}) => {
  const [page, setPage] = useState(1)

  const [searchParams] = useSearchParams()
  const { search } = getUrlSearchParams(searchParams)
  const { params } = useZonesList()
  const { isMobileView } = useAppContext()

  const getZones = useCallback(() => {
    getData(API_ENDPOINTS.zoneList, {
      epp: limit,
      eppOffset: (page - 1) * limit,
      ...params,
    })
  }, [getData, page, params, limit])

  const handleAddPage = useCallback(
    (callback) => {
      if (data?.itemsList.length < data?.itemsTotal) {
        setPage((prev) => prev + 1)
        callback()
      }
    },
    [data],
  )

  useEffect(() => {
    setPage(1)
  }, [search, params])

  useEffect(() => {
    void getZones()
  }, [getZones])

  useEffect(() => {
    onUpdateList?.((prevList) => {
      const updatedList = data?.itemsList || []
      if (!isEqual(prevList, updatedList)) {
        return updatedList
      }

      return prevList
    })
  }, [onUpdateList, data?.itemsList])

  if (view === "list") {
    const tableConfig = createTableConfig(updateResponseData)

    return (
      <Box position="relative" minHeight="200px" overflow="auto" height="100%">
        {isLoading || (isRequestLoading && <Spinner space="sp-16" iconSize="30px" />)}
        {isMobileView ? (
          <VirtualList
            over={10}
            dataList={data?.itemsList}
            dataTotal={data?.itemsTotal}
            isLoading={isRequestLoading}
            infiniteScroll
            infiniteCount={2}
            height={500}
            renderItem={(zone) => (
              <ZoneListItem zone={zone} updateResponseData={updateResponseData} />
            )}
            onLoad={handleAddPage}
          />
        ) : (
          <VirtualizedTable
            over={10}
            tableConfig={tableConfig}
            emptyText="Нет разделов"
            dataList={data?.itemsList}
            dataTotal={data?.itemsTotal}
            isLoading={isRequestLoading}
            onLoad={handleAddPage}
            infiniteScroll
            infiniteCount={2}
          />
        )}
      </Box>
    )
  }

  return (
    <Box
      position="relative"
      display="flex"
      width={isMobileView ? "calc(100% + 20px)" : "unset"}
      flexWrap={isRowView && !isMobileView ? "nowrap" : "wrap"}
      gridGap={isMobileView ? "10px" : "15px"}
      minHeight="286px"
      overflow={isMobileView ? "hidden" : "auto"}
      mx={["-10px", "-20px"]}
      px={["10px", "20px"]}
      pb="5px"
    >
      {isLoading || isRequestLoading ? (
        <Spinner space="sp-16" iconSize="30px" />
      ) : (
        <>
          {!data?.itemsList?.length ? (
            <Text>Нет разделов</Text>
          ) : (
            (data?.itemsList || []).map((zone) => (
              <ZoneCard
                key={`home-zones-card-${zone.id}`}
                zone={zone}
                updateResponseData={updateResponseData}
              />
            ))
          )}
        </>
      )}
    </Box>
  )
}

export const ZonesList = (props) => {
  return (
    <DataListWrapper>
      <ZonesListContent {...props} />
    </DataListWrapper>
  )
}
