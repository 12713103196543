import { FolderIcon, PagesIcon, Settings, FileHeartIcon } from "@dit/core-frontend"

export const PAGE_STATES = {
  DEFAULT: "default",
  ARCHIVE: "archive",
}

export const ZONE_SECTION_LINKS = {
  HOMEPAGE: "homepage",
  PAGES: "pages",
  SETTINGS: "settings",
  PERMISSIONS: "permissions",
  TEMPLATES: "templates",
}

export const PAGE_ICONS = {
  [ZONE_SECTION_LINKS.HOMEPAGE]: FolderIcon,
  [ZONE_SECTION_LINKS.PAGES]: PagesIcon,
  [ZONE_SECTION_LINKS.SETTINGS]: Settings,
  [ZONE_SECTION_LINKS.TEMPLATES]: FileHeartIcon,
}

export const ZONE_SETTINGS_SECTIONS = {
  INTELLIGENCE: "intelligence",
  TEAMS: "teams",
  PERMISSIONS: "permissions",
}

export const settingsSectionsList = [
  {
    title: "Сведения",
    slug: ZONE_SETTINGS_SECTIONS.INTELLIGENCE,
  },
  {
    title: "Разрешения",
    slug: ZONE_SETTINGS_SECTIONS.PERMISSIONS,
  },
  {
    title: "Участники",
    slug: ZONE_SETTINGS_SECTIONS.TEAMS,
  },
]

export const settingsSectionsObject = settingsSectionsList.reduce(
  (acc, { title, slug }) => ({ ...acc, [slug]: title }),
  {},
)

export const getCellPropsByZoneData = (zone) => {
  if (zone.archive) {
    return {
      opacity: "0.4",
    }
  }
  return {}
}
