export const statusBySlug = {
  new: {
    color: "gray-40",
    backgroundColor: "gray-10",
  },
  in_progress: {
    color: "warning-70",
    backgroundColor: "warning-10",
  },
  completed: {
    color: "success-70",
    backgroundColor: "success-10",
  },
  excluded: {
    color: "blue-70",
    backgroundColor: "blue-10",
  },
}

export const defaultColorProps = {
  color: "gray-40",
  backgroundColor: "white",
}
