import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { DateTime } from "luxon"
import cc from "classcat"
// import { checkPermission } from "@store/permissionModel"

import { Stack, Box, Text, Button } from "@mos-cat/ds"

import { getFormattedRecentDate, PencilIcon, Spinner, Tooltip } from "@dit/core-frontend"
import { CommentActions } from "./Actions"

import { useCommentsContext } from "./CommentsBlock"

import styles from "./comment.module.css"
import { Editor, EditorPreview, stringToEditorContent } from "../form"
import { Person } from "../general"

export const CommentsItem = ({ comment, projectId }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [isCreateFieldVisible, setIsCreateFieldVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { updateComment, createComment } = useCommentsContext()

  const { hash } = useLocation()

  const isSelectedComments = hash === `#${comment.id}`

  return (
    <Stack space="10px">
      <Stack
        key={`comment-${comment.id}`}
        id={`comment-${comment.id}`}
        space="8px"
        position="relative"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gridGap="10px"
          className={cc([{ [styles.selectedComment]: isSelectedComments }])}
        >
          <Box display="flex" alignItems="center" gridGap="10px" backgroundColor="inherit">
            <Person
              person={comment.user}
              gridGap="5px"
              fontSize="fs-14"
              color="gray-80"
              backgroundColor="inherit"
            />
            <Text fontSize="fs-14" color="gray-40">
              {getFormattedRecentDate(DateTime.fromISO(comment.createdAt))}
            </Text>
            {!comment.deleted && comment.modifiedAt && comment.modifiedAt !== comment.createdAt && (
              <Tooltip
                content={`Изменено ${getFormattedRecentDate(DateTime.fromISO(comment.modifiedAt))}`}
              >
                <Text
                  as={Box}
                  display="flex"
                  aligntems="center"
                  gridGap="5px"
                  fontSize="fs-14"
                  color="gray-40"
                >
                  <PencilIcon color="#B2B5BB" size="20px" /> Изменено
                </Text>
              </Tooltip>
            )}
          </Box>
          <CommentActions
            comment={comment}
            turnOnEdit={() => setIsEdit(true)}
            projectId={projectId}
          />
        </Box>
        {comment.deleted ? (
          <Text as={Box} fontSize="fs-14" color="gray-40">
            {"Комментарий был удален"}
          </Text>
        ) : (
          <Text as={Box} fontSize="fs-14" color="gray-60">
            {isSubmitting && (
              <Spinner
                space="sp-16"
                wrapper="div"
                iconSize="30px"
                styles={{
                  width: "calc(100% + 5px)",
                  zIndex: "2",
                }}
              />
            )}
            {isEdit ? (
              <Editor
                nameSpace="CommentEditor"
                content={stringToEditorContent(comment.text)}
                id="CommentUpdateComment"
                onCancel={() => setIsEdit(false)}
                onSave={(text) => {
                  updateComment(text, comment.id, setIsSubmitting, setIsEdit)
                }}
              />
            ) : (
              <EditorPreview content={stringToEditorContent(comment.text)} />
            )}
          </Text>
        )}
        {/*<Box>лайк</Box>*/}
        {!comment.deleted && (
          <Box>
            <Button kind="borderless" onClick={() => setIsCreateFieldVisible(true)} vertSize={14}>
              <Text fontSize="fs-14" color="gray-40">
                ответить
              </Text>
            </Button>
          </Box>
        )}
      </Stack>
      {isCreateFieldVisible && (
        <Box pl="20px">
          <Editor
            content={stringToEditorContent(
              comment.user?.username ? `@${comment.user?.username} ` : "",
            )}
            nameSpace="CommentEditor"
            id="CommentChildComment"
            onCancel={() => setIsCreateFieldVisible(false)}
            onSave={(text) => {
              createComment(text, comment.replyTo?.id || comment.id, () =>
                setIsCreateFieldVisible(false),
              )
            }}
          />
        </Box>
      )}
      {(comment.reply || []).map((replyComment) => {
        return (
          <Box pl="20px" key={`comment-child-${replyComment.id}`}>
            <CommentsItem comment={replyComment} />
          </Box>
        )
      })}
    </Stack>
  )
}
