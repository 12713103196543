import React from "react"

import { DownloadIcon } from "./theme/editorIcons"
import { CardWrapper, apiClient } from "@dit/core-frontend"
import { Box, Text } from "@mos-cat/ds"

const downloadFile = async (url, filename = "downloaded_image", fileType) => {
  try {
    const { data } = await apiClient.get(url, { responseType: "blob" })
    const blob = new Blob([data])
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (error) {
    console.error("Ошибка загрузки файла:", error)
  }
}

export default function FileComponent({ src, fileName, fileType }) {
  let fileFormat = fileName?.split?.(".")
  if (fileFormat) {
    fileFormat = fileFormat[fileFormat.length - 1].toLowerCase()
    if (fileFormat.length > 6) {
      fileFormat = fileFormat.slice(0, 5) + "..."
    }
  } else {
    fileFormat = "???"
  }
  return (
    <>
      <Box padding="10px 30px 20px 0">
        <CardWrapper backgroundColor="#F7F5EE" color="brand" withChangeColor={false}>
          <Text
            as={Box}
            position="relative"
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="150px"
            height="140px"
            borderRadius="5px"
            backgroundSize="cover"
            backgroundPosition="center"
            style={{ backgroundImage: `url(${src})` }}
            fontSize="26px"
            fontWeight="600"
          >
            {!["png", "jpg", "jpeg", "webp"].includes(fileFormat) && fileFormat?.toUpperCase()}
            <Box
              position="absolute"
              bottom="5px"
              right="5px"
              styles={{ cursor: "pointer" }}
              onClick={() => {
                downloadFile(src, fileName, fileType)
              }}
            >
              <DownloadIcon />
            </Box>
          </Text>
        </CardWrapper>

        <Text
          as={Box}
          fontSize="fs-14"
          color="gray-90"
          lineheight="20px"
          width="150px"
          styles={{
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "1",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {fileName || ""}
        </Text>
      </Box>
    </>
  )
}
