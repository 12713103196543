import React from "react"
import { Box, Text } from "@mos-cat/ds"
import { Link } from "react-router-dom"
import { getIcon, getObjectType, getUrlByEntity } from "@utils/favorites"

const FavoritesMobile = ({ favoriteItem }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="10px 0"
      borderColor="gray-05"
      gridGap="5px"
      borderTop="1px solid var(--gray-10)"
      styles={{
        fontSize: "fs-14",
      }}
    >
      <Box display="flex" alignItems="flex-start" gridGap="10px">
        <Box paddingTop="1px">{getIcon[favoriteItem.object]}</Box>
        <Text
          as={Link}
          to={getUrlByEntity(favoriteItem.object, favoriteItem.entity)}
          lineHeight="20px"
          styles={{
            display: "-webkit-box",
            textOverflow: "ellipsis",
            overflow: "hidden",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "2",
          }}
        >
          {favoriteItem.entity.title}
        </Text>
      </Box>
      <Box fontSize="fs-14" paddingLeft="30px">
        {getObjectType(favoriteItem.object)}
      </Box>
    </Box>
  )
}

export default FavoritesMobile
