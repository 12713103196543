import React from "react"
import { Link } from "react-router-dom"

import { Favorites as FavoritesIcon } from "@dit/core-frontend"
import { Box, Text } from "@mos-cat/ds"

import { API_ENDPOINTS } from "@constants"
import { getIcon, getObjectType, getUrlByEntity } from "@utils/favorites"

export const createTableConfig = () => ({
  cells: [
    {
      label: "",
      key: "favorites",
      width: "71px",
      getContent: (item) => (
        <FavoritesIcon
          itemId={item.entity.id}
          favoritesId={item?.id}
          type={item.object}
          requestUrl={API_ENDPOINTS.favorites}
        />
      ),
    },
    {
      label: "Название",
      key: "title",
      width: "500px",
      getContent: (item) => (
        <Box display="flex" alignItems="center" gridGap="10px">
          {getIcon[item.object]}{" "}
          <Text
            as={Link}
            to={getUrlByEntity(item.object, item.entity)}
            styles={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {item.entity.title}
          </Text>
        </Box>
      ),
    },
    {
      label: "Тип объекта",
      key: "object",
      getContent: (item) => <Text fontSize="fs-14">{getObjectType(item.object)}</Text>,
    },
    // {
    //   label: "Последняя активность",
    //   key: "TODO",
    //   getContent: () => <Text fontSize="fs-14">null</Text>,
    // },
    {
      label: "",
      key: "",
      width: "250px",
      getContent: () => <></>,
    },
  ],
})
