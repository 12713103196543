import { apiClient, queryOptions } from "@dit/core-frontend"
import { API_ENDPOINTS } from "./apiEndpoints"

const selectData = (res) => res.data.data

export const QUERIES = {
  zone: {
    get: (slug) =>
      queryOptions({
        queryKey: ["zone", "get", slug],
        queryFn: () => apiClient.get(`${API_ENDPOINTS.zone}/${slug}`),
        select: selectData,
      }),
  },
  page: {
    get: (slug) =>
      queryOptions({
        queryKey: ["page", "get", slug],
        queryFn: () => apiClient.get(`${API_ENDPOINTS.page}/${slug}`),
        select: selectData,
      }),
    list: ({ zones, pages }) =>
      queryOptions({
        queryKey: ["page", "list", { zones, pages }],
        queryFn: ({ zones, pages }) =>
          apiClient.get(API_ENDPOINTS.pageList, {
            params: { zones, ids: pages },
          }),
      }),
  },
}
