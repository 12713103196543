import React, { useEffect, useState } from "react"
import { Box, Modal, ModalDisclosure, useModalState } from "@mos-cat/ds"
import { Form } from "react-final-form"
import {
  FormInput,
  SimpleFileUploader,
  getFormErrorMessageForInput,
  useAppContext,
  Button,
} from "@dit/core-frontend"
import * as yup from "yup"
import { INSERT_IMAGE_COMMAND } from "../plugins/ImagesPlugin"

const validationSchema = yup.object().shape({
  imageLink: yup.string().url("Введите корректную ссылку"),
  fileLinkOrFile: yup.string().required(),
})
const ACCEPTFORMATS = ["image/jpeg", "image/jpg", "image/png"]
export const InsertImageModal = ({
  activeEditor,
  children,
  modalDisclosureProps,
  modalStateProps,
}) => {
  const modalState = useModalState()
  const getModalState = { ...modalState, ...modalStateProps }
  const isVisible = getModalState?.visible
  const { showAlert } = useAppContext()
  const { appSettings } = useAppContext()
  const MAX_FILE_SIZE = appSettings?.sso_max_file_weight_mb?.value || 5

  const [fileData, setFileData] = useState(null)
  const [iconURL, setIconURL] = useState(null)

  const handleClick = (payload) => {
    activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload)
  }

  useEffect(() => {
    if (!isVisible) {
      setFileData(null)
      setIconURL(null)
    }
  }, [isVisible])

  const handleClose = () => {
    getModalState.hide()
  }
  const onValidate = (values) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false })
    } catch (errors) {
      return errors.inner.reduce(
        (errors, error) => ({
          ...errors,
          [error.path]: error.message,
        }),
        {},
      )
    }
  }

  const handleUrlSubmit = async (url) => {
    if (!url) {
      return
    }

    try {
      const response = await fetch(url)
      const blob = await response.blob()
      const fileType = response.headers.get("content-type")
      const urlObject = new URL(url)
      const pathSegments = urlObject.pathname.split("/")
      const fileName = pathSegments[pathSegments.length - 1]
      const file = new File([blob], fileName, { type: fileType })
      const formData = new FormData()
      formData.append("file", file)
      return formData
    } catch (error) {
      showAlert("Ошибка загрузки файла по URL-адресу")
    }
  }

  const onSubmit = (values) => {
    try {
      if (values?.imageLink && values.fileLinkOrFile === "link") {
        handleClick({
          src: values?.imageLink,
        })
        return
      }
      handleClick({
        src: fileData.url,
        width: fileData.width,
        height: fileData.height,
      })
    } catch (err) {
      let messageError = err?.data?.errors?.[0]?.message || err?.data?.detail
      if (!messageError && err.status === 413) {
        messageError = "Слишком большой размер файла"
      }
    }
    getModalState.hide()
  }
  return (
    <>
      {children && (
        <ModalDisclosure {...modalDisclosureProps} {...getModalState}>
          {children}
        </ModalDisclosure>
      )}
      <Modal {...getModalState} heading="Добавление изображения">
        {isVisible && (
          <Form
            initialValues={{
              imageLink: "",
              fileLinkOrFile: "",
            }}
            validate={(values) => onValidate(values)}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitErrors,
              errors,
              touched,
              submitting,
              values,
              form,
              dirtyFieldsSinceLastSubmit,
              hasValidationErrors,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormInput
                    wrap={false}
                    place
                    label="Ссылка на изображение"
                    inputName="imageLink"
                    inputProps={{}}
                    fieldProps={{
                      disabled: !!iconURL,
                    }}
                    onChange={(event) => {
                      form.change("fileLinkOrFile", "link")
                      if (!event.target.value) {
                        form.reset()
                      }
                    }}
                    errorMessage={getFormErrorMessageForInput(
                      { touched, errors, submitErrors, dirtyFieldsSinceLastSubmit },
                      "imageLink",
                    )}
                  />
                  <SimpleFileUploader
                    disabled={!!(values.fileLinkOrFile === "link" && values.imageLink !== "")}
                    file={iconURL ? { url: iconURL } : undefined}
                    acceptFormats={ACCEPTFORMATS}
                    maxFileSize={1024 * MAX_FILE_SIZE}
                    onUploadFile={async (file) => {
                      setIconURL(file.url)
                      setFileData(file)
                      form.change("fileLinkOrFile", "file")
                    }}
                    clearFile={() => {
                      form.reset()
                      setIconURL(null)
                    }}
                    label="Изображение из файла"
                    customButton={({ isLoading, disabled }) => (
                      <Button
                        vertSize="40"
                        kind="borderless"
                        type="submit"
                        disabled={disabled}
                        loading={isLoading}
                      >
                        Загрузить файл
                      </Button>
                    )}
                  />

                  <Box display="flex" justifyContent="start" gridGap="16px" marginTop="16px">
                    <Button
                      vertSize="48"
                      kind="primary"
                      type="submit"
                      disabled={submitting || hasValidationErrors}
                    >
                      Сохранить
                    </Button>
                    <Button vertSize="48" kind="secondary" onClick={handleClose} type="reset">
                      Отменить
                    </Button>
                  </Box>
                </form>
              )
            }}
          />
        )}
      </Modal>
    </>
  )
}
