import React, { createContext, useContext } from "react"

import { AppContent, AppContainer } from "@dit/core-frontend"
const MainContext = createContext({})

const useMainContext = () => {
  return useContext(MainContext)
}

const MainContextProvider = function ({ children }) {
  return (
    <MainContext.Provider value={{}}>
      <AppContent>
        <AppContainer>{children}</AppContainer>
      </AppContent>
    </MainContext.Provider>
  )
}

export { MainContext, MainContextProvider, useMainContext }
