import { addClassNamesToElement } from "@lexical/utils"
import { $applyNodeReplacement, $isElementNode, $isRangeSelection, ElementNode } from "lexical"
export class MarkNode extends ElementNode {
  __id

  static getType() {
    return "mark"
  }

  static clone(node) {
    return new MarkNode(node.__id, node.__key)
  }
  static importDOM() {
    return null
  }

  static importJSON(serializedNode) {
    const node = $createMarkNode(serializedNode.id)
    node.setFormat(serializedNode.format)
    node.setIndent(serializedNode.indent)
    node.setDirection(serializedNode.direction)
    return node
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      id: this.getID(),
      type: "mark",
      version: 1,
    }
  }

  constructor(id, key) {
    super(key)
    this.__id = id || ""
  }

  createDOM(config) {
    const element = document.createElement("mark")
    addClassNamesToElement(element, config.theme.mark)
    // if (this.__id !== "") {
    //   addClassNamesToElement(element, config.theme.markOverlap)
    // }
    return element
  }

  updateDOM(prevNode, element, config) {
    const prevID = prevNode.__id
    const nextID = this.__id
    const overlapTheme = config.theme.markOverlap
    if (prevID !== nextID) {
      addClassNamesToElement(element, overlapTheme)
      return true
    }

    return false
  }

  hasID(id) {
    const currentId = this.getID()
    if (id === currentId) {
      return true
    }
    return false
  }

  getID() {
    const self = this.getLatest()
    return $isMarkNode(self) ? self.__id : ""
  }

  addID(id) {
    const self = this.getWritable()
    if ($isMarkNode(self)) {
      self.__id = id
    }
  }

  deleteID(id) {
    const self = this.getWritable()
    if ($isMarkNode(self)) {
      self.__id = id
    }
  }

  insertNewAfter(selection, restoreSelection = true) {
    const element = this.getParentOrThrow().insertNewAfter(selection, restoreSelection)
    if ($isElementNode(element)) {
      const markNode = $createMarkNode(this.__id)
      element.append(markNode)
      return markNode
    }
    return null
  }

  canInsertTextBefore() {
    return false
  }

  canInsertTextAfter() {
    return false
  }

  canBeEmpty() {
    return false
  }

  isInline() {
    return true
  }

  extractWithChild(child, selection, destination) {
    if (!$isRangeSelection(selection) || destination === "html") {
      return false
    }
    const anchor = selection.anchor
    const focus = selection.focus
    const anchorNode = anchor.getNode()
    const focusNode = focus.getNode()
    const isBackward = selection.isBackward()
    const selectionLength = isBackward ? anchor.offset - focus.offset : focus.offset - anchor.offset
    return (
      this.isParentOf(anchorNode) &&
      this.isParentOf(focusNode) &&
      this.getTextContent().length === selectionLength
    )
  }

  excludeFromCopy(destination) {
    return destination !== "clone"
  }
}

export function $createMarkNode(id) {
  const newId = Array.isArray(id) ? id : id
  return $applyNodeReplacement(new MarkNode(newId))
}

export function $isMarkNode(node) {
  return node instanceof MarkNode
}
