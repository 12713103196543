import React from "react"
import { Box, Text } from "@mos-cat/ds"
import { getFormattedDate, Avatar, Favorites, Subscription } from "@dit/core-frontend"
import { Person } from "@components/general"
import { PageActions } from "./PageActions"
import { PageStatus } from "@components/pages/PageStatus"

import { API_ENDPOINTS, ROUTES } from "@constants"
import { EntityLink } from "@components/entity"
import { ENTITY_TYPES } from "@utils/favorites"

export const createTableConfig = (updateResponseData, onDeletePage, isArchivePage, data) => {
  const getCellPropsByStatus = (page) => {
    if (page.archive && !isArchivePage) {
      return {
        opacity: "0.4",
      }
    }
    return {}
  }
  return {
    cells: [
      {
        label: "Раздел",
        key: "zone",
        // width: "100px",
        getContent: (pageData) => {
          return (
            <Box
              display="flex"
              alignItems="center"
              gridGap="10px"
              overflow="hidden"
              {...getCellPropsByStatus(pageData)}
            >
              <Avatar url={pageData.zone.icon?.url || " "} size={28} borderRadius="0" />
              <EntityLink
                entity={pageData.zone}
                customVisibleField={pageData.zone.title}
                link={ROUTES.ZONE}
                overflow="hidden"
                styles={{
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: "3",
                  textOverflow: "-webkit-box",
                }}
              />
            </Box>
          )
        },
      },
      {
        label: "Код страницы",
        key: "slug",
        // width: "100px",
        getContent: (pageData) => (
          <Box
            display="flex"
            alignItems="center"
            gridGap="10px"
            overflow="hidden"
            {...getCellPropsByStatus(pageData)}
          >
            <EntityLink
              entity={pageData}
              link={`${ROUTES.ZONE}/${pageData.zone.slug}/pages`}
              overflow="hidden"
              styles={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "3",
                textOverflow: "-webkit-box",
              }}
            />
          </Box>
        ),
      },
      {
        label: "Название страницы",
        key: "title",
        // width: "320px",
        getContent: (pageData) => (
          <Text
            as={Box}
            fontSize="fs-14"
            styles={{
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "3",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            {...getCellPropsByStatus(pageData)}
          >
            {pageData.title}
          </Text>
        ),
      },
      {
        label: "Автор",
        key: "user",
        // justifyContent: "center",
        // width: "200px",
        getContent: (pageData) => (
          <Person person={pageData.user} {...getCellPropsByStatus(pageData)} />
        ),
      },
      {
        label: "Создана",
        key: "createdAt",
        // width: "140px",
        getContent: (pageData) => (
          <Text as={Box} fontSize="fs-14" {...getCellPropsByStatus(pageData)}>
            {getFormattedDate(pageData.createdAt)}
          </Text>
        ),
      },
      {
        label: "Обновлена",
        key: "modifiedAt",
        // width: "140px",
        getContent: (pageData) => (
          <Text as={Box} fontSize="fs-14" {...getCellPropsByStatus(pageData)}>
            {getFormattedDate(pageData.modifiedAt)}
          </Text>
        ),
      },
      {
        label: "Последний редактор",
        key: "user",
        // justifyContent: "center",
        // width: "200px",
        getContent: (pageData) => (
          <Person
            person={pageData.lastModifiedBy || pageData.user}
            {...getCellPropsByStatus(pageData)}
          />
        ),
      },
      // {
      //   label: "Приватность",
      //   key: "modifiedAt",
      //   width: "140px",
      //   getContent: (zone) => <Text fontSize="fs-14">{getFormattedDate(zone.modifiedAt)}</Text>,
      // },
      {
        label: "Статус",
        key: "status",
        getContent: (pageData) => (
          <Box {...getCellPropsByStatus(pageData)}>
            <PageStatus status={pageData.status} />
          </Box>
        ),
      },
      {
        label: "",
        key: "secret",
        width: "120px",
        getContent: (pageData) => (
          <Box display="flex" alignItems="center" justifyContent="flex-end" gridGap="10px">
            <Subscription
              itemId={pageData.id}
              subscriptionId={pageData.subscription?.id}
              type="page"
              requestUrl={API_ENDPOINTS.subscription}
              onSuccess={(subscriptionId) => {
                updateResponseData?.(pageData.id, "update", {
                  subscription: subscriptionId ? { id: subscriptionId } : null,
                })
              }}
            />
            <Favorites
              itemId={pageData.id}
              favoritesId={pageData.favorites?.id}
              type={ENTITY_TYPES.PAGE}
              requestUrl={API_ENDPOINTS.favorites}
              iconSize={21}
              onSuccess={(favoritesId) => {
                updateResponseData?.(pageData.id, "update", {
                  favorites: favoritesId ? { id: favoritesId } : null,
                })
              }}
            />
            <PageActions
              page={pageData}
              onDelete={() => {
                updateResponseData?.(pageData.id, "remove")
                onDeletePage && onDeletePage(pageData.id)
              }}
              onUpdate={updateResponseData}
              data={data}
            />
          </Box>
        ),
      },
    ],
  }
}
