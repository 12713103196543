import React, { memo } from "react"
import * as yup from "yup"
import { Link, useNavigate } from "react-router-dom"

import { Form, Field } from "react-final-form"
import { Box, Text, Error } from "@mos-cat/ds"
import { useZoneContext } from "@components/contexts/ZoneContext"
import { Editor, stringToEditorContent } from "@components/form"
import {
  PageBlockRow,
  PageBlockRowSection,
  PageBlockContent,
  TextareaAutosize,
  Button,
  useAppContext,
  yupTitleSchema,
} from "@dit/core-frontend"

import { templateRequest } from "./utils"
import { ROUTES } from "@constants"

const validationSchema = yup.object().shape({
  pageTitle: yupTitleSchema(),
})

export const PageTemplateForm = memo(({ template, updateTemplateData }) => {
  const { zone } = useZoneContext()
  const { showAlert } = useAppContext()
  const navigate = useNavigate()

  return (
    <Form
      initialValues={{
        pageTitle: template?.pageTitle || "",
        content: template?.content || "",
      }}
      validate={(values) => {
        try {
          validationSchema.validateSync(values, { abortEarly: false })
          return {}
        } catch (error) {
          return error.inner.reduce(
            (errors, currentError) => ({
              ...errors,
              [currentError.path]: currentError.message,
            }),
            {},
          )
        }
      }}
      onSubmit={async (values) => {
        const { success, errors, alertMessage } = await templateRequest({
          values,
          template: template || {},
        })

        if (success) {
          updateTemplateData(values)
          navigate(`${ROUTES.ZONE}/${zone.slug}/templates/${template.id}`)
        }

        showAlert(alertMessage)
        return errors
      }}
      render={({
        handleSubmit,
        values,
        errors,
        hasValidationErrors,
        submitting,
        touched,
        form,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <PageBlockRow place="middle" alignItems="flex-start">
              <PageBlockRowSection flex="2" maxWidth="860px">
                <Text
                  as={Box}
                  flex="2"
                  position="relative"
                  color="gray-90"
                  fontSize="40px"
                  fontWeight="700"
                  ml="-10px"
                >
                  <Field name="pageTitle">
                    {() => (
                      <TextareaAutosize
                        theme="bordered"
                        isError={!!touched?.pageTitle && errors?.pageTitle}
                        defaultValue={values.pageTitle}
                        placeholder="Заголовок страницы"
                        enterAllowed={false}
                        disabled={submitting}
                        onBlur={() => {
                          form.blur("pageTitle")
                        }}
                        onChange={(event) => {
                          form.change("pageTitle", event.target.value)
                        }}
                      />
                    )}
                  </Field>
                  {!!(touched?.pageTitle && errors?.pageTitle) && <Error>{errors.pageTitle}</Error>}
                </Text>
              </PageBlockRowSection>
              <PageBlockRowSection mt="25px">
                <Text
                  as={Button}
                  disabled={submitting || hasValidationErrors}
                  loading={submitting}
                  kind="primary"
                  vertSize="32px"
                  paddingX="10px !important"
                  height="32px"
                  styles={{ fontSize: "14px" }}
                  onClick={handleSubmit}
                >
                  Сохранить
                </Text>
                <Link to={`${ROUTES.ZONE}/${zone.slug}/templates`}>
                  <Text
                    as={Button}
                    kind="borderless"
                    paddingX="16px !important"
                    vertSize="32px"
                    height="32px"
                    styles={{ fontSize: "14px", fontWeight: "500" }}
                  >
                    Закрыть
                  </Text>
                </Link>
              </PageBlockRowSection>
            </PageBlockRow>
            <PageBlockContent styles={{ width: "100%", maxWidth: "1187px" }}>
              <Field name="content">
                {() => (
                  <Editor
                    id="PageTemplateDescription"
                    content={stringToEditorContent(values.content)}
                    placeholder="Здесь напишите текст, который вы хотите добавить в документ"
                    disabled={submitting}
                    onChange={({ jsonText }) => {
                      form.change("content", jsonText)
                      form.blur("text")
                    }}
                  />
                )}
              </Field>
            </PageBlockContent>
          </form>
        )
      }}
    />
  )
})

PageTemplateForm.displayName = "PageTemplateForm"
