import React from "react"

import { getFormattedDate, Ellipsis, Favorites } from "@dit/core-frontend"
import { Stack, Box, Text } from "@mos-cat/ds"
import TemplateActions from "./TemplateActions"
import { Person } from "@components/general"

import { API_ENDPOINTS } from "@constants"
import { ENTITY_TYPES } from "@utils/favorites"

import defaultImage from "./defaultBgImage.png"

// TODO Перенести в либу
const SelectedBlueCircle = function ({ className, size = "40" }) {
  return (
    <svg
      className={className}
      height={size}
      width={size}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4859_46413)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z"
          fill="#DDE7FF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M20.0001 35.7143C28.6788 35.7143 35.7143 28.6788 35.7143 20C35.7143 11.3212 28.6788 4.28572 20.0001 4.28572C11.3213 4.28572 4.28577 11.3212 4.28577 20C4.28577 28.6788 11.3213 35.7143 20.0001 35.7143Z"
          fill="#BBCFFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M19.9999 34.2857C27.8897 34.2857 34.2857 27.8898 34.2857 20C34.2857 12.1102 27.8897 5.71428 19.9999 5.71428C12.1102 5.71428 5.71423 12.1102 5.71423 20C5.71423 27.8898 12.1102 34.2857 19.9999 34.2857Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          // eslint-disable-next-line max-len
          d="M20 31.4286C13.6983 31.4286 8.57141 26.3017 8.57141 20C8.57141 13.6983 13.6983 8.57141 20 8.57141C26.3017 8.57141 31.4286 13.6983 31.4286 20C31.4286 26.3017 26.3017 31.4286 20 31.4286ZM25.2599 16.4307C24.9717 16.146 24.5043 16.146 24.2161 16.4307L18.8329 21.7476L16.2601 19.2411C15.9718 18.9564 15.5045 18.9564 15.2162 19.241C14.9279 19.5257 14.9279 19.9873 15.2161 20.272L18.8329 23.8095L25.26 17.4616C25.5482 17.1769 25.5482 16.7153 25.2599 16.4307Z"
          fill="#0044CC"
        />
      </g>
      <defs>
        <clipPath id="clip0_4859_46413">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const PageTemplateCard = ({
  template,
  zone,
  updateResponseData,
  openEditTemplateCurtain,
  isSelectedCard = false,
  onClickOnCard,
  isActionsVisible = true,
}) => {
  const isEmptyTemplate = !template.id

  return (
    <Stack
      position="relative"
      width="306px"
      flex="0 0 306px"
      height="380px"
      p="15px"
      space="10px"
      justifyContent="space-between"
      borderRadius="10px"
      backgroundColor="#FDFFF3"
      backgroundSize="cover"
      backgroundPosition="center"
      style={{
        backgroundImage: `url(${template?.icon?.url || defaultImage})`,
        cursor: onClickOnCard ? "pointer" : "default",
      }}
      onClick={() => {
        onClickOnCard?.()
      }}
    >
      <Box display="flex" justifyContent="space-between" gridGap="5px">
        <Text fontSize="22px" color="var(--gray-80)" fontWeight="600">
          <Ellipsis lines={2}>{template.title}</Ellipsis>
        </Text>
        {!isEmptyTemplate && isActionsVisible && (
          <Box display="flex" gridGap="4px">
            <Favorites
              itemId={template.id}
              favoritesId={template.favorites?.id}
              type={ENTITY_TYPES.TEMPLATE}
              requestUrl={API_ENDPOINTS.favorites}
              iconSize={21}
              onSuccess={(favoritesId) => {
                updateResponseData?.(template.id, "update", {
                  favorites: favoritesId ? { id: favoritesId } : null,
                })
              }}
            />
            <Box>
              <TemplateActions
                template={template}
                zone={zone}
                onDelete={() => updateResponseData?.(template.id, "remove")}
                openEditTemplateCurtain={openEditTemplateCurtain}
                onUpdate={(data) => {
                  updateResponseData?.(template.id, "update", data)
                }}
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box flex="2">
        <Text color="var(--gray-90)">
          <Ellipsis lines={6}>{template.description}</Ellipsis>
        </Text>
      </Box>
      {!isEmptyTemplate && (
        <>
          <Box>
            <Person person={template.user} color="var(--gray-90)" />
          </Box>
          <Box display="flex" alignItems="center" gridGap="5px">
            <Text fontSize="14px" color="var(--gray-80)">
              создан:
            </Text>
            <Text fontSize="14px" color="var(--gray-90)">
              {getFormattedDate(template.createdAt)}
            </Text>
          </Box>
          <Box display="flex" alignItems="center" gridGap="5px">
            <Text fontSize="14px" color="var(--gray-80)">
              обновлен:
            </Text>
            <Text fontSize="14px" color="var(--gray-90)">
              {getFormattedDate(template.modifiedAt || template.createdAt)}
            </Text>
          </Box>
        </>
      )}
      {isSelectedCard && (
        <Box position="absolute" bottom="15px" right="15px" width="40px" heigh="40px">
          <SelectedBlueCircle />
        </Box>
      )}
    </Stack>
  )
}
