import { ActionsPopover, Button } from "@dit/core-frontend"
import React from "react"
import { buttonProps } from "./buttonProps"
import { Box, Text } from "@mos-cat/ds"
import {
  CenterAlign,
  IndentDecreaseIcon,
  IndentIncreaseIcon,
  JustifyAlign,
  LeftAlign,
  RightAlign,
} from "../../theme/editorIcons"
import { ArrowDown } from "@mos-cat/ds-icons"
import { FORMAT_ELEMENT_COMMAND, INDENT_CONTENT_COMMAND, OUTDENT_CONTENT_COMMAND } from "lexical"

const TextAlignDropDown = ({ activeEditor }) => {
  return (
    <ActionsPopover
      content={
        <Button
          {...buttonProps}
          kind="borderless"
          vertSize="30"
          width="30px"
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <Text fontSize="fs-14">
              <LeftAlign />
            </Text>
            <Text fontSize="fs-14">
              <ArrowDown />
            </Text>
          </Box>
        </Button>
      }
    >
      {(buttonProps) => (
        <>
          <Button
            {...buttonProps}
            {...buttonProps}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")
            }}
          >
            <LeftAlign /> По левому краю
          </Button>
          <Button
            {...buttonProps}
            {...buttonProps}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")
            }}
          >
            <CenterAlign /> По центру
          </Button>
          <Button
            {...buttonProps}
            {...buttonProps}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")
            }}
          >
            <RightAlign /> По правому краю
          </Button>
          <Button
            {...buttonProps}
            {...buttonProps}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")
            }}
          >
            <JustifyAlign /> По ширине
          </Button>
          <Button
            {...buttonProps}
            {...buttonProps}
            onClick={() => {
              activeEditor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined)
            }}
          >
            <IndentIncreaseIcon /> Увеличить отступ
          </Button>
          <Button
            {...buttonProps}
            {...buttonProps}
            onClick={() => {
              activeEditor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined)
            }}
          >
            <IndentDecreaseIcon /> Уменьшить отступ
          </Button>
        </>
      )}
    </ActionsPopover>
  )
}

export default TextAlignDropDown
