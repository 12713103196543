import React, { useState } from "react"

import { Spinner, VirtualizedTable } from "@dit/core-frontend"
import { Box } from "@mos-cat/ds"
import TemplateCardsList from "./TemplateCardsList"
import { PageTemplateCard } from "./PageTemplateCard"
import PageTemplatesCurtain from "@components/pageTemplates/PageTemplatesCurtain"

import { useZoneContext } from "@components/contexts/ZoneContext"
import { createTableConfig } from "./createTableConfig"

const defaultCurtainConfig = {
  isOpen: false,
  templateId: "",
  isFormInViewMode: false,
}

export const PageTemplatesVirtualTable = ({
  data,
  isLoading,
  isRequestLoading,
  updateResponseData,
  view = "list",
  isRowView = true,
  handleAddPage,
  // fetchPageTemplates,
}) => {
  const [curtainConfig, setCurtainConfig] = useState(defaultCurtainConfig)
  const { zone } = useZoneContext()

  const openEditTemplateCurtain = (templateId, isFormInViewMode = false) => {
    setCurtainConfig({ isOpen: true, templateId, isFormInViewMode })
  }

  if (view === "list") {
    const tableConfig = createTableConfig(zone, updateResponseData, openEditTemplateCurtain)

    return (
      <>
        <Box position="relative" minHeight="200px" overflow="auto" height="100%">
          <VirtualizedTable
            over={10}
            tableConfig={tableConfig}
            emptyText="Нет шаблонов"
            dataList={data?.itemsList}
            dataTotal={data?.itemsTotal}
            isLoading={isRequestLoading}
            onLoad={handleAddPage}
            infiniteScroll
            infiniteCount={2}
          />

          {isLoading && <Spinner space="sp-16" wrapper="div" />}
        </Box>
        <PageTemplatesCurtain
          zone={zone}
          {...curtainConfig}
          onClose={() => {
            setCurtainConfig(defaultCurtainConfig)
          }}
          updateResponseData={updateResponseData}
        />
      </>
    )
  }

  return (
    <>
      <Box
        position="relative"
        display="flex"
        alignItems="space-between"
        flexWrap={isRowView ? "nowrap" : "wrap"}
        gridGap="15px"
        minHeight="286px"
        overflow="auto"
        mx={["-10px", "-20px"]}
        px={["10px", "20px"]}
        pt="12px"
        pb="5px"
      >
        {isLoading ? (
          <Spinner space="sp-16" iconSize="30px" />
        ) : (
          <TemplateCardsList
            onLoad={handleAddPage}
            dataTotal={data?.itemsTotal}
            infiniteCount={2}
            infiniteScroll
            dataList={data?.itemsList}
            renderItem={(template) => (
              <PageTemplateCard
                template={template}
                zone={zone}
                updateResponseData={updateResponseData}
                openEditTemplateCurtain={openEditTemplateCurtain}
              />
            )}
          />
        )}
      </Box>
      <PageTemplatesCurtain
        zone={zone}
        {...curtainConfig}
        onClose={() => {
          setCurtainConfig(defaultCurtainConfig)
        }}
        updateResponseData={updateResponseData}
      />
    </>
  )
}
