// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `del {
  padding: 0 6px;

  text-decoration: line-through;

  background-color: var(--error-10);
}

ins {
  padding: 0 6px;

  text-decoration: none;

  background-color: var(--success-10);
}

ins.mod {
  background-color: var(--blue-10);
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/diff.css"],"names":[],"mappings":"AAAA;EACE,cAAc;;EAEd,6BAA6B;;EAE7B,iCAAiC;AACnC;;AAEA;EACE,cAAc;;EAEd,qBAAqB;;EAErB,mCAAmC;AACrC;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":["del {\n  padding: 0 6px;\n\n  text-decoration: line-through;\n\n  background-color: var(--error-10);\n}\n\nins {\n  padding: 0 6px;\n\n  text-decoration: none;\n\n  background-color: var(--success-10);\n}\n\nins.mod {\n  background-color: var(--blue-10);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
