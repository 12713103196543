import React, { useCallback, useEffect, useLayoutEffect, useRef } from "react"

export const AddCommentBox = ({ anchorKey, editor, onAddComment }) => {
  const boxRef = useRef(null)

  const updatePosition = useCallback(() => {
    const boxElem = boxRef.current
    const rootElement = editor.getRootElement()
    const anchorElement = editor.getElementByKey(anchorKey)

    if (boxElem !== null && rootElement !== null && anchorElement !== null) {
      const { right } = rootElement.getBoundingClientRect()
      const { top } = anchorElement.getBoundingClientRect()
      boxElem.style.left = `${right - 20}px`
      boxElem.style.top = `${top - 30}px`
    }
  }, [anchorKey, editor])

  useEffect(() => {
    window.addEventListener("resize", updatePosition)

    return () => {
      window.removeEventListener("resize", updatePosition)
    }
  }, [editor, updatePosition])

  useLayoutEffect(() => {
    updatePosition()
  }, [anchorKey, editor, updatePosition])

  return (
    <div ref={boxRef}>
      {/* <button className={styles.CommentPlugin_AddCommentBox_button} onClick={onAddComment}>
          Добавить комментарий
        </button> */}
    </div>
  )
}
