import React from "react"

import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin"
import { LexicalComposer } from "@lexical/react/LexicalComposer"
import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin"
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin"
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin"

import { EscapeHandlerPlugin } from "./EscapeHandlerPlugin"
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin"
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin"
import styles from "./styles.module.css"
import CommentTheme from "../../theme/CommentEditorTheme"

export const PlainTextEditor = ({ className, autoFocus, onEscape, onChange, editorRef }) => {
  const initialConfig = {
    namespace: "Commenting",
    nodes: [],
    onError: (error) => {
      throw error
    },
    theme: CommentTheme,
  }

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <div className={styles.CommentPlugin_CommentInputBox_EditorContainer}>
        <PlainTextPlugin
          contentEditable={<ContentEditable className={className} />}
          placeholder=""
          ErrorBoundary={LexicalErrorBoundary}
        />
        <OnChangePlugin onChange={onChange} />
        <HistoryPlugin />
        {autoFocus !== false && <AutoFocusPlugin />}
        <EscapeHandlerPlugin onEscape={onEscape} />
        <ClearEditorPlugin />
        {editorRef !== undefined && <EditorRefPlugin editorRef={editorRef} />}
      </div>
    </LexicalComposer>
  )
}
