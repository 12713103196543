import React, { memo } from "react"
import { Link } from "react-router-dom"
import { DateTime } from "luxon"

import {
  Avatar,
  CustomGetUsersOptionLabel,
  getFormattedRecentDate,
  DotIcon,
  VirtualList,
  useAppContext,
} from "@dit/core-frontend"
import { Box, Stack, Text } from "@mos-cat/ds"

import { ROUTES } from "@constants"
import { EntityLink } from "@components/entity"

import styles from "./row.module.css"

export const PageListRow = memo(({ page, rowRef, showUpdater, ...rowProps }) => {
  const autor = showUpdater ? page.lastModifiedBy : page.user

  const restrictionsProps = {
    styles: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }

  return (
    <Box
      className={styles.PageListRow}
      ref={rowRef}
      styles={{
        "& > div": {
          maxWidth: "100%",
        },
      }}
      {...rowProps}
    >
      <Box className={styles.PageListRowContent}>
        <EntityLink
          link={`${ROUTES.ZONE}/${page.zone.slug}/pages`}
          entity={page}
          visibleField="title"
          className={styles.PageListRowTitle}
          {...restrictionsProps}
          maxWidth="400px"
        />
        <Box className={styles.PageListRowPerson}>
          <Avatar url={autor?.photo?.url} />
          <Text as={Box} {...restrictionsProps}>
            {CustomGetUsersOptionLabel(autor)}
          </Text>
          {showUpdater && (
            <>
              <DotIcon />
              <Text fontSize="fs-14" color="gray-40">
                {getFormattedRecentDate(DateTime.fromISO(page.modifiedAt), true)}
              </Text>
            </>
          )}
        </Box>
      </Box>
      <Box className={styles.PageListRowZone}>
        <Link to={`${ROUTES.ZONE}/${page.zone.slug}`} target="_blank">
          <Text
            as={Box}
            flex="2"
            fontSize="14px"
            color="gray-40"
            styles={{
              "&:hover": {
                textDecoration: "underline",
                color: "var(--blue-60)",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              },
            }}
          >
            {page.zone?.slug}
          </Text>
        </Link>
        <Avatar
          url={page.zone?.icon?.url || " "}
          size={30}
          borderRadius="4px"
          border="1px solid var(--gray-10)"
          backgroundColor="var(--white)"
        />
      </Box>
    </Box>
  )
})

PageListRow.displayName = "PageListRow"

export const PagesList = memo(
  ({ list = [], itemsTotal, refetch, onLoad, isRequestLoading, showUpdater }) => {
    const { isMobileView } = useAppContext()

    if (!list.length) {
      return null
    }

    return (
      <Stack space="0" borderWidth={isMobileView ? "0" : "1px"} borderColor="gray-05">
        <VirtualList
          over={10}
          dataList={list}
          dataTotal={itemsTotal}
          isLoading={isRequestLoading}
          infiniteScroll
          infiniteCount={2}
          height={440}
          renderItem={(page) => (
            <PageListRow key={page.slug} page={page} refetch={refetch} showUpdater={showUpdater} />
          )}
          onLoad={onLoad}
        />
      </Stack>
    )
  },
)

PagesList.displayName = "PagesList"
