import styles from "./styles.module.css"
import React, {
  useCallback,
  useEffect,
  useMemo,
  memo,
  useState,
  createContext,
  useContext,
} from "react"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { mergeRegister } from "@lexical/utils"
import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from "lexical"
import { createPortal } from "react-dom"

import { CommentsPanel } from "./CommentPanel"
import { AddCommentBox } from "./AddCommentBox"
import { CommentInputBox } from "./CommentInputBox"
import { apiClient, DataListWrapper, useAppContext } from "@dit/core-frontend"
import { API_ENDPOINTS } from "@src/constants"
import { usePageContext } from "@src/components/contexts/PageContext"
import { $getMarkID, $isMarkNode, $unwrapMarkNode, MarkNode } from "../../nodes/MarkNode"
import { DateTime } from "luxon"
import { useZoneContext } from "@src/components/contexts/ZoneContext"

export const INSERT_INLINE_COMMAND = createCommand("INSERT_INLINE_COMMAND")

const EditorCommentsContext = createContext({
  commentsList: [],
  updateComment: () => {},
  createComment: () => {},
  setIsLoading: () => {},
  isLoading: null,
})

export const useEditorCommentsContext = () => {
  return useContext(EditorCommentsContext)
}

const CommentPluginContent = memo(({ data, getData }) => {
  const { showAlert } = useAppContext()

  const [editor] = useLexicalComposerContext()
  const [commentsList, setCommentsList] = useState([])
  const { isTextCommentsShow, setIsTextCommentsShow } = useZoneContext()
  const { page } = usePageContext()
  const [isLoading, setIsLoading] = useState(false)
  const getComments = async () => {
    try {
      return getData(API_ENDPOINTS.textBlockComment, {
        epp: 1000,
        pageId: page?.id,
        sortDirection: "asc",
      })
    } catch (err) {
      setIsLoading(false)
      showAlert("Ошибка при запросе комментариев")
    }
  }

  const createComment = useCallback(
    async (newComment, replyToId = null, textBlockId, onSuccess) => {
      try {
        await apiClient.post(API_ENDPOINTS.textBlockComment, {
          pageId: page?.id,
          text: newComment,
          replyToId,
          textBlockId,
        })
        getComments()
      } catch (err) {
        console.error(err)
      }
    },
    [page?.id],
  )

  const approveComment = useCallback(async (id) => {
    try {
      await apiClient.patch(`${API_ENDPOINTS.textBlockComment}/${id}/approve`)
      getComments()
    } catch (err) {
      console.error(err)
    }
  }, [])

  const deleteComment = useCallback(
    async (id, markId, replyToId) => {
      try {
        await apiClient.delete(`${API_ENDPOINTS.textBlockComment}/${id}`)
        getComments()
        if (!replyToId) {
          const markNodeKeys = markNodeMap.get(markId)
          if ([...markNodeKeys.keys()].length) {
            setTimeout(() => {
              editor.update(() => {
                for (const key of [...markNodeKeys.keys()]) {
                  const node = $getNodeByKey(key)
                  if ($isMarkNode(node)) {
                    node.deleteID(markId)

                    $unwrapMarkNode(node)
                  }
                }
              })
            })
          }

          // const node = $getNodeByKey(markId)
          // if ($isMarkNode(node)) {
          //   node.deleteID(markId)
          //   if (node.getID().length === 0) {
          //     $unwrapMarkNode(node)
          //   }

          markNodeMap.delete(markId)
        }
      } catch (err) {
        console.error(err)
      }
    },
    [editor],
  )

  useEffect(() => {
    if (page?.id) {
      getComments()
    }
  }, [page?.id])

  useEffect(() => {
    setCommentsList(data?.itemsList || [])
  }, [data?.itemsList])

  const markNodeMap = useMemo(() => {
    return new Map()
  }, [])
  const [activeAnchorKey, setActiveAnchorKey] = useState()
  const [activeID, setActiveID] = useState("")
  const [isScrollToComment, setIsScrollToComment] = useState(false)
  const [showCommentInput, setShowCommentInput] = useState(false)

  const clickComment = useCallback((id) => {
    setActiveID(id)
    setIsScrollToComment(true)
  }, [])
  const cancelAddComment = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      // Restore selection
      if (selection !== null) {
        selection.dirty = true
      }
    })
    setShowCommentInput(false)
  }, [editor])

  // const deleteCommentOrThread = useCallback(
  //   (comment, thread) => {
  //     if (comment.type === "comment") {
  //       const deletionInfo = commentStore.deleteCommentOrThread(comment, thread)
  //       if (!deletionInfo) {
  //         return
  //       }
  //       const { markedComment, index } = deletionInfo
  //       // commentStore.addComment(markedComment, thread, index)
  //     } else {
  //       // commentStore.deleteCommentOrThread(comment)
  //       // Remove ids from associated marks
  //       const id = thread !== undefined ? thread.id : comment.id
  //       const markNodeKeys = markNodeMap.get(id)
  //       if (markNodeKeys !== undefined) {
  //         // Do async to avoid causing a React infinite loop
  //         setTimeout(() => {
  //           editor.update(() => {
  //             for (const key of markNodeKeys) {
  //               const node = $getNodeByKey(key)
  //               if ($isMarkNode(node)) {
  //                 node.deleteID(id)
  //                 if (node.getID().length === 0) {
  //                   $unwrapMarkNode(node)
  //                 }
  //               }
  //             }
  //           })
  //         })
  //       }
  //     }
  //   },
  //   [ editor, markNodeMap],
  // )

  // const submitAddComment = useCallback(
  //   (commentOrThread, isInlineComment, thread, selection) => {
  //     editor.update(() => {
  //       if ($isRangeSelection(selection)) {
  //         const isBackward = selection.isBackward()
  //         const id = "test"

  //         // Wrap content in a MarkNode
  //         $wrapSelectionInMarkNode(selection, isBackward, id)
  //       }
  //     })

  //     if (isInlineComment) {
  //       setShowCommentInput(false)
  //     }
  //   },
  //   [ editor],
  // )
  useEffect(() => {
    const changedElems = []

    const id = activeID
    const keys = markNodeMap.get(id)

    if (keys !== undefined) {
      for (const key of keys) {
        const elem = editor.getElementByKey(key)
        if (elem !== null) {
          elem.classList.add(`${styles.markSelected}`)
          changedElems.push(elem)
          setIsTextCommentsShow(true)
          if (isScrollToComment) {
            elem.scrollIntoView({
              behavior: "smooth",
              block: "center",
            })
            setIsScrollToComment(false)
          }
        }
      }
    }

    return () => {
      for (let i = 0; i < changedElems.length; i++) {
        const changedElem = changedElems[i]
        changedElem.classList.remove(`${styles.markSelected}`)
        setIsTextCommentsShow(false)
      }
    }
  }, [activeID, editor, markNodeMap, setIsTextCommentsShow, isScrollToComment])
  useEffect(() => {
    if (!isTextCommentsShow) {
      const id = activeID
      const keys = markNodeMap.get(id)
      if (keys !== undefined) {
        for (const key of keys) {
          const elem = editor.getElementByKey(key)
          if (elem !== null) {
            elem.classList.remove(`${styles.markSelected}`)
          }
        }
        setActiveID("")
        setIsScrollToComment(false)
      }
    }
  }, [isTextCommentsShow, setIsTextCommentsShow])

  useEffect(() => {
    const markNodeKeysToIDs = new Map()
    const nodeMap = editor.getEditorState()._nodeMap

    nodeMap.forEach((node, key) => {
      if ($isMarkNode(node)) {
        let markNodeKeys = markNodeMap.get(node.__id) || new Set()
        markNodeKeys.add(key)
        markNodeMap.set(node.__id, markNodeKeys)
      }
    })
    return mergeRegister(
      editor.registerMutationListener(MarkNode, (mutations) => {
        editor.getEditorState().read(() => {
          for (const [key, mutation] of mutations) {
            const node = $getNodeByKey(key)
            let ids = []

            if (mutation === "destroyed") {
              ids = markNodeKeysToIDs.get(key) || ""
            } else if ($isMarkNode(node)) {
              ids = node.getID()
            }

            let markNodeKeys = markNodeMap.get(ids)
            markNodeKeysToIDs.set(key, ids)

            if (mutation === "destroyed") {
              if (markNodeKeys !== undefined) {
                markNodeKeys.delete(key)
                if (markNodeKeys.size === 0) {
                  markNodeMap.delete(ids)
                }
              }
            } else {
              if (markNodeKeys === undefined) {
                markNodeKeys = new Set()
                markNodeMap.set(ids, markNodeKeys)
              }
              if (!markNodeKeys.has(key)) {
                markNodeKeys.add(key)
              }
            }
          }
        })
      }),
      editor.registerUpdateListener(({ editorState, tags }) => {
        editorState.read(() => {
          const selection = $getSelection()
          let hasActiveId = false
          let hasAnchorKey = false

          if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode()

            if ($isTextNode(anchorNode)) {
              const commentID = $getMarkID(anchorNode, selection.anchor.offset)
              if (commentID !== null) {
                setActiveID(commentID)
                setIsScrollToComment(false)
                hasActiveId = true
              }
              // if (!commentID && selection.anchor.offset !== 0 && !!anchorNode.__prev) {
              //   setActiveID("")
              // }
              if (!selection.isCollapsed()) {
                setActiveAnchorKey(anchorNode.getKey())
                hasAnchorKey = true
              }
            }
          }
          // if (!hasActiveId) {
          //   setActiveID((_activeIds) => (_activeIds ? _activeIds : ""))
          // }
          if (!hasAnchorKey) {
            setActiveAnchorKey(null)
          }
          if (!tags.has("collaboration") && $isRangeSelection(selection)) {
            setShowCommentInput(false)
          }
        })
      }),
      editor.registerCommand(
        INSERT_INLINE_COMMAND,
        () => {
          const domSelection = window.getSelection()
          if (domSelection !== null) {
            domSelection.removeAllRanges()
          }
          setShowCommentInput(true)
          return true
        },
        COMMAND_PRIORITY_EDITOR,
      ),
    )
  }, [editor, markNodeMap])

  const onAddComment = () => {
    editor.dispatchCommand(INSERT_INLINE_COMMAND, undefined)
  }

  const sortedCommentsList = useMemo(() => {
    setIsLoading(false)
    return Object.values(
      commentsList.reduce((acc, comment) => {
        const isChildComment = !!comment.replyTo
        const mainCommentId = isChildComment ? comment.replyTo.id : comment.id

        let mainComment = acc[mainCommentId]

        if (!mainComment) {
          mainComment = isChildComment
            ? { ...comment.replyTo, reply: [] }
            : { ...comment, reply: [] }
        }

        if (isChildComment) {
          mainComment.reply.push(comment)
        } else {
          mainComment = { ...mainComment, ...comment }
        }

        return {
          ...acc,
          [mainCommentId]: mainComment,
        }
      }, {}),
    ).sort((a, b) => (DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt) ? -1 : 1))
  }, [commentsList])

  return (
    <EditorCommentsContext.Provider
      value={{
        createComment,
        refetchList: getComments,
        approveComment,
        deleteComment,
        isLoading: isLoading,
        setIsLoading: setIsLoading,
      }}
    >
      <>
        {showCommentInput &&
          createPortal(
            <CommentInputBox editor={editor} cancelAddComment={cancelAddComment} />,
            document.body,
          )}
        {activeAnchorKey !== null &&
          activeAnchorKey !== undefined &&
          !showCommentInput &&
          createPortal(
            <AddCommentBox
              anchorKey={activeAnchorKey}
              editor={editor}
              onAddComment={onAddComment}
            />,
            document.body,
          )}
        {isTextCommentsShow &&
          createPortal(
            <CommentsPanel
              setShowComments={setIsTextCommentsShow}
              comments={sortedCommentsList}
              submitAddComment={() => {}}
              deleteCommentOrThread={() => {}}
              activeID={activeID}
              clickComment={clickComment}
              markNodeMap={markNodeMap}
            />,
            document.body,
          )}
      </>
    </EditorCommentsContext.Provider>
  )
})
CommentPluginContent.displayName = "CommentPluginComp"

export const CommentPlugin = (props) => (
  <DataListWrapper>
    <CommentPluginContent {...props} />
  </DataListWrapper>
)
