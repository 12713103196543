/* eslint-disable */
import React, { FC } from "react"

export const FilterEditorTableIcon = function ({ className, size = "20", color = "#696C71" }) {
  return (
    <svg
      className={className}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9163 7.50016C13.7706 7.50016 14.5048 6.98596 14.8263 6.25016H16.0413C16.3865 6.25016 16.6663 5.97034 16.6663 5.62516C16.6663 5.27998 16.3865 5.00016 16.0413 5.00016H14.958C14.765 4.04923 13.9242 3.3335 12.9163 3.3335C11.9084 3.3335 11.0677 4.04923 10.8747 5.00016H3.95801C3.61283 5.00016 3.33301 5.27998 3.33301 5.62516C3.33301 5.97034 3.61283 6.25016 3.95801 6.25016H11.0064C11.3278 6.98596 12.062 7.50016 12.9163 7.50016ZM3.33301 9.79183C3.33301 9.44665 3.61283 9.16683 3.95801 9.16683H5.04135C5.23438 8.2159 6.07511 7.50016 7.08301 7.50016C8.09091 7.50016 8.93164 8.2159 9.12467 9.16683H16.0413C16.3865 9.16683 16.6663 9.44665 16.6663 9.79183C16.6663 10.137 16.3865 10.4168 16.0413 10.4168H8.99299C8.67151 11.1526 7.93731 11.6668 7.08301 11.6668C6.22871 11.6668 5.49451 11.1526 5.17303 10.4168H3.95801C3.61283 10.4168 3.33301 10.137 3.33301 9.79183ZM3.95801 13.3335C3.61283 13.3335 3.33301 13.6133 3.33301 13.9585C3.33301 14.3037 3.61283 14.5835 3.95801 14.5835H11.0064C11.3278 15.3193 12.062 15.8335 12.9163 15.8335C13.7706 15.8335 14.5048 15.3193 14.8263 14.5835H16.0413C16.3865 14.5835 16.6663 14.3037 16.6663 13.9585C16.6663 13.6133 16.3865 13.3335 16.0413 13.3335H14.958C14.765 12.3826 13.9242 11.6668 12.9163 11.6668C11.9084 11.6668 11.0677 12.3826 10.8747 13.3335H3.95801ZM13.5412 5.41667C13.5412 5.76184 13.2614 6.04167 12.9162 6.04167C12.571 6.04167 12.2912 5.76184 12.2912 5.41667C12.2912 5.07149 12.571 4.79167 12.9162 4.79167C13.2614 4.79167 13.5412 5.07149 13.5412 5.41667ZM12.9162 14.375C13.2614 14.375 13.5412 14.0952 13.5412 13.75C13.5412 13.4048 13.2614 13.125 12.9162 13.125C12.571 13.125 12.2912 13.4048 12.2912 13.75C12.2912 14.0952 12.571 14.375 12.9162 14.375ZM7.70784 9.58333C7.70784 9.92851 7.42802 10.2083 7.08284 10.2083C6.73767 10.2083 6.45784 9.92851 6.45784 9.58333C6.45784 9.23815 6.73767 8.95833 7.08284 8.95833C7.42802 8.95833 7.70784 9.23815 7.70784 9.58333Z"
        fill={color}
      />
    </svg>
  )
}