import React, { useState } from "react"

import { Stack, Text } from "@mos-cat/ds"

import EditorHtmlGenerator from "@components/form/Editor/EditorHtmlGenerator"
import { stringToEditorContent } from "@components/form"
import { PageBlockHeading } from "@dit/core-frontend"

import HtmlDiff from "htmldiff-js"
import { renderToString } from "react-dom/server"

const PageHeaderContent = ({ title }) => (
  <Text fontSize="44px" lineHeight="48px">
    {title}
  </Text>
)

const DiffContent = ({ versions }) => {
  const [firstVersionHtml, setFirstVersionHtml] = useState("")
  const [secondVersionHtml, setSecondVersionHtml] = useState("")

  const [firstVersionData, secondVersionData] = versions
  const firstVersionTitle = renderToString(<PageHeaderContent title={firstVersionData.title} />)
  const secondVersionTitle = renderToString(<PageHeaderContent title={secondVersionData.title} />)

  return (
    <>
      <Stack maxWidth="1200px" space="20px">
        <PageBlockHeading
          styles={{ maxWidth: "860px" }}
          dangerouslySetInnerHTML={{
            __html: HtmlDiff.execute(firstVersionTitle, secondVersionTitle),
          }}
        />
        <div
          dangerouslySetInnerHTML={{
            __html: HtmlDiff.execute(firstVersionHtml, secondVersionHtml),
          }}
        />
      </Stack>
      <EditorHtmlGenerator
        content={stringToEditorContent(firstVersionData.text)}
        onGenerateHtmlString={setFirstVersionHtml}
      />
      <EditorHtmlGenerator
        content={stringToEditorContent(secondVersionData.text)}
        onGenerateHtmlString={setSecondVersionHtml}
      />
    </>
  )
}

export default DiffContent
