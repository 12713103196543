import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Form, Field } from "react-final-form"
import { Stack, Box, Text, Error } from "@mos-cat/ds"
import {
  ERROR_MESSAGES,
  FormInput,
  Message,
  apiClient,
  Tooltip,
  Button,
  CircleCrossIcon,
  CustomSelect,
  stylesForForm,
  CustomGetUsersOptionLabel,
  Spinner,
  useAppContext,
  ErrorPage,
  TextLink,
  LinkIcon,
  defaultInputHelperText,
  isFormUnready,
  runValidation,
  AvatarPicker,
} from "@dit/core-frontend"

import {
  validationSchema,
  visibilityLevelOptions,
  VISIBILITY_LEVEL,
  templateRequest,
} from "./utils"
import { API_ENDPOINTS, ROUTES } from "@constants"
import defaultImage from "./defaultBgImage.png"

const CardTemplateForm = ({
  closeCurtain,
  updateResponseData,
  zone,
  defaultTemplateData,
  templateId,
  defaultIsFormInViewMode = false,
}) => {
  const [isFormInViewMode, setIsFormInViewMode] = useState(defaultIsFormInViewMode)
  const [isLoading, setIsLoading] = useState(true)
  const [templateData, setTemplateData] = useState(defaultTemplateData || null)
  const { showAlert } = useAppContext()
  const navigate = useNavigate()
  const { user } = useAppContext()

  const isCreateMode = !templateData?.id
  const isEditable = user.id === templateData?.user?.id && !templateData?.isArchive

  const getTemplate = useCallback(async () => {
    if (templateData || !templateId) {
      setIsLoading(false)
      return
    }

    try {
      const { data } = await apiClient.get(`${API_ENDPOINTS.pageTemplates}/${templateId}`)
      setTemplateData(data.data)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [templateId, templateData])

  useEffect(() => {
    void getTemplate()
  }, [getTemplate])

  const loadMembers = async (search, _, { page }) => {
    try {
      const { data } = await apiClient.get(API_ENDPOINTS.teamMembersList, {
        params: {
          search: search,
          epp: 20,
          eppOffset: (page - 1) * 20,
          zoneId: zone.id,
        },
      })

      return {
        options: (data?.data.itemsList || []).map((opt) => opt.assignedToUser),
        hasMore: !!data?.data?.itemsLeft,
        additional: {
          page: page + 1,
        },
      }
    } catch (err) {
      showAlert(err?.data?.errors?.[0]?.message || ERROR_MESSAGES.list)
    }
  }

  if (isLoading) {
    return (
      <Spinner
        styles={{ position: "static" }}
        space="sp-16"
        iconSize="30px"
        overlayVisible={false}
      />
    )
  }

  if (templateId && !templateData) {
    return (
      <ErrorPage
        title="Шаблон не найден"
        text="Шаблон не существует или был удален"
        mainLink={ROUTES.HOME}
      />
    )
  }

  if (!zone || (isFormInViewMode && !templateId)) {
    return null
  }

  const templatePageLinkBlock = (
    <>
      <Text fontWeight="600">Страница шаблона</Text>
      <Box backgroundColor="#F7F5EE" padding="20px 15px" borderRadius="4px">
        <TextLink to={`${ROUTES.ZONE}/${zone.slug}/templates/${templateId}`}>
          <Text styles={{ svg: { display: "inline-block" } }}>
            {templateData?.pageTitle} <LinkIcon size={18} color="currentColor" />
          </Text>
        </TextLink>
      </Box>
    </>
  )

  return (
    <Stack minHeight="100%" space="20px">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="0 40px 20px"
        margin="0 -40px"
        borderBottom="1px solid"
        borderColor="var(--gray-20)"
      >
        <Text fontWeight="600" fontSize="fs-26" lineHeight="32px">
          {isFormInViewMode
            ? templateData.title
            : `${isCreateMode ? "Добавить" : "Редактировать"} шаблон`}
        </Text>
        <Tooltip content="Закрыть">
          <Box as={Button} kind="borderless" onClick={closeCurtain} vertSize={20}>
            <CircleCrossIcon />
          </Box>
        </Tooltip>
      </Box>
      {isFormInViewMode ? (
        <>
          <Stack space="8px">
            <Text fontWeight="600">Описание</Text>
            <Text>{templateData.description}</Text>
          </Stack>
          <Stack space="8px">{templatePageLinkBlock}</Stack>
          <Stack space="8px">
            <Text fontWeight="600">Логотип шаблона</Text>
            <Box
              width="72px"
              height="72px"
              borderRadius="4px"
              backgroundSize="cover"
              backgroundPosition="center"
              style={{ backgroundImage: `url(${templateData?.icon?.url || defaultImage})` }}
            />
          </Stack>
          <Stack flex="2" justifyContent="flex-end !important">
            <Box display="flex" justifyContent="start" gridGap="16px">
              {isEditable && (
                <Button
                  kind="primary"
                  padding="0.6875rem 1.9375rem !important"
                  onClick={() => setIsFormInViewMode(false)}
                >
                  Редактировать
                </Button>
              )}
              <Button
                kind="secondary"
                padding="0.6875rem 1.9375rem !important"
                onClick={closeCurtain}
              >
                Отменить
              </Button>
            </Box>
          </Stack>
        </>
      ) : (
        <Form
          initialValues={{
            title: templateData?.title || "",
            description: templateData?.description || "",
            icon: templateData?.icon || "",
            visibility: templateData?.visibility || visibilityLevelOptions[0].value,
            assignedUsers: templateData?.assignedUsers || [],
          }}
          validate={runValidation(validationSchema)}
          onSubmit={async (formValues) => {
            const { success, templateId, errors } = await templateRequest({
              values: formValues,
              template: templateData || { zone },
            })

            if (success) {
              if (isCreateMode) {
                navigate(`${ROUTES.ZONE}/${zone.slug}/templates/${templateId}/edit`)
              } else {
                updateResponseData?.(templateData?.id, "update", formValues)
              }
              closeCurtain(true)
            }

            if (errors) {
              return errors
            }
          }}
          render={(formRenderProps) => {
            const { errors, touched, values, form } = formRenderProps

            return (
              <form
                onSubmit={formRenderProps.handleSubmit}
                style={{ display: "flex", flexDirection: "column", flex: "2" }}
              >
                <Message
                  type="error"
                  isAlwaysVisible
                  isVisible={!formRenderProps.dirtySinceLastSubmit}
                  text={formRenderProps.submitError}
                />
                <FormInput
                  wrap={false}
                  label="Название шаблона"
                  inputName="title"
                  required
                  formError
                  helper={() => defaultInputHelperText.title}
                />
                <FormInput
                  wrap={false}
                  as="textarea"
                  label="Описание"
                  inputName="description"
                  formError
                />
                <Field name="icon">
                  {() => (
                    <AvatarPicker
                      label="Логотип шаблона"
                      value={values.icon}
                      url={`${API_ENDPOINTS.pageTemplatesIcons}?type=page`}
                      onSelect={(image, hide) => {
                        form.change("icon", image)
                        form.blur("icon")
                        hide()
                      }}
                      onClear={() => {
                        form.change("icon", null)
                        form.blur("icon")
                      }}
                    />
                  )}
                </Field>
                {!isCreateMode && (
                  <Stack space="8px" marginBottom="1rem">
                    {templatePageLinkBlock}
                  </Stack>
                )}
                <Box marginBottom="1rem">
                  <Field name="visibility">
                    {() => (
                      <Stack space="8px">
                        <Text fontWeight="600">
                          Уровень видимости <Text color="#de350b">*</Text>
                        </Text>
                        <CustomSelect
                          type="select"
                          placeholder="Выберите из списка"
                          options={visibilityLevelOptions}
                          getOptionLabel={(option) => option.label}
                          getOptionValue={(option) => option.value}
                          value={visibilityLevelOptions.find(
                            (opt) => opt.value === values.visibility,
                          )}
                          customStyles={stylesForForm}
                          onChange={(selected) => {
                            form.change("visibility", selected.value)
                            form.change("assignedUsers", [])
                            form.blur("visibility")
                            form.blur("assignedUsers")
                          }}
                          isSearchable={false}
                        />
                      </Stack>
                    )}
                  </Field>
                </Box>
                {values.visibility === VISIBILITY_LEVEL.RESTRICTED && (
                  <Box marginBottom="1rem">
                    <Field name="assignedUsers">
                      {() => (
                        <Stack space="8px">
                          <Text fontWeight="600">
                            Участники <Text color="#de350b">*</Text>
                          </Text>
                          <CustomSelect
                            type="async-paginate"
                            placeholder="Выберите участников"
                            defaultOptions
                            cacheOptions
                            customStyles={stylesForForm}
                            loadOptions={loadMembers}
                            isMulti
                            hideSelectedOptions={false}
                            closeMenuOnSelect={false}
                            noOptionsMessage={() => "не найдено"}
                            value={values.assignedUsers}
                            getOptionLabel={(teamMember) => CustomGetUsersOptionLabel(teamMember)}
                            getOptionValue={(teamMember) => teamMember.id}
                            onChange={(selected) => {
                              form.focus("assignedUsers")
                              form.change("assignedUsers", selected)
                              form.blur("assignedUsers")
                            }}
                            loadingMessage={() => (
                              <Box position="relative" minHeight="25px">
                                <Spinner space="sp-16" wrapper="div" />
                              </Box>
                            )}
                            additional={{
                              page: 1,
                            }}
                            isGroupedOptions
                            debounceTimeout={500}
                          />
                          {touched.assignedUsers && !!errors.assignedUsers && (
                            <Error>{errors.assignedUsers}</Error>
                          )}
                        </Stack>
                      )}
                    </Field>
                  </Box>
                )}

                <Stack flex="2" justifyContent="flex-end !important">
                  <Box display="flex" justifyContent="start" gridGap="16px">
                    <Button
                      kind="primary"
                      padding="0.6875rem 1.9375rem !important"
                      disabled={isFormUnready(formRenderProps) || (isFormInViewMode && !isEditable)}
                      loading={formRenderProps.submitting}
                      onClick={formRenderProps.handleSubmit}
                    >
                      {isCreateMode ? "Добавить" : "Сохранить"}
                    </Button>
                    <Button
                      kind="secondary"
                      padding="0.6875rem 1.9375rem !important"
                      onClick={closeCurtain}
                    >
                      Отменить
                    </Button>
                  </Box>
                </Stack>
              </form>
            )
          }}
        />
      )}
    </Stack>
  )
}

export default CardTemplateForm
