export const buttonProps = {
  kind: "borderless",
  vertSize: "32",
  styles: {
    padding: "0 !important",
    "& > *": {
      display: "flex",
      alignItems: "center",
      gridGap: "5px",
      fontWeight: "400",
      color: "var(--gray-90)",
    },
    "&:hover > *, &:hover > * > *": {
      color: "var(--blue-60)",
    },
  },
}
