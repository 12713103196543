// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs_tabsList__0o-p0 .tabs_tab__TeewO {
    padding: 0 0 1px;

    color: var(--gray-60);
    border: none;
    box-shadow: 0 2px 0 0 transparent;
}

.tabs_tabsList__0o-p0 .tabs_tab__TeewO[aria-selected="true"],
.tabs_tabsList__0o-p0 .tabs_tab__TeewO[aria-selected="true"]:hover {
    color: var(--gray-90);
    border-color: var(--brand);
    border-radius: 0;
    background-color: transparent;

    box-shadow: 0 2px 0 0 var(--brand);
}

.tabs_tabsList__0o-p0 .tabs_tab__TeewO:hover {
    border-color: var(--error-05);
    border-radius: 0;
}

.tabs_tabsList__0o-p0 .tabs_tab__TeewO:hover,
.tabs_tabsList__0o-p0 .tabs_tab__TeewO[aria-selected="true"]:hover {
    color: var(--gray-80);
    border-radius: 0;
    background-color: transparent;
    box-shadow: 0 2px 0 0 var(--gray-40);
}

.tabs_tabsBadge__mWDXD {
    padding: 4px 10px;

    color: var(--gray-60);
    border-radius: 38px;
    background-color: var(--gray-05);

    font-size: 14px;
}

@media (max-width: 768px) {
  .tabs_tabsListWrapper__aHNLk {
    display: flex;

    overflow: auto;

    margin: 0 -10px;
    padding: 0 10px 2px;
  }

  .tabs_tabsList__0o-p0 > div {
    flex-wrap: nowrap;

    white-space: nowrap;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/tabs.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;;IAEhB,qBAAqB;IACrB,YAAY;IACZ,iCAAiC;AACrC;;AAEA;;IAEI,qBAAqB;IACrB,0BAA0B;IAC1B,gBAAgB;IAChB,6BAA6B;;IAE7B,kCAAkC;AACtC;;AAEA;IACI,6BAA6B;IAC7B,gBAAgB;AACpB;;AAEA;;IAEI,qBAAqB;IACrB,gBAAgB;IAChB,6BAA6B;IAC7B,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;;IAEjB,qBAAqB;IACrB,mBAAmB;IACnB,gCAAgC;;IAEhC,eAAe;AACnB;;AAEA;EACE;IACE,aAAa;;IAEb,cAAc;;IAEd,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;;IAEjB,mBAAmB;EACrB;AACF","sourcesContent":[".tabsList .tab {\n    padding: 0 0 1px;\n\n    color: var(--gray-60);\n    border: none;\n    box-shadow: 0 2px 0 0 transparent;\n}\n\n.tabsList .tab[aria-selected=\"true\"],\n.tabsList .tab[aria-selected=\"true\"]:hover {\n    color: var(--gray-90);\n    border-color: var(--brand);\n    border-radius: 0;\n    background-color: transparent;\n\n    box-shadow: 0 2px 0 0 var(--brand);\n}\n\n.tabsList .tab:hover {\n    border-color: var(--error-05);\n    border-radius: 0;\n}\n\n.tabsList .tab:hover,\n.tabsList .tab[aria-selected=\"true\"]:hover {\n    color: var(--gray-80);\n    border-radius: 0;\n    background-color: transparent;\n    box-shadow: 0 2px 0 0 var(--gray-40);\n}\n\n.tabsBadge {\n    padding: 4px 10px;\n\n    color: var(--gray-60);\n    border-radius: 38px;\n    background-color: var(--gray-05);\n\n    font-size: 14px;\n}\n\n@media (max-width: 768px) {\n  .tabsListWrapper {\n    display: flex;\n\n    overflow: auto;\n\n    margin: 0 -10px;\n    padding: 0 10px 2px;\n  }\n\n  .tabsList > div {\n    flex-wrap: nowrap;\n\n    white-space: nowrap;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsList": `tabs_tabsList__0o-p0`,
	"tab": `tabs_tab__TeewO`,
	"tabsBadge": `tabs_tabsBadge__mWDXD`,
	"tabsListWrapper": `tabs_tabsListWrapper__aHNLk`
};
export default ___CSS_LOADER_EXPORT___;
