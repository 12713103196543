import React from "react"

import { Box, Text } from "@mos-cat/ds"
import { getFormattedDate, Avatar, Favorites, Subscription } from "@dit/core-frontend"
import { EntityLink } from "@components/entity"
import { Person } from "@components/general"

import { API_ENDPOINTS, ROUTES } from "@constants"
import { ZoneState, ZoneActions } from "@components/zones"
import { getCellPropsByZoneData } from "@utils/zones"
import { ENTITY_TYPES } from "@utils/favorites"

export const createTableConfig = (updateResponseData) => ({
  cells: [
    {
      label: "Код раздела",
      key: "slug",
      // width: "100px",
      getContent: (zone) => (
        <Box
          display="flex"
          alignItems="center"
          gridGap="10px"
          overflow="hidden"
          {...getCellPropsByZoneData(zone)}
        >
          <Avatar url={zone.icon?.url || " "} size={28} borderRadius="0" />
          <EntityLink
            entity={zone}
            link={ROUTES.ZONE}
            overflow="hidden"
            styles={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "3",
              textOverflow: "-webkit-box",
            }}
          />
        </Box>
      ),
    },
    {
      label: "Название",
      key: "title",
      // width: "320px",
      getContent: (zone) => (
        <Text
          as={Box}
          fontSize="fs-14"
          styles={{
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            "-webkit-line-clamp": "3",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          {...getCellPropsByZoneData(zone)}
        >
          {zone.title}
        </Text>
      ),
    },
    {
      label: "Автор",
      key: "user",
      // justifyContent: "center",
      // width: "200px",
      getContent: (zone) => (
        <Box {...getCellPropsByZoneData(zone)}>
          <Person person={zone.user} />
        </Box>
      ),
    },
    {
      label: "Создано",
      key: "createdAt",
      // width: "140px",
      getContent: (zone) => (
        <Text as={Box} fontSize="fs-14" {...getCellPropsByZoneData(zone)}>
          {getFormattedDate(zone.createdAt)}
        </Text>
      ),
    },
    {
      label: "Обновлено",
      key: "modifiedAt",
      // width: "140px",
      getContent: (zone) => (
        <Text as={Box} fontSize="fs-14" {...getCellPropsByZoneData(zone)}>
          {getFormattedDate(zone.modifiedAt)}
        </Text>
      ),
    },
    {
      label: "Документов",
      key: "modifiedAt",
      // width: "140px",
      getContent: (zone) => <Text fontSize="fs-14">{zone.numberOfPages}</Text>,
    },
    // {
    //   label: "Приватность",
    //   key: "modifiedAt",
    //   width: "140px",
    //   getContent: (zone) => <Text fontSize="fs-14">{getFormattedDate(zone.modifiedAt)}</Text>,
    // },

    {
      label: "Статус",
      key: "status",
      width: "124px",
      getContent: (zone) => <ZoneState zone={zone} />,
    },
    {
      label: "",
      key: "secret",
      width: "120px",
      getContent: (zone) => (
        <Box display="flex" alignItems="center" gridGap="10px">
          <Subscription
            itemId={zone.id}
            subscriptionId={zone.subscription?.id}
            type="zone"
            requestUrl={API_ENDPOINTS.subscription}
            onSuccess={(subscriptionId) => {
              updateResponseData?.(zone.id, "update", {
                subscription: subscriptionId ? { id: subscriptionId } : null,
              })
            }}
          />
          <Favorites
            itemId={zone.id}
            favoritesId={zone.favorites?.id}
            type={ENTITY_TYPES.ZONE}
            requestUrl={API_ENDPOINTS.favorites}
            iconSize={21}
            onSuccess={(favoritesId) => {
              updateResponseData?.(zone.id, "update", {
                favorites: favoritesId ? { id: favoritesId } : null,
              })
            }}
          />
          <ZoneActions
            zone={zone}
            onDelete={() => updateResponseData?.(zone.id, "remove")}
            onArchive={(archiveState) =>
              updateResponseData?.(zone.id, "update", { archive: archiveState })
            }
          />
        </Box>
      ),
    },
  ],
})
