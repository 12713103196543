import React, { useEffect, useState } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { useUnit } from "effector-react"

import {
  AppContext,
  AppContextProvider,
  ModuleHeader,
  ErrorPage,
  StarIcon,
} from "@dit/core-frontend"
import { MainContext, MainContextProvider } from "@components/contexts/MainContext"
import { HomePage, ZonesPage, ZonePage, FavoritesPage } from "@pages"
import { Loader, Stack } from "@mos-cat/ds"

import { API_ENDPOINTS, modulesLinks, ROUTES } from "@constants"
import { checkPermission, $isPermissionsLoading, loadPermissions } from "../store/permissionModel"
import AllPagesPage from "@src/pages/AllPages"
import { Helmet } from "react-helmet"
import { ZoneCreateTarget } from "@components/zones"
import { CreatePageModal } from "@components/pages/CreatePageModal"

const MODAL_OPTIONS = {
  CREATE_ZONE: "create-zone",
  CREATE_PAGE: "create-page",
}

const RedirectToHome = () => <Navigate to={ROUTES.HOME} replace />

const checkPermissionOrRenderError = (permission, Component) => {
  return checkPermission(permission) ? (
    <Component />
  ) : (
    <ErrorPage
      code="403"
      title="Доступ запрещен"
      text="У вас нет прав доступа к этой странице"
      mainLink={ROUTES.HOME}
    />
  )
}

export const MainLayout = () => {
  const [showModal, setShowModal] = useState(null)
  const isLoading = useUnit($isPermissionsLoading)
  const loadpermissions = useUnit(loadPermissions)

  const handleSetModal = (value) => {
    setShowModal(value)
  }

  const onSearch = (val) => {
    if (val) {
      window.location.href = `${ROUTES.PAGES_LIST}?search=${val}`
    }
  }

  useEffect(() => {
    loadpermissions?.()
  }, [loadpermissions])

  if (isLoading) {
    return (
      <Loader
        bg={["gray-05", "white"]}
        space="sp-16"
        wrapper="div"
        style={{ display: "flex", alignItems: "center" }}
        overlayVisible
      />
    )
  }

  return (
    <AppContextProvider
      endpointMeUrl={API_ENDPOINTS.me}
      urlOnNotAuthorized={ROUTES.HOME}
      currentModule="wiki"
      modulesLinks={modulesLinks}
    >
      <AppContext.Consumer>
        {() => {
          return (
            <BrowserRouter>
              <ModuleHeader
                sseTopics={[]}
                leftItems={[
                  {
                    type: "popover",
                    title: "Разделы",
                    isVisible: checkPermission("ZONE_LIST_READ"),
                    content: [
                      { type: "link", title: "Все разделы", to: ROUTES.ZONES_LIST },
                      {
                        type: "action",
                        title: "Создать",
                        isVisible: checkPermission("ZONE_CREATE"),
                        onClick: () => handleSetModal(MODAL_OPTIONS.CREATE_ZONE),
                      },
                    ],
                  },
                  {
                    type: "popover",
                    title: "Страницы",
                    isVisible: checkPermission("ZONE_LIST_READ"),
                    content: [
                      { type: "link", title: "Все страницы", to: ROUTES.PAGES_LIST },
                      {
                        type: "action",
                        title: "Создать",
                        isVisible: checkPermission("ZONE_CREATE"),
                        onClick: () => handleSetModal(MODAL_OPTIONS.CREATE_PAGE),
                      },
                    ],
                  },
                ]}
                iconItems={[
                  {
                    type: "link",
                    title: "Избранное",
                    Icon: StarIcon,
                    to: ROUTES.FAVORITES,
                  },
                ]}
                centerItems={[
                  {
                    type: "action",
                    title: "Создать",
                    onClick: () => handleSetModal(MODAL_OPTIONS.CREATE_PAGE),
                  },
                ]}
                onSearch={onSearch}
              />
              <Helmet title="МосВики" />
              <MainContextProvider>
                <MainContext.Consumer>
                  {() => (
                    <Routes>
                      <Route path="/" element={<RedirectToHome />} />
                      <Route path={ROUTES.HOME} element={<HomePage />} exact />
                      <Route
                        path={`${ROUTES.ZONES_LIST}`}
                        element={checkPermissionOrRenderError("ZONE_LIST_READ", ZonesPage)}
                      />
                      <Route
                        path={`${ROUTES.ZONE}/:zoneSlug/*`}
                        element={checkPermissionOrRenderError("PAGE_LIST_READ", ZonePage)}
                      />
                      <Route path={ROUTES.FAVORITES} element={<FavoritesPage />} />
                      <Route path={`${ROUTES.PAGES_LIST}`} element={<AllPagesPage />} />
                      <Route path="*" element={<ErrorPage mainLink={ROUTES.HOME} />} />
                    </Routes>
                  )}
                </MainContext.Consumer>
              </MainContextProvider>

              <ZoneCreateTarget
                modalDisclosureProps={{
                  as: Stack,
                  space: "10px",
                  position: "relative",
                }}
                modalStateProps={{
                  visible: showModal === MODAL_OPTIONS.CREATE_ZONE,
                  hide: () => handleSetModal(null),
                }}
              />
              <CreatePageModal
                modalDisclosureProps={{
                  as: Stack,
                  space: "10px",
                  position: "relative",
                }}
                modalStateProps={{
                  visible: showModal === MODAL_OPTIONS.CREATE_PAGE,
                  hide: () => handleSetModal(null),
                }}
              />
            </BrowserRouter>
          )
        }}
      </AppContext.Consumer>
    </AppContextProvider>
  )
}
