import React, { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

import {
  PageBlockContent,
  PageBlockHeading,
  PageBlockRow,
  PageBlockRowSection,
  DataListWrapper,
  Search,
  VirtualizedTable,
  Spinner,
  useAppContext,
  VirtualList,
} from "@dit/core-frontend"
import { Box, Button, Stack } from "@mos-cat/ds"
import { useZoneContext } from "@components/contexts/ZoneContext"

import { API_ENDPOINTS, ROUTES } from "@constants"
import { ZONE_SETTINGS_SECTIONS } from "@utils/zones"
import { createTableConfig } from "./createTableConfig"
import { ChangeTeamMember } from "./components/ChangeTeamMember"
import { TeamsFilter } from "./components/TeamsFilter"
import TeamMemberMobile from "@components/zones/TeamMemberMobile"
import { Helmet } from "react-helmet"

const TeamsBlock = ({
  data,
  getData,
  isLoading,
  isRequestLoading,
  updateResponseData,
  setAdditionalBreadcrumbs,
}) => {
  const { zone, checkZonePermission } = useZoneContext()
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const search = searchParams.get("search")
  const roles = searchParams.getAll("roles")
  const [showModal, setShowModal] = useState(null)
  const [page, setPage] = useState(1)
  const [teamMemberData, setTeamMemberData] = useState(null)
  const { isMobileView } = useAppContext()

  const tableConfig = createTableConfig(
    updateResponseData,
    setShowModal,
    setTeamMemberData,
    checkZonePermission,
  )

  const handleAddPage = useCallback(() => {
    if (data?.itemsList.length < data?.itemsTotal) {
      setPage((prev) => prev + 1)
    }
  }, [data, page])

  const onChangeParams = (key, val) => {
    const params = new URLSearchParams(searchParams)
    params.delete(key)

    if (val) {
      if (Array.isArray(val)) {
        val.forEach((v) => params.append(key, v))
      } else {
        params.set(key, (val || "").trim())
      }
    }

    navigate(`${location.pathname}?${params}`)
  }

  const fetchTeams = () => {
    getData(API_ENDPOINTS.teamMembersList, {
      zoneId: zone.id,
      search,
      roles,
      epp: 60,
      eppOffset: (page - 1) * 60,
    })
  }
  const handleSetModal = (value) => {
    setShowModal(value)
  }

  useEffect(() => {
    setPage(1)
  }, [search])

  useEffect(() => {
    fetchTeams()
  }, [getData, page, searchParams])

  useEffect(() => {
    setAdditionalBreadcrumbs([
      {
        label: "Участники раздела",
        to: `${ROUTES.ZONE}/${zone.slug}/settings/${ZONE_SETTINGS_SECTIONS.TEAMS}`,
      },
    ])
  }, [setAdditionalBreadcrumbs, zone.slug])

  const renderButton = (
    <Button
      onClick={() => {
        handleSetModal("create-member")
        setTeamMemberData({})
      }}
      kind="primary"
      vertSize={32}
      padding={["4px 10px!important", "0.25rem 1rem 0.315rem 1rem!important"]}
    >
      Добавить участника
    </Button>
  )

  return (
    <>
      {zone?.title && <Helmet title={`Участники · ${zone?.title || ""} · МосВики`} />}
      <PageBlockRow place="middle">
        <PageBlockRowSection
          gridGap={["10px", "20px"]}
          justifyContent={["space-between", "flex-start"]}
          flexWrap={["wrap", "nowrap"]}
          width={["100%", "auto"]}
        >
          <PageBlockHeading>Участники</PageBlockHeading>
          {isMobileView && checkZonePermission("ZONE_SETTINGS_TEAM_MEMBERS_CREATE") && renderButton}
          <Box width={["100%", "auto"]}>
            <Search
              onSearch={(value) => onChangeParams("search", value)}
              defaultValue={search}
              fullWidth={["100%", "400px"]}
              width={["100%", "102px"]}
            />
          </Box>
        </PageBlockRowSection>
        {!isMobileView && checkZonePermission("ZONE_SETTINGS_TEAM_MEMBERS_CREATE") && (
          <PageBlockRowSection>{renderButton}</PageBlockRowSection>
        )}
      </PageBlockRow>
      <PageBlockRow place="bottom">
        <TeamsFilter
          filterParams={{ roles: roles }}
          setFilterParams={onChangeParams}
          defaultBg="none"
        />
      </PageBlockRow>

      <PageBlockContent flex="2">
        {!isLoading && !isMobileView && (
          <VirtualizedTable
            over={10}
            dataList={data?.itemsList || []}
            dataTotal={data?.itemsTotal}
            tableConfig={tableConfig}
            emptyText="Нет участников"
            isLoading={isRequestLoading}
            onLoad={handleAddPage}
            infiniteScroll
            infiniteCount={2}
          />
        )}

        {!isLoading && isMobileView && (
          <VirtualList
            over={10}
            dataList={data?.itemsList}
            dataTotal={data?.itemsTotal}
            isLoading={isLoading}
            infiniteScroll
            infiniteCount={2}
            height={500}
            renderItem={(person) => (
              <TeamMemberMobile
                person={person}
                updateResponseData={updateResponseData}
                handleSetModal={setShowModal}
                setTeamMemberData={setTeamMemberData}
              />
            )}
            onLoad={handleAddPage}
          />
        )}

        {isLoading && <Spinner space="sp-16" wrapper="div" />}
        <ChangeTeamMember
          teamMemberData={teamMemberData}
          onSuccess={(requestData) => {
            updateResponseData(teamMemberData.id, "update", requestData)
          }}
          modalDisclosureProps={{
            as: Stack,
            space: "0",
            position: "relative",
            styles: { cursor: "pointer" },
          }}
          modalStateProps={{
            visible: showModal === "update-member",
            hide: () => handleSetModal(null),
          }}
        />
        <ChangeTeamMember
          type="create"
          onSuccess={fetchTeams}
          modalDisclosureProps={{
            as: Stack,
            space: "0",
            position: "relative",
            styles: { cursor: "pointer" },
          }}
          modalStateProps={{
            visible: showModal === "create-member",
            hide: () => handleSetModal(null),
          }}
        />
      </PageBlockContent>
    </>
  )
}
const Teams = ({ setAdditionalBreadcrumbs }) => {
  return (
    <DataListWrapper>
      <TeamsBlock setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />
    </DataListWrapper>
  )
}
export default Teams
