// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_container__8ZzGd {
  position: relative;

  overflow: hidden auto;

  box-sizing: content-box;

  background-color: var(--gray-10);
}

.Sidebar_container__8ZzGd::-webkit-scrollbar {
  position: absolute;
  right: 0;

  width: 10px;
}

.Sidebar_container__8ZzGd::-webkit-scrollbar-track {
  background-color: var(--white);
}

.Sidebar_container__8ZzGd::-webkit-scrollbar-thumb {
  border: 3px solid white;
  border-radius: 20px;
  background-color: var(--gray-60);
}

.Sidebar_container__8ZzGd::-webkit-scrollbar-thumb:hover {
  background-color: var(--gray-60);
}

.Sidebar_curtainSidebar__IcltZ {
  background-color: var(--gray-05);
}
`, "",{"version":3,"sources":["webpack://./src/components/zones/Sidebar/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;;EAElB,qBAAqB;;EAErB,uBAAuB;;EAEvB,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;;EAER,WAAW;AACb;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".container {\n  position: relative;\n\n  overflow: hidden auto;\n\n  box-sizing: content-box;\n\n  background-color: var(--gray-10);\n}\n\n.container::-webkit-scrollbar {\n  position: absolute;\n  right: 0;\n\n  width: 10px;\n}\n\n.container::-webkit-scrollbar-track {\n  background-color: var(--white);\n}\n\n.container::-webkit-scrollbar-thumb {\n  border: 3px solid white;\n  border-radius: 20px;\n  background-color: var(--gray-60);\n}\n\n.container::-webkit-scrollbar-thumb:hover {\n  background-color: var(--gray-60);\n}\n\n.curtainSidebar {\n  background-color: var(--gray-05);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Sidebar_container__8ZzGd`,
	"curtainSidebar": `Sidebar_curtainSidebar__IcltZ`
};
export default ___CSS_LOADER_EXPORT___;
