import React, { useEffect, useState } from "react"
import { PAGE_STATUSES, PAGE_STATUSES_OBJECT } from "@utils/pages"

import {
  $objectIdPermissionsLoading,
  $objectIdPermissionsStore,
  checkPermission,
  loadObjectIdPermissions,
} from "@src/store/permissionModel"
import { useUnit } from "effector-react"

import {
  ActionsPopover,
  apiClient,
  ArchiveIcon,
  Button,
  ConfirmModal,
  CopyIcon,
  PencilIcon,
  UnArchiveIcon,
  useAppContext,
  ERROR_MESSAGES,
  Spinner,
  HistoryIcon,
  FileHeartIcon,
} from "@dit/core-frontend"
import { API_ENDPOINTS, ROUTES } from "@constants"
import { Box, Text } from "@mos-cat/ds"
import { Delete, Meatball } from "@mos-cat/ds-icons"
import { Link, useNavigate } from "react-router-dom"
import { CurtainModal } from "@components/general"
import HistoryCurtainContent from "@components/pages/History/HistoryCurtainContent"
import { PageExportAction } from "@components/pages/PageExportAction"

import { templateRequest, VISIBILITY_LEVEL } from "@components/pageTemplates/utils"
import { createNewDraftPage } from "@components/pages/utils"
import { useZoneContext } from "../contexts/ZoneContext"

export const PageActions = ({ page, onDelete, onUpdate, data, ...popoverProps }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isArchiveLoading, setIsArchiveLoading] = useState(false)
  const [isTemplateLoading, setIsTemplateLoading] = useState(false)
  const [isCopyLoading, setIsCopyLoading] = useState(false)
  const [isHistoryCurtainOpen, setIsHistoryCurtainOpen] = useState(false)
  const { deletePage, refetchPages } = useZoneContext()
  const { showAlert } = useAppContext()
  const navigate = useNavigate()
  const loadPermissions = useUnit(loadObjectIdPermissions)
  const permissionsLoading = useUnit($objectIdPermissionsLoading)
  const zonePermissions = useUnit($objectIdPermissionsStore)
  const permissions = zonePermissions[page?.zone?.id]

  const checkZonePermission = (identifier) => {
    return permissions?.includes(identifier) || checkPermission(identifier)
  }

  useEffect(() => {
    if (isOpen) {
      loadPermissions(page.zone.id)
    }
  }, [page.zone.id, loadPermissions, isOpen])

  const isArchive = !!page.archive
  const isDraft = page.status?.slug === PAGE_STATUSES.DRAFT

  const onArchive = (archive) => {
    let childrenIds = []

    const findChildrenIds = (pageId) => {
      data.itemsList?.forEach((item) => {
        if (item.parent?.id === pageId) {
          childrenIds.push(item.id)
          findChildrenIds(item.id)
        }
      })
    }

    findChildrenIds(page.id)

    onUpdate([page.id, ...childrenIds], "update", {
      archive,
      status: archive
        ? PAGE_STATUSES_OBJECT[PAGE_STATUSES.ARCHIVE]
        : PAGE_STATUSES_OBJECT[PAGE_STATUSES.PUBLISH],
    })
    deletePage(page.id)
    if (!archive) {
      refetchPages()
    }
  }

  const createTemplate = async (hidePopover) => {
    setIsTemplateLoading(true)
    const { success, alertMessage, templateId } = await templateRequest({
      values: {
        title: page.title,
        description: "",
        visibility: VISIBILITY_LEVEL.PRIVATE,
        pageTitle: page.title,
        content: page.text,
        assignedUsers: [],
        zone: page.zone,
      },
    })

    if (success) {
      navigate(`${ROUTES.ZONE}/${page.zone.slug}/templates/${templateId}`)
    } else {
      showAlert(alertMessage)
    }

    hidePopover()
    setIsTemplateLoading(false)
  }

  const copyPage = async (hidePopover) => {
    setIsCopyLoading(true)

    void createNewDraftPage({
      zoneId: page.zone.id,
      copyPage: page,
      parent: page.parent?.id,
      onError: (message) => {
        showAlert(message)
        setIsCopyLoading(false)
      },
      onSuccess: (newPage) => {
        setIsCopyLoading(false)
        hidePopover()
        navigate(`${ROUTES.ZONE}/${page.zone.id}/pages/${newPage?.slug || newPage?.id}/edit`, {
          replace: true,
        })
      },
    })
  }

  return (
    <>
      <ActionsPopover
        customHoverStyle={{ backgroundColor: "" }}
        hoverColor=""
        customBackground="none"
        content={
          <Text color="gray-60" fontSize="14px" onClick={() => setIsOpen(true)}>
            <Meatball />
          </Text>
        }
        {...popoverProps}
      >
        {(buttonProps, hidePopover) =>
          isOpen && permissionsLoading ? (
            <Box height="20px">
              <Spinner space="sp-16" wrapper="div" iconSize="20px" />
            </Box>
          ) : (
            <>
              {!isArchive && (
                <>
                  <PageExportAction page={page} onCloseModal={hidePopover} />
                  {checkZonePermission("PAGE_CREATE") && (
                    <Button
                      kind="menu-button"
                      disabled={isCopyLoading}
                      loading={isCopyLoading}
                      onClick={() => copyPage(hidePopover)}
                    >
                      <CopyIcon color="currentColor" size={21} />
                      Копировать
                    </Button>
                  )}
                  {/* Скрыто в релизе 2.2.0 */}
                  {/*{checkProjectPermission("AREA_SETTINGS_PERMISSIONS_READ") && (*/}
                  {/*  <Box*/}
                  {/*    as={Link}*/}
                  {/*    display="flex"*/}
                  {/*    to={`${ROUTES.ZONE}/${page.zone.slug}/pages/${page.slug}/permissions`}*/}
                  {/*  >*/}
                  {/*    <Button kind="menu-button">*/}
                  {/*      <PencilIcon color="currentColor" />*/}
                  {/*      Разрешения*/}
                  {/*    </Button>*/}
                  {/*  </Box>*/}
                  {/*)}*/}
                  {checkZonePermission("PAGE_EDIT") && (
                    <Box
                      as={Link}
                      display="flex"
                      to={`${ROUTES.ZONE}/${page.zone.slug}/pages/${page.slug}/edit`}
                    >
                      <Button kind="menu-button">
                        <PencilIcon color="currentColor" size={21} />
                        Редактировать
                      </Button>
                    </Box>
                  )}
                </>
              )}
              {!isDraft && checkZonePermission("PAGE_EDIT") && (
                <>
                  {isArchive ? (
                    <Button
                      kind="menu-button"
                      disabled={isArchiveLoading}
                      loading={isArchiveLoading}
                      onClick={async () => {
                        setIsArchiveLoading(true)
                        try {
                          await apiClient.put(`${API_ENDPOINTS.page}/${page.id}/archive`, {
                            archive: false,
                          })
                          onArchive(false)
                          hidePopover()
                        } catch (err) {
                          showAlert(err?.data?.errors?.[0].message || ERROR_MESSAGES.default)
                        } finally {
                          setIsArchiveLoading(false)
                        }
                      }}
                    >
                      <UnArchiveIcon color="currentColor" size={21} />
                      Вернуть из архива
                    </Button>
                  ) : (
                    <ConfirmModal
                      title="Архивация страницы"
                      description={
                        <>
                          Архивировать страницу «{page.title}»?
                          {page.numberOfChildren > 0 && (
                            <>
                              <br />
                              <br />
                              При архивации этой страницы все связанные с ней дочерние страницы
                              также будут заархивированы
                            </>
                          )}
                        </>
                      }
                      modalDisclosureProps={{
                        as: Button,
                        kind: "menu-button",
                        children: (
                          <>
                            <ArchiveIcon color="currentColor" size={21} />
                            Архивировать
                          </>
                        ),
                      }}
                      successText="Страница архивирована"
                      confirmButtonText="Архивировать"
                      onCancel={hidePopover}
                      onSuccess={() => {
                        hidePopover()
                        onArchive(true)
                      }}
                      request={() =>
                        apiClient.put(`${API_ENDPOINTS.page}/${page.id}/archive`, { archive: true })
                      }
                    />
                  )}
                  <Button
                    kind="menu-button"
                    onClick={() => {
                      hidePopover()
                      setIsHistoryCurtainOpen(true)
                    }}
                  >
                    <HistoryIcon color="currentColor" size={21} />
                    История версий
                  </Button>
                </>
              )}
              <Button
                kind="menu-button"
                disabled={isTemplateLoading}
                loading={isTemplateLoading}
                onClick={() => createTemplate(hidePopover)}
              >
                <FileHeartIcon color="currentColor" size={21} />
                Сохранить как шаблон
              </Button>
              {checkZonePermission("PAGE_DELETE") && (
                <ConfirmModal
                  title="Удаление страницы"
                  description={`Удалить страницу «${page.title}»?`}
                  modalDisclosureProps={{
                    as: Button,
                    kind: "menu-button",
                    children: (
                      <>
                        <Delete color="currentColor" />
                        Удалить
                      </>
                    ),
                  }}
                  successText="Страница удалена"
                  confirmButtonText="Удалить"
                  onSuccess={() => {
                    hidePopover()
                    onDelete()
                  }}
                  onCancel={hidePopover}
                  request={() => apiClient.delete(`${API_ENDPOINTS.page}/${page.id}`)}
                />
              )}
            </>
          )
        }
      </ActionsPopover>
      <CurtainModal
        isOpen={isHistoryCurtainOpen}
        onClose={() => setIsHistoryCurtainOpen(false)}
        content={(hide) => (
          <HistoryCurtainContent
            zone={page.zone}
            page={page}
            closeModal={hide}
            onUpdatePage={onUpdate}
          />
        )}
      />
    </>
  )
}
