import { modulesLinks } from "./modulesLinks"

export const ROUTES = {
  HOME: "/wiki",
  ZONE: "/wiki/zone",
  ZONES_LIST: "/wiki/zones",
  FAVORITES: "/wiki/favorites",
  PAGES_LIST: "/wiki/pages",

  CODE: "/track",
  TRACK_TASK: `${modulesLinks.track}/task`,
}
