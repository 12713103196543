import { apiClient } from "@dit/core-frontend"
import { API_ENDPOINTS } from "@constants"
import { PAGE_STATUSES } from "@utils/pages"

export const createNewDraftPage = async ({
  homePage = false,
  zoneId,
  copyPage = {},
  parent,
  onError,
  onSuccess,
}) => {
  let draftStatus
  try {
    const statueRes = await apiClient.get(`${API_ENDPOINTS.pageStatus}/${PAGE_STATUSES.DRAFT}`)
    draftStatus = statueRes.data.data
  } catch (err) {
    console.error(err)
    onError?.("Не удалось получить статусы")
    return
  }

  try {
    const title = copyPage?.title || "Новая страница"
    // Запрос на создание страницы
    const { data } = await apiClient.post(API_ENDPOINTS.page, {
      zoneId: zoneId,
      statusId: draftStatus.id,
      title,
      text: copyPage?.text || "",
      plainText: copyPage?.plainText || "",
      version: "1",
      parentId: parent,
    })

    if (homePage) {
      await apiClient
        .patch(`${API_ENDPOINTS.page}/${data.data.id}`, {
          zoneId: zoneId,
          statusId: draftStatus.id,
          title,
          text: "",
          plainText: "",
          version: "1",
          homePage,
        })
        .then(() => new Promise((resolve) => setTimeout(resolve, 200)))
    }

    onSuccess(data.data)
  } catch (err) {
    console.error(err)
    onError?.(err?.data?.errors?.[0]?.message || "Не удалось создать новую страницу")
  }
}
