import React, { memo } from "react"

import { Box, Text } from "@mos-cat/ds"
import { Avatar, CustomGetUsersOptionLabel } from "@dit/core-frontend"

export const Person = memo(({ person, personWidth = "250px", ...boxProps }) => {
  return (
    <Text
      as={Box}
      display="flex"
      alignItems="flex-start"
      gridGap="10px"
      color="inherit"
      fontSize="inherit"
      {...boxProps}
    >
      <Avatar url={person?.photo?.url || person?.photo} size={20} />
      <Text
        as={Box}
        maxWidth={personWidth}
        styles={{
          display: "-webkit-box",
          "-webkit-box-orient": "vertical",
          "-webkit-line-clamp": "2",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {CustomGetUsersOptionLabel(person)}
      </Text>
    </Text>
  )
})

Person.displayName = "Person"
