import { AutoEmbedOption, LexicalAutoEmbedPlugin } from "@lexical/react/LexicalAutoEmbedPlugin"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import * as React from "react"
import * as ReactDOM from "react-dom"
import * as yup from "yup"
import { INSERT_FIGMA_COMMAND } from "../FigmaPlugin"
import { INSERT_YOUTUBE_COMMAND } from "../YouTubePlugin"
import { Box, Button, Modal, ModalDisclosure, useModalState } from "@mos-cat/ds"
import { Form } from "react-final-form"
import {
  ERROR_MESSAGES,
  FormInput,
  getFormErrorMessageForInput,
  useAppContext,
} from "@dit/core-frontend"
import { FigmaIcon, YoutubeIcon } from "../../theme/editorIcons"

export const YoutubeEmbedConfig = {
  contentName: "Видео Youtube",
  header: "Добавление видео Youtube",
  exampleUrl: "https://www.youtube.com/watch?v=jNQXAC9IVRw",
  match: "^.*(youtu\\.be\\/|v\\/|u\\/\\w\\/|embed\\/|watch\\?v=|&v=)([^#&?]*).*",

  icon: <YoutubeIcon />,

  insertNode: (editor, result) => {
    editor.dispatchCommand(INSERT_YOUTUBE_COMMAND, result.id)
  },

  keywords: ["youtube", "video"],

  // Determine if a given URL is a match and return url data.
  parseUrl: async (url) => {
    const match = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/.exec(url)

    const id = match ? (match?.[2].length === 11 ? match[2] : null) : null

    if (id != null) {
      return {
        id,
        url,
      }
    }

    return null
  },

  type: "youtube-video",
}

export const FigmaEmbedConfig = {
  contentName: "Документ Figma",
  header: "Добавление документа Figma",
  exampleUrl: "https://www.figma.com/file/LKQ4FJ4bTnCSjedbRpk931/Sample-File",
  match: "https://([\\w.-]+\\.)?figma.com/(file|proto)/([0-9a-zA-Z]{22,128})(?:/.*)?$",
  icon: <FigmaIcon />,

  insertNode: (editor, result) => {
    editor.dispatchCommand(INSERT_FIGMA_COMMAND, result.id)
  },

  keywords: ["figma", "figma.com", "mock-up"],

  // Determine if a given URL is a match and return url data.
  parseUrl: (text) => {
    const match =
      /https:\/\/([\w.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/.exec(text)

    if (match != null) {
      return {
        id: match[3],
        url: match[0],
      }
    }

    return null
  },

  type: "figma",
}

export const EmbedConfigs = [FigmaEmbedConfig, YoutubeEmbedConfig]

function AutoEmbedMenuItem({ index, isSelected, onClick, onMouseEnter, option }) {
  let className = "item"
  if (isSelected) {
    className += " selected"
  }
  return (
    <li
      key={option.key}
      tabIndex={-1}
      className={className}
      ref={option.setRefElement}
      role="option"
      aria-selected={isSelected}
      id={"typeahead-item-" + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
    >
      <span className="text">{option.title}</span>
    </li>
  )
}

function AutoEmbedMenu({ options, selectedItemIndex, onOptionClick, onOptionMouseEnter }) {
  return (
    <div className="typeahead-popover">
      <ul>
        {options.map((option, i) => (
          <AutoEmbedMenuItem
            index={i}
            isSelected={selectedItemIndex === i}
            onClick={() => onOptionClick(option, i)}
            onMouseEnter={() => onOptionMouseEnter(i)}
            key={option.key}
            option={option}
          />
        ))}
      </ul>
    </div>
  )
}

export function AutoEmbedDialog({ embedConfig, children, modalDisclosureProps, modalStateProps }) {
  const { showAlert } = useAppContext()
  const validationSchema = yup.object().shape({
    link: yup
      .string()
      .matches(new RegExp(embedConfig.match), "Неверный формат ссылки")
      .required(ERROR_MESSAGES.required),
  })
  const [editor] = useLexicalComposerContext()
  const modalState = useModalState()
  const getModalState = { ...modalState, ...modalStateProps }
  const isVisible = getModalState?.visible

  const onSubmit = async (values) => {
    try {
      const parseResult = await Promise.resolve(embedConfig.parseUrl(values.link))
      embedConfig.insertNode(editor, parseResult)
    } catch {
      showAlert("Некорректная ссылка")
    }
  }
  // onClose()
  const onValidate = (values) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false })
    } catch (errors) {
      return errors.inner.reduce(
        (errors, error) => ({
          ...errors,
          [error.path]: error.message,
        }),
        {},
      )
    }
  }
  const handleClose = () => {
    getModalState.hide()
  }

  return (
    <>
      {children && (
        <ModalDisclosure {...modalDisclosureProps} {...getModalState}>
          {children}
        </ModalDisclosure>
      )}

      <Modal {...getModalState} heading={embedConfig.header}>
        {isVisible && (
          <Form
            initialValues={{
              columns: "",
              rows: "",
            }}
            validate={(values) => onValidate(values)}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitErrors,
              errors,
              touched,
              dirtyFieldsSinceLastSubmit,
              hasValidationErrors,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormInput
                    wrap={false}
                    label="Введите ссылку"
                    inputName="link"
                    required
                    errorMessage={getFormErrorMessageForInput(
                      { touched, errors, submitErrors, dirtyFieldsSinceLastSubmit },
                      "link",
                    )}
                  />

                  <Box display="flex" justifyContent="start" gridGap="16px">
                    <Button kind="primary" type="submit" disabled={hasValidationErrors}>
                      Сохранить
                    </Button>
                    <Button kind="secondary" onClick={handleClose} type="reset">
                      Отменить
                    </Button>
                  </Box>
                </form>
              )
            }}
          />
        )}
      </Modal>
    </>
  )
}

export default function AutoEmbedPlugin() {
  const modalState = useModalState()

  const getMenuOptions = (activeEmbedConfig, embedFn, dismissFn) => {
    return [
      new AutoEmbedOption("Dismiss", {
        onSelect: dismissFn,
      }),
      new AutoEmbedOption(`Embed ${activeEmbedConfig.contentName}`, {
        onSelect: embedFn,
      }),
    ]
  }

  return (
    <>
      <LexicalAutoEmbedPlugin
        {...modalState}
        embedConfigs={EmbedConfigs}
        onOpenEmbedModalForConfig={() => {}}
        getMenuOptions={getMenuOptions}
        menuRenderFn={(
          anchorElementRef,
          { selectedIndex, options, selectOptionAndCleanUp, setHighlightedIndex },
        ) =>
          anchorElementRef.current
            ? ReactDOM.createPortal(
                <div
                  className="typeahead-popover auto-embed-menu"
                  style={{
                    marginLeft: anchorElementRef.current.style.width,
                    width: 200,
                  }}
                >
                  <AutoEmbedMenu
                    options={options}
                    selectedItemIndex={selectedIndex}
                    onOptionClick={(option, index) => {
                      setHighlightedIndex(index)
                      selectOptionAndCleanUp(option)
                    }}
                    onOptionMouseEnter={(index) => {
                      setHighlightedIndex(index)
                    }}
                  />
                </div>,
                anchorElementRef.current,
              )
            : null
        }
      />
    </>
  )
}
