import styles from "./styles.module.css"

import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useLexicalNodeSelection } from "@lexical/react/useLexicalNodeSelection"
import { mergeRegister } from "@lexical/utils"
import { Box } from "@mos-cat/ds"
import {
  $getNodeByKey,
  $getSelection,
  $isNodeSelection,
  CLICK_COMMAND,
  COMMAND_PRIORITY_HIGH,
  COMMAND_PRIORITY_LOW,
  DecoratorNode,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
} from "lexical"
import * as React from "react"
import { useCallback, useEffect } from "react"

const indent = (tagName) => {
  if (tagName === "h2") {
    return styles.heading2
  } else if (tagName === "h3") {
    return styles.heading3
  }
}

function TableOfContentComponent({ nodeKey, tableOfContentsData }) {
  const [editor] = useLexicalComposerContext()
  const [isSelected, setSelected, clearSelection] = useLexicalNodeSelection(nodeKey)

  const onDelete = useCallback(
    (event) => {
      event.preventDefault()
      if (isSelected && $isNodeSelection($getSelection())) {
        const node = $getNodeByKey(nodeKey)
        if ($isTableOfContentNode(node)) {
          node.remove()
          return true
        }
      }
      return false
    },
    [isSelected, nodeKey],
  )

  const scrollToNode = useCallback(
    (key) => {
      editor.getEditorState().read(() => {
        const domElement = editor.getElementByKey(key)

        if (domElement !== null) {
          domElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
          })
        }
      })
    },
    [editor],
  )

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        CLICK_COMMAND,
        (event) => {
          const pbElem = editor.getElementByKey(nodeKey)

          if (event.target === pbElem) {
            if (!event.shiftKey) {
              clearSelection()
            }
            setSelected(!isSelected)
            return true
          }

          return false
        },
        COMMAND_PRIORITY_LOW,
      ),
      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
    )
  }, [clearSelection, editor, isSelected, nodeKey, onDelete, setSelected])

  useEffect(() => {
    const pbElem = editor.getElementByKey(nodeKey)
    if (pbElem !== null) {
      pbElem.className = isSelected ? styles.selected : ""
    }
  }, [editor, isSelected, nodeKey])

  return (
    <Box>
      {" "}
      <Box as="ul" className={styles.tableOfContents}>
        {tableOfContentsData.map(([key, text, tag], index) => (
          <Box
            as="li"
            key={key}
            onClick={() => scrollToNode(key, index, editor)}
            role="button"
            className={indent(tag)}
            tabIndex={0}
          >
            {("" + text).length > 100 ? text.substring(0, 100) + "..." : text}
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export class TableOfContentNode extends DecoratorNode {
  __tableOfContentsData
  static getType() {
    return "table-of-content"
  }

  static clone(node) {
    return new TableOfContentNode(node.__tableOfContentsData, node.__key)
  }

  static importJSON(serializedNode) {
    return $createTableOfContentNode(serializedNode.tableOfContentsData)
  }

  static importDOM() {
    return {
      span: (domNode) => {
        const tp = domNode.getAttribute("type")
        if (tp !== this.getType()) return null

        return {
          conversion: convertTableOfContentElement,
          priority: COMMAND_PRIORITY_HIGH,
        }
      },
    }
  }

  constructor(tableOfContentsData, key) {
    super(key)
    this.__tableOfContentsData = tableOfContentsData
  }

  exportJSON() {
    const tableOfContentsData = this.__tableOfContentsData
    return {
      type: this.getType(),
      ...(tableOfContentsData ? { tableOfContentsData } : {}),
      version: 1,
    }
  }

  createDOM() {
    const el = document.createElement("span")
    el.setAttribute("type", this.getType())
    return el
  }

  getTextContent() {
    return "\n"
  }

  isInline() {
    return false
  }

  updateDOM() {
    return false
  }

  decorate() {
    return (
      <TableOfContentComponent
        nodeKey={this.__key}
        tableOfContentsData={this.__tableOfContentsData}
      />
    )
  }
}

function convertTableOfContentElement() {
  return { node: $createTableOfContentNode() }
}

export function $createTableOfContentNode(currentTableOfContents) {
  return new TableOfContentNode(currentTableOfContents)
}

export function $isTableOfContentNode(node) {
  return node instanceof TableOfContentNode
}
