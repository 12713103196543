import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { apiClient, PageBlockContent, Spinner, useAppContext, ErrorPage } from "@dit/core-frontend"

import { API_ENDPOINTS, ROUTES } from "@constants"
import { useZoneContext } from "../ZoneContext"
import { PAGE_STATUSES } from "@utils/pages"

const PageContext = createContext({
  page: null,
  updatePage: () => {},
  statusList: [],
  // comments
  commentList: [],
  getComments: () => {},
  isHomePage: false,
})

const usePageContext = () => {
  return useContext(PageContext)
}

const PageContextProvider = function ({
  children,
  pageSlug,
  isHomePage = false,
  withoutRedirect = false,
}) {
  const { showAlert } = useAppContext()
  const { updatePage: updateZonePages } = useZoneContext()
  const [isLoading, setIsLoading] = useState(true)
  const [isUpdateLoading, setIsUpdateLoading] = useState(false)
  const [page, setPage] = useState(null)
  const [statusList, setStatusList] = useState([])
  const [commentList, setCommentList] = useState([])

  const navigate = useNavigate()
  const location = useLocation()

  const getPage = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get(`${API_ENDPOINTS.page}/${pageSlug}`)
      const pageData = data.data

      setPage(pageData)
      if (!withoutRedirect && pageSlug !== pageData.slug) {
        const [start, end] = location.pathname.split(pageSlug)
        navigate(`${start}${pageData.slug}${end}${location.search}`, { replace: true })
        return
      }
    } catch (e) {
      console.error(e)
    }

    if (!statusList.length) {
      try {
        const { data } = await apiClient.get(API_ENDPOINTS.pageStatusList)
        setStatusList(data?.data?.itemsList || [])
      } catch (e) {
        console.error(e)
        showAlert("Не удалось получить статусы")
      }
    }

    setIsLoading(false)
  }, [pageSlug, withoutRedirect, location.pathname, navigate, showAlert])

  // Comments
  const getComments = useCallback(
    async (pageId) => {
      try {
        const { data } = await apiClient.get(API_ENDPOINTS.commentList, {
          params: {
            epp: 1000,
            pageId: pageId,
            sortDirection: "desc",
          },
        })
        setCommentList(data?.data?.itemsList || [])
      } catch (err) {
        showAlert("Произошла ошибка при загрузке комментариев")
      }
    },
    [setCommentList],
  )

  useEffect(() => {
    setIsLoading(true)
    void getPage()

    return () => {
      setPage(null)
    }
  }, [getPage, pageSlug])

  const updatePage = useCallback(
    async (values, withRedirect = true, callback = null) => {
      setIsUpdateLoading(true)
      const version = values.status?.slug === PAGE_STATUSES.DRAFT ? "1" : values.version
      const status = values.status || statusList[0]
      const requestData = {
        zoneId: values.zone.id,
        title: values.title,
        text: values.text,
        plainText: values.plainText,
        statusId: status.id,
        parentId: values.parent?.id,
      }

      if (callback === null) {
        requestData.version = version
      }

      try {
        await apiClient.patch(`${API_ENDPOINTS.page}/${page.id}`, requestData)
        updateZonePages(page.id, values)

        if (callback) {
          callback()
        }

        if (withRedirect) {
          setTimeout(() => {
            navigate(
              isHomePage
                ? `${ROUTES.ZONE}/${values.zone.slug}`
                : `${ROUTES.ZONE}/${values.zone.slug}/pages/${page.id}`,
            )
          }, 100)
        } else {
          setPage((prevValue) => ({
            ...prevValue,
            zone: values.zone,
            title: values.title,
            text: values.text,
            plainText: values.plainText,
            parentId: values.parent?.id,
            status,
            version,
          }))
        }
      } catch (err) {
        console.error(err)
        showAlert(err?.data?.errors?.[0]?.message)
      } finally {
        setIsUpdateLoading(false)
      }
    },
    [navigate, page?.id, showAlert, statusList, updateZonePages],
  )

  return (
    <PageContext.Provider
      value={{
        page,
        setPage,
        updatePage,
        statusList,
        isUpdateLoading,
        // comments
        commentList,
        getComments,
        isHomePage,
      }}
    >
      {isLoading ? (
        <PageBlockContent ition="relative" minHeight="100px">
          <Spinner bg={["gray-05", "white"]} space="sp-16" wrapper="div" />
        </PageBlockContent>
      ) : (
        // Домашняя страница должна отрисовываться даже при ошибке
        <>{page ? <>{children}</> : <ErrorPage mainLink={ROUTES.HOME} />}</>
      )}
    </PageContext.Provider>
  )
}

export { PageContext, PageContextProvider, usePageContext }
