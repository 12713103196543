import React, { useCallback, useMemo, useState } from "react"
import {
  DataListWrapper,
  PageBlockContent,
  PageBlockHeading,
  PageBlockRow,
  PageBlockRowSection,
  PageBlockWrapper,
  Search,
  useAppContext,
} from "@dit/core-frontend"
import { Box, Text, Inline, Tab, TabList, TabPanel, useTabState, Stack } from "@mos-cat/ds"
import { PagesBlock } from "./PagesBlock"

import tabsStyles from "@assets/styles/tabs.module.css"
import { FeedBlock } from "./FeedBlock"

const DocumentsBlock = () => {
  const [search, setSearch] = useState("")
  const { user } = useAppContext()
  const tabState = useTabState({ selectedId: "my-works" })

  const tabsConfig = useMemo(
    () => [
      {
        id: "my-works",
        label: "Моя работа",
        // params: { users: [user.id] },
      },
      { id: "updates", label: "Обновления", params: { sortBy: "modifiedAt" }, showUpdater: true },
      // { id: "mentioned", label: "Упомянули меня" },
      // { id: "favorites", label: "Избранное", params: { favorites: true } },
      // { id: "subscriptions", label: "Мои подписки", params: { subscription: true } },
      { id: "feed", label: "Лента активности", Component: FeedBlock },
    ],
    [],
  )

  const [tabsCount, setTabsCount] = useState([].fill(0, 0, tabsConfig.length))

  const handleSetTabsCount = (index, count) => {
    setTabsCount((prev) => {
      const next = [...prev]
      next[index] = count
      return next
    })
  }

  const handleChangeSearch = useCallback((value) => {
    setSearch(value)
  }, [])

  return (
    <PageBlockWrapper>
      <PageBlockRow place="middle">
        <PageBlockRowSection
          gridGap={["10px", "20px"]}
          justifyContent={["space-between", "flex-start"]}
          flexWrap={["wrap", "nowrap"]}
          width={["100%", "auto"]}
        >
          <PageBlockHeading>Мои документы</PageBlockHeading>
          <Box width={["100%", "auto"]}>
            <Search
              onSearch={handleChangeSearch}
              placeholder="Поиск документа"
              fullWidth={["100%", "300px"]}
              width={["100%", "180px"]}
            />
          </Box>
        </PageBlockRowSection>
      </PageBlockRow>
      <PageBlockContent as={Stack} maxWidth="1280px" space="20px">
        <Box display="flex" justifyContent="space-between" borderBottom="2px solid var(--gray-10)">
          <Box className={tabsStyles.tabsListWrapper}>
            <TabList as={Inline} {...tabState} space="20px" className={tabsStyles.tabsList}>
              {tabsConfig.map((tab, index) => (
                <Tab
                  key={`body-tab-${tab.id}`}
                  className={tabsStyles.tab}
                  {...tabState}
                  id={tab.id}
                  kind="tab-2"
                  vertSize="size-1"
                  borderRadius="0"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gridGap="5px"
                  >
                    {tab.label}

                    {tabsCount[index] > 0 && (
                      <Text as={Box} className={tabsStyles.tabsBadge}>
                        {tabsCount[index]}
                      </Text>
                    )}
                  </Box>
                </Tab>
              ))}
            </TabList>
          </Box>
        </Box>
        <Stack space={0} position="relative" minHeight="30px">
          {tabsConfig.map((tab, index) => (
            <TabPanel key={`pages-tabs-${tab.id}`} {...tabState}>
              <DataListWrapper>
                {tab.Component ? (
                  <tab.Component setCount={(count) => handleSetTabsCount(index, count)} />
                ) : (
                  <PagesBlock
                    params={{ ...tab.params, search }}
                    isVisible={tabState.currentId === tab.id}
                    setCount={(count) => handleSetTabsCount(index, count)}
                    showUpdater={tab.showUpdater}
                  />
                )}
              </DataListWrapper>
            </TabPanel>
          ))}
        </Stack>
      </PageBlockContent>
    </PageBlockWrapper>
  )
}

export default DocumentsBlock
