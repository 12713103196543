import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { useEffect } from "react"
import { $isHeadingNode } from "@lexical/rich-text"
import { $getRoot, COMMAND_PRIORITY_EDITOR, createCommand } from "lexical"
import {
  $createTableOfContentNode,
  $isTableOfContentNode,
  TableOfContentNode,
} from "../../nodes/TableOfContentNode"

import { $insertFirst } from "@lexical/utils"
import { useAppContext } from "@dit/core-frontend"

export const INSERT_TABLE_OF_CONTENT = createCommand()

const TableOfContentPlugin = () => {
  const [editor] = useLexicalComposerContext()
  const { showAlert } = useAppContext()

  useEffect(() => {
    if (!editor.hasNodes([TableOfContentNode])) {
      throw new Error("FigmaPlugin: FigmaNode not registered on editor")
    }
    let currentTableOfContents = []
    let tableContentNode = null
    editor.getEditorState().read(() => {
      const root = $getRoot()
      const rootChildren = root.getChildren()

      for (const child of rootChildren) {
        if ($isHeadingNode(child)) {
          currentTableOfContents.push([child.getKey(), child.getTextContent(), child.getTag()])
        }
        if ($isTableOfContentNode(child)) {
          tableContentNode = child
        }
      }
      if (tableContentNode) {
        editor.update(() => {
          tableContentNode.remove()
          const nodesMap = editor._editorState._nodeMap
          const [first] = nodesMap.values()

          const tableOfContentNode = $createTableOfContentNode(currentTableOfContents)
          $insertFirst(first, tableOfContentNode)
        })
      }
    })

    return editor.registerCommand(
      INSERT_TABLE_OF_CONTENT,
      () => {
        let currentTableOfContents = []
        const root = $getRoot()
        const rootChildren = root.getChildren()

        for (const child of rootChildren) {
          if ($isTableOfContentNode(child)) {
            child.remove()
          }
          if ($isHeadingNode(child)) {
            currentTableOfContents.push([child.getKey(), child.getTextContent(), child.getTag()])
          }
        }

        const nodesMap = editor._editorState._nodeMap
        const [first] = nodesMap.values()
        if (currentTableOfContents.length) {
          const tableOfContentNode = $createTableOfContentNode(currentTableOfContents)

          $insertFirst(first, tableOfContentNode)
          return true
        } else {
          showAlert("Для формирования оглавления требуются заголовки", { type: "error" })
        }
      },
      COMMAND_PRIORITY_EDITOR,
    )
  }, [editor])

  return null
}

export default TableOfContentPlugin
