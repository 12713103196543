import React from "react"
import { Tooltip, getFormattedRecentDate } from "@dit/core-frontend"
import { Box, Text } from "@mos-cat/ds"
import CommentContent from "./CommentContent"

import styles from "./styles.module.css"
import { Person } from "@src/components/general"
import { DateTime } from "luxon"
import { ApproveCommentIcon, DeleteCommentIcon } from "../../theme/editorIcons"
import { useEditorCommentsContext } from "."

export const CommentsPanelListComment = ({ markNodeMap, activeID, comment, clickComment }) => {
  const { approveComment, deleteComment } = useEditorCommentsContext()
  return (
    <Box
      onClick={() => {
        clickComment(comment.textBlockId)
      }}
      className={`${styles.CommentPlugin_CommentsPanel_List_Comment} ${
        markNodeMap.has(comment.textBlockId) ? styles.interactive : ""
      } ${activeID !== comment.textBlockId ? "" : styles.active}`}
      style={{
        borderLeft: `5px solid ${comment.approved ? "#006633" : "#FFAA00"}`,
      }}
    >
      <Box className={styles.CommentPlugin_CommentsPanel_List_Details}>
        <Box className={styles.CommentPlugin_CommentsPanel_List_Comment_Author}>
          <Person
            person={comment.user}
            gridGap="5px"
            fontSize="fs-14"
            color="gray-80"
            backgroundColor="inherit"
          />

          <Box display="flex" alignItems="center" gridGap="7px" styles={{ cursor: "pointer" }}>
            {!comment.approved && (
              <Tooltip content="Закрыть комментарий">
                <Box onClick={() => approveComment(comment.id)}>
                  <ApproveCommentIcon />
                </Box>
              </Tooltip>
            )}

            {/* <Box>
              <PencilIcon color="currentColor" size={21} />
            </Box> */}
            <Tooltip content="Удалить комментарий">
              <Box
                styles={{ cursor: "pointer" }}
                onClick={() => deleteComment(comment.id, comment.textBlockId, comment?.replyTo?.id)}
              >
                <DeleteCommentIcon />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Text fontSize="fs-14" color="gray-40">
          {getFormattedRecentDate(DateTime.fromISO(comment.createdAt))}
        </Text>
      </Box>
      <Text className={comment.deleted ? styles.CommentPlugin_CommentsPanel_DeletedComment : ""}>
        <CommentContent text={comment.text} />
      </Text>
    </Box>
  )
}
