import React, { useState } from "react"
import { Routes, Route, Navigate, useParams } from "react-router-dom"

import { PageBlockWrapper, PageBlockRow, PageBlockRowSection } from "@dit/core-frontend"
import { ZoneContextProvider, ZoneContext } from "@components/contexts/ZoneContext"
import { ZoneBreadcrumbs } from "@components/zones"
import PagesPage from "./Pages"
import PagePage from "./Page"
import SettingsPage from "./Settings"
import ViewPage from "./View"
import TemplatesPage from "./PageTemplates"

import { ZONE_SECTION_LINKS } from "@utils/zones"

const RedirectToFirstSection = () => <Navigate to={Object.values(ZONE_SECTION_LINKS)[0]} replace />

export const ZonePage = () => {
  const [additionalBreadcrumbs, setAdditionalBreadcrumbs] = useState([])
  const [additionalTopSection, setAdditionalTopSection] = useState(null)
  const { zoneSlug } = useParams()

  return (
    <ZoneContextProvider zoneSlug={zoneSlug}>
      <ZoneContext.Consumer>
        {({ zone }) => (
          <PageBlockWrapper flex="2" position="relative">
            <PageBlockRow place="top">
              <PageBlockRowSection maxWidth="100%">
                <ZoneBreadcrumbs zone={zone} additionalCrumbs={additionalBreadcrumbs} />
              </PageBlockRowSection>
              {!!additionalTopSection && (
                <PageBlockRowSection maxWidth="100%">{additionalTopSection}</PageBlockRowSection>
              )}
            </PageBlockRow>
            <Routes>
              <Route path="/" element={<RedirectToFirstSection />} />
              <Route
                path={`/${ZONE_SECTION_LINKS.HOMEPAGE}/*`}
                element={<ViewPage setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />}
              />
              <Route
                path={`/${ZONE_SECTION_LINKS.PAGES}`}
                element={<PagesPage setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />}
              />
              <Route
                path={`/${ZONE_SECTION_LINKS.TEMPLATES}/*`}
                element={<TemplatesPage setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />}
              />
              <Route
                path={`/${ZONE_SECTION_LINKS.PAGES}/:pageSlug/*`}
                element={
                  <PagePage
                    setAdditionalBreadcrumbs={setAdditionalBreadcrumbs}
                    setAdditionalTopSection={setAdditionalTopSection}
                  />
                }
              />
              <Route
                path={`/${ZONE_SECTION_LINKS.SETTINGS}/*`}
                element={<SettingsPage setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />}
              />
            </Routes>
          </PageBlockWrapper>
        )}
      </ZoneContext.Consumer>
    </ZoneContextProvider>
  )
}
