import { createStorageVariable } from "@dit/core-frontend"

export const VIEW_TYPES = {
  LIST: "list",
  CARDS: "cards",
}

export const STORE_VARIABLES = {
  pagesView: createStorageVariable("pages-view", VIEW_TYPES.LIST),
  zonesView: createStorageVariable("zones-view", VIEW_TYPES.LIST),
  zonePagesView: createStorageVariable("zone-pages-view", VIEW_TYPES.LIST),
}
