import React from "react"
import * as yup from "yup"
import { useNavigate } from "react-router-dom"

import { Field, Form } from "react-final-form"
import { Box, Button } from "@mos-cat/ds"
import {
  FormInput,
  Message,
  apiClient,
  useAppContext,
  yupTitleSchema,
  yupSlugSchema,
  defaultInputHelperText,
  createValidation,
  runValidation,
  isFormUnready,
  createFormError,
  AvatarPicker,
} from "@dit/core-frontend"
import { ZoneAuthor } from "@components/zones"

import { API_ENDPOINTS, ROUTES } from "@constants"
import { ZONE_SECTION_LINKS, ZONE_SETTINGS_SECTIONS } from "@utils/zones"
import { useZoneContext } from "../contexts/ZoneContext"

const zonesValidation = createValidation({
  schema: yup.object().shape({
    title: yupTitleSchema(),
    slug: yupSlugSchema(),
    description: yup.string().max(65536, "Максимальное допустимое количество символов - 65 536"),
    iconId: yup.string(),
  }),
})

export const ZoneForm = ({ zone = {}, isCopying = false, onClose }) => {
  const { appSettings } = useAppContext()
  const { checkZonePermission } = useZoneContext()
  const navigate = useNavigate()
  const isReadOnly = zone?.id
    ? !!zone.archive || !(checkZonePermission ? checkZonePermission("ZONE_EDIT") : true)
    : false

  const MAX_FILE_SIZE = appSettings?.sso_max_file_weight_mb?.value || 5
  const isCopyButtonDisabled = !(isCopying && zone.slug?.length < 9)

  return (
    <Form
      initialValues={{
        title: (isCopying ? `Копия ${zone.title}` : zone.title) || "",
        slug: (isCopying ? `copy${zone.slug}` : zone.slug) || "",
        description: zone.description || "",
        icon: zone?.icon || null,
      }}
      validate={runValidation(zonesValidation)}
      onSubmit={async (values) => {
        try {
          const requestData = {
            title: values.title || "",
            slug: values.slug || "",
            description: values.description || "",
            iconId: values?.icon?.id || null,
          }

          let method = "post"
          let fullRequestUrl = API_ENDPOINTS.zone

          if (isCopying) {
            fullRequestUrl = `${API_ENDPOINTS.zone}/${zone.id}/copy`
          } else if (zone.id) {
            method = "patch"
            fullRequestUrl += `/${zone.id}`
          }

          const { data } = await apiClient[method](fullRequestUrl, requestData)
          if (data.success) {
            onClose?.()
            if (!isCopying && zone.id) {
              const zoneURL = `${ROUTES.ZONE}/${zone.id}`
              const setURL = `${ZONE_SECTION_LINKS.SETTINGS}/${ZONE_SETTINGS_SECTIONS.INTELLIGENCE}`
              navigate(`${zoneURL}/${setURL}`)
            } else {
              navigate(`${ROUTES.ZONE}/${data.data?.id || zone.id}`)
            }
          }
        } catch (err) {
          if (err?.data?.errors?.length) {
            return createFormError(err.data.errors ?? [], values)
          }
          return createFormError()
        }
      }}
      render={(form) => {
        return (
          <form onSubmit={form.handleSubmit}>
            <Message
              type="error"
              isAlwaysVisible
              isVisible={!form.dirtySinceLastSubmit}
              text={form.submitError}
            />

            <FormInput
              wrap={false}
              label="Название раздела"
              inputName="title"
              required
              formError
              inputProps={{ disabled: isReadOnly }}
              helper={() => defaultInputHelperText.title}
            />
            <FormInput
              wrap={false}
              label="Код раздела"
              inputName="slug"
              required
              formError
              inputProps={{ disabled: isReadOnly }}
              helper={() => defaultInputHelperText.slug}
              onChange={(event) => form.form.change("slug", event.target.value.toLowerCase())}
            />
            <FormInput
              wrap={false}
              as="textarea"
              inputName="description"
              label="Описание"
              formError
              inputProps={{ disabled: isReadOnly }}
              helper={() => {
                return "Максимум 65 536 символов"
              }}
            />
            <Field name="icon">
              {() => (
                <AvatarPicker
                  label="Логотип раздела"
                  value={form.values.icon}
                  url={`${API_ENDPOINTS.pageTemplatesIcons}?type=zone`}
                  onSelect={(image, hide) => {
                    form.form.change("icon", image)
                    form.form.blur("icon")
                    hide()
                  }}
                  onClear={() => {
                    form.form.change("icon", null)
                    form.form.blur("icon")
                  }}
                />
              )}
            </Field>

            {!isCopying && !!zone.user && (
              <ZoneAuthor
                title="Автор раздела"
                user={zone.user}
                stackProps={{
                  mt: "1rem",
                }}
              />
            )}

            <Box display="flex" justifyContent="start" gridGap="16px" mt="40px">
              <Button
                kind="primary"
                type="submit"
                disabled={isCopyButtonDisabled && (isFormUnready(form) || isReadOnly)}
                loading={form.submitting}
                onClick={form.handleSubmit}
              >
                {!isCopying && zone.id ? "Обновить" : "Сохранить"}
              </Button>
              <Button kind="secondary" onClick={() => onClose()} type="reset">
                Отменить
              </Button>
            </Box>
          </form>
        )
      }}
    />
  )
}
