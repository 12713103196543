import React, { Fragment, memo, useState } from "react"

import { Box, Stack, Text } from "@mos-cat/ds"

import {
  Button,
  ClickableField,
  SearchIcon,
  SortDown,
  SortUp,
  TextareaAutosize,
} from "@dit/core-frontend"
import styles from "./tableFilterBox.module.css"
import { FilterEditorTableIcon } from "./TablePreviewNodeIcons"
import { MiniCheck, MiniClose } from "@mos-cat/ds-icons"

const FiltersContent = ({
  handleSort,
  handleFilter,
  accessor,
  filtersList,
  sortParams,
  setSortParams,
  filtersParams,
  setFiltersParams,
  setIsLoading,
  hide,
}) => {
  const onSortClick = (order) => {
    handleSort(accessor, order)
    hide()
  }

  const [searchTerm, setSearchTerm] = useState("")
  const uniqueFiltersList = [...new Set(filtersList)]
  const handleSearch = (e) => {
    setSearchTerm(e.target.value)
  }

  const filteredList = uniqueFiltersList.filter((item) =>
    item.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const deleteFiltersByAccessor = () => {
    setIsLoading(true)
    const newFiltersParams = { ...filtersParams }
    delete newFiltersParams[accessor]
    setFiltersParams(newFiltersParams)
    hide()
  }

  const deleteSortByAccessor = () => {
    const newSortParams = { ...sortParams }
    delete newSortParams[accessor]
    setSortParams(newSortParams)
    deleteFiltersByAccessor()
    hide()
  }
  return (
    <Box padding="0" margin="0" width="270px" height="322px" position="relative">
      <Button styles={{ marginLeft: "-20px" }} onClick={() => onSortClick("asc")}>
        {" "}
        <Box
          display="flex"
          alignItems="center"
          gridGap="5px"
          color={sortParams[accessor] === "asc" ? "rgba(0, 68, 204, 1)" : "inherit"}
        >
          <SortDown
            size={20}
            color={sortParams[accessor] === "asc" ? "rgba(0, 68, 204, 1)" : "rgba(51, 51, 51, 1)"}
          />
          <Text fontSize="fs-16" fontWeight="400">
            {"Сортировать А > Я"}
          </Text>
        </Box>
      </Button>
      <Button styles={{ marginLeft: "-20px" }} onClick={() => onSortClick("desc")}>
        <Box
          display="flex"
          alignItems="center"
          gridGap="5px"
          color={sortParams[accessor] === "desc" ? "rgba(0, 68, 204, 1)" : "inherit"}
        >
          {" "}
          <SortUp
            size={20}
            color={sortParams[accessor] === "desc" ? "rgba(0, 68, 204, 1)" : "rgba(51, 51, 51, 1)"}
          />
          <Text fontSize="fs-16" fontWeight="400">
            {"Сортировать Я > А"}
          </Text>
        </Box>
      </Button>
      <Box height="1px" mx="-15px" backgroundColor="gray-20" />
      <Box display="flex" gridGap="10px" margin="10px 0px">
        <Box
          width="150px"
          display="flex"
          padding="5px 5px"
          border="1px solid rgba(196, 200, 208, 1)"
          gridGap="5px"
          alignItems="center"
          styles={{ cursor: "pointer" }}
          onClick={deleteFiltersByAccessor}
        >
          {" "}
          <MiniCheck width={21} height={21} /> <Text fontSize="fs-14">Выбрать все</Text>
        </Box>

        <Box
          width="150px"
          display="flex"
          padding="5px 13px"
          border="1px solid rgba(196, 200, 208, 1)"
          gridGap="5px"
          alignItems="center"
          styles={{ cursor: "pointer" }}
          onClick={deleteSortByAccessor}
        >
          {" "}
          <MiniClose width={21} height={21} />
          <Text fontSize="fs-14"> Сбросить </Text>
        </Box>
      </Box>
      <Box height="1px" mx="-15px" backgroundColor="gray-20" />
      <Stack space="0" as="label">
        <Text
          as={Box}
          minHeight="40px"
          display="flex"
          alignItems="center"
          gridGap="5px"
          fontSize="fs-14"
        >
          <SearchIcon />
          <TextareaAutosize
            placeholder="Поиск значения"
            enterAllowed={false}
            rows={1}
            maxRows={3}
            onChange={handleSearch}
          />
        </Text>
      </Stack>
      <Box height="1px" mx="-15px" backgroundColor="gray-20" />
      <Stack
        position="relative"
        minHeight="30px"
        space="10px"
        maxHeight="179px"
        overflow="auto"
        px="10px"
        pt="10px"
        pb="9px"
        m="0 -25px 0px -10px"
      >
        <Stack space="10px" overflowY="auto" height="210px" overflowX="hidden">
          {filteredList.length ? (
            filteredList.map((item, index) => {
              const isChecked = filtersParams[accessor] && filtersParams[accessor].includes(item)
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  gridGap="5px"
                  onClick={() => {
                    handleFilter(accessor, item)
                    hide()
                  }}
                  key={`task-list-entity-${index}`}
                  color={isChecked ? "rgba(105, 108, 113, 1)" : "rgba(0, 68, 204, 1)"}
                >
                  <MiniCheck />
                  {item.length === 0 ? (
                    <Text>Пустое значение</Text>
                  ) : (
                    <Text>{item.length >= 20 ? `${item.slice(0, 20)}...` : item}</Text>
                  )}
                </Box>
              )
            })
          ) : (
            <Box>Вариантов не найдено</Box>
          )}
        </Stack>
      </Stack>
    </Box>
  )
}
export const TableFilterBox = memo(
  ({
    isReadonly = false,
    handleSort,
    handleFilter,
    accessor,
    filtersList,

    sortParams,
    setSortParams,
    filtersParams,
    setFiltersParams,
    setIsLoading,
    ...boxProps
  }) => {
    return (
      <ClickableField
        as={Box}
        display="flex"
        alignItems="center"
        justifyContent="center"
        borderRadius="4px"
        width="32px"
        height="32px"
        color="inherit"
        fontSize="inherit"
        disabled={isReadonly}
        margin="0"
        padding="0"
        popoverOptions={{ placement: "right-start" }}
        popoverProps={{
          className: styles.filterBoxContainer,
        }}
        {...boxProps}
        PopoverComp={
          <FiltersContent
            filtersList={filtersList}
            handleSort={handleSort}
            handleFilter={handleFilter}
            accessor={accessor}
            sortParams={sortParams}
            setSortParams={setSortParams}
            filtersParams={filtersParams}
            setFiltersParams={setFiltersParams}
            setIsLoading={setIsLoading}
          />
        }
      >
        {() => (
          <>
            <FilterEditorTableIcon
              color={
                filtersParams[accessor] || sortParams[accessor] ? "rgba(0, 68, 204, 1)" : "#696C71"
              }
            />
          </>
        )}
      </ClickableField>
    )
  },
)

TableFilterBox.displayName = "TableFilterBoxComp"
