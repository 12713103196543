import React from "react"
import ReactDOM from "react-dom"

import { attachReduxDevTools } from "@effector/redux-devtools-adapter"
import { apiClient, getCookieMiddleware } from "@dit/core-frontend"
import { Provider } from "@mos-cat/ds-theme"
import { MainLayout } from "@layouts"

import "@mos-cat/ds-theme/reset.css"
import "sanitize.css"
import "@dit/core-frontend/dist/theme.css"
import "@assets/styles/diff.css"

apiClient.interceptors.request.use(
  function (config) {
    return getCookieMiddleware(config, process.env.REACT_APP_SECURITY_COOKIE_NAME)
  },
  function (error) {
    return Promise.reject(error)
  },
)
attachReduxDevTools()

ReactDOM.render(
  <React.StrictMode>
    <Provider>
      <MainLayout />
    </Provider>
  </React.StrictMode>,
  document.getElementById("app-root"),
)
