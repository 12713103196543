import { Button } from "@mos-cat/ds"
import { grayButtonProps } from "@utils/buttons"
import { CardsListIcon, List, PageBlockRowSection } from "@dit/core-frontend"
import React from "react"
import { VIEW_TYPES } from "@constants"

export const ViewSwitcher = ({ value, onChange }) => {
  const isListView = value === VIEW_TYPES.LIST

  return (
    <PageBlockRowSection>
      <Button
        {...grayButtonProps}
        backgroundColor={`${isListView ? "var(--blue-70)" : "var(--gray-05)"} !important`}
        border="1px solid"
        borderColor={isListView ? "var(--blue-70)" : "var(--gray-30)"}
        onClick={() => onChange(VIEW_TYPES.LIST)}
      >
        <List color={isListView ? "var(--white)" : "#0E0E0F"} />
      </Button>
      <Button
        {...grayButtonProps}
        backgroundColor={`${!isListView ? "var(--blue-70)" : "var(--gray-05)"} !important`}
        border="1px solid"
        borderColor={!isListView ? "var(--blue-70)" : "var(--gray-30)"}
        onClick={() => onChange(VIEW_TYPES.CARDS)}
      >
        <CardsListIcon color={!isListView ? "var(--white)" : "#0E0E0F"} />
      </Button>
    </PageBlockRowSection>
  )
}
