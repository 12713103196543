import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import {
  apiClient,
  SideContainer,
  MainContainer,
  Spinner,
  ErrorPage,
  useAppContext,
} from "@dit/core-frontend"
import { API_ENDPOINTS, ROUTES } from "@constants"
import { sortPagesByTitle } from "@src/utils/pages"
import { useLocation, useNavigate } from "react-router-dom"
import { useUnit } from "effector-react"

import {
  checkPermission,
  loadObjectIdPermissions,
  $objectIdPermissionsLoading,
  $objectIdPermissionsStore,
} from "@store/permissionModel"
import { ZoneSideBar } from "@components/zones/Sidebar"

import globalStyles from "@src/global.module.css"

const Error = ({ code }) => {
  switch (code) {
    case 403:
      return (
        <ErrorPage
          code={code}
          title="Доступ запрещен"
          text="У вас нет прав доступа к этой странице"
        />
      )
    default:
      return <ErrorPage code={code} />
  }
}

const ZoneContext = createContext({
  zone: null,
  isTextCommentsShow: false,
  pages: [],
  addPage: () => {},
  updatePage: () => {},
  deletePage: () => {},
  togleTextCommentsShow: () => {},
  setIsTextCommentsShow: () => {},
})

const useZoneContext = () => {
  return useContext(ZoneContext)
}

const ZoneContextProvider = function ({ children, zoneSlug, withoutRedirect = false }) {
  const { showAlert } = useAppContext()
  const [isLoading, setIsLoading] = useState(true)
  const [zone, setZone] = useState(null)
  const [zonePages, setZonePages] = useState([])
  const [homePage, setHomePage] = useState(null)
  const [errorCode, setErrorCode] = useState(null)
  const loadPermissions = useUnit(loadObjectIdPermissions)
  const permissionsLoading = useUnit($objectIdPermissionsLoading)
  const zonesPermissions = useUnit($objectIdPermissionsStore)
  const permissions = zonesPermissions[zone?.id]
  const [isTextCommentsShow, setIsTextCommentsShow] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const checkZonePermission = useCallback(
    (identifier) => {
      return permissions?.includes(identifier) || checkPermission(identifier)
    },
    [permissions],
  )

  const togleTextCommentsShow = () => {
    setIsTextCommentsShow((prev) => !prev)
  }
  useEffect(() => {
    setIsTextCommentsShow(false)
  }, [location.pathname])

  const getPages = useCallback(async (zoneId) => {
    try {
      const { data } = await apiClient.get(API_ENDPOINTS.pageList, {
        params: { zones: [zoneId], epp: 1000 },
      })
      setZonePages(sortPagesByTitle(data?.data?.itemsList) || [])
    } catch (e) {
      console.error(e)
    }
  }, [])

  const getHomePage = useCallback(async (zoneId, PageId) => {
    try {
      if (PageId === null) {
        setHomePage(null)
        return
      }
      const { data } = await apiClient.get(API_ENDPOINTS.pageList, {
        params: { zones: [zoneId], ids: [PageId] },
      })
      setHomePage(data?.data?.itemsList[0] || null)
    } catch (e) {
      console.error(e)
    }
  }, [])

  const getZone = useCallback(async () => {
    try {
      const { data } = await apiClient.get(`${API_ENDPOINTS.zone}/${zoneSlug}`)
      const zoneData = data.data

      setZone(zoneData)
      if (!withoutRedirect && zoneSlug !== zoneData.slug) {
        const [start, end] = location.pathname.split(zoneSlug)
        navigate(`${start}${zoneData.slug}${end}${location.search}`, { replace: true })
      }
      void loadPermissions(zoneData.id)
      await getPages(zoneData.id)
      if (zoneData?.homePage) {
        void getHomePage(zoneData.id, zoneData.homePage)
      }
    } catch (e) {
      setErrorCode(e.status)
    }
    setIsLoading(false)
    // eslint-disable-next-line
  }, [zoneSlug, withoutRedirect])

  const addPage = useCallback(
    (page) => {
      setZonePages((pages) => {
        if (pages.find((p) => p.id === page.id)) {
          return pages
        }
        return [...pages, page]
      })
    },
    [setZonePages],
  )

  const updatePage = useCallback(
    async (pageId, data, request = false) => {
      const copyZonePages = [...zonePages]

      const page = zonePages.find((page) => page.id === pageId)
      if (!page) {
        return
      }

      setZonePages((pages) => {
        return pages.map((page) => {
          if (page.id === pageId) {
            return { ...page, ...data }
          }
          return page
        })
      })

      if (request) {
        try {
          const { parent, ...restData } = data
          await apiClient.patch(`${API_ENDPOINTS.page}/${pageId}`, {
            zoneId: zone.id,
            statusId: page.status.id,
            title: page.title,
            text: page.text,
            plainText: page.plainText,
            version: page.version,
            parentId: parent?.id !== undefined ? parent?.id : page.parentId || page.parent?.id,
            ...restData,
          })
        } catch (err) {
          showAlert(err?.data?.errors?.[0]?.message || "Произошла ошибка при обновлении страницы")
          setZonePages(copyZonePages)
        }
      }
    },
    [setZonePages, zone, zonePages, showAlert],
  )

  const deletePage = useCallback(
    (pageId) => {
      const deleteChildren = (array, id) => {
        const children = array.filter((page) => page.parent?.id === id)
        if (!children.length) {
          return array
        }

        children.forEach((child) => {
          array = array.filter((page) => page.id !== child.id)
          array = deleteChildren(array, child.id)
        })

        return array
      }

      let copyZonePages = [...zonePages]
      copyZonePages = deleteChildren(copyZonePages, pageId)
      setZonePages(copyZonePages.filter((p) => p.id !== pageId))
    },
    [zonePages],
  )
  useEffect(() => {
    setIsLoading(true)
    void getZone()

    return () => {}
  }, [getZone])

  if (isLoading || permissionsLoading) {
    return <Spinner bg={["gray-05", "white"]} space="sp-16" wrapper="div" />
  }

  return (
    <ZoneContext.Provider
      value={{
        zone,
        setZone,
        zonePages,
        homePage,
        getZone,
        refetchPages: () => getPages(zone.id),
        getHomePage,
        addPage,
        updatePage,
        deletePage,
        isTextCommentsShow,
        togleTextCommentsShow,
        setIsTextCommentsShow,
        checkZonePermission,
      }}
    >
      {zone ? (
        <>
          <SideContainer className={globalStyles.sideContainer}>
            <ZoneSideBar />
          </SideContainer>
          <MainContainer>{children}</MainContainer>
        </>
      ) : (
        <Error code={errorCode} />
      )}
    </ZoneContext.Provider>
  )
}

export { ZoneContext, ZoneContextProvider, useZoneContext }
