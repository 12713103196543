import React, { useState } from "react"
import { useSearchParams } from "react-router-dom"

import {
  MainContainer,
  PageBlockWrapper,
  PageBlockRow,
  PageBlockRowSection,
  PageBlockHeading,
  PageBlockContent,
  Search,
  FiltersBox,
  getUrlSearchParams,
  useStorageVariable,
  useAppContext,
} from "@dit/core-frontend"
import { Box, Text } from "@mos-cat/ds"
import { Add } from "@mos-cat/ds-icons"
import { ZoneCreateTarget, ZonesList } from "@components/zones"
import useZonesList from "@components/zones/useZonesList"
import HubLink from "@components/general/HubLink"
import { ViewSwitcher } from "@components/ViewSwitcher"
import { STORE_VARIABLES } from "@constants"
import { Helmet } from "react-helmet"
import { checkPermission } from "@src/store/permissionModel"

export const ZonesPage = () => {
  const [view, setView] = useStorageVariable(STORE_VARIABLES.zonesView)
  const [searchParams] = useSearchParams()
  const { search } = getUrlSearchParams(searchParams)

  const [projectList, setProjectList] = useState([])
  const { isMobileView } = useAppContext()

  const { filtersList, defaultFilterParams, onChangeParams, params, onSearch } = useZonesList()

  const renderButton = (
    <ZoneCreateTarget>
      <Text
        as={Box}
        display="flex"
        alignItems="center"
        gridGap="5px"
        color="blue-60"
        fontSize="fs-14"
      >
        <Add /> Создать новый раздел
      </Text>
    </ZoneCreateTarget>
  )

  return (
    <>
      <Helmet title="Разделы · МосВики" />
      <MainContainer>
        <HubLink type="projects" items={projectList} />
        <PageBlockWrapper flex="2" space="20px">
          <PageBlockRow place="middle">
            <PageBlockRowSection
              gridGap={["10px", "20px"]}
              justifyContent={["space-between", "flex-start"]}
              flexWrap={["wrap", "nowrap"]}
              width={["100%", "auto"]}
            >
              <PageBlockHeading>Разделы</PageBlockHeading>
              {isMobileView && checkPermission("ZONE_CREATE") && renderButton}
              <Box width={["100%", "auto"]}>
                <Search
                  onSearch={onSearch}
                  defaultValue={search}
                  placeholder="Поиск раздела"
                  fullWidth={["100%", "300px"]}
                  width={["100%", "180px"]}
                />
              </Box>
            </PageBlockRowSection>
            {!isMobileView && checkPermission("ZONE_CREATE") && (
              <PageBlockRowSection>{renderButton}</PageBlockRowSection>
            )}
          </PageBlockRow>
          <PageBlockRow place="bottom">
            <PageBlockRowSection>
              <FiltersBox
                filteredList={filtersList}
                filterParams={params}
                defaultParams={defaultFilterParams}
                setFilterParams={onChangeParams}
                isResetAllFilters
                isQuery
              />
            </PageBlockRowSection>
            <ViewSwitcher value={view} onChange={setView} />
          </PageBlockRow>
          <PageBlockContent flex="2">
            <ZonesList isRowView={false} view={view} onUpdateList={setProjectList} />
          </PageBlockContent>
        </PageBlockWrapper>
      </MainContainer>
    </>
  )
}
