import { $applyNodeReplacement, DecoratorNode } from "lexical"
import React from "react"

import MentionComponent from "../../components/MentionComponent"

function convertElement(domNode) {
  const trigger = domNode.getAttribute("data-lexical-mention-trigger")
  const value = domNode.getAttribute("data-lexical-mention-value")
  let data = undefined
  const dataStr = domNode.getAttribute("data-lexical-mention-data")
  if (dataStr) {
    try {
      data = JSON.parse(dataStr)
    } catch (e) {
      console.warn("Failed to parse data attribute of mention node", e)
    }
  }
  if (trigger != null && value !== null) {
    const node = $createMentionNode(trigger, value, data)
    return { node }
  }
  return null
}

/**
 * This node is used to represent a mention used in the BeautifulMentionPlugin.
 */
export class MentionNode extends DecoratorNode {
  __trigger
  __value
  __data

  static getType() {
    return "mention"
  }

  static clone(node) {
    return new MentionNode(node.__trigger, node.__value, node.__data, node.__key)
  }
  // importJSON используется для распаковки после перезагрузки страницы или загрузке
  static importJSON(serializedNode) {
    return $createMentionNode(serializedNode.trigger, serializedNode.value, serializedNode.data)
  }

  exportDOM() {
    const element = document.createElement("span")
    element.setAttribute("data-lexical-mention", "true")
    element.setAttribute("data-lexical-mention-trigger", this.__trigger)
    element.setAttribute("data-lexical-mention-value", this.__value?.title)
    if (this.__data) {
      element.setAttribute("data-lexical-mention-data", JSON.stringify(this.__data))
    }
    element.textContent = this.getTextContent()
    return { element }
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute("data-lexical-mention")) {
          return null
        }
        return {
          conversion: convertElement,
          priority: 1,
        }
      },
    }
  }

  constructor(trigger, value, data, key) {
    super(key)
    this.__trigger = trigger
    this.__value = value?.title || value
    this.__data = data
  }

  exportJSON() {
    const data = this.__data
    return {
      trigger: this.__trigger,
      value: this.__value,
      ...(data ? { data } : {}),
      type: "mention",
      version: 1,
    }
  }

  createDOM(config) {
    const element = document.createElement("span")

    const theme = config.theme
    const className = theme.mention
    element.className = className
    return element
  }

  getTextContent() {
    return this.__value.title
  }

  updateDOM() {
    return false
  }

  getTrigger() {
    const self = this.getLatest()
    return self.__trigger
  }

  getValue() {
    const self = this.getLatest()
    return self.__value
  }

  setValue(value) {
    const self = this.getWritable()
    self.__value = value
  }

  getData() {
    const self = this.getLatest()
    return self.__data
  }

  setData(data) {
    const self = this.getWritable()
    self.__data = data
  }

  component() {
    return null
  }

  decorate(_editor, config) {
    const theme = config.theme.beautifulMentions || {}
    const entry = Object.entries(theme).find(([trigger]) =>
      new RegExp(trigger).test(this.__trigger),
    )
    const key = entry && entry[0]
    const value = entry && entry[1]
    const className = typeof value === "string" ? value : undefined
    const classNameFocused =
      className && typeof theme[key + "Focused"] === "string" ? theme[key + "Focused"] : undefined
    const themeValues = entry && typeof value !== "string" ? value : undefined
    return (
      <MentionComponent
        nodeKey={this.getKey()}
        trigger={this.getTrigger()}
        value={this.getValue()}
        data={this.getData()}
        className={className}
        classNameFocused={classNameFocused}
        themeValues={themeValues}
        // component={this.component()}
      />
    )
  }
}

export function $createMentionNode(trigger, value, data) {
  const mentionNode = new MentionNode(trigger, value, data)
  return $applyNodeReplacement(mentionNode)
}

export function $isMentionNode(node) {
  return node instanceof MentionNode
}
