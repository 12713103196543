import React from "react"

import { API_ENDPOINTS } from "@constants"
import { FilterItem, FiltersBox } from "@dit/core-frontend"

const filtersList = [
  {
    title: "Роль",
    endPoint: API_ENDPOINTS.rolesList,
    key: "roles",
    defaultText: "Все роли",
    component: FilterItem,
  },
]

export const TeamsFilter = ({ filterParams, setFilterParams, defaultBg }) => {
  return (
    <FiltersBox
      filteredList={filtersList}
      filterParams={filterParams}
      setFilterParams={setFilterParams}
      defaultBg="none"
    />
  )
}
