import React, { useState, useEffect, memo, useCallback } from "react"

import theme from "../../theme"

import HeadingItem from "./HeadingItem"

// import { orderBy } from "lodash"
import { Spinner } from "@dit/core-frontend"
import TableContent from "./TableContent"

function orderBy(array, iteratees, orders) {
  if (!Array.isArray(array)) {
    return []
  }

  if (!Array.isArray(iteratees)) {
    iteratees = [iteratees]
  }

  if (!Array.isArray(orders)) {
    orders = [orders]
  }

  const getPriority = (value) => {
    if (value === null || value === undefined || value === "") {
      return 5 // Пустые значения в конце
    }
    if (/^[а-яА-ЯёЁ]/.test(value)) {
      return 0 // Русский текст
    }
    if (/^[a-zA-Z]/.test(value)) {
      return 1 // Иностранные слова (английский)
    }
    if (/^\d/.test(value)) {
      return 2 // Цифры
    }
    if (/^[\u{1F600}-\u{1F64F}]/u.test(value)) {
      return 3 // Смайлики (емодзи)
    }
    return 4 // Всё остальное
  }

  return array.slice().sort((a, b) => {
    for (let i = 0; i < iteratees.length; i++) {
      const iteratee = iteratees[i]
      const order = orders[i] === "desc" ? -1 : 1

      const valueA = typeof iteratee === "function" ? iteratee(a) : a[iteratee]
      const valueB = typeof iteratee === "function" ? iteratee(b) : b[iteratee]

      const priorityA = getPriority(valueA)
      const priorityB = getPriority(valueB)

      if (priorityA !== priorityB) {
        return priorityA - priorityB // Сортируем по приоритету без учета asc/desc
      }

      if (valueA < valueB) {
        return -order
      }
      if (valueA > valueB) {
        return order
      }
    }
    return 0
  })
}

const transformedRowsForSorting = (rows = [], onlyText = false) => {
  if (!rows?.length) {
    return {}
  }
  if (onlyText) {
    return rows.reduce((acc, item) => {
      Object.keys(item).forEach((key) => {
        if (!acc[key]) {
          acc[key] = []
        }
        // Извлечение первого элемента массива значений, который является строкой
        acc[key].push(item[key][0])
      })
      return acc
    }, {})
  }
  return rows.map((item, index) => {
    const transformedItem = {}

    Object.keys(item).forEach((key) => {
      transformedItem[key] = item[key][0]
    })

    transformedItem.index = index

    return transformedItem
  })
}

const matchesFilter = (row, params) => {
  return Object.keys(params).some((key) => {
    return row[key] && params[key].includes(row[key][0])
  })
}

const TableComponent = memo(({ headingColumns, rows }) => {
  const [currentRows, setCurrentRows] = useState(rows)
  const [sortParams, setSortParams] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [filtersParams, setFiltersParams] = useState({})
  const [baseRowsData, setBaseRowsData] = useState({})

  const handleSort = useCallback((accessor, order = null) => {
    setIsLoading(true)
    setSortParams((prev) => {
      const newSortParams = { ...prev }
      if (newSortParams[accessor] && newSortParams[accessor] === order) {
        delete newSortParams[accessor]
      } else {
        newSortParams[accessor] = order
      }
      return newSortParams
    })
  }, [])

  const handleFilter = useCallback(
    (accessor, text) => {
      setIsLoading(true)

      // Используем функцию в setFiltersParams, чтобы иметь доступ к текущему состоянию

      const newFiltersParams = { ...filtersParams }

      if (newFiltersParams[accessor]) {
        const index = newFiltersParams[accessor].indexOf(text)
        if (index > -1) {
          // Удаляем значение из массива
          newFiltersParams[accessor].splice(index, 1)
          // Если массив пуст, удаляем ключ
          if (newFiltersParams[accessor].length === 0) {
            delete newFiltersParams[accessor]
          }
        } else {
          // Добавляем значение в массив
          newFiltersParams[accessor].push(text)
        }
      } else {
        // Создаем массив с новым значением
        newFiltersParams[accessor] = [text]
      }

      setFiltersParams(newFiltersParams)
    },
    [filtersParams],
  )

  useEffect(() => {
    const data = transformedRowsForSorting(rows, true)
    setBaseRowsData(data)
  }, [])

  useEffect(() => {
    const sortedTransformedTest = orderBy(
      transformedRowsForSorting(rows),
      Object.keys(sortParams),
      Object.values(sortParams),
    )
    const sortedTest = sortedTransformedTest.map((item) => rows[item.index])

    if (Object.keys(filtersParams).length) {
      const filteredSortedTest = sortedTest.filter((row) => !matchesFilter(row, filtersParams))
      setCurrentRows(filteredSortedTest)
    } else {
      setCurrentRows(sortedTest)
    }

    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [sortParams, filtersParams, rows])

  return (
    <table className={theme.table}>
      <thead>
        <tr>
          {headingColumns.map((column, index) => (
            <HeadingItem
              column={column}
              rows={baseRowsData}
              handleSort={handleSort}
              handleFilter={handleFilter}
              sortParams={sortParams}
              setSortParams={setSortParams}
              filtersParams={filtersParams}
              setFiltersParams={setFiltersParams}
              setIsLoading={setIsLoading}
              key={index}
            />
          ))}
        </tr>
      </thead>
      {isLoading ? (
        <Spinner space="sp-16" wrapper="div" style={{ display: "flex", alignItems: "center" }} />
      ) : (
        <tbody style={{ width: "100%" }}>
          <>
            <TableContent rows={currentRows} />
          </>
        </tbody>
      )}
    </table>
  )
})

TableComponent.displayName = "TableComponentComp"

export default TableComponent
