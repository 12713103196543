// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableFilterBox_filterBoxContainer__nRcTj {
    z-index: 1010 !important;

    height: 322px;

    box-shadow: 0 4px 12px 0 rgb(0 20 67 / 12%);
}`, "",{"version":3,"sources":["webpack://./src/components/form/Editor/nodes/TablePreviewNode/tableFilterBox.module.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;;IAExB,aAAa;;IAEb,2CAA2C;AAC/C","sourcesContent":[".filterBoxContainer {\n    z-index: 1010 !important;\n\n    height: 322px;\n\n    box-shadow: 0 4px 12px 0 rgb(0 20 67 / 12%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterBoxContainer": `tableFilterBox_filterBoxContainer__nRcTj`
};
export default ___CSS_LOADER_EXPORT___;
