import React, { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { DateTime } from "luxon"

import {
  PageBlockRow,
  PageBlockContent,
  PageBlockRowSection,
  PageBlockHeading,
  Button,
  getUrlSearchParams,
  Spinner,
  apiClient,
  Avatar,
  CustomGetUsersOptionLabel,
} from "@dit/core-frontend"
import { API_ENDPOINTS } from "@constants"
import { Stack, Box, Text } from "@mos-cat/ds"
import { useZoneContext } from "@components/contexts/ZoneContext"
import { usePageContext } from "@components/contexts/PageContext"
import HistoryCurtainContent from "@components/pages/History/HistoryCurtainContent"
import { CurtainModal } from "@components/general"
import DiffContent from "@components/pages/Diff/DiffContent"
import TopSectionButton from "@components/pages/TopSectionButton"

import styles from "./diff.module.css"

const getCurrentDate = (date) => {
  return DateTime.fromISO(date).toFormat("dd.MM.yyyy • HH:mm")
}

const DiffPage = ({ setAdditionalTopSection }) => {
  const [error, setError] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [versionForDiff, setVersionForDiff] = useState([])
  const [isHistoryCurtainOpen, setIsHistoryCurtainOpen] = useState(false)
  const { zone, updatePage } = useZoneContext()
  const { page } = usePageContext()

  const [searchParams] = useSearchParams()
  const { firstVersion, secondVersion } = getUrlSearchParams(searchParams)

  const getVersionData = async (version) => {
    const { data } = await apiClient.get(`${API_ENDPOINTS.page}/${page.id}?version=${version}`)
    return data?.data
  }

  const getVersions = useCallback(async () => {
    if (!firstVersion || !secondVersion) {
      setError("Не указана версия для сравнения")
      setIsLoading(false)
    }

    try {
      const versionsList = await Promise.all([
        getVersionData(firstVersion),
        getVersionData(secondVersion),
      ])

      if (versionsList.length !== 2) {
        setError("Не удалось получить версии для сравнения")
      } else {
        // Нужно для того, что бы корректно сравнить 2 версии
        // Т.к. версия 2.10 больше, чем 2.9 - при сравнении их получим
        // неправильный результат, по этому преобразуем версии в 210 и 29 и сравниваем их
        setVersionForDiff(
          versionsList.sort(
            (a, b) => Number(a.version.replace(".", "")) - Number(b.version.replace(".", "")),
          ),
        )
      }
    } catch (err) {
      console.error(err)
      setError("Не удалось получить версии для сравнения")
    }

    setIsLoading(false)
  }, [firstVersion, secondVersion, page?.id])

  useEffect(() => {
    setAdditionalTopSection(<TopSectionButton zoneId={zone?.id} pageId={page?.id} />)

    return () => {
      setAdditionalTopSection(null)
    }
  }, [setAdditionalTopSection, zone?.id, page?.id])

  useEffect(() => {
    void getVersions()
  }, [getVersions])

  return (
    <>
      <PageBlockRow place="middle">
        <PageBlockRowSection>
          <PageBlockHeading>
            <Text fontSize="26px" fontWeight="600" lineHeight="32px">
              Сравнение версий
            </Text>
          </PageBlockHeading>
        </PageBlockRowSection>
      </PageBlockRow>
      <PageBlockContent>
        {isLoading ? (
          <Spinner
            styles={{ position: "static" }}
            space="sp-16"
            iconSize="30px"
            overlayVisible={false}
          />
        ) : (
          <>
            {error ? (
              <Text fontSize="16px">{error}</Text>
            ) : (
              <Stack space="15px">
                <Box display="flex" justifyContent="space-between" gridGap="10px">
                  <Box display="flex" gridGap="10px">
                    {versionForDiff.map((v) => (
                      <Stack
                        key={`version-card-${v.id}`}
                        space="5px"
                        className={styles.versionCard}
                      >
                        <Text fontSize="16px" fontWeight="600" color="gray-90">
                          v. {v.version}
                        </Text>
                        <Box display="flex" alignItems="center" gridGap="10px">
                          <Avatar size={20} url={v.user?.photo?.url} />
                          <Text fontSize="16px" color="gray-90">
                            {CustomGetUsersOptionLabel(v.user)}
                          </Text>
                        </Box>
                        {!!v.modifiedAt && (
                          <Text fontSize="14px" color="gray-90">
                            {getCurrentDate(v.modifiedAt)}
                          </Text>
                        )}
                      </Stack>
                    ))}
                  </Box>
                  <Stack space="10px">
                    <Text>Ключ</Text>
                    <Stack space="5px">
                      <Box className={styles.diffKey}>
                        <ins>Эта строка добавлена</ins>
                      </Box>
                      <Box className={styles.diffKey}>
                        <del>Эта строка удалена</del>
                      </Box>
                      <Box className={styles.diffKey}>
                        <ins className="mod">Изменено форматирование</ins>
                      </Box>
                    </Stack>
                  </Stack>
                </Box>
                <Box borderBottom="1px solid" borderColor="gray-20">
                  <Button
                    kind="borderless"
                    onClick={() => {
                      setIsHistoryCurtainOpen(true)
                    }}
                  >
                    История версий страницы
                  </Button>
                </Box>
                <DiffContent versions={versionForDiff} />
              </Stack>
            )}
          </>
        )}
      </PageBlockContent>

      <CurtainModal
        isOpen={isHistoryCurtainOpen}
        onClose={() => setIsHistoryCurtainOpen(false)}
        content={(hide) => (
          <HistoryCurtainContent
            zone={zone}
            page={page}
            closeModal={hide}
            onUpdatePage={(data) => updatePage(page.id, data)}
          />
        )}
      />
    </>
  )
}

export default DiffPage
