import React from "react"
import { Route, Routes } from "react-router-dom"

import PageTemplatesList from "./List"
import PageTemplatesPage from "./Page"

const PageTemplates = ({ setAdditionalBreadcrumbs }) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<PageTemplatesList setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />}
      />
      <Route
        path="/:templateId"
        element={<PageTemplatesPage setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />}
      />
      <Route
        path="/:templateId/edit"
        element={<PageTemplatesPage isEdit setAdditionalBreadcrumbs={setAdditionalBreadcrumbs} />}
      />
    </Routes>
  )
}

export default PageTemplates
