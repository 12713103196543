import { ClickableField } from "@dit/core-frontend"
import React, { memo, useCallback } from "react"
import { ColorPicker } from "."
import { ColorText } from "../../theme/editorIcons"
import { Button } from "@mos-cat/ds"
import { $getSelection, $isRangeSelection } from "lexical"
import { $patchStyleText } from "@lexical/selection"

const ColorPickerPopover = memo(({ color, editor, isReadonly = false }) => {
  const applyStyleText = useCallback(
    (styles, skipHistoryStack) => {
      editor.update(
        () => {
          const selection = $getSelection()
          if ($isRangeSelection(selection)) {
            $patchStyleText(selection, styles)
          }
        },
        skipHistoryStack ? { tag: "historic" } : {},
      )
    },
    [editor],
  )
  const onFontColorSelect = useCallback((value, skipHistoryStack) => {
    applyStyleText({ color: value }, skipHistoryStack)
  }, [])
  return (
    <ClickableField
      as={Button}
      width="72px"
      height="72px"
      backgroundColor="#EBF1FF"
      display="flex"
      alignItems="center"
      justifyContent="center"
      color="inherit"
      fontSize="inherit"
      popoverOptions={{ placement: "right-end", modal: true }}
      disabled={isReadonly}
      margin="0"
      padding="0"
      PopoverComp={
        onFontColorSelect && color ? (
          <ColorPicker color={color} onChange={onFontColorSelect} />
        ) : null
      }
    >
      {() => <ColorText />}
    </ClickableField>
  )
})

ColorPickerPopover.displayName = "ColorPickerPopover"

export default ColorPickerPopover
