import React from "react"
import { useZoneContext } from "../contexts/ZoneContext"
import {
  PageBlockContent,
  PageBlockHeading,
  PageBlockRow,
  PageBlockRowSection,
} from "@dit/core-frontend"
import { Box, Stack, Text } from "@mos-cat/ds"
import { CreatePageModal } from "@components/pages/CreatePageModal"

export const DefaultHomePage = () => {
  const { zone } = useZoneContext()
  return (
    <>
      <PageBlockRow place="middle" styles={{ marginTop: "15px !important" }}>
        <PageBlockRowSection>
          <PageBlockHeading level={2} fontWeight="700 !important" color={"gray-30"}>
            {"Главная страница раздела"}
          </PageBlockHeading>
        </PageBlockRowSection>
      </PageBlockRow>
      <PageBlockRow place="middle" styles={{ marginTop: "10px !important" }}>
        <PageBlockRowSection>
          <Text
            fontSize="22px"
            color={"gray-30"}
          >{`Создайте главную страницу для раздела ${zone?.title}`}</Text>
        </PageBlockRowSection>
      </PageBlockRow>

      <PageBlockContent styles={{ marginTop: "30px !important" }}>
        <Box width="180px">
          <CreatePageModal defaultZone={zone} homePage>
            <Stack alignItems="center" space="10px">
              <Box
                height="230px"
                display="flex"
                paddingTop="50px"
                justifyContent="center"
                width="180px"
                border="1px solid rgba(238, 239, 242, 1)"
              >
                <svg
                  width="127"
                  height="127"
                  viewBox="0 0 127 127"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M13 0H72.7864L114 40V127H13V0Z" fill="#99BFF9" />
                  <path d="M73 0L114 40H73V0Z" fill="#3E8BFE" />
                  {/* eslint-disable-next-line */}
                  <path d="M41.8333 81.442C41.8333 76.4825 41.8333 74.0038 42.9599 71.9498C44.0823 69.8937 46.1384 68.6197 50.2486 66.0673L54.5819 63.3785C58.9261 60.681 61.0993 59.3333 63.4999 59.3333C65.9006 59.3333 68.0716 60.681 72.4179 63.3785L76.7512 66.0673C80.8614 68.6197 82.9176 69.8937 84.0421 71.9498C85.1666 74.0038 85.1666 76.4825 85.1666 81.4398V84.7375C85.1666 93.1875 85.1666 97.4147 82.6272 100.041C80.0901 102.667 76.0037 102.667 67.8333 102.667H59.1666C50.9961 102.667 46.9098 102.667 44.3726 100.041C41.8333 97.4147 41.8333 93.1897 41.8333 84.7375V81.442Z"
                    stroke="white"
                    strokeWidth="4"
                  />
                  <path
                    d="M70 85.3333H63.5M63.5 85.3333H57M63.5 85.3333V78.8333M63.5 85.3333V91.8333"
                    stroke="white"
                    strokeWidth="4"
                    strokeLinecap="round"
                  />
                </svg>
              </Box>
              <Text color="gray-60">Создать страницу</Text>
            </Stack>
          </CreatePageModal>
        </Box>
      </PageBlockContent>
    </>
  )
}
