import React from "react"
import { Box } from "@mos-cat/ds"
import { getCellPropsByZoneData } from "@utils/zones"
import { Avatar, Favorites, Subscription } from "@dit/core-frontend"
import { EntityLink } from "@components/entity"
import { API_ENDPOINTS, ROUTES } from "@constants"
import { PageStatus } from "@components/pages/PageStatus"
import { PageActions } from "@components/pages/PageActions"

const getCellPropsByStatus = (page) => {
  if (page.archive) {
    return {
      opacity: "0.4",
    }
  }
  return {}
}

const PageListRowMobile = ({ page, updateResponseData, onDeletePage }) => {
  return (
    <Box
      display="flex"
      alignItems="flex-start"
      flexDirection="column"
      gridGap="5px"
      padding="10px 0"
      borderTop="1px solid var(--gray-10)"
      styles={{
        fontSize: "14px",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
        gridGap="10px"
      >
        <Box
          display="flex"
          alignItems="center"
          gridGap="10px"
          overflow="hidden"
          {...getCellPropsByStatus(page)}
        >
          <Avatar url={page.zone.icon?.url || " "} size={28} borderRadius="0" />
          <EntityLink
            visibleField="title"
            entity={page.zone}
            link={ROUTES.ZONE}
            overflow="hidden"
            styles={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "3",
              textOverflow: "-webkit-box",
            }}
          />
          <Box
            styles={{
              "& > div": {
                width: "110px",
                height: "24px",
              },
            }}
          >
            <PageStatus status={page.status} />
          </Box>
        </Box>
        <PageActions
          page={page}
          onDelete={() => {
            updateResponseData?.(page.id, "remove")
            onDeletePage(page.id)
          }}
          onArchive={(archiveState) =>
            updateResponseData?.(page.id, "update", { archive: archiveState })
          }
        />
      </Box>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        width="100%"
        gridGap="15px"
      >
        <Box
          display="flex"
          alignItems="flex-start"
          overflow="hidden"
          gridGap="7px"
          {...getCellPropsByZoneData(page)}
        >
          <EntityLink
            visibleField="title"
            entity={page}
            link={`${ROUTES.ZONE}/${page.zone.slug}/pages`}
            overflow="hidden"
            styles={{
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: "2",
              textOverflow: "-webkit-box",
              "& > span": {
                whiteSpace: "normal!important",
                display: "-webkit-box!important",
                textOverflow: "-webkit-box!important",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "2",
                textAlign: "left",
              },
            }}
          />
        </Box>
        <Box display="flex" alignItems="flex-start" justifyContent="space-between" gridGap="10px">
          <Subscription
            itemId={page.id}
            subscriptionId={page.subscription?.id}
            type="page"
            requestUrl={API_ENDPOINTS.subscription}
            onSuccess={(subscriptionId) => {
              updateResponseData?.(page.id, "update", {
                subscription: subscriptionId ? { id: subscriptionId } : null,
              })
            }}
          />
          <Favorites
            itemId={page.id}
            favoritesId={page.favorites?.id}
            type="page"
            requestUrl={API_ENDPOINTS.favorites}
            iconSize={21}
            onSuccess={(favoritesId) => {
              updateResponseData?.(page.id, "update", {
                favorites: favoritesId ? { id: favoritesId } : null,
              })
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default PageListRowMobile
