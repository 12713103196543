import { Box, Text } from "@mos-cat/ds"
import React, { useCallback, useState } from "react"
import { taskTypeIconsBySlug } from "@editor/utils/TaskTypeIconsBySlug"
import { defaultColorProps, statusBySlug } from "@editor/utils/statusBySlug"
import { DefaultTypeIcon, Ellipsis, Tooltip } from "@dit/core-frontend"
import { Link } from "react-router-dom"
import { ROUTES } from "@src/constants"
import { MiniClose } from "@mos-cat/ds-icons"
import { $getNodeByKey } from "lexical"

const TaskMention = ({ currentData, editor, nodeKey, ref }) => {
  const [isHovered, setIsHovered] = useState(false)
  const TypeIcon = taskTypeIconsBySlug[currentData?.type?.slug] || DefaultTypeIcon
  const statusColorProps = statusBySlug[currentData?.status?.slug] || defaultColorProps

  const deleteTaskMention = useCallback(() => {
    editor.update(() => {
      const node = $getNodeByKey(nodeKey)
      node.remove()
    })
  }, [editor, nodeKey])

  const changeHovered = (bool) => {
    if (editor._config.namespace !== "EditorPreview") {
      setIsHovered(bool)
    }
  }
  return (
    <Box
      border="1px solid rgba(223, 225, 230, 1)"
      backgroundColor={isHovered ? "rgba(247, 245, 238, 1)" : ""}
      ref={ref}
      onMouseEnter={() => changeHovered(true)}
      onMouseLeave={() => changeHovered(false)}
    >
      {!currentData?.id ? (
        <Box display="flex" alignItems="center" gridGap="5px" margin="5px">
          {" "}
          {"Задача не найдена"}{" "}
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="center"
          gridGap="5px"
          margin="5px"
          data-mention={`${currentData?.slug || ""} ${currentData?.status?.title || ""} ${
            currentData?.title || ""
          }`}
        >
          <Text as={Box} fontSize="fs-14">
            <TypeIcon />
          </Text>
          <Link to={`${ROUTES.TRACK_TASK}/${currentData?.slug || ""}`}>
            <Tooltip
              disabled={!currentData?.slug || currentData?.slug?.length < 8}
              content={<Text>{currentData?.slug?.toUpperCase() || ""} </Text>}
            >
              <Text as={Box} fontSize="fs-14" maxWidth="100px">
                <Ellipsis> {currentData?.slug?.toUpperCase() || ""} </Ellipsis>
              </Text>
            </Tooltip>
          </Link>

          <Text
            as={Box}
            display="flex"
            alignItems="center"
            justifyContent="center"
            p="2px 10px"
            borderRadius="4px"
            backgroundColor={statusColorProps.backgroundColor}
            color={statusColorProps.color}
            fontSize="fs-14"
            lineHeight="20px"
            fontWeight="400"
          >
            {currentData?.status?.title || ""}{" "}
          </Text>
          <Tooltip
            disabled={!currentData?.title || currentData?.title?.length < 25}
            content={<Text fontSize="fs-14">{currentData?.title || ""} </Text>}
          >
            {isHovered ? (
              <Link to={`${ROUTES.TRACK_TASK}/${currentData?.slug || ""}`}>
                <Text as={Box} fontSize="fs-14" maxWidth="300px">
                  <Ellipsis>{currentData?.title || ""} </Ellipsis>
                </Text>
              </Link>
            ) : (
              <Text as={Box} fontSize="fs-14" maxWidth="300px">
                <Ellipsis>{currentData?.title || ""} </Ellipsis>
              </Text>
            )}
          </Tooltip>
          {isHovered && (
            <Tooltip content="удалить">
              <Box
                onClick={deleteTaskMention}
                display="inline-flex"
                backgroundColor="white"
                borderRadius="50%"
                style={{ cursor: "pointer" }}
              >
                {" "}
                <MiniClose />{" "}
              </Box>
            </Tooltip>
          )}
        </Box>
      )}
    </Box>
  )
}

export default TaskMention
