/* eslint-disable max-len */
import { ActionsPopover, Button } from "@dit/core-frontend"
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import {
  $deleteTableColumn,
  $getElementGridForTableNode,
  $getTableCellNodeFromLexicalNode,
  $getTableColumnIndexFromTableCellNode,
  $getTableNodeFromLexicalNodeOrThrow,
  $getTableRowIndexFromTableCellNode,
  $insertTableColumn,
  $insertTableRow,
  $removeTableRowAtIndex,
  $isTableCellNode,
  getTableSelectionFromTableElement,
  TableCellNode,
} from "@lexical/table"
import { Box, Stack, Text } from "@mos-cat/ds"
import { Add } from "@mos-cat/ds-icons"
import {
  $getRoot,
  $getSelection,
  $isRangeSelection,
  DEPRECATED_$getNodeTriplet,
  DEPRECATED_$isGridSelection,
} from "lexical"
import * as React from "react"
import { useCallback, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom"
import ColorPickerModal from "../components/ColorPicker/ColorPickerModal"
import { ColumnIcon } from "../theme/editorIcons"
import { $isTableSelection } from "./TableCellResizer"

function currentCellBackgroundColor(editor) {
  return editor.getEditorState().read(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection) || $isTableSelection(selection)) {
      const [cell] = DEPRECATED_$getNodeTriplet(selection.anchor)
      if ($isTableCellNode(cell)) {
        return cell.getBackgroundColor()
      }
    }
    return null
  })
}

function TableActionMenu({
  onClose,
  tableCellNode: _tableCellNode,
  setIsMenuOpen,
  contextRef,
  menuRootRef,
}) {
  const [editor] = useLexicalComposerContext()
  const dropDownRef = useRef(null)
  const [tableCellNode, updateTableCellNode] = useState(_tableCellNode)
  const [selectionCounts, updateSelectionCounts] = useState({
    columns: 1,
    rows: 1,
  })
  const [backgroundColor, setBackgroundColor] = useState(
    () => currentCellBackgroundColor(editor) || "",
  )
  const [showModal, setShowModal] = useState(null)
  const handleSetModal = (value) => {
    setShowModal(value)
  }

  useEffect(() => {
    return editor.registerMutationListener(TableCellNode, (nodeMutations) => {
      const nodeUpdated = nodeMutations.get(tableCellNode.getKey()) === "updated"

      if (nodeUpdated) {
        editor.getEditorState().read(() => {
          updateTableCellNode(tableCellNode.getLatest())
        })
        setBackgroundColor(currentCellBackgroundColor(editor) || "")
      }
    })
  }, [editor, tableCellNode])

  useEffect(() => {
    editor.getEditorState().read(() => {
      const selection = $getSelection()

      if (DEPRECATED_$isGridSelection(selection)) {
        const selectionShape = selection.getShape()

        updateSelectionCounts({
          columns: selectionShape.toX - selectionShape.fromX + 1,
          rows: selectionShape.toY - selectionShape.fromY + 1,
        })
      }
    })
  }, [editor])

  useEffect(() => {
    const menuButtonElement = contextRef.current
    const dropDownElement = dropDownRef.current

    if (menuButtonElement != null && dropDownElement != null) {
      const menuButtonRect = menuButtonElement.getBoundingClientRect()

      dropDownElement.style.opacity = "1"

      dropDownElement.style.left = `${
        menuButtonRect.left + menuButtonRect.width + window.pageXOffset + 5
      }px`

      dropDownElement.style.top = `${menuButtonRect.top + window.pageYOffset}px`
    }
  }, [contextRef, dropDownRef])

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropDownRef.current != null &&
        contextRef.current != null &&
        !dropDownRef.current.contains(event.target) &&
        !contextRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false)
      }
    }

    window.addEventListener("click", handleClickOutside)

    return () => window.removeEventListener("click", handleClickOutside)
  }, [setIsMenuOpen, contextRef])

  const clearTableSelection = useCallback(() => {
    editor.update(() => {
      if (tableCellNode.isAttached()) {
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
        const tableElement = editor.getElementByKey(tableNode.getKey())

        if (!tableElement) {
          throw new Error("Expected to find tableElement in DOM")
        }

        const tableSelection = getTableSelectionFromTableElement(tableElement)
        if (tableSelection !== null) {
          tableSelection.clearHighlight()
        }

        tableNode.markDirty()
        updateTableCellNode(tableCellNode.getLatest())
      }

      const rootNode = $getRoot()
      rootNode.selectStart()
    })
  }, [editor, tableCellNode])

  const insertTableRowAtSelection = useCallback(
    (shouldInsertAfter) => {
      editor.update(() => {
        const selection = $getSelection()

        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)

        let tableRowIndex

        if (DEPRECATED_$isGridSelection(selection)) {
          const selectionShape = selection.getShape()
          tableRowIndex = shouldInsertAfter ? selectionShape.toY : selectionShape.fromY
        } else {
          tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode)
        }

        const grid = $getElementGridForTableNode(editor, tableNode)

        $insertTableRow(tableNode, tableRowIndex, shouldInsertAfter, selectionCounts.rows, grid)

        clearTableSelection()

        onClose()
      })
    },
    [editor, tableCellNode, selectionCounts.rows, clearTableSelection, onClose],
  )

  const insertTableColumnAtSelection = useCallback(
    (shouldInsertAfter) => {
      editor.update(() => {
        const selection = $getSelection()

        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)

        let tableColumnIndex

        if (DEPRECATED_$isGridSelection(selection)) {
          const selectionShape = selection.getShape()
          tableColumnIndex = shouldInsertAfter ? selectionShape.toX : selectionShape.fromX
        } else {
          tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode)
        }

        const grid = $getElementGridForTableNode(editor, tableNode)

        $insertTableColumn(
          tableNode,
          tableColumnIndex,
          shouldInsertAfter,
          selectionCounts.columns,
          grid,
        )

        clearTableSelection()

        onClose()
      })
    },
    [editor, tableCellNode, selectionCounts.columns, clearTableSelection, onClose],
  )

  const deleteTableRowAtSelection = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
      const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode)

      $removeTableRowAtIndex(tableNode, tableRowIndex)

      clearTableSelection()
      onClose()
    })
  }, [editor, tableCellNode, clearTableSelection, onClose])

  const deleteTableAtSelection = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)
      tableNode.remove()

      clearTableSelection()
      onClose()
    })
  }, [editor, tableCellNode, clearTableSelection, onClose])

  const deleteTableColumnAtSelection = useCallback(() => {
    editor.update(() => {
      const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode)

      const tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode)

      $deleteTableColumn(tableNode, tableColumnIndex)

      clearTableSelection()
      onClose()
    })
  }, [editor, tableCellNode, clearTableSelection, onClose])

  const handleCellBackgroundColor = useCallback(
    (value) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection) || $isTableSelection(selection)) {
          const [cell] = DEPRECATED_$getNodeTriplet(selection.anchor)
          if ($isTableCellNode(cell)) {
            cell.setBackgroundColor(value)
          }

          if ($isTableSelection(selection)) {
            const nodes = selection.getNodes()

            for (let i = 0; i < nodes.length; i++) {
              const node = nodes[i]
              if ($isTableCellNode(node)) {
                node.setBackgroundColor(value)
              }
            }
          }
        }
      })
    },
    [editor],
  )

  return (
    <>
      <ActionsPopover
        width="220px"
        customHoverStyle={{ backgroundColor: "none", boxShadow: "none" }}
        content={
          <button
            style={{ backgroundColor: "white", zIndex: "10", borderRadius: "5px" }}
            // onClick={(e) => {
            //   e.stopPropagation()
            //   setIsMenuOpen(!isMenuOpen)
            // }}
            ref={menuRootRef}
          >
            {" "}
            <Add size="10px" />
          </button>
        }
      >
        {(buttonProps) => (
          <>
            <Button
              {...buttonProps}
              kind="borderless"
              onClick={() => handleSetModal("change-color")}
            >
              <ColumnIcon />
              <Text>Цвет ячейки</Text>
            </Button>

            <Button
              {...buttonProps}
              kind="borderless"
              className="item"
              onClick={() => insertTableRowAtSelection(false)}
            >
              <>
                Добавить {selectionCounts.rows === 1 ? "строку" : `${selectionCounts.rows} строки`}{" "}
                выше
              </>
            </Button>
            <Button
              {...buttonProps}
              kind="borderless"
              className="item"
              onClick={() => insertTableRowAtSelection(true)}
            >
              <>
                Добавить {selectionCounts.rows === 1 ? "строку" : `${selectionCounts.rows} строки`}{" "}
                ниже
              </>
            </Button>

            <Button
              {...buttonProps}
              kind="borderless"
              className="item"
              onClick={() => insertTableColumnAtSelection(false)}
            >
              <>
                Добавить{" "}
                {selectionCounts.columns === 1 ? "столбец" : `${selectionCounts.columns} столбца`}{" "}
                слева
              </>
            </Button>
            <Button
              {...buttonProps}
              kind="borderless"
              className="item"
              onClick={() => insertTableColumnAtSelection(true)}
            >
              <>
                Добавить{" "}
                {selectionCounts.columns === 1 ? "столбец" : `${selectionCounts.columns} столбца`}{" "}
                справа
              </>
            </Button>
            <hr />
            <Button
              {...buttonProps}
              kind="borderless"
              className="item"
              onClick={() => deleteTableColumnAtSelection()}
            >
              <>Удалить столбец</>
            </Button>
            <Button
              {...buttonProps}
              kind="borderless"
              className="item"
              onClick={() => deleteTableRowAtSelection()}
            >
              <>Удалить строку</>
            </Button>
            <Button
              {...buttonProps}
              kind="borderless"
              className="item"
              onClick={() => deleteTableAtSelection()}
            >
              <>Удалить таблицу</>
            </Button>
          </>
        )}
      </ActionsPopover>

      <ColorPickerModal
        color={backgroundColor}
        onChange={handleCellBackgroundColor}
        modalDisclosureProps={{
          as: Stack,
          position: "relative",
          styles: { cursor: "pointer" },
        }}
        modalStateProps={{
          visible: showModal === "change-color",
          hide: () => handleSetModal(null),
        }}
      />
    </>
  )
}

function TableCellActionMenuContainer({ anchorElem }) {
  const [editor] = useLexicalComposerContext()
  const menuButtonRef = useRef(null)
  const menuRootRef = useRef(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [tableCellNode, setTableMenuCellNode] = useState(null)

  const moveMenu = useCallback(() => {
    const menu = menuButtonRef.current
    const selection = $getSelection()
    const nativeSelection = window.getSelection()
    const activeElement = document.activeElement

    if (selection == null || menu == null) {
      setTableMenuCellNode(null)
      return
    }

    const rootElement = editor.getRootElement()

    if (
      $isRangeSelection(selection) &&
      rootElement !== null &&
      nativeSelection !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const tableCellNodeFromSelection = $getTableCellNodeFromLexicalNode(
        selection.anchor.getNode(),
      )

      if (tableCellNodeFromSelection == null) {
        setTableMenuCellNode(null)
        return
      }

      const tableCellParentNodeDOM = editor.getElementByKey(tableCellNodeFromSelection.getKey())

      if (tableCellParentNodeDOM == null) {
        setTableMenuCellNode(null)
        return
      }

      setTableMenuCellNode(tableCellNodeFromSelection)
    } else if (!activeElement) {
      setTableMenuCellNode(null)
    }
  }, [editor])

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.getEditorState().read(() => {
        moveMenu()
      })
    })
  })

  useEffect(() => {
    const menuButtonDOM = menuButtonRef.current

    if (menuButtonDOM != null && tableCellNode != null) {
      const tableCellNodeDOM = editor.getElementByKey(tableCellNode.getKey())

      if (tableCellNodeDOM != null) {
        const tableCellRect = tableCellNodeDOM.getBoundingClientRect()
        const menuRect = menuButtonDOM.getBoundingClientRect()
        const anchorRect = anchorElem.getBoundingClientRect()

        menuButtonDOM.style.opacity = "1"

        menuButtonDOM.style.left = `${
          tableCellRect.right - menuRect.width - 10 - anchorRect.left
        }px`

        menuButtonDOM.style.top = `${tableCellRect.top - anchorRect.top + 4}px`
      } else {
        menuButtonDOM.style.opacity = "0"
      }
    }
  }, [menuButtonRef, tableCellNode, editor, anchorElem])

  const prevTableCellDOM = useRef(tableCellNode)

  useEffect(() => {
    if (prevTableCellDOM.current !== tableCellNode) {
      setIsMenuOpen(false)
    }

    prevTableCellDOM.current = tableCellNode
  }, [prevTableCellDOM, tableCellNode])

  return (
    <div
      style={{ position: "absolute", top: 0, left: 0, willChange: "transform", zIndex: 10 }}
      ref={menuButtonRef}
    >
      {tableCellNode != null && (
        <>
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            // className="table-cell-action-button chevron-down"
          >
            <TableActionMenu
              menuRootRef={menuRootRef}
              contextRef={menuRootRef}
              setIsMenuOpen={setIsMenuOpen}
              onClose={() => setIsMenuOpen(false)}
              tableCellNode={tableCellNode}
            />
          </Box>
        </>
      )}
    </div>
  )
}

export default function TableActionMenuPlugin({ anchorElem = document.body }) {
  return createPortal(<TableCellActionMenuContainer anchorElem={anchorElem} />, anchorElem)
}
