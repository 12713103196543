import React, { useMemo } from "react"

import { Button, Sidebar, SidebarEntityHeader } from "@dit/core-frontend"
import { Box, Stack, Text } from "@mos-cat/ds"
import { TreePages } from "./TreePages/TreePages"
import HubLink from "@components/general/HubLink"
import { CreatePageModal } from "@components/pages/CreatePageModal"

import { useZoneContext } from "@components/contexts/ZoneContext"
import { Add } from "@mos-cat/ds-icons"

import {
  PAGE_ICONS,
  ZONE_SECTION_LINKS,
  settingsSectionsObject,
  ZONE_SETTINGS_SECTIONS,
} from "@utils/zones"
import { transformArray } from "./TreePages/utils"
import { ROUTES } from "@constants"

import styles from "./Sidebar.module.css"

export const ZoneSideBar = () => {
  const { zone, zonePages, checkZonePermission } = useZoneContext()

  const settingsSubSections = useMemo(() => {
    const settingsSections = []

    if (checkZonePermission("ZONE_READ")) {
      settingsSections.push({
        title: settingsSectionsObject.intelligence,
        slug: ZONE_SETTINGS_SECTIONS.INTELLIGENCE,
      })
    }
    // if (checkZonePermission("AREA_SETTINGS_PERMISSIONS_ALL_READ")) {
    //   settingsSections.push({
    //     title: settingsSectionsObject.permissions,
    //     slug: ZONE_SETTINGS_SECTIONS.PERMISSIONS,
    //   })
    // }
    if (checkZonePermission("ZONE_SETTINGS_TEAM_MEMBERS_LIST")) {
      settingsSections.push({
        title: settingsSectionsObject.teams,
        slug: ZONE_SETTINGS_SECTIONS.TEAMS,
      })
    }

    return settingsSections.map(({ title, slug }) => ({
      label: title,
      link: `${ZONE_SECTION_LINKS.SETTINGS}/${slug}`,
    }))
  }, [checkZonePermission])

  const pages = useMemo(() => {
    return transformArray(zonePages)
  }, [zonePages])

  const sectionsLinkList = useMemo(() => {
    const startLink = `${ROUTES.ZONE}/${zone.slug}`

    const links = [
      {
        label: "Главная",
        link: `${startLink}/${ZONE_SECTION_LINKS.HOMEPAGE}`,
        icon: PAGE_ICONS[ZONE_SECTION_LINKS.HOMEPAGE],
      },
      {
        label: "Страницы",
        expandable: true,
        link: `${startLink}/${ZONE_SECTION_LINKS.PAGES}`,
        icon: PAGE_ICONS[ZONE_SECTION_LINKS.PAGES],
        subElement: (
          <>
            {checkZonePermission("PAGE_CREATE") && (
              <CreatePageModal
                defaultZone={zone}
                withChangeZone={false}
                modalDisclosureProps={{
                  as: Button,
                  kind: "menu-button",
                  paddingLeft: "10px !important",
                  styles: {
                    "&": { color: "#B2B5BB !important" },
                    "&:hover": { color: "#0044CC !important" },
                  },
                }}
              >
                <Text as={Box} fontSize="12px">
                  <Add fr={1} />
                </Text>
                Новая страница
              </CreatePageModal>
            )}
            {pages.length > 0 ? (
              <Stack space={0} maxHeight="480px" className={styles.container}>
                <TreePages pages={pages} isCreate={checkZonePermission("PAGE_CREATE")} />
              </Stack>
            ) : (
              <></>
            )}
          </>
        ),
      },
      {
        label: "Шаблоны",
        link: `${startLink}/${ZONE_SECTION_LINKS.TEMPLATES}`,
        icon: PAGE_ICONS[ZONE_SECTION_LINKS.TEMPLATES],
      },
    ]

    if (settingsSubSections.length) {
      links.push({
        label: "Настройки",
        expandable: true,
        link: `${startLink}/${ZONE_SECTION_LINKS.SETTINGS}`,
        icon: PAGE_ICONS[ZONE_SECTION_LINKS.SETTINGS],
        subSections: settingsSubSections,
      })
    }

    return links
  }, [zone, settingsSubSections, pages])

  return (
    <Sidebar
      headerContent={
        <Stack space="0">
          <HubLink type="projects" items={[zone]} />
          <SidebarEntityHeader title={zone.title} slug={zone.slug} iconUrl={zone.icon?.url} />
        </Stack>
      }
      sectionsLinkList={sectionsLinkList}
      curtainClassName={styles.curtainSidebar}
    />
  )
}
