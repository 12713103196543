import styles from "./collapsible.module.css"

import { $createParagraphNode, $isElementNode, ElementNode } from "lexical"

import { $isCollapsibleContainerNode } from "./CollapsibleContainerNode"
import { $isCollapsibleContentNode } from "./CollapsibleContentNode"

export function convertSummaryElement() {
  const node = $createCollapsibleTitleNode()
  return {
    node,
  }
}

export class CollapsibleTitleNode extends ElementNode {
  static getType() {
    return "collapsible-title"
  }

  static clone(node) {
    return new CollapsibleTitleNode(node.__key)
  }

  createDOM() {
    const dom = document.createElement("summary")
    dom.classList.add(styles.CollapsibleTitle)
    return dom
  }

  updateDOM() {
    return false
  }

  static importDOM() {
    return {
      summary: () => {
        return {
          conversion: convertSummaryElement,
          priority: 1,
        }
      },
    }
  }

  static importJSON() {
    return $createCollapsibleTitleNode()
  }

  exportDOM() {
    const element = document.createElement("summary")
    return { element }
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: "collapsible-title",
      version: 1,
    }
  }

  collapseAtStart() {
    this.getParentOrThrow().insertBefore(this)
    return true
  }

  insertNewAfter(_, restoreSelection = true) {
    const containerNode = this.getParentOrThrow()

    if (!$isCollapsibleContainerNode(containerNode)) {
      throw new Error("CollapsibleTitleNode expects to be child of CollapsibleContainerNode")
    }

    if (containerNode.getOpen()) {
      const contentNode = this.getNextSibling()
      if (!$isCollapsibleContentNode(contentNode)) {
        throw new Error("CollapsibleTitleNode expects to have CollapsibleContentNode sibling")
      }

      const firstChild = contentNode.getFirstChild()
      if ($isElementNode(firstChild)) {
        return firstChild
      } else {
        const paragraph = $createParagraphNode()
        contentNode.append(paragraph)
        return paragraph
      }
    } else {
      const paragraph = $createParagraphNode()
      containerNode.insertAfter(paragraph, restoreSelection)
      return paragraph
    }
  }
}

export function $createCollapsibleTitleNode() {
  return new CollapsibleTitleNode()
}

export function $isCollapsibleTitleNode(node) {
  return node instanceof CollapsibleTitleNode
}
