import React, { createContext, useContext, useMemo } from "react"
import { createEmptyHistoryState } from "@lexical/react/LexicalHistoryPlugin"

const Context = createContext({})

export const SharedHistoryContext = ({ children }) => {
  const historyContext = useMemo(() => ({ historyState: createEmptyHistoryState() }), [])
  return <Context.Provider value={historyContext}>{children}</Context.Provider>
}

export const useSharedHistoryContext = () => {
  return useContext(Context)
}
