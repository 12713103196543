import React from "react"
import { DecoratorNode } from "lexical"

import FileComponent from "../FileComponent"

function convertFile(domNode) {
  const excalidrawData = domNode.getAttribute("data-lexical-excalidraw-json")
  if (excalidrawData) {
    const node = $createFileNode()
    node.__data = excalidrawData
    return {
      node,
    }
  }
  return null
}

export class FileNode extends DecoratorNode {
  __src
  __fileType
  __fileName

  constructor(src, fileType, fileName, key) {
    super(key)
    this.state = {
      fileType: fileType,
      fileName: fileName,
      src: src,
    }
  }

  static getType() {
    return "file-item"
  }

  static clone(node) {
    return new FileNode(node.state.src, node.state.fileType, node.state.fileName, node.key)
  }

  createDOM(config) {
    const div = document.createElement("span")
    const theme = config.theme
    const className = theme.image
    if (className !== undefined) {
      div.className = className
    }
    return div
  }

  updateDOM() {
    return false
  }

  exportDOM(editor) {
    const element = document.createElement("span")

    element.style.display = "inline-block"

    const content = editor.getElementByKey(this.getKey())

    if (content !== null) {
      const svg = content.querySelector("svg")
      if (svg !== null) {
        element.innerHTML = svg.outerHTML
      }
    }

    element.setAttribute("data-lexical-file", this.state)
    return { element }
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (!domNode.hasAttribute("data-lexical-file")) {
          return null
        }
        return {
          conversion: convertFile,
          priority: 1,
        }
      },
    }
  }

  exportJSON() {
    return {
      src: this.state.src,
      type: "file-item",
      fileType: this.state.fileType,
      fileName: this.state.fileName,
      version: 1,
    }
  }

  static importJSON(serializedNode) {
    const { fileType, fileName, src } = serializedNode
    return new FileNode(src, fileType, fileName)
  }

  getfileType() {
    return this.__fileType
  }

  decorate() {
    const { fileType, fileName, src } = this.state
    return <FileComponent fileType={fileType} fileName={fileName} src={src} />
  }
}

export function $createFileNode({ src, fileType, fileName }) {
  return new FileNode(src, fileType, fileName)
}

export function $isFileNode(node) {
  return node instanceof FileNode
}
