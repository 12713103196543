import React, { useEffect, useState } from "react"

import {
  $objectIdPermissionsLoading,
  $objectIdPermissionsStore,
  checkPermission,
  loadObjectIdPermissions,
} from "@src/store/permissionModel"
import { useUnit } from "effector-react"

import {
  ActionsPopover,
  apiClient,
  ArchiveIcon,
  Button,
  ConfirmModal,
  CopyIcon,
  PencilIcon,
  Spinner,
  UnArchiveIcon,
  Tooltip,
} from "@dit/core-frontend"
import { API_ENDPOINTS, ROUTES } from "@constants"
import { Box, Text } from "@mos-cat/ds"
import { Delete, Meatball } from "@mos-cat/ds-icons"
import { Link } from "react-router-dom"
import { ZoneCreateTarget } from "@components/zones/ZoneCreateTarget"
import { ZoneArchiveButton, ZoneUnArchiveButton } from "@components/zones"
import { ZONE_SECTION_LINKS } from "@utils/zones"

export const ZoneActions = ({ zone, onDelete, onArchive, ...popoverProps }) => {
  const [isOpen, setIsOpen] = useState(false)
  const loadPermissions = useUnit(loadObjectIdPermissions)
  const permissionsLoading = useUnit($objectIdPermissionsLoading)
  const zonePermissions = useUnit($objectIdPermissionsStore)
  const permissions = zonePermissions[zone?.id]

  const checkZonePermission = (identifier) => {
    return permissions?.includes(identifier) || checkPermission(identifier)
  }

  useEffect(() => {
    if (isOpen) {
      loadPermissions(zone.id)
    }
  }, [zone.id, loadPermissions, isOpen])

  const isArchive = !!zone.archive

  return (
    <ActionsPopover
      customHoverStyle={{ backgroundColor: "" }}
      hoverColor=""
      customBackground="none"
      content={
        <Text color="gray-60" fontSize="14px" onClick={() => setIsOpen(true)}>
          <Meatball />
        </Text>
      }
      {...popoverProps}
    >
      {(b, hidePopover) =>
        isOpen && permissionsLoading ? (
          <Box height="20px">
            <Spinner space="sp-16" wrapper="div" iconSize="20px" />
          </Box>
        ) : !checkZonePermission("ZONE_CREATE") &&
          !checkZonePermission("ZONE_EDIT") &&
          !checkZonePermission("ZONE_ARCHIVE") &&
          !checkZonePermission("ZONE_DELETE") ? (
          <Text fontSize="14px">Нет доступных действий</Text>
        ) : (
          <>
            {isArchive ? (
              <ZoneUnArchiveButton
                zone={zone}
                onSuccess={() => {
                  onArchive(false)
                  hidePopover()
                }}
                buttonProps={{
                  kind: "menu-button",
                  children: (
                    <>
                      <UnArchiveIcon color="currentColor" size={21} />
                      Вернуть из архива
                    </>
                  ),
                }}
              />
            ) : (
              <>
                {checkZonePermission("ZONE_CREATE") && (
                  <ZoneCreateTarget
                    copyZone={zone}
                    modalDisclosureProps={{ as: Button, kind: "menu-button" }}
                    onCloseModal={hidePopover}
                  >
                    <CopyIcon color="currentColor" />
                    Копировать
                  </ZoneCreateTarget>
                )}
                {checkZonePermission("ZONE_EDIT") && (
                  <Box
                    as={Link}
                    display="flex"
                    to={`${ROUTES.ZONE}/${zone.slug}/${ZONE_SECTION_LINKS.SETTINGS}`}
                    onClick={hidePopover}
                  >
                    <Button kind="menu-button">
                      <PencilIcon color="currentColor" />
                      Редактировать
                    </Button>
                  </Box>
                )}
                {checkZonePermission("ZONE_ARCHIVE") && (
                  <ZoneArchiveButton
                    zone={zone}
                    onSuccess={() => {
                      onArchive(true)
                      hidePopover()
                    }}
                    onCancel={hidePopover}
                    buttonProps={{
                      kind: "menu-button",
                      children: (
                        <>
                          <ArchiveIcon color="currentColor" size={21} />
                          Архивировать
                        </>
                      ),
                    }}
                  />
                )}
              </>
            )}
            {checkZonePermission("ZONE_DELETE") && (
              <ConfirmModal
                title="Удаление раздела"
                description={`Удалить раздел «${zone.title}»?`}
                modalDisclosureProps={{
                  as: Button,
                  kind: "menu-button",
                  children: (
                    <>
                      <Delete color="currentColor" />
                      Удалить
                    </>
                  ),
                }}
                successText="Раздел удален"
                confirmButtonText="Удалить"
                onSuccess={() => {
                  onDelete()
                  hidePopover()
                }}
                onCancel={hidePopover}
                request={() => apiClient.delete(`${API_ENDPOINTS.zone}/${zone.id}`)}
              />
            )}
          </>
        )
      }
    </ActionsPopover>
  )
}
