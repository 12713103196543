import React from "react"
import { Link } from "react-router-dom"

import { Button, ReturnArrowIcon } from "@dit/core-frontend"
import { Box, Text } from "@mos-cat/ds"

import { ROUTES } from "@constants"

const TopSectionButton = ({ zoneId = "", pageId = "" }) => {
  return (
    <Link to={`${ROUTES.ZONE}/${zoneId}/pages/${pageId}`}>
      <Button kind="gray" backgroundColor="transparent !important">
        <ReturnArrowIcon />
        <Text as={Box} color="gray-60" fontSize="14px">
          Вернуться к странице
        </Text>
      </Button>
    </Link>
  )
}

export default TopSectionButton
