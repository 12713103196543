// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .collapsible_CollapsibleContainer__92Pg9 {
    margin-bottom: 8px;

    border: 1px solid #eee;
    border-radius: 10px;
    background: #fcfcfc;
  }
  
  .collapsible_CollapsibleTitle__eSVSJ {
    position: relative;

    padding: 5px 5px 5px 20px;

    list-style: none;

    cursor: pointer;

    outline: none;

    font-weight: bold;
  }
  
  .collapsible_CollapsibleTitle__eSVSJ::marker,
  .collapsible_CollapsibleTitle__eSVSJ::-webkit-details-marker {
    display: none;
  }
  
  .collapsible_CollapsibleTitle__eSVSJ::before {
    position: absolute;
    top: 50%;
    left: 7px;

    display: block;

    content: '';
    transform: translateY(-50%);

    border-width: 4px 6px;
    border-style: solid;
    border-color: transparent;
    border-left-color: #000;
  }
  
  .collapsible_CollapsibleContainer__92Pg9[open] > .collapsible_CollapsibleTitle__eSVSJ::before {
    border-width: 6px 4px 0;
    border-color: transparent;
    border-top-color: #000;
  }
  
  .collapsible_CollapsibleContent__gdXeT {
    padding: 0 5px 5px 20px;
  }
  
  .collapsible_CollapsibleCollapsed__\\+g0I\\+ .collapsible_CollapsibleContent__gdXeT {
    display: none;

    -webkit-user-select: none;

            user-select: none;
  }`, "",{"version":3,"sources":["webpack://./src/components/form/Editor/plugins/CollapsiblePlugin/collapsible.module.css"],"names":[],"mappings":"CAAC;IACG,kBAAkB;;IAElB,sBAAsB;IACtB,mBAAmB;IACnB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;;IAElB,yBAAyB;;IAEzB,gBAAgB;;IAEhB,eAAe;;IAEf,aAAa;;IAEb,iBAAiB;EACnB;;EAEA;;IAEE,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;;IAET,cAAc;;IAEd,WAAW;IACX,2BAA2B;;IAE3B,qBAAqB;IACrB,mBAAmB;IACnB,yBAAyB;IACzB,uBAAuB;EACzB;;EAEA;IACE,uBAAuB;IACvB,yBAAyB;IACzB,sBAAsB;EACxB;;EAEA;IACE,uBAAuB;EACzB;;EAEA;IACE,aAAa;;IAEb,yBAAiB;;YAAjB,iBAAiB;EACnB","sourcesContent":[" .CollapsibleContainer {\n    margin-bottom: 8px;\n\n    border: 1px solid #eee;\n    border-radius: 10px;\n    background: #fcfcfc;\n  }\n  \n  .CollapsibleTitle {\n    position: relative;\n\n    padding: 5px 5px 5px 20px;\n\n    list-style: none;\n\n    cursor: pointer;\n\n    outline: none;\n\n    font-weight: bold;\n  }\n  \n  .CollapsibleTitle::marker,\n  .CollapsibleTitle::-webkit-details-marker {\n    display: none;\n  }\n  \n  .CollapsibleTitle::before {\n    position: absolute;\n    top: 50%;\n    left: 7px;\n\n    display: block;\n\n    content: '';\n    transform: translateY(-50%);\n\n    border-width: 4px 6px;\n    border-style: solid;\n    border-color: transparent;\n    border-left-color: #000;\n  }\n  \n  .CollapsibleContainer[open] > .CollapsibleTitle::before {\n    border-width: 6px 4px 0;\n    border-color: transparent;\n    border-top-color: #000;\n  }\n  \n  .CollapsibleContent {\n    padding: 0 5px 5px 20px;\n  }\n  \n  .CollapsibleCollapsed .CollapsibleContent {\n    display: none;\n\n    user-select: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CollapsibleContainer": `collapsible_CollapsibleContainer__92Pg9`,
	"CollapsibleTitle": `collapsible_CollapsibleTitle__eSVSJ`,
	"CollapsibleContent": `collapsible_CollapsibleContent__gdXeT`,
	"CollapsibleCollapsed": `collapsible_CollapsibleCollapsed__+g0I+`
};
export default ___CSS_LOADER_EXPORT___;
