// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapse_collapseButton__G7wGu {
    transition: all 0.3s ease;
    transform: rotate(0);

    &.collapse_collapsed__HVOw9 {
        transform: rotate(90deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/assets/styles/collapse.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,oBAAoB;;IAEpB;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".collapseButton {\n    transition: all 0.3s ease;\n    transform: rotate(0);\n\n    &.collapsed {\n        transform: rotate(90deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapseButton": `collapse_collapseButton__G7wGu`,
	"collapsed": `collapse_collapsed__HVOw9`
};
export default ___CSS_LOADER_EXPORT___;
