import React, { memo } from "react"

import { Box, Text } from "@mos-cat/ds"
import { PAGE_STATUSES } from "@utils/pages"

const defaultColors = {
  color: "var(--gray-60)",
  backgroundColor: "var(--gray-10)",
}

const statusColorBySlug = {
  [PAGE_STATUSES.DRAFT]: {
    color: "var(--warning-70)",
    backgroundColor: "var(--warning-10)",
  },
  [PAGE_STATUSES.PUBLISH]: {
    color: "var(--success-70)",
    backgroundColor: "var(--success-10)",
  },
  [PAGE_STATUSES.ARCHIVE]: {
    color: "var(--white)",
    backgroundColor: "#C5AD8D",
  },
  // deleted: {
  //   color: "var(--error-70)",
  //   backgroundColor: "var(--error-05)",
  // },
}

export const PageStatus = memo(({ status = {} }) => {
  const { slug, title } = status
  const additionalProps = statusColorBySlug[slug] || defaultColors

  return (
    <Text
      as={Box}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={["auto", "120px"]}
      height={["29px", "30px"]}
      padding={["4px 10px!important", "0.25rem 1rem 0.315rem 1rem!important"]}
      borderRadius="4px"
      fontSize="14px"
      lineHeight="1"
      {...additionalProps}
    >
      {title}
    </Text>
  )
})

PageStatus.displayName = "PageStatus"
