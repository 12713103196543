import { LexicalNestedComposer } from "@lexical/react/LexicalNestedComposer"
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin"
import React, { memo, useState } from "react"
import theme from "../../theme"

import { ContentEditable } from "@lexical/react/LexicalContentEditable"
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary"
import FilesPlugin from "../../plugins/FilesPlugin"
import { Box } from "@mos-cat/ds"
import { PreviewNodes } from "../../context/PreviewNodesList"
import { TableFilterBox } from "./TableFilterBox"
import { $createParagraphNode, $getRoot, createEditor } from "lexical"
import { $generateNodesFromSerializedNodes } from "@lexical/clipboard"

const HeadingItem = memo(
  ({
    column,
    rows,
    handleSort,
    handleFilter,
    sortParams,
    setSortParams,
    filtersParams,
    setFiltersParams,
    setIsLoading,
  }) => {
    const [isHovered, setIsHovered] = useState(false)

    const newEditor = createEditor({
      nodes: PreviewNodes,
      editable: false,
      disableEvents: true,
    })
    newEditor.update(() => {
      const root = $getRoot()
      const paragraphNode = $createParagraphNode()
      const node = $generateNodesFromSerializedNodes(column?.content?.children || [])
      paragraphNode.append(...(node || node))
      root.append(paragraphNode)
    })
    return (
      <td
        className={theme.tableCell}
        style={{
          backgroundColor: column.backgroundColor,
          width: column.width,
          position: "relative",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <LexicalNestedComposer
          initialEditor={newEditor}
          initialTheme={theme}
          initialNodes={PreviewNodes}
        >
          <RichTextPlugin
            contentEditable={<ContentEditable />}
            placeholder={<div> {""} </div>}
            ErrorBoundary={LexicalErrorBoundary}
          />
          <FilesPlugin captionsEnabled={true} />
        </LexicalNestedComposer>

        <Box position="absolute" right="0px" top="2px" style={{ cursor: "pointer" }}>
          <TableFilterBox
            handleSort={handleSort}
            handleFilter={handleFilter}
            accessor={column.accessor}
            filtersList={rows[column.accessor]}
            sortParams={sortParams}
            setSortParams={setSortParams}
            filtersParams={filtersParams}
            setFiltersParams={setFiltersParams}
            setIsLoading={setIsLoading}
          />
        </Box>
      </td>
    )
  },
)
HeadingItem.displayName = "HeadingItemComp"
export default HeadingItem
