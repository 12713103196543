// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.global_sideContainer__Z\\+AV6 {
  background-color: var(--gray-05);
}

@media (max-width: 1366px) {
  .global_sideContainer__Z\\+AV6 {
    display: none !important;
  }
}

body {overflow: hidden;}
`, "",{"version":3,"sources":["webpack://./src/global.module.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;AAClC;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF;;AAEA,MAAM,gBAAgB,CAAC","sourcesContent":[".sideContainer {\n  background-color: var(--gray-05);\n}\n\n@media (max-width: 1366px) {\n  .sideContainer {\n    display: none !important;\n  }\n}\n\nbody {overflow: hidden;}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideContainer": `global_sideContainer__Z+AV6`
};
export default ___CSS_LOADER_EXPORT___;
