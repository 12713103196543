import { ActionsPopover, Button, Ellipsis } from "@dit/core-frontend"
import { Box, Text } from "@mos-cat/ds"
import React from "react"
import { TextIcon } from "@editor/theme/editorIcons"
import { ArrowDown } from "@mos-cat/ds-icons"
import { $getSelection, $isRangeSelection } from "lexical"
import { $patchStyleText } from "@lexical/selection"
import { buttonProps } from "./buttonProps"
const FONT_FAMILY_OPTIONS = [
  ["Arial", "Arial"],
  ["Courier New", "Courier New"],
  ["Georgia", "Georgia"],
  ["Times New Roman", "Times New Roman"],
  ["Trebuchet MS", "Trebuchet MS"],
  ["Verdana", "Verdana"],
]

const FontFamilyDropDown = ({ editor, value, style }) => {
  return (
    <ActionsPopover
      content={
        <Button {...buttonProps} kind="borderless" vertSize="30" width="138px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gridGap="2px"
            width="100%"
          >
            <Box display="flex" gridGap="2px" alignItems="center" color="#13151A">
              <Text>
                <TextIcon />
              </Text>
              <Text fontSize="fs-14">
                <Ellipsis lines="1">{value || FONT_FAMILY_OPTIONS[0][0]}</Ellipsis>
              </Text>
            </Box>

            <Box justifySelf="flex-end" fontSize="fs-14">
              <ArrowDown width="21px" height="21px" />
            </Box>
          </Box>
        </Button>
      }
    >
      {(buttonProps) => (
        <>
          {FONT_FAMILY_OPTIONS.map(([option, text]) => (
            <Button
              {...buttonProps}
              key={text}
              onClick={() => {
                editor.update(() => {
                  const selection = $getSelection()
                  if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, {
                      [style]: option,
                    })
                  }
                })
              }}
            >
              {text}
            </Button>
          ))}
        </>
      )}
    </ActionsPopover>
  )
}

export default FontFamilyDropDown
