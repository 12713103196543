import { $setSelection, createEditor, DecoratorNode } from "lexical"
import * as React from "react"
import { Suspense } from "react"
import { createPortal } from "react-dom"

const StickyComponent = React.lazy(() => import("./StickyComponent"))

export class StickyNode extends DecoratorNode {
  __x
  __y
  __color
  __caption

  static getType() {
    return "sticky"
  }

  static clone(node) {
    return new StickyNode(node.__x, node.__y, node.__color, node.__caption, node.__key)
  }
  static importJSON(serializedNode) {
    const stickyNode = new StickyNode(
      serializedNode.xOffset,
      serializedNode.yOffset,
      serializedNode.color,
    )
    const caption = serializedNode.caption
    const nestedEditor = stickyNode.__caption
    const editorState = nestedEditor.parseEditorState(caption.editorState)
    if (!editorState.isEmpty()) {
      nestedEditor.setEditorState(editorState)
    }
    return stickyNode
  }

  constructor(x, y, color, caption, key) {
    super(key)
    this.__x = x
    this.__y = y
    this.__caption = caption || createEditor()
    this.__color = color
  }

  exportJSON() {
    return {
      caption: this.__caption.toJSON(),
      color: this.__color,
      type: "sticky",
      version: 1,
      xOffset: this.__x,
      yOffset: this.__y,
    }
  }

  createDOM(config) {
    const div = document.createElement("div")
    div.style.display = "contents"
    return div
  }

  updateDOM() {
    return false
  }

  setPosition(x, y) {
    const writable = this.getWritable()
    writable.__x = x
    writable.__y = y
    $setSelection(null)
  }

  toggleColor() {
    const writable = this.getWritable()
    writable.__color = writable.__color === "pink" ? "yellow" : "pink"
  }

  decorate(editor) {
    if (editor._config.namespace !== "EditorPreviewFeed") {
      const mainContainer = document.getElementById("mainContainer")
      const sideContainer = document.getElementById("sideContainer")
      const editorRoot = editor.getRootElement()
      const mainContainerRect = mainContainer?.getBoundingClientRect()
      const sideContainerRect = sideContainer?.getBoundingClientRect()
      const editorRect = editorRoot?.getBoundingClientRect()

      return createPortal(
        <Suspense fallback={null}>
          <StickyComponent
            color={this.__color}
            x={this.__x}
            y={this.__y}
            nodeKey={this.getKey()}
            caption={this.__caption}
            mainContainer={mainContainer}
            mainContainerRect={mainContainerRect}
            sideContainerRect={sideContainerRect}
            editorRoot={editorRoot}
            editorRect={editorRect}
          />
        </Suspense>,
        mainContainer,
      )
    }
    return null
  }

  isIsolated() {
    return true
  }
}

export function $isStickyNode(node) {
  return node instanceof StickyNode
}

export function $createStickyNode(xOffset, yOffset) {
  return new StickyNode(xOffset, yOffset, "yellow")
}
