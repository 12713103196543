import React, { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { useZoneContext } from "@components/contexts/ZoneContext"
import { apiClient, ErrorPage, Spinner } from "@dit/core-frontend"
import { TemplatePagePreview } from "@components/pageTemplates/TemplatePagePreview"
import { PageTemplateForm } from "@components/pageTemplates/PageTemplateForm"
import PageTemplatesCurtain from "@components/pageTemplates/PageTemplatesCurtain"

import { API_ENDPOINTS, ROUTES } from "@constants"

const Page = ({ setAdditionalBreadcrumbs, isEdit }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [templateData, setTemplateData] = useState(null)
  const [isCurtainOpen, setIsCurtainOpen] = useState(false)

  // const { showAlert } = useAppContext()
  const { zone } = useZoneContext()
  const { templateId } = useParams()

  const fetchTemplatePage = useCallback(async () => {
    setIsLoading(true)
    try {
      const { data } = await apiClient.get(`${API_ENDPOINTS.pageTemplates}/${templateId}`)
      setTemplateData(data.data)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }, [templateId])

  useEffect(() => {
    void fetchTemplatePage()
  }, [fetchTemplatePage, templateId])

  useEffect(() => {
    if (isLoading) {
      return
    }

    setAdditionalBreadcrumbs([
      { label: "Шаблоны", to: `${ROUTES.ZONE}/${zone.slug}/templates` },
      { label: templateData?.title, to: `${ROUTES.ZONE}/${zone.slug}/templates/${templateId}` },
    ])
  }, [setAdditionalBreadcrumbs, zone.slug, templateData, templateId, isLoading])

  const updateTemplateData = (values) => {
    setTemplateData((prevTemplate) => ({ ...prevTemplate, ...values }))
  }

  if (isLoading) {
    return (
      <Spinner
        styles={{ position: "static" }}
        space="sp-16"
        iconSize="30px"
        overlayVisible={false}
      />
    )
  }

  if (!templateData) {
    return (
      <ErrorPage
        title="Шаблон не найден"
        text="Шаблон не существует или был удален"
        mainLink={ROUTES.HOME}
      />
    )
  }

  return (
    <>
      {isEdit ? (
        <PageTemplateForm template={templateData} updateTemplateData={updateTemplateData} />
      ) : (
        <TemplatePagePreview
          template={templateData}
          openEditTemplateCurtain={() => setIsCurtainOpen(true)}
          updateTemplateData={updateTemplateData}
        />
      )}

      <PageTemplatesCurtain
        zone={zone}
        isOpen={isCurtainOpen}
        defaultTemplateData={templateData}
        templateId={templateData.id}
        onClose={() => {
          setIsCurtainOpen(false)
        }}
        updateResponseData={(i, k, formValues) => {
          updateTemplateData(formValues)
        }}
      />
    </>
  )
}

export default Page
