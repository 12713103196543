// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageResizer_imageControlWrapper__jL8X3 {
  touch-action: none;
}

.imageResizer_imageResizer__C7oY5 {
  position: absolute;

  display: block;

  width: 7px;
  height: 7px;

  border: 1px solid #fff;
  background-color: rgb(60 132 244);
}

.imageResizer_imageResizer__n__wB7e- {
  top: 1px;
  left: 40%;

  cursor: n-resize;
}

.imageResizer_imageResizer__ne__uGfrm {
  top: 1px;
  right: 23px;

  cursor: ne-resize;
}

.imageResizer_imageResizer__e__djFB6 {
  right: 23px;
  bottom: 44%;

  cursor: e-resize;
}

.imageResizer_imageResizer__se__A6meS {
  right: 23px;
  bottom: -6px;

  cursor: nwse-resize;
}

.imageResizer_imageResizer__s__jyDr0 {
  bottom: -7px;
  left: 40%;

  cursor: s-resize;
}

.imageResizer_imageResizer__sw__eio2Y {
  bottom: -7px;
  left: -7px;

  cursor: sw-resize;
}

.imageResizer_imageResizer__w__aHD\\+w {
  bottom: 44%;
  left: -7px;

  cursor: w-resize;
}

.imageResizer_imageResizer__nw__mdWr4 {
  top: 1px;
  left: -7px;

  cursor: nw-resize;
}
`, "",{"version":3,"sources":["webpack://./src/components/form/Editor/components/ImageResizer/imageResizer.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;;EAElB,cAAc;;EAEd,UAAU;EACV,WAAW;;EAEX,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,QAAQ;EACR,SAAS;;EAET,gBAAgB;AAClB;;AAEA;EACE,QAAQ;EACR,WAAW;;EAEX,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,WAAW;;EAEX,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;;EAEZ,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,SAAS;;EAET,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,UAAU;;EAEV,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,UAAU;;EAEV,gBAAgB;AAClB;;AAEA;EACE,QAAQ;EACR,UAAU;;EAEV,iBAAiB;AACnB","sourcesContent":[".imageControlWrapper {\n  touch-action: none;\n}\n\n.imageResizer {\n  position: absolute;\n\n  display: block;\n\n  width: 7px;\n  height: 7px;\n\n  border: 1px solid #fff;\n  background-color: rgb(60 132 244);\n}\n\n.imageResizer__n {\n  top: 1px;\n  left: 40%;\n\n  cursor: n-resize;\n}\n\n.imageResizer__ne {\n  top: 1px;\n  right: 23px;\n\n  cursor: ne-resize;\n}\n\n.imageResizer__e {\n  right: 23px;\n  bottom: 44%;\n\n  cursor: e-resize;\n}\n\n.imageResizer__se {\n  right: 23px;\n  bottom: -6px;\n\n  cursor: nwse-resize;\n}\n\n.imageResizer__s {\n  bottom: -7px;\n  left: 40%;\n\n  cursor: s-resize;\n}\n\n.imageResizer__sw {\n  bottom: -7px;\n  left: -7px;\n\n  cursor: sw-resize;\n}\n\n.imageResizer__w {\n  bottom: 44%;\n  left: -7px;\n\n  cursor: w-resize;\n}\n\n.imageResizer__nw {\n  top: 1px;\n  left: -7px;\n\n  cursor: nw-resize;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageControlWrapper": `imageResizer_imageControlWrapper__jL8X3`,
	"imageResizer": `imageResizer_imageResizer__C7oY5`,
	"imageResizer__n": `imageResizer_imageResizer__n__wB7e-`,
	"imageResizer__ne": `imageResizer_imageResizer__ne__uGfrm`,
	"imageResizer__e": `imageResizer_imageResizer__e__djFB6`,
	"imageResizer__se": `imageResizer_imageResizer__se__A6meS`,
	"imageResizer__s": `imageResizer_imageResizer__s__jyDr0`,
	"imageResizer__sw": `imageResizer_imageResizer__sw__eio2Y`,
	"imageResizer__w": `imageResizer_imageResizer__w__aHD+w`,
	"imageResizer__nw": `imageResizer_imageResizer__nw__mdWr4`
};
export default ___CSS_LOADER_EXPORT___;
