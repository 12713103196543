// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes comment_highlight-selected-card__hXQtW {
    from {
        background-color: var(--gray-05);
    }

    to {
        background-color: var(--white);
    }
}

.comment_selectedComment__-5TKU {
    animation: comment_highlight-selected-card__hXQtW 6s;
}
`, "",{"version":3,"sources":["webpack://./src/components/Comment/comment.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,gCAAgC;IACpC;;IAEA;QACI,8BAA8B;IAClC;AACJ;;AAEA;IACI,oDAAqC;AACzC","sourcesContent":["@keyframes highlight-selected-card {\n    from {\n        background-color: var(--gray-05);\n    }\n\n    to {\n        background-color: var(--white);\n    }\n}\n\n.selectedComment {\n    animation: highlight-selected-card 6s;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedComment": `comment_selectedComment__-5TKU`,
	"highlight-selected-card": `comment_highlight-selected-card__hXQtW`
};
export default ___CSS_LOADER_EXPORT___;
