import React, { useEffect, useState, useCallback } from "react"
import { Stack, Text } from "@mos-cat/ds"
import { apiClient, Spinner, useAppContext } from "@dit/core-frontend"
import HistoryList from "@components/pages/History/HistoryList"
import { API_ENDPOINTS } from "@constants"

const HistoryCurtainContent = ({ zone, page, closeModal, onUpdatePage }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [historyList, setHistoryList] = useState([])
  const { showAlert } = useAppContext()

  const getHistoryList = useCallback(async () => {
    try {
      const { data } = await apiClient.get(`${API_ENDPOINTS.page}/${page.id}/history`)
      setHistoryList(data?.data || [])
    } catch (err) {
      console.error(err)
      showAlert(err?.data?.errors?.[0]?.message || "Произошла ошибка при загрузке истории")
    }
    setIsLoading(false)
  }, [page, showAlert])

  useEffect(() => {
    void getHistoryList()
  }, [getHistoryList])

  return (
    <Stack space="50px" minHeight="100%">
      <Text color="gray-90" fontSize="26px" fontWeight="600" lineHeight="32px">
        История версий
      </Text>
      {isLoading ? (
        <Spinner styles={{ position: "static" }} overlayVisible={false} />
      ) : (
        <HistoryList
          page={page}
          historyList={historyList}
          zone={zone}
          closeModal={closeModal}
          onUpdatePage={onUpdatePage}
        />
      )}
    </Stack>
  )
}

export default HistoryCurtainContent
