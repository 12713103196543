import React from "react"
import { Link } from "react-router-dom"

import { API_ENDPOINTS } from "@constants"

import { Box } from "@mos-cat/ds"
import { ActionsPopover, apiClient, ConfirmModal, PencilIcon, Button } from "@dit/core-frontend"
import { Person } from "@components/general"
import { Delete } from "@mos-cat/ds-icons"

export const createTableConfig = (
  updateResponseData,
  handleSetModal,
  setTeamMemberData,
  checkZonePermission,
) => ({
  cells: [
    {
      label: "Пользователь",
      key: "assignedToUser",
      width: "450px",
      getContent: (user) => <Person person={user?.assignedToUser} emptyText="Не назначен" />,
    },
    {
      label: "E-mail",
      key: "role",
      width: "350px",

      getContent: (user) => (
        <Box
          minWidth="350px"
          as={Link}
          to={`mailto:${user?.assignedToUser?.email}`}
          width="150px"
          fontSize="fs-14"
        >
          {user?.assignedToUser?.email}{" "}
        </Box>
      ),
    },
    {
      label: "Роль",
      key: "role",
      getContent: (user) => (
        <Box width="150px" fontSize="fs-14">
          {user?.role?.title}{" "}
        </Box>
      ),
    },
    {
      label: "",
      key: "secret",
      getContent: (user) =>
        checkZonePermission("ZONE_SETTINGS_TEAM_MEMBERS_EDIT") ||
        checkZonePermission("ZONE_SETTINGS_TEAM_MEMBERS_DELETE") ? (
          <ActionsPopover customHoverStyle={{ backgroundColor: "" }} customBackground="none">
            {(buttonProps, hidePopover) => (
              <Box display="flex" flexDirection="column" alignItems="flex-start" gridGap="10px">
                {checkZonePermission("ZONE_SETTINGS_TEAM_MEMBERS_EDIT") && (
                  <Button
                    display="flex"
                    kind="menu-button"
                    onClick={() => {
                      handleSetModal("update-member")
                      setTeamMemberData(user)
                    }}
                  >
                    <PencilIcon color="currentColor" size={21} />
                    Редактировать
                  </Button>
                )}
                {checkZonePermission("ZONE_SETTINGS_TEAM_MEMBERS_DELETE") && (
                  <ConfirmModal
                    title="Удаление участника"
                    description="Вы точно хотите удалить участника?"
                    modalDisclosureProps={{
                      as: Button,
                      kind: "menu-button",
                      children: (
                        <>
                          <Delete color="currentColor" size={21} />
                          Удалить
                        </>
                      ),
                    }}
                    successText="Удаление прошло успешно"
                    confirmButtonText="Удалить"
                    onSuccess={() => {
                      updateResponseData?.(user.id, "remove")
                      hidePopover()
                    }}
                    onCancel={hidePopover}
                    request={() => apiClient.delete(`${API_ENDPOINTS.teamMember}/${user.id}`)}
                  />
                )}
              </Box>
            )}
          </ActionsPopover>
        ) : (
          <></>
        ),
    },
  ],
})
