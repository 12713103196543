import { Spinner } from "@dit/core-frontend"
import { Box } from "@mos-cat/ds"
import React, { forwardRef } from "react"

/**
 * Menu component for the BeautifulMentionsPlugin.
 */
export function Menu({ isOpen, ...other }) {
  return (
    <>
      {isOpen && (
        <Box
          backgroundColor="white"
          width="600px"
          maxHeigh="230px"
          // zIndex="500"
          overflowY="hidden"
          overflowX="hidden"
          boxShadow="0px 4px 12px 0px rgba(0, 20, 67, 0.12)"
        >
          <ul
            style={{
              padding: "0",
              listStyle: "none",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
            {...other}
          />
        </Box>
      )}
    </>
  )
}

/**
 * MenuItem component for the BeautifulMentionsPlugin.
 */
export const MenuItem = forwardRef(({ selected, item, itemValue, ...props }, ref) => {
  return <li ref={ref} {...props} />
})

MenuItem.displayName = "MenuItem"
