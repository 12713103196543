import React, { memo, useCallback, useEffect, useMemo } from "react"

import { Form } from "react-final-form"
import { Box, Button, Text, Error } from "@mos-cat/ds"
import { usePageContext } from "@components/contexts/PageContext"
import { useZoneContext } from "@components/contexts/ZoneContext"
import { Editor, stringToEditorContent } from "@components/form"
import {
  PageBlockRow,
  PageBlockRowSection,
  PageBlockContent,
  getValidationErrors,
  TextareaAutosize,
  Tooltip,
} from "@dit/core-frontend"

import { Link } from "react-router-dom"
import isEqual from "lodash/isEqual"
import debounce from "lodash/debounce"
import { PAGE_STATUSES } from "@utils/pages"
import { CommentTextIcon } from "../form/Editor/theme/editorIcons"

const validationSchema = {
  title: { required: true, type: "string" },
}

// isSubmitting для того, что бы если нажать на обновление версии при задержке - не летел доп запрос
const debouncedUpdatePage = debounce(
  ({ values, initialData, hasValidationErrors, updatePage, isSubmitting }) => {
    if (isSubmitting || hasValidationErrors) {
      return
    }

    const newValues = { ...values }
    const initialValues = { ...initialData }

    delete newValues.plainText
    delete initialValues.plainText

    if (!isEqual(initialValues, newValues)) {
      // В случае авто обновления нужно добавить к версии + 0.1,
      // но так, что бы версия не "округлилась
      const oldVersionArr = String(values.version).split(".")

      updatePage(
        {
          ...values,
          version: oldVersionArr[0] + "." + (Number(oldVersionArr[1] || 0) + 1),
        },
        false,
      )
    }
  },
  3000,
)

export const PageForm = memo(() => {
  const { page, statusList, updatePage, isUpdateLoading } = usePageContext()
  const { zone, addPage, togleTextCommentsShow } = useZoneContext()

  const isDraft = page.status.slug === PAGE_STATUSES.DRAFT

  const initialData = useMemo(() => {
    return {
      zone,
      title: page?.title || "",
      status: page?.status,
      plainText: page?.plainText || "",
      text: page?.text || "",
      pageId: page?.id,
      version: page?.version || "1",
      parent: page?.parent,
    }
  }, [page, zone])

  const onChangeFormValues = useCallback(
    (values, hasValidationErrors, isSubmitting) => {
      debouncedUpdatePage({ values, initialData, hasValidationErrors, updatePage, isSubmitting })
    },
    [initialData, updatePage],
  )

  useEffect(() => {
    if (page?.id) {
      addPage(page)
    }
  })

  return (
    <Form
      initialValues={initialData}
      validate={(values) => getValidationErrors(values, validationSchema)}
      onSubmit={async (values) => {
        return await updatePage({
          ...values,
          version: isDraft ? "1" : String(Math.floor(Number(values.version) + 1)),
          status: statusList.find((status) => status.slug === PAGE_STATUSES.PUBLISH),
        })
      }}
      render={({ handleSubmit, values, errors, submitting, hasValidationErrors, form }) => {
        // form.subscribe(
        //   ({ values }) => {
        //     onChangeFormValues(values, hasValidationErrors, submitting)
        //   },
        //   { values: true, submitting: true },
        // )

        return (
          <>
            <PageBlockRow
              place="middle"
              alignItems={["flex-end", "flex-start"]}
              flexDirection={["column-reverse", "row"]}
              flexWrap={["wrap-reverse", "wrap"]}
            >
              <PageBlockRowSection flex="2" maxWidth="860px" flexBasis={["100%", "unset"]}>
                <Text
                  as={Box}
                  flex={["unset", "2"]}
                  width={["100%", "auto"]}
                  position="relative"
                  color="gray-90"
                  fontSize={["22px", "30px", "40px"]}
                  fontWeight="700"
                  ml={["0", "-10px"]}
                >
                  <TextareaAutosize
                    theme="bordered"
                    isError={!!errors?.title}
                    defaultValue={values.title}
                    placeholder="Заголовок страницы"
                    enterAllowed={false}
                    disabled={submitting}
                    onChange={(event) => {
                      form.change("title", event.target.value)
                      form.blur("title")
                      form.resumeValidation()
                    }}
                  />
                  {!!errors?.title && <Error>{errors.title}</Error>}
                </Text>
              </PageBlockRowSection>
              <PageBlockRowSection mt={["0", "17px", "25px"]}>
                <Text
                  as={Button}
                  disabled={isUpdateLoading || hasValidationErrors}
                  loading={isUpdateLoading}
                  kind="primary"
                  vertSize="32px"
                  p="0 10px"
                  height="32px"
                  styles={{ fontSize: "14px" }}
                  onClick={handleSubmit}
                >
                  {isDraft ? "Опубликовать" : "Обновить"}
                </Text>
                {/* Возврат на страницу верхнего уровня */}
                <Link to={".."}>
                  <Text
                    as={Button}
                    kind="borderless"
                    vertSize="32px"
                    width="90px"
                    height="32px"
                    styles={{ fontSize: "14px" }}
                  >
                    Закрыть
                  </Text>
                </Link>
                <Tooltip content="Панель комментариев">
                  <Text
                    onClick={togleTextCommentsShow}
                    as={Button}
                    kind="borderless"
                    vertSize="32px"
                    width="30px"
                    height="32px"
                    styles={{ fontSize: "14px" }}
                  >
                    {<CommentTextIcon size="20" />}
                  </Text>
                </Tooltip>
              </PageBlockRowSection>
            </PageBlockRow>
            <PageBlockContent
              as="form"
              onSubmit={(e) => e.preventDefault()}
              style={{ width: "100%", maxWidth: "1187px", marginTop: 0 }}
            >
              <Editor
                id="PageDescription"
                content={stringToEditorContent(values.text)}
                placeholder="Здесь напишите текст, который вы хотите добавить в документ"
                disabled={submitting}
                onChange={({ text, jsonText }) => {
                  form.change("text", jsonText)
                  form.change("plainText", text)
                  form.blur("text")
                }}
              />
            </PageBlockContent>
          </>
        )
      }}
    />
  )
})

PageForm.displayName = "PageForm"
