import { Button, Tooltip } from "@dit/core-frontend"
import { Box, Text } from "@mos-cat/ds"
import { CommentTextIcon } from "@src/components/form/Editor/theme/editorIcons"
import React from "react"

const TextCommentsButton = ({ togleTextCommentsShow, ...buttonProps }) => {
  return (
    <Tooltip content="Панель комментариев">
      <Box
        onClick={togleTextCommentsShow}
        as={Button}
        width="auto !important"
        kind="menu-button"
        {...buttonProps}
      >
        <Text fontSize="14px">{<CommentTextIcon size="20" color="currentColor" />}</Text>
      </Box>
    </Tooltip>
  )
}

export default TextCommentsButton
