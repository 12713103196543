import React, { memo, useMemo } from "react"
import { Link } from "react-router-dom"

import { Breadcrumbs, Crumb } from "@mos-cat/ds"

import { ROUTES } from "@constants"

export const ZoneBreadcrumbs = memo(({ zone, additionalCrumbs = [] }) => {
  const breadcrumbsList = useMemo(() => {
    return [
      { label: "Разделы", to: ROUTES.ZONES_LIST },
      { label: zone.title, to: `${ROUTES.ZONE}/${zone.slug}` },
      ...additionalCrumbs,
    ]
  }, [zone, additionalCrumbs])

  return (
    <Breadcrumbs maxWidth="100%" styles={{ padding: 0 }}>
      {breadcrumbsList.map(({ label, ...props }, i) => (
        <Crumb key={`zone-${zone.id}-breadcrumb-${i}`} as={Link} {...props}>
          {label}
        </Crumb>
      ))}
    </Breadcrumbs>
  )
})

ZoneBreadcrumbs.displayName = "ZoneBreadcrumbs"
