import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext"
import { Box, Button, Modal, ModalDisclosure, useModalState } from "@mos-cat/ds"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { $insertNodes, COMMAND_PRIORITY_EDITOR, createCommand } from "lexical"
import * as yup from "yup"

import * as React from "react"
import { $createTableNodeWithDimensions, INSERT_TABLE_COMMAND, TableNode } from "@lexical/table"
import { Form } from "react-final-form"
import { FormInput, getFormErrorMessageForInput, ERROR_MESSAGES } from "@dit/core-frontend"

const validationSchema = yup.object().shape({
  columns: yup
    .number("Введите число")
    .required(ERROR_MESSAGES.required)
    .typeError("Введите число")
    .min(1, "Минимальное количество столбцов - 1 ")
    .max(10, "Превышено максимальное количество столбцов 10 шт")
    .transform((value, originalValue) => (originalValue === "" ? undefined : value)),
  rows: yup
    .number("Введите число")
    .required(ERROR_MESSAGES.required)
    .typeError("Введите число")
    .min(1, "Минимальное количество строк - 1 ")
    .max(50, "Превышено максимальное количество строк 50 шт")
    .transform((value, originalValue) => (originalValue === "" ? undefined : value)),
})

export default function invariant(cond, message, ...args) {
  if (cond) {
    return
  }

  throw new Error(
    "Internal Lexical error: invariant() is meant to be replaced at compile " +
      "time. There is no runtime version. Error: " +
      message,
  )
}

export const INSERT_NEW_TABLE_COMMAND = createCommand("INSERT_NEW_TABLE_COMMAND")

export const CellContext = createContext({
  cellEditorConfig: null,
  cellEditorPlugins: null,
  set: () => {
    // Empty
  },
})

export function TableContext({ children }) {
  const [contextValue, setContextValue] = useState({
    cellEditorConfig: null,
    cellEditorPlugins: null,
  })
  return (
    <CellContext.Provider
      value={useMemo(
        () => ({
          cellEditorConfig: contextValue.cellEditorConfig,
          cellEditorPlugins: contextValue.cellEditorPlugins,
          set: (cellEditorConfig, cellEditorPlugins) => {
            setContextValue({ cellEditorConfig, cellEditorPlugins })
          },
        }),
        [contextValue.cellEditorConfig, contextValue.cellEditorPlugins],
      )}
    >
      {children}
    </CellContext.Provider>
  )
}

export function InsertTableDialog({
  activeEditor,
  children,
  modalDisclosureProps,
  modalStateProps,
}) {
  const modalState = useModalState()
  const getModalState = { ...modalState, ...modalStateProps }
  const isVisible = getModalState?.visible

  const handleClose = () => {
    getModalState.hide()
  }

  const onValidate = (values) => {
    try {
      validationSchema.validateSync(values, { abortEarly: false })
    } catch (errors) {
      return errors.inner.reduce(
        (errors, error) => ({
          ...errors,
          [error.path]: error.message,
        }),
        {},
      )
    }
  }
  const onSubmit = (values) => {
    activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, {
      columns: values.columns,
      rows: values.rows,
    })
    getModalState.hide()
  }

  return (
    <>
      {children && (
        <ModalDisclosure {...modalDisclosureProps} {...getModalState}>
          {children}
        </ModalDisclosure>
      )}
      <Modal {...getModalState} heading="Создание таблицы">
        {isVisible && (
          <Form
            initialValues={{
              columns: "",
              rows: "",
            }}
            validate={(values) => onValidate(values)}
            onSubmit={onSubmit}
            render={({
              handleSubmit,
              submitErrors,
              errors,
              touched,
              submitting,
              pristine,
              dirtyFieldsSinceLastSubmit,
              hasValidationErrors,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormInput
                    wrap={false}
                    label="Введите количество столбцов"
                    inputName="columns"
                    required
                    errorMessage={getFormErrorMessageForInput(
                      { touched, errors, submitErrors, dirtyFieldsSinceLastSubmit },
                      "columns",
                    )}
                  />
                  <FormInput
                    wrap={false}
                    label="Введите количество строк"
                    inputName="rows"
                    required
                    errorMessage={getFormErrorMessageForInput(
                      { touched, errors, submitErrors, dirtyFieldsSinceLastSubmit },
                      "rows",
                    )}
                  />

                  <Box display="flex" justifyContent="start" gridGap="16px">
                    <Button
                      kind="primary"
                      type="submit"
                      disabled={submitting || hasValidationErrors}
                    >
                      Сохранить
                    </Button>
                    <Button kind="secondary" onClick={handleClose} type="reset">
                      Отменить
                    </Button>
                  </Box>
                </form>
              )
            }}
          />
        )}
      </Modal>
    </>
  )
}

export function TablePlugin({ cellEditorConfig, children }) {
  const [editor] = useLexicalComposerContext()
  const cellContext = useContext(CellContext)

  useEffect(() => {
    if (!editor.hasNodes([TableNode])) {
      invariant(false, "TablePlugin: TableNode is not registered on editor")
    }

    cellContext.set(cellEditorConfig, children)

    return editor.registerCommand(
      INSERT_NEW_TABLE_COMMAND,
      ({ columns, rows, includeHeaders }) => {
        const tableNode = $createTableNodeWithDimensions(
          Number(rows),
          Number(columns),
          includeHeaders,
        )
        $insertNodes([tableNode])
        return true
      },
      COMMAND_PRIORITY_EDITOR,
    )
  }, [cellContext, cellEditorConfig, children, editor])

  return null
}
