import React, { memo } from "react"

import { Box, Button } from "@mos-cat/ds"
import { Link } from "react-router-dom"

export const EntityLink = memo(
  ({ entity, link, visibleField = "slug", customVisibleField = null, styles = {}, ...props }) => {
    return (
      <Box
        as={Button}
        kind="borderless"
        vertSize={20}
        justifyContent="flex-start !important"
        styles={{
          alignItems: "flex-start",
          ...(styles || {}),
          "& span": {
            display: "block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            ...(styles["& span"] || {}),
          },
        }}
        {...props}
      >
        <Link to={`${link}/${entity.slug}`}>{customVisibleField || entity[visibleField]}</Link>
      </Box>
    )
  },
)

EntityLink.displayName = "EntityLink"
