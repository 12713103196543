import React from "react"
import {
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_CHECK_LIST_COMMAND,
} from "@lexical/list"
import { Box } from "@mos-cat/ds"
import { Button, Tooltip } from "@dit/core-frontend"
import { BulletList, CheckList, NumberedList } from "../../theme/editorIcons"
import { buttonProps } from "./buttonProps"

const FormattedListDropDown = ({ editor, blockType }) => {
  const formatBulletList = () => {
    if (blockType !== "bullet") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatNumberedList = () => {
    if (blockType !== "number") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }

  const formatCheckList = () => {
    if (blockType !== "check") {
      editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined)
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
    }
  }
  return (
    <Box display="flex" alignItems="center" gridGap="10px">
      <Tooltip content="Маркировочный список">
        <Button {...buttonProps} width="16px" kind="borderless" onClick={formatBulletList}>
          <BulletList />
        </Button>
      </Tooltip>
      <Tooltip content="Нумерованный список">
        <Button {...buttonProps} kind="borderless" onClick={formatNumberedList}>
          <NumberedList />
        </Button>
      </Tooltip>
      <Tooltip content="Чеклист">
        <Button {...buttonProps} kind="borderless" onClick={formatCheckList}>
          <CheckList />
        </Button>
      </Tooltip>
    </Box>
  )
}

export default FormattedListDropDown
