import React, { useCallback, useEffect } from "react"
import {
  ActionsPopover,
  apiClient,
  ConfirmModal,
  PageBlockContent,
  PageBlockHeading,
  PageBlockRow,
  PageBlockRowSection,
  PageBlockWrapper,
  Spinner,
  Table,
  DataListWrapper,
  Avatar,
  Button,
} from "@dit/core-frontend"
import { Box, Stack, Text } from "@mos-cat/ds"

import { API_ENDPOINTS } from "@constants"
import { Delete } from "@mos-cat/ds-icons"
import { AddPermissionModal } from "./AddPermissionModal"
import { usePageContext } from "@src/components/contexts/PageContext"
import { useZoneContext } from "@src/components/contexts/ZoneContext"

const createTableConfig = (updateResponseData, isDeletable) => ({
  cells: [
    {
      label: "Пользователь",
      key: "assignedToUser",
      width: "450px",
      getContent: (grantedRole) => (
        <>
          <Avatar url={grantedRole.user?.photo} size={20} />
          <Text
            as={Box}
            maxWidth="250px"
            styles={{
              display: "-webkit-box",
              "-webkit-box-orient": "vertical",
              "-webkit-line-clamp": "2",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {`${grantedRole.user?.firstName || ""} ${grantedRole.user?.lastName || ""}`}
          </Text>
        </>
      ),
    },
    {
      label: "Роль",
      key: "role",
      getContent: (grantedRole) => (
        <Box width="150px" fontSize="fs-14">
          {grantedRole?.role?.title}{" "}
        </Box>
      ),
    },
    {
      label: "",
      key: "secret",
      getContent: (grantedRole) =>
        isDeletable ? (
          <ActionsPopover customHoverStyle={{ backgroundColor: "" }} customBackground="none">
            {(b, hidePopover) => (
              <Box display="flex" flexDirection="column" alignItems="flex-start" gridGap="10px">
                <ConfirmModal
                  title="Удаление участника из команды проекта"
                  description="Вы точно хотите удалить участника?"
                  modalDisclosureProps={{
                    as: Button,
                    kind: "menu-button",
                    children: (
                      <>
                        <Delete color="currentColor" size={21} />
                        Удалить
                      </>
                    ),
                  }}
                  successText="Удаление прошло успешно"
                  confirmButtonText="Удалить"
                  onSuccess={() => {
                    updateResponseData?.(grantedRole.id, "remove")
                    hidePopover()
                  }}
                  onCancel={hidePopover}
                  request={() =>
                    apiClient.delete(`${API_ENDPOINTS.grantedRoles}/${grantedRole.id}`)
                  }
                />
              </Box>
            )}
          </ActionsPopover>
        ) : (
          <></>
        ),
    },
  ],
})

const PermissionsBlock = ({ data, getData, isLoading, isRequestLoading, updateResponseData }) => {
  const { page } = usePageContext()
  const { checkZonePermission } = useZoneContext()

  const fetchGrantedRoles = useCallback(() => {
    getData(API_ENDPOINTS.grantedRoles, {
      objectId: page.id,
    })
  }, [page.id, getData])

  const tableConfig = createTableConfig(
    updateResponseData,
    checkZonePermission("AREA_SETTINGS_PERMISSIONS_REMOVE", page.id),
  )

  useEffect(() => {
    fetchGrantedRoles()
  }, [fetchGrantedRoles])

  return (
    <>
      <PageBlockWrapper>
        <PageBlockRow place="middle">
          <PageBlockRowSection>
            <PageBlockHeading>Разрешения</PageBlockHeading>
          </PageBlockRowSection>
          {checkZonePermission("AREA_SETTINGS_PERMISSIONS_ADD", page.id) && (
            <PageBlockRowSection>
              <AddPermissionModal
                onSuccess={fetchGrantedRoles}
                modalDisclosureProps={{
                  as: Stack,
                  position: "relative",
                  styles: { cursor: "pointer" },
                }}
              >
                <Button kind="primary" vertSize="15px" width="172px" height="29px">
                  Выдать роль
                </Button>
              </AddPermissionModal>
            </PageBlockRowSection>
          )}
        </PageBlockRow>
        <PageBlockContent>
          <Table
            isLoading={isLoading}
            dataList={data?.itemsList || []}
            tableConfig={tableConfig}
            isNewTheme
            emptyText="Нет участников"
          />
          {isRequestLoading && (
            <Spinner
              space="sp-16"
              wrapper="div"
              style={{ display: "flex", alignItems: "center" }}
              overlayVisible
            />
          )}
        </PageBlockContent>
      </PageBlockWrapper>
    </>
  )
}

export const PermissionsPage = () => {
  return (
    <Stack space="40px">
      <DataListWrapper>
        <PermissionsBlock />
      </DataListWrapper>
    </Stack>
  )
}
