import * as React from "react"
import { Box, Button, Modal, ModalDisclosure, Stack, useModalState } from "@mos-cat/ds"
import { Field, Form } from "react-final-form"
import { CustomSelect, Message } from "@dit/core-frontend"
import { INSERT_LAYOUT_COMMAND } from "."

const LAYOUTS = [
  { label: "2 столбца", value: "1fr 1fr" },
  { label: "2 столбца (25% - 75%)", value: "1fr 3fr" },
  { label: "3 столбца", value: "1fr 1fr 1fr" },
  { label: "3 столбца (25% - 50% - 25%)", value: "1fr 2fr 1fr" },
  { label: "4 столбца", value: "1fr 1fr 1fr 1fr" },
]

export default function InsertLayoutDialog({
  activeEditor,
  modalDisclosureProps,
  modalStateProps,
  children,
}) {
  const modalState = useModalState()
  const getModalState = { ...modalState, ...modalStateProps }
  const isVisible = getModalState?.visible

  return (
    <>
      {children && (
        <ModalDisclosure {...modalDisclosureProps} {...getModalState}>
          {children}
        </ModalDisclosure>
      )}
      <Modal {...getModalState} heading="Макет столбцов">
        {isVisible && (
          <>
            <Form
              initialValues={{
                layout: "",
              }}
              onSubmit={async (values) => {
                let errors = null
                activeEditor.dispatchCommand(INSERT_LAYOUT_COMMAND, values.layout)
                getModalState.hide()

                if (errors) {
                  return errors
                }
              }}
              render={({
                handleSubmit,
                submitting,
                form,
                submitErrors,
                pristine,
                valid,
                dirtySinceLastSubmit,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Stack minHeight="200px">
                      <Message
                        type="error"
                        isAlwaysVisible
                        isVisible={!dirtySinceLastSubmit}
                        text={Object.values(submitErrors || {})}
                      />
                      <Field name="layout">
                        {() => (
                          <>
                            <CustomSelect
                              type="select"
                              placeholder=""
                              options={LAYOUTS}
                              defaultValue={LAYOUTS[0].value}
                              isMulti={false}
                              noOptionsMessage={() => "не найдено"}
                              isSearchable
                              customStyles={{
                                control: (provided) => ({ ...provided, minHeight: "48px" }),
                                menuList: (provided) => ({ ...provided, maxHeight: "150px" }),
                              }}
                              getOptionLabel={(option) => option.label}
                              getOptionValue={(option) => option.value}
                              onChange={(selected) => {
                                form.change("layout", selected.value)
                              }}
                            />
                          </>
                        )}
                      </Field>
                      <Box display="flex" alignItems="flex-end" flex="2">
                        <Button
                          kind="primary"
                          type="submit"
                          disabled={submitting || pristine || (!valid && !dirtySinceLastSubmit)}
                          marginTop="20px"
                        >
                          Сохранить
                        </Button>
                      </Box>
                    </Stack>
                  </form>
                )
              }}
            />
          </>
        )}
      </Modal>
    </>
  )
}
