import { addClassNamesToElement, isHTMLElement } from "@lexical/utils"
import { $applyNodeReplacement } from "lexical"
import * as React from "react"

import { DecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode"
import TableComponent from "./TableComponent"
import { $isTableRowNode } from "@lexical/table"
export class TableNode extends DecoratorBlockNode {
  __nodes

  static getType() {
    return "table"
  }

  static clone(node) {
    return new TableNode(node.__key)
  }

  static importDOM() {
    return {
      table: (domNode) => ({
        conversion: convertTableElement,
        priority: 1,
      }),
    }
  }

  static importJSON(serializedNode) {
    const node = $createTableNode(serializedNode.children)
    return node
  }

  constructor(nodes, key) {
    super(key)
    this.__nodes = nodes
    this.state = {
      tableData: nodes,
      sortBy: "",
      sortOrder: "asc",
    }
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: "table",
      nodes: this.__nodes,
      version: 1,
    }
  }

  createDOM(config, editor) {
    const tableElement = document.createElement("div")

    addClassNamesToElement(tableElement, config.theme.tablePreview)

    return tableElement
  }

  updateDOM() {
    return false
  }

  exportDOM(editor) {
    return {
      ...super.exportDOM(editor),
      after: (tableElement) => {
        if (tableElement) {
          const newElement = tableElement.cloneNode()
          const colGroup = document.createElement("colgroup")
          const tBody = document.createElement("tbody")
          if (isHTMLElement(tableElement)) {
            tBody.append(...tableElement.children)
          }
          const firstRow = this.getFirstChildOrThrow()

          if (!$isTableRowNode(firstRow)) {
            throw new Error("Expected to find row node.")
          }

          const colCount = firstRow.getChildrenSize()

          for (let i = 0; i < colCount; i++) {
            const col = document.createElement("col")
            colGroup.append(col)
          }

          newElement.replaceChildren(colGroup, tBody)

          return newElement
        }
      },
    }
  }

  isIsolated() {
    return true
  }
  decorate(editor) {
    const headingColumns = []
    const rows = []
    function findText(obj) {
      if (obj && typeof obj === "object") {
        if (obj.text !== undefined) {
          return String(obj.text)
        } else {
          for (let key in obj) {
            const result = findText(obj[key])
            if (result !== "") {
              return result
            }
          }
        }
      }
      return ""
    }
    this.__nodes.map((row, index) => {
      if (index === 0) {
        row.children.map((headerColumn, index) => {
          headingColumns.push({
            accessor: `accessor-${index}`,
            content: headerColumn,
            backgroundColor: headerColumn.backgroundColor,
            width: headerColumn?.width,
            direction: headerColumn?.children ? headerColumn?.children[0]?.direction : "",
          })
          return undefined
        })
      }
      if (index !== 0) {
        const columns = {}
        row.children.map((column, i) => {
          columns[`accessor-${i}`] = [
            findText(column),
            {
              accessor: `accessor-${i}`,
              text: findText(column),
              content: column,
              backgroundColor: column.backgroundColor,
              width: column.width,
              direction: column.children[0].direction,
            },
          ]
          return undefined
        })
        rows.push(columns)
      }
      return undefined
    })

    return (
      <TableComponent
        nodes={this.__nodes}
        editor={editor}
        headingColumns={headingColumns}
        rows={rows}
      />
    )
  }

  // TODO 0.10 deprecate
  canExtractContents() {
    return false
  }

  canBeEmpty() {
    return false
  }

  isShadowRoot() {
    return true
  }

  canSelectBefore() {
    return true
  }

  canIndent() {
    return false
  }
}

export function convertTableElement(domNode) {
  return { node: $createTableNode() }
}

export function $createTableNode(nodes) {
  return $applyNodeReplacement(new TableNode(nodes))
}

export function $isTableNode(node) {
  return node instanceof TableNode
}
