import React, { useState, useEffect, useCallback } from "react"

import { Box, Text, Stack } from "@mos-cat/ds"
import { Button, TextLink, ClickableField, apiClient, HubIcon } from "@dit/core-frontend"

import isEqual from "lodash/isEqual"
import { API_ENDPOINTS } from "@constants"

const API_ULD_BY_TYPE = {
  projects: API_ENDPOINTS.moshubProject,
  groups: API_ENDPOINTS.moshubGroup,
}

const HubLink = ({ type = "projects", items = [] }) => {
  const [links, setLinks] = useState([])
  const [itemSlugs, setItemSlugs] = useState([])

  const getLinks = useCallback(async () => {
    if (!itemSlugs?.length) {
      setLinks([])
      return
    }

    try {
      const apiUrl = API_ULD_BY_TYPE[type]
      const { data } = await apiClient.get(apiUrl, {
        params: { slug: itemSlugs },
      })
      setLinks(data.data.itemsList || [])
    } catch (err) {
      console.error(err)
      setLinks([])
    }
  }, [itemSlugs, type])

  useEffect(() => {
    const newIdsList = items.reduce((acc, item) => {
      return acc.includes(item.slug) ? acc : [...acc, item.slug]
    }, [])

    if (!isEqual(newIdsList, itemSlugs)) {
      setItemSlugs(newIdsList)
    }
  }, [items, itemSlugs])

  useEffect(() => {
    void getLinks()
  }, [getLinks])

  if (!links.length) {
    return null
  }

  const linkContent = (
    <Text as={Box} display="inline-flex" alignItems="center" gridGap="5px" color="var(--blue-60)">
      <HubIcon size="24" color="currentColor" />
      <Text fontSize="14px" color="inherit" fontWeight="500">
        В репозиторий
      </Text>
    </Text>
  )

  return (
    <Box mb={["10px", "20px"]} mt={["-10px", "-25px"]}>
      {links.length === 1 ? (
        // <a href={links[0].url} target="_blank" rel="noopener noreferrer">
        <a href={links[0].url}>
          <Button kind="borderless">{linkContent}</Button>
        </a>
      ) : (
        <ClickableField
          height="34px"
          padding="5px 10px !important"
          marginLeft="-10px"
          kind="borderless"
          backgroundColor="transparent"
          display="inline-block"
          PopoverComp={
            <Stack
              space="10px"
              maxHeight="180px"
              overflow="auto"
              margin="-10px -15px -10px 0"
              padding="10px 15px 10px 0"
            >
              {links.map((link) => {
                return (
                  <TextLink
                    key={link.slug}
                    as="a"
                    href={link.url}
                    // target="_blank"
                    // rel="noopener noreferrer"
                  >
                    <Text
                      as={Box}
                      overflow="hidden"
                      styles={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {link.title}
                    </Text>
                  </TextLink>
                )
              })}
            </Stack>
          }
        >
          {() => linkContent}
        </ClickableField>
      )}
    </Box>
  )
}

export default HubLink
