import { ActionsPopover, Button } from "@dit/core-frontend"
import React, { useCallback } from "react"
import { buttonProps } from "./buttonProps"
import { Box, Text } from "@mos-cat/ds"
import {
  DeleteFormatIcon,
  MatchCaseIcon,
  StrikethroughIcon,
  SubScriptIcon,
  SuperScriptIcon,
} from "../../theme/editorIcons"
import { ArrowDown } from "@mos-cat/ds-icons"
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  FORMAT_TEXT_COMMAND,
} from "lexical"
import { $getNearestBlockElementAncestorOrThrow } from "@lexical/utils"
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode"

import { $isHeadingNode, $isQuoteNode } from "@lexical/rich-text"
const TextFormatDropdown = ({ activeEditor }) => {
  const clearFormatting = useCallback(() => {
    activeEditor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        const anchor = selection.anchor
        const focus = selection.focus
        const nodes = selection.getNodes()

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            // Use a separate variable to ensure TS does not lose the refinement
            let textNode = node
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode
            }

            if (textNode.__style !== "") {
              textNode.setStyle("")
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0)
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat("")
            }
            node = textNode
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true)
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat("")
          }
        })
      }
    })
  }, [activeEditor])
  return (
    <ActionsPopover
      content={
        <Button
          {...buttonProps}
          kind="borderless"
          vertSize="30"
          width="30px"
          display="flex"
          justifyContent="start"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <Text fontSize="fs-14">
              <MatchCaseIcon />
            </Text>
            <Text fontSize="fs-14">
              <ArrowDown />
            </Text>
          </Box>
        </Button>
      }
    >
      {(buttonProps) => (
        <>
          <Button
            {...buttonProps}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")
            }}
          >
            <StrikethroughIcon />
            <Text>Зачеркнутый</Text>
          </Button>

          <Button
            {...buttonProps}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript")
            }}
          >
            <SuperScriptIcon />
            <Text>Надстрочный</Text>
          </Button>
          <Button
            {...buttonProps}
            onClick={() => {
              activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript")
            }}
          >
            <SubScriptIcon />
            <Text>Подстрочный</Text>
          </Button>
          <Button {...buttonProps} {...buttonProps} onClick={clearFormatting}>
            <DeleteFormatIcon />
            <Text>Очистка формата</Text>
          </Button>
        </>
      )}
    </ActionsPopover>
  )
}

export default TextFormatDropdown
