import { Spinner } from "@dit/core-frontend"
import { BlockWithAlignableContents } from "@lexical/react/LexicalBlockWithAlignableContents"
import { DecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode"
import { Box } from "@mos-cat/ds"
import * as React from "react"

function FigmaComponent({ className, format, nodeKey, documentID }) {
  const [loading, setLoading] = React.useState(true)

  const handleLoad = () => {
    setLoading(false)
  }
  return (
    <BlockWithAlignableContents className={className} format={format} nodeKey={nodeKey}>
      {loading && (
        <Box width="560px" height="315px" position="relative" minHeight="25px">
          <Spinner space="sp-16" wrapper="div" />
        </Box>
      )}
      <iframe
        width={loading ? "0" : "560"}
        height={loading ? "0" : "315"}
        onLoad={handleLoad}
        src={`https://www.figma.com/embed?embed_host=wiki&url=\
          https://www.figma.com/file/${documentID}`}
        allowFullScreen={true}
      />
    </BlockWithAlignableContents>
  )
}

export class FigmaNode extends DecoratorBlockNode {
  __id

  static getType() {
    return "figma"
  }

  static clone(node) {
    return new FigmaNode(node.__id, node.__format, node.__key)
  }

  static importJSON(serializedNode) {
    const node = $createFigmaNode(serializedNode.documentID)
    node.setFormat(serializedNode.format)
    return node
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      documentID: this.__id,
      type: "figma",
      version: 1,
    }
  }

  constructor(id, format, key) {
    super(format, key)
    this.__id = id
  }

  updateDOM() {
    return false
  }

  getId() {
    return this.__id
  }

  getTextContent() {
    return `https://www.figma.com/file/${this.__id}`
  }

  decorate(_editor, config) {
    const embedBlockTheme = config.theme.embedBlock || {}
    const className = {
      base: embedBlockTheme.base || "",
      focus: embedBlockTheme.focus || "",
    }
    return (
      <FigmaComponent
        className={className}
        format={this.__format}
        nodeKey={this.getKey()}
        documentID={this.__id}
      />
    )
  }
}

export function $createFigmaNode(documentID) {
  return new FigmaNode(documentID)
}

export function $isFigmaNode(node) {
  return node instanceof FigmaNode
}
