import { modulesLinks } from "@constants/modulesLinks"

export const API_ENDPOINTS = {
  me: `${modulesLinks.wiki}/api/me`,
  zone: `${modulesLinks.wiki}/api/zone`,
  zoneList: `${modulesLinks.wiki}/api/zones`,
  users: `${modulesLinks.wiki}/api/users`,
  page: `${modulesLinks.wiki}/api/page`,
  pageList: `${modulesLinks.wiki}/api/pages`,
  pageStatus: `${modulesLinks.wiki}/api/page-status`,
  pageStatusList: `${modulesLinks.wiki}/api/page-statuses`,
  favorites: `${modulesLinks.wiki}/api/favorites`,
  subscription: `${modulesLinks.wiki}/api/subscription`,

  file: `${modulesLinks.storage}/api/file`,

  teamMembersList: `${modulesLinks.wiki}/api/team-members`,
  teamMember: `${modulesLinks.wiki}/api/team-member`,
  rolesList: `${modulesLinks.wiki}/api/user-roles`,
  roles: `${modulesLinks.admin}/api/roles`,
  grantedRoles: `${modulesLinks.admin}/api/grantedRoles`,
  myPermissions: `${modulesLinks.passport}/api/acl/query`,
  permissions: `${modulesLinks.wiki}/api/me/permissions`,

  comment: `${modulesLinks.wiki}/api/comment`,
  commentList: `${modulesLinks.wiki}/api/comments`,

  export: `${modulesLinks.wiki}/api/export`,
  import: `${modulesLinks.wiki}/api/import`,
  textBlockComment: `${modulesLinks.wiki}/api/text-block-comments`,
  tasksListShort: `${modulesLinks.track}/api/tasks/short`,
  task: `${modulesLinks.track}/api/task`,

  // feed
  feed: `${modulesLinks.wiki}/api/dashboards/feed`,

  // Шаблоны
  pageTemplatesIcons: `${modulesLinks.wiki}/api/icons`,
  pageTemplates: `${modulesLinks.wiki}/api/page-templates`,

  // moshub
  moshubProject: `${modulesLinks.wiki}/api/moshub/project`,
  moshubGroup: `${modulesLinks.wiki}/api/moshub/group`,
}
