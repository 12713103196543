import React, { useState, useEffect } from "react"

import ModalContent from "./ModalContent"

export const CurtainModal = ({
  children,
  content,
  isOpen,
  onClose,
  wrapClass,
  contentClass,
  isCloseVisible = true,
}) => {
  const [isOpenState, setIsOpenState] = useState(isOpen || false)

  const handleOpen = () => {
    setIsOpenState(true)
  }

  const handleClose = () => {
    onClose?.()
    setIsOpenState(false)
  }

  useEffect(() => {
    setIsOpenState(isOpen)
  }, [isOpen])

  return (
    <>
      {children ? children(handleOpen) : null}
      <ModalContent
        isOpen={isOpenState}
        hide={handleClose}
        wrapClass={wrapClass}
        contentClass={contentClass}
        isCloseVisible={isCloseVisible}
      >
        {typeof content === "function" ? content(handleClose) : content}
      </ModalContent>
    </>
  )
}
